import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  Box,
  Flex,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  Link,
} from '@chakra-ui/react';

import { colors, fonts } from '../../../utils';
import { TooltipIcon, FilterIcon } from '../../../assets/icons';
import {
  StatesType,
  AgenciesWithEmailsType,
  CompaniesType,
} from '../../../types';
import { AddNewAgencyCompanyModal } from '../modals/AddNewAgencyCompanyModal';
import {
  renderTableBody,
  renderTableHeader,
} from '../../../common/helpers/table/tableRendering';
import {
  CustomButton,
  SearchInput,
  Typography,
  FilterPopup,
} from '../../../common/components';
import { NavLink } from 'react-router-dom';

interface IAgenciesTableProps {
  agencyOrderType: boolean;
  auxiliaryLoading: boolean;
  states: Array<StatesType>;
  agencySearchQuery?: string;
  agencyCheckedStates: Array<number>;
  setAgencyOrderType: Dispatch<SetStateAction<boolean>>;
  agenciesWithEmails: Array<AgenciesWithEmailsType>;
  setAgencyCheckedStates: Dispatch<SetStateAction<Array<number>>>;
  setAgencySearchQuery: Dispatch<SetStateAction<string | undefined>>;
  setAgencyOrderField?: Dispatch<SetStateAction<string | undefined>>;
  companies: CompaniesType[];
  archivedCheckedStates: number[];
  setArchivedCheckedStates: (states: number[]) => void;
}

export const AgenciesTable: React.FC<IAgenciesTableProps> = React.memo(
  ({
    agencyOrderType,
    agencySearchQuery,
    setAgencyOrderType,
    setAgencySearchQuery,
    // setAgencyOrderField,
    auxiliaryLoading,
    // agenciesWithEmails,
    companies,
    setArchivedCheckedStates,
    archivedCheckedStates,
    states,
  }) => {
    const [isAddNewAgencyModalOpen, setIsAddNewAgencyModalOpen] =
      useState(false);

    const handleAddNewAgencyCompanyModal = (isOpen: boolean) => {
      setIsAddNewAgencyModalOpen(isOpen);
    };

    const handleOrderUsers = () => {
      setAgencyOrderType(!agencyOrderType);
    };

    return (
      <Box>
        <Flex justify="space-between" align="center" p="25px 40px 40px">
          <Box d="flex" flexDir="row" alignItems="center">
            <Typography size={20} family={fonts.roboto}>
              Brokerage
            </Typography>
            <Box ml="5px">
              <Tooltip
                shouldWrapChildren
                hasArrow
                textColor="#888DAB"
                fontSize="14px"
                fontWeight="400"
                label="List of agencies with email, phone, broker name, and more."
                placement="bottom-start"
                bg="white"
                color="black"
                mt="1vh"
                p="25px"
                borderRadius="10px"
              >
                <TooltipIcon />
              </Tooltip>
            </Box>
          </Box>
          <Flex>
            <FilterPopup
              states={states}
              checkedItems={archivedCheckedStates}
              setCheckedItems={setArchivedCheckedStates}
            >
              <Box w="270px" mr="4">
                <CustomButton
                  variant="outline"
                  color={colors.gray}
                  isSmall
                  leftIcon={<FilterIcon />}
                  background="transparent"
                >
                  State
                </CustomButton>
              </Box>
            </FilterPopup>
            <SearchInput
              placeholder="Search..."
              value={agencySearchQuery}
              onChange={setAgencySearchQuery}
            />
            <Box w="120px" ml="4">
              <CustomButton
                isSmall
                onClick={() => handleAddNewAgencyCompanyModal(true)}
              >
                Add new
              </CustomButton>
            </Box>
          </Flex>
        </Flex>
        <Box p="0 20px" mb="50px">
          <Table colorScheme="blackAlpha" className="fixed">
            <Thead>
              <Tr bg="#EDF2F7">
                {renderTableHeader(colors.gray, 'Name', () =>
                  handleOrderUsers()
                )}
                {renderTableHeader(colors.gray, '')}
                <Th isNumeric textTransform="capitalize" pr="100px">
                  <Typography
                    size={16}
                    family={fonts.roboto}
                    color={colors.gray}
                  >
                    Actions
                  </Typography>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {auxiliaryLoading ? (
                <Tr pos="relative" h="200px">
                  <Td
                    border="0"
                    pos="absolute"
                    top="50%"
                    left="50%"
                    transform="translate(-50%, -50%)"
                  >
                    <Spinner size="xl" />
                  </Td>
                </Tr>
              ) : companies.length !== 0 ? (
                companies.map((item) => {
                  const receivedCompanyName = item.name.includes('%')
                    ? encodeURIComponent(encodeURIComponent(item.name))
                    : item.name;
                  return (
                    <Tr key={item.id}>
                      {renderTableBody(colors.gray, item.name, item.name)}
                      {renderTableBody(colors.gray, '')}
                      <Td isNumeric pr="100px">
                        <NavLink
                          to={`/inner-agencies/${item.id}/${receivedCompanyName}`}
                        >
                          <Typography
                            size={16}
                            family={fonts.roboto}
                            color={colors.gray}
                          >
                            Details
                          </Typography>
                        </NavLink>
                      </Td>
                    </Tr>
                  );
                })
              ) : null}
            </Tbody>
          </Table>
        </Box>
        <AddNewAgencyCompanyModal
          isOpen={isAddNewAgencyModalOpen}
          setIsModalOpen={handleAddNewAgencyCompanyModal}
        />
      </Box>
    );
  }
);
