import { createAction } from '@reduxjs/toolkit';
import {
  GetAgencyOfficesPayload,
  PayloadError,
  Office,
  EditOfficePayload,
  DeleteOfficePayload,
  AddOfficePayload,
} from '../../types';

export const getOfficesRequest = createAction<GetAgencyOfficesPayload>('GET_OFFICES_REQUEST');
export const getOfficesSuccess = createAction<{ totalCount: number, agencyOffices: Office[] }>('GET_OFFICES_SUCCESS');
export const getOfficesError = createAction<PayloadError[]>('GET_OFFICES_ERROR');

export const editOfficeRequest = createAction<EditOfficePayload>('EDIT_OFFICE_REQUEST');
export const editOfficeSuccess = createAction<number>('EDIT_OFFICE_SUCCESS');
export const editOfficeError = createAction<PayloadError[]>('EDIT_OFFICE_ERROR');

export const deleteOfficeRequest = createAction<DeleteOfficePayload>('DELETE_OFFICE_REQUEST');

export const addOfficeRequest = createAction<AddOfficePayload>('ADD_OFFICE_REQUEST');
