import {
  call, ForkEffect, put, takeEvery,
} from 'redux-saga/effects';
import API, { URL } from '../../../common/api/api.base';
import {
  uploadNewUsersRequest,
  uploadNewUsersSuccess,
  uploadNewUsersError,
  toggleInviteUserListModal,
} from '../../actions/invitation.actions';
import { IResponseWithoutValue } from '../../../types';

function* workerUploadNewUsers(action: ReturnType<typeof uploadNewUsersRequest>) {
  try {
    const result: IResponseWithoutValue = yield call(API.post, `${URL}/api/admin/upload-users`, { uploadedUsers: action.payload });
    if (result.success) {
      yield put(uploadNewUsersSuccess());
      yield put(toggleInviteUserListModal(false));
    } else {
      yield put(uploadNewUsersError(result.errors));
    }
  } catch (error) {
    console.log(error, 'workerUploadNewUsers error');
  }
}

export default function* workerUploadNewUsersSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(uploadNewUsersRequest.type, workerUploadNewUsers);
}
