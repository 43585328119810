import {
  Box,
  Flex,
  Modal,
  ModalBody,
  CloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  IconButton,
  HStack,
  VStack,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CustomButton,
  Typography,
  FilterPopup,
} from '../../../common/components';
import {
  renderTableBody,
  renderTableHeader,
} from '../../../common/helpers/table/tableRendering';
import { RootStore } from '../../../redux';
import { colors, fonts } from '../../../utils';
import { DashboardPaginator } from '../components';
import { UpDownArrowIcon, BackArrow, FilterIcon } from '../../../assets/icons';
import { GetUploadedUsersPayload, UploadedUsersResponse } from '../../../types';
import {
  getUploadedUsersRequest,
  inviteNewUsersRequest,
  deleteInviteRequest,
} from '../../../redux/actions/agency.actions';

interface IStateUserModal {
  isOpen: boolean;
  setIsModalOpen: (v: boolean) => void;
  stateId: number;
  agencyId: number;
}

enum UploadedUserFilterEnum {
  Sent = 1,
  Joined = 2,
}

const PAGE_SIZE = 50;

const StateUserModal: React.FC<IStateUserModal> = ({
  isOpen,
  setIsModalOpen,
  stateId,
  agencyId,
}) => {
  const { count, users, loading } = useSelector(
    (state: RootStore) => state.agency.uploadedUsers
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [checkedFiler, setCheckedFiler] = useState<number[]>([
    UploadedUserFilterEnum.Sent,
    UploadedUserFilterEnum.Joined,
  ]);
  const [isAscendingFilter, setIsAscendingFilter] = useState(true);

  const dispatch = useDispatch();

  const getUploadedUsers = (payload: GetUploadedUsersPayload) =>
    dispatch(getUploadedUsersRequest(payload));

  useEffect(() => {
    if (isOpen) {
      getUploadedUsers({
        stateId,
        agencyId,
        pageNumber: currentPage,
        pageSize: PAGE_SIZE,
        filter:
          checkedFiler.length !== 1
            ? ''
            : UploadedUserFilterEnum[checkedFiler[0]],
        isAsc: isAscendingFilter,
      });
    }
  }, [currentPage, isOpen, checkedFiler, isAscendingFilter]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => setIsModalOpen(false)}
        autoFocus={false}
        size="4xl"
      >
        <ModalOverlay />
        <ModalContent p="20px">
          <ModalHeader
            alignItems="center"
            d="flex"
            justifyContent="space-between"
          >
            <Flex alignItems="center">
              <IconButton
                aria-label="Back to previous modal"
                icon={<BackArrow />}
                onClick={() => setIsModalOpen(false)}
                mr="30px"
                variant="unstyled"
              />
              <Typography weight={500} size={20}>
                {`State User List (${count} users)`}
              </Typography>
            </Flex>
            <HStack>
              <FilterPopup
                states={[
                  {
                    id: UploadedUserFilterEnum.Sent,
                    name: UploadedUserFilterEnum[UploadedUserFilterEnum.Sent],
                    code: UploadedUserFilterEnum.Sent.toString(),
                  },
                  {
                    id: UploadedUserFilterEnum.Joined,
                    name: UploadedUserFilterEnum[UploadedUserFilterEnum.Joined],
                    code: UploadedUserFilterEnum.Joined.toString(),
                  },
                ]}
                checkedItems={checkedFiler}
                withoutSearch
                setCheckedItems={(value) => setCheckedFiler(value)}
              >
                <Box>
                  <CustomButton
                    variant="outline"
                    color={colors.gray}
                    isSmall
                    leftIcon={<FilterIcon />}
                    background="transparent"
                    width="auto"
                  >
                    Show all
                  </CustomButton>
                </Box>
              </FilterPopup>
              <CloseButton
                color={colors.gray}
                onClick={() => setIsModalOpen(false)}
              />
            </HStack>
          </ModalHeader>
          <ModalBody>
            <Table colorScheme="blackAlpha">
              <Thead>
                <Tr bg="#EDF2F7">
                  {renderTableHeader(colors.gray, 'Name')}
                  {renderTableHeader(colors.gray, 'Email')}
                  <Th isNumeric textTransform="capitalize">
                    <Flex align="center" justify="flex-end">
                      <Typography
                        size={16}
                        family={fonts.roboto}
                        color={colors.gray}
                      >
                        Status
                      </Typography>
                      <IconButton
                        aria-label="Status filter switch"
                        ml="10px"
                        onClick={() => setIsAscendingFilter((prev) => !prev)}
                      >
                        <UpDownArrowIcon />
                      </IconButton>
                    </Flex>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {loading ? (
                  <Tr pos="relative" h="400px">
                    <Td
                      border="0"
                      pos="absolute"
                      top="50%"
                      left="50%"
                      transform="translate(-50%, -50%)"
                    >
                      <Spinner size="xl" />
                    </Td>
                  </Tr>
                ) : count !== 0 ? (
                  users.map((item: UploadedUsersResponse) => (
                    <Tr key={item.id}>
                      {renderTableBody(
                        colors.gray,
                        `${item.firstName} ${item.lastName}`,
                        item.firstName + item.lastName
                      )}
                      {renderTableBody(colors.gray, item.email)}
                      <Td isNumeric>
                        {loading ? (
                          <Spinner size="sm" />
                        ) : item.statusId === 2 ? (
                          <VStack spacing={0} align="flex-end">
                            <CustomButton
                              width="auto"
                              onClick={() =>
                                dispatch(
                                  deleteInviteRequest({
                                    userId: item.id,
                                    isInvited: true,
                                    agencyId,
                                    stateId,
                                  })
                                )
                              }
                              background="white"
                              color={colors.primary}
                              fontFamily={fonts.roboto}
                              fontSize={16}
                              textDecoration="underline"
                              height="30px"
                            >
                              Delete Invite
                            </CustomButton>
                            <CustomButton
                              width="auto"
                              onClick={() =>
                                dispatch(
                                  inviteNewUsersRequest({
                                    userId: item.id,
                                    isInvited: true,
                                    agencyId,
                                    stateId,
                                  })
                                )
                              }
                              background="white"
                              color={colors.primary}
                              fontFamily={fonts.roboto}
                              fontSize={16}
                              textDecoration="underline"
                              height="30px"
                            >
                              Reinvite
                            </CustomButton>
                          </VStack>
                        ) : item.statusId === 3 ? (
                          <Typography
                            size={16}
                            family={fonts.roboto}
                            color={colors.green2}
                          >
                            Joined
                          </Typography>
                        ) : (
                          <CustomButton
                            width="auto"
                            onClick={() =>
                              dispatch(
                                inviteNewUsersRequest({
                                  userId: item.id,
                                  isInvited: false,
                                  agencyId,
                                  stateId,
                                })
                              )
                            }
                            background="white"
                            color={colors.primary}
                            fontFamily={fonts.roboto}
                            fontSize={16}
                            textDecoration="underline"
                            isSmall
                          >
                            Invite
                          </CustomButton>
                        )}
                      </Td>
                    </Tr>
                  ))
                ) : (
                  count === 0 && (
                    <Tr pos="relative" h="400px">
                      <Td
                        border="0"
                        pos="absolute"
                        top="50%"
                        left="50%"
                        transform="translate(-50%, -50%)"
                      >
                        <Typography weight={500} size={20}>
                          List is Empty
                        </Typography>
                      </Td>
                    </Tr>
                  )
                )}
              </Tbody>
            </Table>
            <DashboardPaginator
              pageSize={PAGE_SIZE}
              currentPage={currentPage}
              membersLoading={loading}
              usersTotalCount={count}
              setCurrentPage={setCurrentPage}
            />
            <Flex
              wrap="wrap"
              justify="space-between"
              mt={count === 8 ? '55px' : '10px'}
            >
              <CustomButton
                background="transparent"
                variant="outline"
                color={colors.gray}
                isSmall
                w="45%"
                mb="10px"
                onClick={() => setIsModalOpen(false)}
              >
                Back
              </CustomButton>
              <CustomButton
                w="45%"
                mb="10px"
                isSmall
                onClick={() =>
                  dispatch(
                    inviteNewUsersRequest({
                      userId: '',
                      isInvited: true,
                      agencyId,
                      stateId,
                    })
                  )
                }
                isDisabled={!users.length}
              >
                Resend Invite for All Unjoined
              </CustomButton>
              <CustomButton
                isSmall
                w="45%"
                mb="10px"
                onClick={() =>
                  dispatch(
                    deleteInviteRequest({
                      userId: '',
                      isInvited: true,
                      agencyId,
                      stateId,
                    })
                  )
                }
                isDisabled={!users.length}
              >
                Delete Invite for All Unjoined
              </CustomButton>
              <CustomButton
                isSmall
                w="45%"
                mb="10px"
                onClick={() =>
                  dispatch(
                    inviteNewUsersRequest({
                      userId: '',
                      isInvited: false,
                      agencyId,
                      stateId,
                    })
                  )
                }
                isDisabled={!users.length}
              >
                Send Invite for All Unjoined
              </CustomButton>
            </Flex>
          </ModalBody>
          {/* </>
          )} */}
        </ModalContent>
      </Modal>
    </>
  );
};

export default StateUserModal;
