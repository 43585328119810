import React, { useState } from 'react';

import {
  Box,
  Input,
  Text,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  FormControl,
  InputLeftAddon,
  TextProps,
  FormErrorMessage,
} from '@chakra-ui/react';

import { colors, fonts } from '../../utils';

import { ClosedEyeIcon, EyeIcon } from '../../assets/icons';

interface ICustomInputProps {
  labelProps?: TextProps;
  props?: any;
  label?: string;
  width?: string | { base?: string; sm?: string; md?: string; lg?: string };
  leftIcon?: any;
  rightIcon?: any;
  placeholder?: string;
  margin?: string | number;
  register?: any;
  errors?: any;
  isPassword?: boolean;
  bgColor?: string;
  isBorder?: boolean;
  disabled?: boolean;
  defaultValue?: string | number;
  isReadOnly?: boolean;
  color?: string;
  labelColor?: string;
  mask?: string;
  pattern?: string | RegExp;
  value?: string;
  cursor?: string;
  onChange?: (arg0: any) => void;
  fontWeight?: number;
  fontSize?: string;
  fontFamily?: string;
  textSize?: string;
  placeholderColor?: string;
  height?: string;
  borderRadius?: string;
  textTransform?: string;
  maxLength?: number;
  type?:
    | 'none'
    | 'text'
    | 'tel'
    | 'url'
    | 'email'
    | 'number'
    | 'decimal'
    | 'search'
    | 'password'
    | 'time'
    | undefined;
}

const CustomInput = React.forwardRef<HTMLInputElement, ICustomInputProps>(
  (
    {
      maxLength,
      onChange,
      type,
      label,
      width,
      leftIcon,
      rightIcon,
      props,
      placeholder,
      errors,
      isPassword,
      mask,
      disabled,
      defaultValue,
      isReadOnly = false,
      labelProps,
      placeholderColor,
      margin,
      register,
      value,
      color = colors.primary,
      labelColor = colors.primary,
      cursor = 'text',
      isBorder = true,
      bgColor = '#F6F7FB',
      fontWeight = '400',
      fontSize = '14px',
      fontFamily = fonts.poppins,
      textSize = '14px',
      height = '50px',
      borderRadius = '20px',
      textTransform = 'none',
    },
    ref
  ) => {
    const [showPassword, setShowPassword] = useState(false);

    const setType = () => {
      if (isPassword) {
        if (showPassword) {
          return 'text';
        } else {
          return 'password';
        }
      } else {
        return type;
      }
    };

    return (
      <Box w={width} textAlign="left" color="#fff" m={margin}>
        <FormControl isInvalid={Boolean(errors?.message)}>
          {label && (
            <Text
              textTransform={'capitalize'}
              color={labelColor}
              fontWeight={fontWeight}
              fontSize={fontSize}
              fontFamily={fontFamily}
              {...labelProps}
            >
              {label}
            </Text>
          )}
          <InputGroup>
            {leftIcon && (
              <InputLeftElement h={height} w={height} children={leftIcon} />
            )}
            {type === 'tel' && (
              <InputLeftAddon
                h={height}
                children="+1"
                borderRadius={borderRadius}
                color={colors.primary}
                fontSize="14px"
                borderWidth={isBorder ? '1px' : '0'}
              />
            )}
            <Input
              maxLength={maxLength}
              ref={ref}
              textTransform={textTransform}
              type={setType()}
              h={height}
              cursor={cursor}
              bg={bgColor}
              borderRadius={borderRadius}
              borderWidth={isBorder ? '1px' : '0'}
              color={color}
              placeholder={placeholder}
              _placeholder={{
                color: placeholderColor ? 'black' : null,
              }}
              disabled={disabled}
              defaultValue={defaultValue}
              value={value}
              isReadOnly={isReadOnly}
              focusBorderColor={isReadOnly && 'none'}
              onChange={onChange ? onChange : null}
              fontWeight={fontWeight}
              fontSize={textSize}
              fontFamily={fontFamily}
              boxShadow={'none'}
              borderColor={'inherit'}
              _hover={isReadOnly ? 'none' : null}
              _focus={isReadOnly ? 'none' : null}
              {...register}
              {...props}
            />
            {isPassword && (
              <InputRightElement
                onClick={() => setShowPassword(!showPassword)}
                h="100%"
                mr="5px"
                zIndex="10"
                children={showPassword ? <EyeIcon /> : <ClosedEyeIcon />}
              />
            )}
            {rightIcon && !isReadOnly && (
              <InputRightElement
                zIndex={0}
                h="100%"
                mr="5px"
                children={rightIcon}
              />
            )}
          </InputGroup>
          {errors?.message && (
            <FormErrorMessage fontSize="1.2vh">
              {errors.message}
            </FormErrorMessage>
          )}
        </FormControl>
      </Box>
    );
  }
);

CustomInput.displayName = 'CustomInput';

export { CustomInput };
