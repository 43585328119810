import {
  call, ForkEffect, put, takeEvery,
} from 'redux-saga/effects';

import ApiBase, { URL } from '../../../common/api/api.base';
import { getStatesByAgency, getStatesByAgencySuccess, getStatesByAgencyError } from '../../actions/auxiliary.actions';
import { IResponseWithValue, StatesType } from '../../../types';

function* workerGetStatesByAgency(action: ReturnType<typeof getStatesByAgency>) {
  try {
    const result: IResponseWithValue<{
      states: StatesType[]
    }> = yield call(ApiBase.get, `${URL}/api/admin/states-by-agency?Id=${action.payload}`);
    yield console.log('getStates', action, result);
    if (result.success) {
      yield put(getStatesByAgencySuccess(result.value.states));
    } else {
      yield put(getStatesByAgencyError(result.errors));
    }
  } catch (error) {
    yield put(getStatesByAgencyError(error));
    console.log('getStatesError', error);
  }
}

export default function* watchGetStatesByAgencySaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(getStatesByAgency.type, workerGetStatesByAgency);
}
