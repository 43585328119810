import React from 'react';

export const SearchIcon = () => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3592 12.1808L16.9283 15.7492L15.7492 16.9283L12.1808 13.3592C10.8531 14.4235 9.20167 15.0024 7.5 15C3.36 15 0 11.64 0 7.5C0 3.36 3.36 0 7.5 0C11.64 0 15 3.36 15 7.5C15.0024 9.20167 14.4235 10.8531 13.3592 12.1808ZM11.6875 11.5625C12.7451 10.4749 13.3357 9.01702 13.3333 7.5C13.3333 4.27667 10.7225 1.66667 7.5 1.66667C4.27667 1.66667 1.66667 4.27667 1.66667 7.5C1.66667 10.7225 4.27667 13.3333 7.5 13.3333C9.01702 13.3357 10.4749 12.7451 11.5625 11.6875L11.6875 11.5625Z"
      fill="#CBD5E0"
    />
  </svg>
);
