import { configureStore, getDefaultMiddleware, Store } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  Persistor,
} from 'redux-persist';
import { routerMiddleware } from 'connected-react-router';

import { createBrowserHistory } from 'history';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import rootSlice from './slices/root.slice';
import rootSaga from './sagas/root.saga';

type CreateStore = {
  store: Store;
  persistor: Persistor;
};

export const history = createBrowserHistory();

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['session'],
};

const persistedSlice = persistReducer(persistConfig, rootSlice(history));

const createStore = (): CreateStore => {
  const sagaMiddleware = createSagaMiddleware();

  const store = configureStore({
    reducer: persistedSlice,
    middleware: [
      sagaMiddleware,
      routerMiddleware(history),
      ...getDefaultMiddleware({
        thunk: false,
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
    ],
  });

  sagaMiddleware.run(rootSaga);

  const persistor = persistStore(store);

  return { store, persistor };
};

export default createStore;
