import {
  call, ForkEffect, put, takeEvery,
} from 'redux-saga/effects';
import {
  updateUserProfile,
  updateUserProfileError,
  updateUserProfileSuccess,
} from '../../actions/licenses.actions';
import ApiBase, { URL } from '../../../common/api/api.base';
import { IResponseWithoutValue } from '../../../types';

function* workerUpdateUserProfile(action: ReturnType<typeof updateUserProfile>) {
  try {
    const result: IResponseWithoutValue = yield call(ApiBase.put, `${URL}/api/admin/update-user-profile`, action.payload);
    yield console.log('updateUserProfile', action, result);
    if (result.success) {
      yield put(updateUserProfileSuccess());
    } else {
      yield put(updateUserProfileError(result.errors));
    }
  } catch (error) {
    // yield put(updateUserProfileError());
    console.log(error);
  }
}

export default function* watchUpdateUserProfile(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(updateUserProfile.type, workerUpdateUserProfile);
}
