import {
  call, ForkEffect, put, takeEvery,
} from 'redux-saga/effects';

import ApiBase, { URL } from '../../../common/api/api.base';
import {
  getAgenciesWithEmails,
  getAgenciesWithEmailsError,
  getAgenciesWithEmailsSuccess,
} from '../../actions/agency.actions';
import { IResponseWithValue, AgenciesWithEmailsType } from '../../../types';

function* workerGetAgenciesWithEmails(action: ReturnType<typeof getAgenciesWithEmails>) {
  const statesNumber = action.payload.States.map((state) => `&States=${state}`);
  const states = action.payload.States.length !== 0 ? statesNumber.join('') : '';
  const searchQuery = action.payload.SearchQuery ? `&SearchQuery=${action.payload.SearchQuery}` : '';
  const orderField = action.payload.OrderField ? `&OrderField=${action.payload.OrderField}` : '';
  // console.log(`${URL}/api/admin/companies/${action.payload.companyId}/agencies?PageNumber=${action.payload.PageNumber}&PageSize=${action.payload.PageSize}&OrderType=${action.payload.OrderType}${orderField}${states}${searchQuery}`)
  try {
    const result: IResponseWithValue<{
      agencies: AgenciesWithEmailsType[];
      totalCount: number;
    }> = yield call(ApiBase.get, `${URL}/api/admin/companies/agencies?States=${action.payload.StateIds}&PageNumber=${action.payload.PageNumber}&PageSize=${action.payload.PageSize}&OrderType=${action.payload.OrderType}${orderField}${states}${searchQuery}`);
    // yield console.log('getAgenciesWithEmails', action, result);
    if (result.success) {
      yield put(getAgenciesWithEmailsSuccess(result));
    } else {
      yield put(getAgenciesWithEmailsError(result.errors));
    }
  } catch (error: any) {
    yield put(getAgenciesWithEmailsError(error));
    console.log('getAgenciesWithEmailsError', error);
  }
}

export default function* watchGetAgenciesWithEmailsSaga():
Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(getAgenciesWithEmails.type, workerGetAgenciesWithEmails);
}
