import React from 'react';

export const AccountIcon = () => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.4">
      <path
        d="M8.76868 0C7.15241 0 5.57244 0.479279 4.22856 1.37723C2.88468 2.27518 1.83726 3.55147 1.21874 5.04471C0.600221 6.53795 0.438388 8.18107 0.753706 9.76628C1.06902 11.3515 1.84733 12.8076 2.99021 13.9505C4.13308 15.0934 5.58919 15.8717 7.17441 16.187C8.75962 16.5023 10.4027 16.3405 11.896 15.7219C13.3892 15.1034 14.6655 14.056 15.5635 12.7121C16.4614 11.3682 16.9407 9.78827 16.9407 8.172C16.9333 6.00692 16.07 3.93263 14.539 2.40168C13.0081 0.870726 10.9338 0.00738041 8.76868 0V0ZM8.76868 2.452C9.09198 2.44605 9.41317 2.50527 9.71309 2.62613C10.013 2.747 10.2855 2.92703 10.5143 3.15549C10.7432 3.38395 10.9237 3.65616 11.045 3.95588C11.1664 4.2556 11.2261 4.57669 11.2207 4.9C11.2271 5.22389 11.168 5.54574 11.0471 5.84628C10.9262 6.14682 10.7458 6.41987 10.5169 6.64908C10.288 6.87829 10.0151 7.05896 9.71475 7.18025C9.41436 7.30155 9.09258 7.36098 8.76868 7.355C8.44487 7.36085 8.1232 7.30131 7.82292 7.17995C7.52265 7.0586 7.24994 6.87791 7.02112 6.64872C6.79229 6.41952 6.61205 6.14652 6.49119 5.84605C6.37032 5.54557 6.31131 5.22381 6.31768 4.9C6.31226 4.57678 6.37198 4.25577 6.49327 3.95612C6.61456 3.65646 6.79495 3.3843 7.02367 3.15585C7.2524 2.92741 7.52478 2.74735 7.82458 2.62643C8.12439 2.5055 8.44547 2.44618 8.76868 2.452ZM8.76868 14.052C7.80104 14.0465 6.84927 13.8055 5.99558 13.3499C5.14188 12.8943 4.4119 12.2378 3.86868 11.437C3.86868 9.803 7.13768 8.904 8.76868 8.904C10.3997 8.904 13.6687 9.804 13.6687 11.437C13.1259 12.2385 12.3961 12.8959 11.5424 13.3522C10.6887 13.8085 9.73669 14.05 8.76868 14.056V14.052Z"
        fill="#2C335C"
      />
    </g>
  </svg>
);
