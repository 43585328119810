import {
  call, ForkEffect, put, takeEvery,
} from 'redux-saga/effects';
import ApiBase, { URL } from '../../../common/api/api.base';
import { getAgency, getAgencyError, getAgencySuccess } from '../../actions/agency.actions';
import { IResponseWithValue, AgencyType } from '../../../types';

function* workerGetAgency(action: ReturnType<typeof getAgency>) {
  try {
    const result: IResponseWithValue<{ agency: AgencyType }> = yield call(ApiBase.get, `${URL}/api/admin/agencies/${action.payload}`);
    yield console.log('getAgency', action, result);
    if (result.success) {
      yield put(getAgencySuccess(result));
    } else {
      yield put(getAgencyError(result.errors));
    }
  } catch (error) {
    yield put(getAgencyError(error));
    console.log(error);
  }
}

export default function* watchGetAgencySaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(getAgency.type, workerGetAgency);
}
