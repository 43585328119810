import { ComponentStyleConfig } from '@chakra-ui/theme';

export const Badge: ComponentStyleConfig = {
  baseStyle: {
    fontSize: '12px',
    fontWeight: '400',
    textTransform: 'capitalize',
    color: 'white',
    borderRadius: '4px',
    py: '3px',
    px: '8px',
  },
  variants: {
    primary: {
      bg: 'active',
    },
    warn: {
      bg: 'orange',
    },
    error: {
      bg: 'darkError',
    },
  },
  defaultProps: {
    variant: 'primary',
  },
};
