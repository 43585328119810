export const colors = {
  primary: '#2C335C',
  white: '#FFFFFF',
  ghost: '#F6F7FB',
  active: '#4FD1C5',
  green: '#68D391',
  gray: '#888DAB',
  error: '#FE805C',
  orange: '#FE805C',
  green2: '#27AE60',
  darkError: '#EB5757',
};

export interface IColors {
  primary: string;
  white: string;
  ghost: string;
  active: string;
  green: string;
  gray: string;
  error: string;
  darkError: string;
  orange: string;
  green2: string;
}
