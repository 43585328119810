import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import './App.css';
import SignIn from './pages/Auth/SignIn/SignIn.container';
import PrivateRoute from './common/helpers/routing/PrivateRoute';
import ForgotPassword from './pages/Auth/ForgotPassword/ForgotPassword.container';
import { MainLayout } from './pages/Dashboard/MainLayout';
import {
  ActiveUsers,
  PendingApprovals,
  NewApplications,
  SuspendedUsers,
  Agencies,
  UpdatesReports,
  Invitation,
  DataImport,
  ArchivedUsers,
  InnerAgencies,
  Advertisment
} from './pages/Dashboard/pages';
import { SettingsPage } from './pages/Dashboard/pages/SettingsPage';

const App: React.FC = () => (
  <Switch>
    <Route path="/auth" component={SignIn} />
    <Route path="/password" component={ForgotPassword} />
    <Suspense fallback={<div>loading ...</div>}>
      <MainLayout>
        <Switch>
          <PrivateRoute path="/active-users" component={ActiveUsers} />
          <PrivateRoute path="/settings" component={SettingsPage} />
          <PrivateRoute path="/pending-approvals" component={PendingApprovals} />
          <PrivateRoute path="/new-applications" component={NewApplications} />
          <PrivateRoute path="/suspended-users" component={SuspendedUsers} />
          <PrivateRoute path="/agencies" component={Agencies} />
          <PrivateRoute path="/inner-agencies" component={InnerAgencies} />
          <PrivateRoute path="/updates-reports" component={UpdatesReports} />
          <PrivateRoute path="/advertisment" component={Advertisment} />
          <PrivateRoute path="/invitation" component={Invitation} />
          <PrivateRoute path="/data-import" component={DataImport} />
          <PrivateRoute path="/archived-users" component={ArchivedUsers} />
          <Redirect to="/active-users" from="*" exact />
        </Switch>
      </MainLayout>
    </Suspense>
    {/* <PrivateRoute path="/app" component={Dashboard} /> */}
  </Switch>
);

export default App;
