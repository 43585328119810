import React from 'react';

export const CallIcon = () => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.4"
      d="M14.1209 10.392C14.0819 10.361 11.2209 8.292 10.4309 8.444C10.0559 8.51 9.84086 8.766 9.41086 9.279C9.34186 9.362 9.17486 9.56 9.04586 9.701C8.77385 9.61233 8.50851 9.50439 8.25186 9.378C6.92669 8.73286 5.856 7.66216 5.21086 6.337C5.08438 6.08038 4.97644 5.81504 4.88786 5.543C5.02886 5.413 5.22786 5.243 5.31186 5.175C5.82186 4.747 6.07786 4.532 6.14486 4.156C6.28086 3.377 4.21786 0.493 4.19586 0.467C4.10238 0.333199 3.98031 0.221876 3.83848 0.141103C3.69665 0.0603292 3.53862 0.01213 3.37586 0C2.53886 0 0.151855 3.1 0.151855 3.619C0.151855 3.649 0.195855 6.73 3.99486 10.595C7.85586 14.395 10.9369 14.434 10.9669 14.434C11.4889 14.434 14.5859 12.047 14.5859 11.21C14.5738 11.0478 14.5259 10.8904 14.4457 10.7489C14.3654 10.6075 14.2549 10.4856 14.1219 10.392H14.1209Z"
      fill="#2C335C"
    />
  </svg>
);
