import {
  takeEvery, ForkEffect, call, put,
} from 'redux-saga/effects';
import ApiBase, { URL } from '../../../common/api/api.base';
import { getIdLicense, getIdLicenseError, getIdLicenseSuccess } from '../../actions/licenses.actions';
import { IResponseWithValue, LicenseModelType } from '../../../types';

function* workerGetIdLicense(action: ReturnType<typeof getIdLicense>) {
  try {
    const result: IResponseWithValue<{ licenseModel: LicenseModelType }> = yield call(ApiBase.get, `${URL}/api/admin/get-id-license`, action.payload);
    yield console.log('getIdLicense', action, result);
    if (result.success) {
      yield put(getIdLicenseSuccess(result));
    } else {
      yield put(getIdLicenseError(result.errors));
    }
  } catch (error) {
    yield put(getIdLicenseError(error));
    console.log(error);
  }
}

export default function* watchGetIdLicenseSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(getIdLicense.type, workerGetIdLicense);
}
