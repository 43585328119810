import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SideTextEnum } from '../../../../common/const/sideBar';
import { RootStore } from '../../../../redux';
import { getAgenciesAll } from '../../../../redux/actions/auxiliary.actions';
import { getMembers } from '../../../../redux/actions/members.actions';
import { DashboardPaginator, NewDashboardTable } from '../../components';
import AdvertismentTab from './tabs/AdvertismentTab';

interface Icomponent {
  agencyId: number;
}

const PAGE_SIZE = 50;

export const AgencyAgentsTable: React.FC<Icomponent> = ({
  agencyId
}) => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [nameValue, setNameValue] = useState('');
  const [orderType, setOrderType] = useState(true);
  const [orderField, setOrderField] = useState<string | undefined>();
  const [memberCheckedStates, setMemberCheckedStates] = useState<number[]>([]);
  const [checkedAgencies, setcheckedAgencies] = useState<number[]>([]);
  const { usersTotalCount, loading: membersLoading } = useSelector(
    (state: RootStore) => state.members
  );
  
  const { licensesInfo} = useSelector(
    (state: RootStore) => state.licenses
  );

  const [changeTable, setChangeTable] = useState(1);

  useEffect(() => {
    dispatch(getAgenciesAll());
  }, []);

  useEffect(() => {
    dispatch(
      getMembers({
        OrderField: orderField,
        OrderType: orderType,
        PageNumber: currentPage,
        PageSize: PAGE_SIZE,
        States: memberCheckedStates,
        Name: nameValue,
        IsIdLicenseVerified: true,
        IsAllLicensesVerified: null,
        agencies: [agencyId],
      })
    );
  }, [currentPage, nameValue, orderType, orderField, memberCheckedStates, licensesInfo, changeTable]);

  return (
    <>
      <NewDashboardTable
        allowBtnArchive={false}
        allowBtnLogin={false}
        checkedStates={memberCheckedStates}
        setCheckedStates={setMemberCheckedStates}
        nameValue={nameValue}
        orderType={orderType}
        setNameValue={setNameValue}
        setOrderField={setOrderField}
        title={SideTextEnum.WorkMembers}
        setOrderType={setOrderType}
        setChangeTable={setChangeTable}
        tooltip="List of currently verified and active users within the system."
        showBadge={false}
      />
      <DashboardPaginator
        pageSize={PAGE_SIZE}
        currentPage={currentPage}
        membersLoading={membersLoading}
        setCurrentPage={setCurrentPage}
        usersTotalCount={usersTotalCount}
      />
    </>
  );
};
