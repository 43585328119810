import { createAction } from '@reduxjs/toolkit';

import {
  IResponseWithoutValue,
  PayloadError,
  IResponseWithValue,
  GetAgenciesForPartnershipPayload,
  AddPartnerToAgencyPayload,
  RemovePartnerFromAgencyPayload,
  EditAgencyEmailPayload,
  GetAgenciesWithEmailsPayload,
  EditAgencyDetailsPayload,
  AddNewAgencyPayload,
  ChangeAgencyLogoPayload,
  UploadCSVPayload,
  GetUploadedUsersPayload,
  UploadedUsersResponse,
  SaveAgencyStatesPayload,
  AgencyPartnershipDetails,
  AgenciesWithEmailsType,
  AgencyType,
  InviteUserPayload,
  EditPartnerAgencyChoicePayload,
  AddPartnerToAgencySuccessPayload,
  AddNewAgencyCompanyPayload,
  GetCompaniesPayload,
  AddOfficePayload,
} from '../../types';

export const getAgency = createAction<number>('GET_AGENCY');
export const getAgencySuccess = createAction<IResponseWithValue<{ agency: AgencyType }>>('GET_AGENCY_SUCCESS');
export const getAgencyError = createAction<PayloadError[]>('GET_AGENCY_ERROR');

export const getAgenciesWithEmails = createAction<GetAgenciesWithEmailsPayload>('GET_AGENCIES_WITH_EMAILS');
export const getAgenciesWithEmailsSuccess = createAction<IResponseWithValue<{
  agencies: AgenciesWithEmailsType[];
  totalCount: number;
}>>('GET_AGENCIES_WITH_EMAILS_SUCCESS');
export const getAgenciesWithEmailsError = createAction<PayloadError[]>('GET_AGENCIES_WITH_EMAILS_ERROR');

export const getAgenciesForPartnership = createAction<GetAgenciesForPartnershipPayload>('GET_AGENCIES_FOR_PARTNERSHIP');
export const getAgenciesForPartnershipSuccess = createAction<IResponseWithValue<{ agencies: AgencyPartnershipDetails[]; count: number }>>('GET_AGENCIES_FOR_PARTNERSHIP_SUCCESS');
export const getAgenciesForPartnershipError = createAction<PayloadError[]>('GET_AGENCIES_FOR_PARTNERSHIP_ERROR');

export const addPartnerToAgency = createAction<AddPartnerToAgencyPayload>('ADD_PARTNER_TO_AGENCY');
export const addPartnerToAgencySuccess = createAction<AddPartnerToAgencySuccessPayload>('ADD_PARTNER_TO_AGENCY_SUCCESS');
export const addPartnerToAgencyError = createAction<PayloadError[]>('ADD_PARTNER_TO_AGENCY_ERROR');

export const editAgencyEmail = createAction<EditAgencyEmailPayload>('EDIT_AGENCY_EMAIL');
export const editAgencyEmailSuccess = createAction<EditAgencyEmailPayload>('EDIT_AGENCY_EMAIL_SUCCESS');
export const editAgencyEmailError = createAction<PayloadError[]>('EDIT_AGENCY_EMAIL_ERROR');

export const removePartnerFromAgency = createAction<RemovePartnerFromAgencyPayload>('REMOVE_PARTNER_FROM_AGENCY');
export const removePartnerFromAgencySuccess = createAction<number>('REMOVE_PARTNER_FROM_AGENCY_SUCCESS');
export const removePartnerFromAgencyError = createAction<PayloadError[]>('REMOVE_PARTNER_FROM_AGENCY_ERROR');

export const addNewAgency = createAction<AddNewAgencyPayload>('ADD_NEW_AGENCY');
export const addNewAgencySuccess = createAction<IResponseWithoutValue>('ADD_NEW_AGENCY_SUCCESS');
export const addNewAgencyError = createAction<PayloadError[]>('ADD_NEW_AGENCY_ERROR');

export const changeAgencyLogo = createAction<ChangeAgencyLogoPayload>('CHANGE_AGENCY_LOGO');
export const changeAgencyLogoSuccess = createAction<IResponseWithoutValue>('CHANGE_AGENCY_LOGO_SUCCESS');
export const changeAgencyLogoError = createAction<PayloadError[]>('CHANGE_AGENCY_LOGO_ERROR');

export const editAgencyDetails = createAction<EditAgencyDetailsPayload>('EDIT_AGENCY_DETAILS');
export const editAgencyDetailsSuccess = createAction<IResponseWithoutValue>('EDIT_AGENCY_DETAILS_SUCCESS');
export const editAgencyDetailsError = createAction<PayloadError[]>('EDIT_AGENCY_DETAILS_ERROR');

export const uploadCSVRequest = createAction<UploadCSVPayload>('UPLOAD_CSV_REQUEST');
export const uploadCSVSuccess = createAction('UPLOAD_CSV_SUCCESS');
export const uploadCSVError = createAction<PayloadError[]>('UPLOAD_CSV_ERROR');

export const getUploadedUsersRequest = createAction<GetUploadedUsersPayload>('GET_UPLOADED_USERS_REQUEST');
export const getUploadedUsersSuccess = createAction<{ users: UploadedUsersResponse[], count: number }>('GET_UPLOADED_USERS_SUCCESS');
export const getUploadedUsersError = createAction<PayloadError[]>('GET_UPLOADED_USERS_ERROR');

export const saveAgencyStatesRequest = createAction<SaveAgencyStatesPayload>('SAVE_AGENCY_STATES_REQUEST');
export const saveAgencyStatesSuccess = createAction('SAVE_AGENCY_STATES_SUCCESS');
export const saveAgencyStatesError = createAction<PayloadError[]>('SAVE_AGENCY_STATES_ERROR');

export const inviteNewUsersRequest = createAction<InviteUserPayload>('INVITE_NEW_USERS_REQUEST');
export const inviteNewUsersSuccess = createAction<InviteUserPayload>('INVITE_NEW_USERS_SUCCESS');
export const inviteNewUsersError = createAction<PayloadError[]>('INVITE_NEW_USERS_ERROR');

export const deleteInviteRequest = createAction<InviteUserPayload>('DELETE_INVITE_REQUEST');
export const deleteInviteSuccess = createAction<InviteUserPayload>('DELETE_INVITE_SUCCESS');
export const deleteInviteError = createAction<PayloadError[]>('DELETE_INVITE_ERROR');

export const editPartnerAgencyChoicesRequest = createAction<EditPartnerAgencyChoicePayload>('EDIT_PARTNER_AGENCY_CHOICES_REQUEST');
export const editPartnerAgencyChoicesSuccess = createAction<EditPartnerAgencyChoicePayload>('EDIT_PARTNER_AGENCY_CHOICES_SUCCESS');
export const editPartnerAgencyChoicesError = createAction<PayloadError[]>('EDIT_PARTNER_AGENCY_CHOICES_ERROR');

export const clearAgency = createAction('CLEAR_AGENCY');

export const addNewAgencyCompanyRequest = createAction<AddNewAgencyCompanyPayload>('ADD_NEW_AGENCY_COMPANY_REQUEST');
export const addNewAgencyCompanySuccess = createAction<number>('ADD_NEW_AGENCY_COMPANY_SUCCESS');
export const addNewAgencyCompanyError = createAction<PayloadError[]>('ADD_NEW_AGENCY_COMPANY_ERROR');

export const getAgencyCompanyIdRequest = createAction<number>('GET_AGENCY_COMPANY_ID_REQUEST');
export const getAgencyCompanyIdSuccess = createAction<number>('GET_AGENCY_COMPANY_ID_SUCCESS');
export const getAgencyCompanyIdError = createAction<PayloadError[]>('GET_AGENCY_COMPANY_ID_ERROR');

export const getCompaniesRequest = createAction<GetCompaniesPayload>('GET_COMPANIES_REQUEST');
export const getCompaniesSuccess = createAction<any>('GET_COMPANIES_SUCCESS');
export const getCompaniesError = createAction<PayloadError[]>('GET_COMPANIES_ERROR');

export const addNewOffice = createAction<AddOfficePayload>('ADD_NEW_OFFICE');
export const addNewOfficeSuccess = createAction<IResponseWithoutValue>('ADD_NEW_OFFICE_SUCCESS');
export const addNewOfficeError = createAction<PayloadError[]>('ADD_NEW_OFFICE_ERROR');

export const openModalDraftDetails = createAction('OPEN_MODAL_DRAFT_DETAILS');
export const closeModalDraftDetails = createAction('CLOSE_MODAL_DRAFT_DETAILS');

export const reorderCompanies = createAction('REORDER_COMPANIES');
