import React, {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import {
  Box,
  Flex, FormControl, FormErrorMessage,
  Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';

import { useDelta } from 'react-delta';
import {
  CustomButton, MultiSelect, TextInput, Typography,
} from '../../../common/components';
import { colors } from '../../../utils';
import { LicensesType, StatesType, SelectValue } from '../../../types';
import { RootStore } from '../../../redux';
import { getMemberLicenses } from '../../../redux/actions/licenses.actions';
import { addNewAgency } from '../../../redux/actions/agency.actions';

interface IProps {
  isOpen: boolean
  onClose: Dispatch<SetStateAction<boolean>>
  agentId: number,
  license: LicensesType,
}

const AddNewAgencyFromDraft: React.FC<IProps> = ({
  isOpen,
  onClose,
  agentId,
  license,
}) => {
  const [defaultStates, setDefaultStates] = useState<Array<SelectValue>>();
  const dispatch = useDispatch();
  const states: Array<StatesType> = useSelector((state: RootStore) => state.auxiliary.states);
  const { buttonLoading, errors: agencyErrors } = useSelector((state: RootStore) => state.agency);

  const buttonLoadingDelta = useDelta(buttonLoading);

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();

  const onSubmit = (data: any) => {
    dispatch(addNewAgency({
      data: {
        agentId,
        licenseNumber: data.license,
        name: data.name,
        principalBrokerLicenseNumber: data.principalBrokerLicenseNumber,
        principalBrokerName: data.principalBrokerName,
        stateIds: data.states,
      },
      companyId: 8,
    }));
  };

  useEffect(() => {
    if (states.length !== 0 && license.state) {
      setDefaultStates(states.filter((it) => it.name === license.state).map((st) => ({
        value: st.id,
        label: st.name,
      })));
      setValue('states',
        states.filter((it) => it.name === license.state).map((st) => st.id));
    }
  }, [states, license.state, setValue]);

  useEffect(() => {
    if (!buttonLoadingDelta?.prev && buttonLoadingDelta?.curr && !agencyErrors.length) {
      dispatch(getMemberLicenses({
        UserId: agentId,
      }));
      onClose(false);
    }
  }, [buttonLoadingDelta]);

  return (
    <Modal
      onClose={() => onClose(false)}
      autoFocus={false}
      size="4xl"
      isOpen={isOpen}
    >
      <ModalOverlay />
      <ModalContent p="20px">
        <ModalHeader alignItems="center" d="flex">
          <Typography weight={600} size={20}>
            {license.agencyDraft?.name}
          </Typography>
          <ModalCloseButton m="20px" color={colors.gray} />
        </ModalHeader>
        <ModalBody>
          {/*  FORM */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Flex mt="24px" justifyContent="space-between">
              <Box>
                <FormControl>
                  <TextInput
                    defaultValue={license.agencyDraft?.name || ''}
                    label="Agency Name"
                    {...register('name', {
                      required: { value: true, message: 'This is required' },
                    })}
                    placeholder="Enter"

                  />
                  <FormErrorMessage m="0px">
                    {errors.name && errors.name.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.address} mt={3}>
                  <TextInput
                    defaultValue={license.agencyDraft?.address || ''}
                    label="Agency Address"
                    {...register('address')}
                    placeholder="Enter"
                  />
                  <FormErrorMessage m="0px">
                    {errors.address && errors.address.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.phoneNumber} mt={3}>
                  <TextInput
                    defaultValue={license.agencyDraft?.phoneNumber || ''}
                    label="Phone Number"
                    {...register('phoneNumber',
                      {
                        required: { value: true, message: 'This is required' },
                      })}
                    placeholder="Enter"
                  />
                  <FormErrorMessage m="0px">
                    {errors.phoneNumber && errors.phoneNumber.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.contactPerson} mt={3}>
                  <TextInput
                    defaultValue={license.agencyDraft?.contactPerson || ''}
                    label="Contact Person"
                    {...register('contactPerson')}
                    placeholder="Enter"
                  />
                  <FormErrorMessage m="0px">
                    {errors.contactPerson && errors.contactPerson.message}
                  </FormErrorMessage>
                </FormControl>
                <Controller
                  name="states"
                  control={control}
                  render={({ field }) => (
                    <Box w="345px">
                      <MultiSelect
                        onChange={field.onChange}
                        errors={errors.states?.message}
                        label="States"
                        defaultValue={defaultStates}
                        options={
                          states.map((st) => ({
                            value: st.id,
                            label: st.name,
                          }))
                        }
                      />
                    </Box>

                  )}
                />

              </Box>
              <Box>
                <FormControl isInvalid={errors.email}>
                  <TextInput
                    defaultValue={license.agencyDraft?.email || ''}
                    label="Email"
                    type="email"
                    {...register('email', {
                      required: { value: true, message: 'This is required' },
                    })}
                    placeholder="Enter"
                  />
                  <FormErrorMessage m="0px">
                    {errors.email && errors.email.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.license} mt={3}>
                  <TextInput
                    defaultValue={license.agencyDraft?.license || ''}
                    label="Agency License Number"
                    {...register('license', {
                      required: { value: true, message: 'This is required' },
                    })}
                    placeholder="Enter"
                  />
                  <FormErrorMessage m="0px">
                    {errors.license && errors.license.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.principalBrokerName} mt={3}>
                  <TextInput
                    defaultValue={license.agencyDraft?.principalBrokerName || ''}
                    label="Principal Broker Name"
                    {...register('principalBrokerName')}
                    placeholder="Enter"
                  />
                  <FormErrorMessage m="0px">
                    {errors.principalBrokerName && errors.principalBrokerName.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.principalBrokerLicenseNumber} mt={3}>
                  <TextInput
                    defaultValue={license.agencyDraft?.principalBrokerLicenseNumber || ''}
                    label="Principal Broker License Number"
                    {...register('principalBrokerLicenseNumber')}
                    placeholder="Enter"
                  />
                  <FormErrorMessage m="0px">
                    {errors.principalBrokerLicenseNumber
                      && errors.principalBrokerLicenseNumber.message}
                  </FormErrorMessage>
                </FormControl>
              </Box>
            </Flex>
            <Flex
              pt="15px"
              borderTop="1px solid #D1D2DB"
              mt="35px"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box w="227px">
                <CustomButton
                  onClick={() => onClose(false)}
                  backgroundColor="white"
                  color="#888DAB"
                  variant="outline"
                  isSmall
                >
                  Cancel
                </CustomButton>
              </Box>

              <Box w="227px">
                <CustomButton isLoading={buttonLoading} type="submit" isSmall>Add</CustomButton>
              </Box>

            </Flex>

          </form>

        </ModalBody>

      </ModalContent>

    </Modal>
  );
};

export default AddNewAgencyFromDraft;
