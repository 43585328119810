import { Flex, Text } from '@chakra-ui/react';

import { fonts } from '../../../../utils';
import { Typography } from '../../../components';
import { countDuplicates } from '../../countDuplicates';

interface TdContentProps {
  content: number | string | string[];
  color: string;
}

interface CounterProps extends Pick<TdContentProps, 'color' | 'content'> {
  count?: number;
}

export const TdContent: React.FC<TdContentProps> = ({ content, color }) => {
  if (Array.isArray(content)) {
    const count = countDuplicates(content);
    const countEntries = Object.entries(count);

    return (
      <>
        {countEntries.map(([status, count], idx) => (
          <>
            <Label content={status} count={count} color={color} />
            {countEntries.length > 1 && idx !== countEntries.length - 1 ? (
              <Text color={color}>,&nbsp;</Text>
            ) : null}
          </>
        ))}
      </>
    );
  }

  return <Label content={content} color={color} />;
};

const Label: React.FC<CounterProps> = ({ content, count = 0, color }) => (
  <Flex alignItems="center">
    <Typography noOfLines={2} size={16} family={fonts.roboto} color={color}>
      {content}
    </Typography>
    {count > 1 ? (
      <Flex
        alignItems="center"
        justifyContent="center"
        display="inline-flex"
        bg="active"
        color="white"
        h="18px"
        w="18px"
        borderRadius="50%"
        ml="3px"
      >
        <Text fontSize="10px">{count}</Text>
      </Flex>
    ) : null}
  </Flex>
);
