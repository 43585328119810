import React from 'react';
import {
  Flex, Box, Divider,
} from '@chakra-ui/react';
import {
  NavLink,
} from 'react-router-dom';

import { colors, fonts } from '../../../utils';
import { Typography } from '../../../common/components';
import { SideTextEnum } from '../../../common/const/sideBar';

interface IDashboardSideTextProps {
  text: string;
  isLast?: boolean;
  isActive?: boolean;
  link: string;
  icon: React.ReactElement;
  isPendingViewed: boolean
  isNewViewed: boolean
}

export const DashboardSideText: React.FC<IDashboardSideTextProps> = ({
  text,
  icon,
  isActive,
  link,
  isLast,
  isPendingViewed,
  isNewViewed,
}) => (
  <Box>
    {isLast ? <Divider w="40px" mt="40px" /> : null}
    <Flex
      align="baseline"
      mt="40px"
      pos="relative"
      as={NavLink}
      to={link}
      _hover={{ textDecorationColor: 'white' }}
    >
      <Box mr="12px">{icon}</Box>
      <Typography
        size={16}
        family={fonts.roboto}
        color={isActive ? colors.active : colors.white}
      >
        {text}
      </Typography>
      {
        ((text === SideTextEnum.VerifiedUsers && !isPendingViewed)
        || (text === SideTextEnum.UnVerifiedUsers && !isNewViewed))
          && (
          <Box
            top="50%"
            transform="translateY(-50%)"
            right="-18px"
            position="absolute"
            w="7px"
            h="7px"
            borderRadius="20px"
            bgColor="#4FD1C5"
          />

          )
      }

      {isActive && (
        <Box
          h="43px"
          w="6px"
          pos="absolute"
          top="-5px"
          right="-43px"
          bg={colors.active}
          borderRadius={100}
        />
      )}
    </Flex>
  </Box>
);
