import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SignIn from './SignIn';
import { RootStore } from '../../../redux';
import { AuthPayload } from '../../../types';
import { login } from '../../../redux/actions/auth.actions';

const SignInContainer = () => {
  const loading = useSelector((state: RootStore) => state.session.loading);

  const dispatch = useDispatch();
  const onLogin = (data: AuthPayload) => dispatch(login(data));

  return <SignIn onLogin={onLogin} loading={loading} />;
};

export default SignInContainer;
