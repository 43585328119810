import React, { memo, useCallback } from 'react';

import { Box, Center, IconButton, Image, Text } from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';

import { ImgIcon, CloseIcon } from '../../assets/icons';

interface IImageUploadProps {
  onDrop: (acceptedFiles: any) => void;
  uploadedPhoto: any;
  resetPhoto: () => void;
  errorMessage?: string;
}

export const ImageUpload: React.FC<IImageUploadProps> = memo(
  ({ onDrop, uploadedPhoto, resetPhoto, errorMessage }) => {
    const handleDrop = useCallback(onDrop, []);

    const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
      onDrop: handleDrop,
      accept: {
        'image/jpeg': ['.jpeg', '.jpg', '.png'],
      },
    });

    const showError = () => {
      let error = '';

      if (errorMessage) {
        error = errorMessage;
      } else if (fileRejections.length) {
        error = 'Incorrect file format. Please select .jpg, .jpeg or .png';
      }

      return (
        error && (
          <Text align="center" mt="8px" fontSize="12px" color="#EC7474">
            {error}
          </Text>
        )
      );
    };

    return (
      <Box>
        <Box
          {...getRootProps()}
          height="220px"
          overflow="hidden"
          borderRadius="24px"
          bg={isDragActive ? '#F6F7FB' : ''}
          border="1px dashed #888DAB"
          position="relative"
          cursor="pointer"
        >
          <Center flexDirection="column" h="100%">
            <input {...getInputProps()} />
            {uploadedPhoto ? (
              <Image
                maxH="220px"
                objectFit="contain"
                alt="post photo"
                src={
                  typeof uploadedPhoto === 'string'
                    ? uploadedPhoto
                    : URL.createObjectURL(uploadedPhoto)
                }
              />
            ) : (
              <>
                <ImgIcon />
                <Text mt="25px">{isDragActive ? 'Drop the file here' : 'Upload Cover Photo'}</Text>
              </>
            )}
          </Center>
          {uploadedPhoto && (
            <IconButton
              onClick={e => {
                e.stopPropagation();
                resetPhoto();
                fileRejections.length = 0;
              }}
              top="10px"
              right="10px"
              position="absolute"
              aria-label="delete image"
              icon={<CloseIcon />}
              variant="ghost"
              _hover={{
                background: 'gray',
              }}
            />
          )}
        </Box>

        {showError()}
      </Box>
    );
  },
);
