import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DashboardPaginator, ArchiveTable } from '../components';
import { RootStore } from '../../../redux';
import { openModal, closeModal } from '../../../redux/actions/licenses.actions';
import {
  unArchiveMember,
  getArchivedMembers,
} from '../../../redux/actions/members.actions';

const PAGE_SIZE = 50;

export const ArchivedUsers: React.FC = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [archivedCheckedStates, setArchivedCheckedStates] = useState<number[]>(
    []
  );
  const [orderType, setOrderType] = useState(true);
  const [nameValue, setNameValue] = useState<string | undefined>('');
  const [orderField, setOrderField] = useState<string | undefined>();

  const {
    isModalOpen,
    memberInfo,
    idLicenseInfo,
    isDriverLicense,
    loading: licensesLoading,
  } = useSelector((state: RootStore) => state.licenses);
  const { archivedUsersTotalCount, loading: membersLoading } = useSelector(
    (state: RootStore) => state.members
  );
  const { states } = useSelector((state: RootStore) => state.auxiliary);
  const { archivedUsers } = useSelector((state: RootStore) => state.members);

  const onOpenModal = () => dispatch(openModal());
  const onCloseModal = () => dispatch(closeModal());

  const onUnArchiveMember = (id: number) => dispatch(unArchiveMember(id));

  const [changeTable, setChangeTable] = useState(1);

  useEffect(() => {
    dispatch(
      getArchivedMembers({
        OrderField: orderField,
        OrderType: orderType,
        PageNumber: currentPage,
        PageSize: PAGE_SIZE,
        SearchQuery: nameValue,
        StateIds: archivedCheckedStates,
      })
    );
  }, [orderField, orderType, currentPage, nameValue, archivedCheckedStates, changeTable]);

  return (
    <>
      <ArchiveTable
        archivedCheckedStates={archivedCheckedStates}
        idLicenseInfo={idLicenseInfo}
        isDriverLicense={isDriverLicense}
        isModalOpen={isModalOpen}
        licensesLoading={licensesLoading}
        memberInfo={memberInfo}
        membersLoading={membersLoading}
        onCloseModal={onCloseModal}
        onOpenModal={onOpenModal}
        onUnArchiveMember={onUnArchiveMember}
        orderType={orderType}
        setArchivedCheckedStates={setArchivedCheckedStates}
        setNameValue={setNameValue}
        setOrderField={setOrderField}
        setOrderType={setOrderType}
        states={states}
        title="Archived Users"
        users={archivedUsers}
        nameValue={nameValue}
        setChangeTable={setChangeTable}
      />
      <DashboardPaginator
        pageSize={PAGE_SIZE}
        currentPage={currentPage}
        membersLoading={membersLoading}
        setCurrentPage={setCurrentPage}
        usersTotalCount={archivedUsersTotalCount}
      />
    </>
  );
};
