import {
  takeEvery, ForkEffect, call, put,
} from 'redux-saga/effects';
import ApiBase, { URL } from '../../../common/api/api.base';
import { getMembers, getMembersError, getMembersSuccess } from '../../actions/members.actions';
import { IResponseWithValue, UserViewedFlagsType, ZootProUsersType } from '../../../types';

function* workerGetMembers(action: ReturnType<typeof getMembers>) {
  const {
    IsAllLicensesVerified,
    IsIdLicenseVerified,
    OrderType,
    PageNumber,
    PageSize,
    States,
    Name,
    OrderField,
    agencies,
    isCustomNew,
  } = action.payload;
  const orderField = OrderField ? `&OrderField=${OrderField}` : '';
  const name = Name ? `&Name=${Name}` : '';
  const isIdLicenseVerified = IsIdLicenseVerified !== null ? `&IsIdLicenseVerified=${IsIdLicenseVerified}` : '';
  const isAllLicensesVerified = IsAllLicensesVerified !== null ? `&IsAllLicensesVerified=${IsAllLicensesVerified}` : '';

  const statesNumber = States.map((state) => `&States=${state}`);
  const states = States.length !== 0 ? statesNumber.join('') : '';

  const agenciesNew = agencies? agencies : [];

  const agencyNumber = agenciesNew.map((agency) => `&agency=${agency}`);
  const agencyParam = agenciesNew.length !== 0 ? agencyNumber.join('') : '';

  const isCustomNewP = isCustomNew? '&isCustomNew=1' : '';

  try {
    const result: IResponseWithValue<{
      isAnyUserViewedFlags: UserViewedFlagsType;
      zootProUsers: ZootProUsersType[];
      count: number;
    }> = yield call(ApiBase.get, `${URL}/api/admin/get-members?OrderType=${OrderType}${orderField}${isCustomNewP}${isIdLicenseVerified}${isAllLicensesVerified}${name}${states}${agencyParam}&PageNumber=${PageNumber}&PageSize=${PageSize}`);
    yield console.log('getMembers', action, result);
    if (result.success) {
      yield put(getMembersSuccess(result));
    } else {
      yield put(getMembersError(result.errors));
    }
  } catch (error) {
    yield put(getMembersError(error));
    console.log(error);
  }
}

export default function* watchGetMembersSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(getMembers.type, workerGetMembers);
}
