import { createSlice } from '@reduxjs/toolkit';
import {
  getInvitationUsersRequest,
  getInvitationUsersSuccess,
  getInvitationUsersError,
  deleteInvitedUserRequest,
  deleteInvitedUserSuccess,
  deleteInvitedUserError,
  getParsedUsersFromFileRequest,
  getParsedUsersFromFileSuccess,
  getParsedUsersFromFileError,
  uploadNewUsersRequest,
  uploadNewUsersSuccess,
  uploadNewUsersError,
  inviteUsersRequest,
  inviteUsersSuccess,
  inviteUsersError,
  toggleInviteUserListModal,
  deleteUserFromInviteList,
  changeInvitaionFilter,
  setInvitationListOrder,
} from '../actions/invitation.actions';
import { InvitationUser, ParsedInvitationUser, PayloadError } from '../../types';

type InitialState = {
  allUsers: {
    items: InvitationUser[];
    loading: boolean;
    errors: PayloadError[];
    totalCount: number;
  },
  inviteList: {
    errors: PayloadError[];
    items: ParsedInvitationUser[];
    loading: boolean;
    order: string;
  },
  uploadNewUsersLoading: boolean;
  inviteListModalOpen: boolean;
  invitationFilter: number;
  inviteAllLoading: boolean;
  inviteUser: {
    loading: boolean;
    errors: PayloadError[];
  },
  deleteUser: {
    loading: boolean;
    errors: PayloadError[];
  }
};

const initialState: InitialState = {
  allUsers: {
    errors: [],
    items: [],
    loading: false,
    totalCount: 0,
  },
  inviteList: {
    errors: [],
    items: [],
    loading: false,
    order: '',
  },
  inviteUser: {
    errors: [],
    loading: false,
  },
  uploadNewUsersLoading: false,
  inviteListModalOpen: false,
  invitationFilter: 0,
  inviteAllLoading: false,
  deleteUser: {
    errors: [],
    loading: false,
  },
};

const invitationSlice = createSlice({
  name: 'invitation',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getInvitationUsersRequest, (state) => {
      state.allUsers.loading = true;
      state.allUsers.errors = [];
    }).addCase(getInvitationUsersSuccess, (state, action) => {
      state.allUsers.loading = false;
      state.allUsers.totalCount = action.payload.count;
      state.allUsers.items = action.payload.users;
    }).addCase(getInvitationUsersError, (state, action) => {
      state.allUsers.loading = false;
      state.allUsers.errors = action.payload;
    }).addCase(getParsedUsersFromFileRequest, (state) => {
      state.inviteList.loading = true;
      state.inviteList.errors = [];
    })
      .addCase(getParsedUsersFromFileSuccess, (state, action) => {
        state.inviteList.loading = false;
        state.inviteList.items = action.payload.users;
      })
      .addCase(getParsedUsersFromFileError, (state, action) => {
        state.inviteList.loading = false;
        state.inviteList.errors = action.payload;
      })
      .addCase(toggleInviteUserListModal, (state, action) => {
        state.inviteListModalOpen = action.payload;
      })
      .addCase(deleteUserFromInviteList, (state, action) => {
        state.inviteList.items = state.inviteList.items.filter((_elem, index) => index !== action.payload);
      })
      .addCase(uploadNewUsersRequest, (state) => {
        state.uploadNewUsersLoading = true;
      })
      .addCase(uploadNewUsersSuccess, (state) => {
        state.uploadNewUsersLoading = false;
      })
      .addCase(uploadNewUsersError, (state) => {
        state.uploadNewUsersLoading = false;
      })
      .addCase(changeInvitaionFilter, (state, action) => {
        state.invitationFilter = action.payload;
      })
      .addCase(inviteUsersRequest, (state, action) => {
        if (action.payload.userId === null) {
          state.inviteAllLoading = true;
        } else {
          state.inviteUser.loading = true;
          state.inviteUser.errors = [];
        }
      })
      .addCase(inviteUsersSuccess, (state) => {
        state.inviteAllLoading = false;
        state.inviteUser.loading = false;
      })
      .addCase(inviteUsersError, (state, action) => {
        state.inviteAllLoading = false;
        state.inviteUser.loading = false;
        state.inviteUser.errors = action.payload;
      })
      .addCase(setInvitationListOrder, (state, action) => {
        function sort(a:ParsedInvitationUser, b:ParsedInvitationUser) {
          if (action.payload === 'firstName') {
            if (a.firstName < b.firstName) return -1;
            if (a.firstName > b.firstName) return 1;
            return 0;
          }
          if (action.payload === 'lastName') {
            if (a.lastName < b.lastName) return -1;
            if (a.lastName > b.lastName) return 1;
            return 0;
          }
          return 1;
        }
        state.inviteList.order = action.payload;
        state.inviteList.items = state.inviteList.items.sort(sort);
      })
      .addCase(deleteInvitedUserRequest, (state) => {
        state.deleteUser.errors = [];
        state.deleteUser.loading = true;
      })
      .addCase(deleteInvitedUserSuccess, (state) => {
        state.deleteUser.loading = false;
      })
      .addCase(deleteInvitedUserError, (state, action) => {
        state.deleteUser.loading = false;
        state.deleteUser.errors = action.payload;
      });
  },
});

export default invitationSlice.reducer;
