import {
  takeEvery, ForkEffect, call, put,
} from 'redux-saga/effects';
import ApiBase, { URL } from '../../../common/api/api.base';
import {
  agentSearchError,
  agentSearchRequest,
  agentSearchSuccess,
  deleteImportLog,
  deleteImportLogError,
  deleteImportLogSuccess,
  getImportInfoError,
  getImportInfoRequest,
  getImportInfoSuccess,
} from '../../actions/import.actions';
import { AgentsType, ImportLogsType, IResponseWithValue } from '../../../types';

function* workerGetImportInfo(action: ReturnType<typeof getImportInfoRequest>) {
  try {
    const result: IResponseWithValue<ImportLogsType> = yield call(
      ApiBase.get,
      `${URL}/api/admin/import-logs`,
      action.payload,
    );
    if (result.success) {
      yield put(getImportInfoSuccess(result));
    } else {
      yield put(getImportInfoError(result.errors));
    }
  } catch (error) {
    yield put(getImportInfoError(error));
    console.log(error);
  }
}

function* workerDeleteImportInfo(action: ReturnType<typeof deleteImportLog>) {
  try {
    const result: IResponseWithValue<any> = yield call(
      ApiBase.delete,
      `${URL}/api/admin/import-logs/${action.payload}`,
    );
    if (result.success) {
      yield put(deleteImportLogSuccess(action.payload));
    } else {
      yield put(deleteImportLogError(result.errors));
    }
  } catch (error) {
    yield put(getImportInfoError(error));
    console.log(error);
  }
}

function* workerAgentSearch(action: ReturnType<typeof agentSearchRequest>) {
  try {
    const result: IResponseWithValue<AgentsType[]> = yield call(
      ApiBase.get,
      `${URL}/api/admin/agent-search?fullName=${action.payload}`,
    );
    if (result) {
      yield put(agentSearchSuccess(result.value));
    } else {
      yield put(agentSearchError(result));
    }
  } catch (error) {
    yield put(agentSearchError(error));
    console.log('workerAgentSearch ERROR', error);
  }
}

export default function* watchGetImportInfoSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(getImportInfoRequest.type, workerGetImportInfo);
  yield takeEvery(deleteImportLog.type, workerDeleteImportInfo);
  yield takeEvery(agentSearchRequest.type, workerAgentSearch);
}
