import {
  call, ForkEffect, put, takeEvery,
} from 'redux-saga/effects';
import {
  removePartnerFromAgency,
  removePartnerFromAgencyError,
  removePartnerFromAgencySuccess,
} from '../../actions/agency.actions';
import ApiBase, { URL } from '../../../common/api/api.base';
import { IResponseWithoutValue } from '../../../types';

function* workerRemovePartnerFromAgency(action: ReturnType<typeof removePartnerFromAgency>) {
  try {
    const result: IResponseWithoutValue = yield call(ApiBase.delete, `${URL}/api/admin/remove-partnership?AgencyId=${action.payload.AgencyId}&PartnerId=${action.payload.PartnerId}`);
    yield console.log('removePartnerFromAgency', action, result);
    if (result.success) {
      yield put(removePartnerFromAgencySuccess(action.payload.PartnerId));
    } else {
      yield put(removePartnerFromAgencyError(result.errors));
    }
  } catch (error) {
    yield put(removePartnerFromAgencyError(error));
    console.log(error);
  }
}

export default function* watchRemovePartnerFromAgencySaga():
Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(removePartnerFromAgency.type, workerRemovePartnerFromAgency);
}
