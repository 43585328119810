/* eslint indent: 0 */
import React from 'react';
import {
    Modal,
    ModalContent,
    ModalOverlay,
    ModalCloseButton,
    FormControl,
    FormErrorMessage,
    Box,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { addNewAgencyCompanyRequest } from '../../../redux/actions/agency.actions';
import { Typography, TextInput, CustomButton } from '../../../common/components';
import { colors } from '../../../utils';
import { LicensesType } from '../../../types';

interface IProps {
    isOpen: boolean
    setIsModalOpen: (isOpen: boolean) => void
    draftLicense?: LicensesType
    userId?: number
}

export const AddNewAgencyCompanyModal: React.FC<IProps> = ({
    isOpen,
    setIsModalOpen,
    draftLicense,
    userId,
}) => {
    const dispatch = useDispatch();

    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm();

    const onSubmit = (name: any) => {
        if (draftLicense?.agencyDraft) {
            const data = draftLicense.agencyDraft;

            dispatch(addNewAgencyCompanyRequest({
                name: name,
                data: {
                    name: data.name,
                    licenseNumber: data.license || '',
                    principalBrokerName: data.principalBrokerName,
                    principalBrokerLicenseNumber: data.principalBrokerLicenseNumber,
                    stateIds: [data.stateId],
                    agentId: userId,
                },
                officePayload: {
                    data: {
                        name: data.name,
                        email: data.email,
                        address: data.address,
                        phoneNumber: data.phoneNumber,
                        contactPerson: data.contactPerson || '',
                    },
                    agencyId: 0,
                },
            }));
        } else {
            dispatch(addNewAgencyCompanyRequest({
                name: name,
            }));
        }
        setIsModalOpen(false);

        reset();
    };

    // useEffect(() => {
    //     if (createdCompanyId && setIsDetailsOpen) {
    //         if (draftLicense?.agencyDraft) {
    //             const data = draftLicense.agencyDraft;
    //
    //             dispatch(addNewAgency({
    //                 data: {
    //                     name: data.name,
    //                     licenseNumber: data.license || '',
    //                     principalBrokerName: data.principalBrokerName,
    //                     principalBrokerLicenseNumber: data.principalBrokerLicenseNumber,
    //                     stateIds: [data.id]
    //                 },
    //                 companyId: createdCompanyId ? createdCompanyId : 0
    //             }));
    //         }
    //
    //         setIsModalOpen(false)
    //     }
    // }, [createdCompanyId])

    // useEffect(() => {
    //     if(agencyCompanyId?.agencies && agencyCompanyId?.agencies[0]?.id  && setIsDetailsOpen) {
    //         setIsDetailsOpen(true)
    //     }
    // }, [agencyCompanyId])

    return (
        <Modal
            onClose={() => setIsModalOpen(false)}
            autoFocus={false}
            isOpen={isOpen}
            isCentered
        >
            <ModalOverlay />
            <ModalContent p="20px">
                <Box
                    d="flex"
                    alignItems="center"
                    p="10px 0"
                >
                    <Typography weight={600} size={20}>
                        Add Brokerage
                    </Typography>

                    <ModalCloseButton m="21px 10px" color={colors.gray} />
                </Box>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormControl isInvalid={errors.address} mt={10}>
                        <TextInput
                            label="Brokerage Name"
                            {...register('name')}
                            defaultValue={draftLicense?.agencyDraft?.name}
                            width="100%"
                        />
                        <FormErrorMessage m="0px">
                            {errors.name && errors.name.message}
                        </FormErrorMessage>
                    </FormControl>
                    <CustomButton mt="20px" type="submit">
                        Add
                    </CustomButton>
                </form>
            </ModalContent>
        </Modal>
    );
};
