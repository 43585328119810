import {
  takeEvery, ForkEffect, call, put,
} from 'redux-saga/effects';

import ApiBase from '../../../common/api/api.base';
import { confirmMlsId, confirmMlsIdSuccess, confirmMlsIdError } from '../../actions/licenses.actions';
import { IResponseWithoutValue } from '../../../types';

function* workerConfirmMlsId(action: ReturnType<typeof confirmMlsId>) {
  try {
    const result: IResponseWithoutValue = yield call(ApiBase.put, '/api/admin/confirm-mls-id', {
      ...action.payload,
    });
    console.log('workerConfirmMlsId', result);
    if (result.success) {
      yield put(confirmMlsIdSuccess());
    } else {
      yield put(confirmMlsIdError(result.errors));
    }
  } catch (error) {
    console.log('workerConfirmMlsIdError', error);
  }
}

export default function* watchConfirmMlsIdSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(confirmMlsId.type, workerConfirmMlsId);
}
