import {
  call, ForkEffect, put, takeEvery,
} from 'redux-saga/effects';
import { createStandaloneToast } from '@chakra-ui/react';
import {
  addPartnerToAgency,
  addPartnerToAgencyError,
  addPartnerToAgencySuccess,
} from '../../actions/agency.actions';
import ApiBase, { URL } from '../../../common/api/api.base';
import { IResponseWithValue, AgencyDetails } from '../../../types';

function* workerAddPartnerToAgency(action: ReturnType<typeof addPartnerToAgency>) {
  const toast = createStandaloneToast();

  try {
    const result: IResponseWithValue<AgencyDetails> = yield call(ApiBase.post, `${URL}/api/admin/add-partner?AgencyId=${action.payload.AgencyId}&PartnerId=${action.payload.PartnerId}&IsShowingAssistance=${action.payload.IsShowingAssistance}&IsHostOpenHouse=${action.payload.IsHostOpenHouse}`);
    yield console.log('addPartnerToAgency', action, result);
    if (result.success) {
      yield put(addPartnerToAgencySuccess({ ...result.value, isHostOpenHouse: action.payload.IsHostOpenHouse, isShowingAssistance: action.payload.IsShowingAssistance }));
      toast({
        title: 'Partnership added successfully!',
        status: 'success',
        duration: 5000,
      });
    } else {
      yield put(addPartnerToAgencyError(result.errors));
    }
  } catch (error) {
    yield put(addPartnerToAgencyError(error));
    console.log(error);
  }
}

export default function* watchAddPartnerToAgencySaga():
Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(addPartnerToAgency.type, workerAddPartnerToAgency);
}
