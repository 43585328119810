import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';
import {
  deleteOfficeRequest,
  getOfficesRequest,
  editOfficeError,
} from '../../actions/offices.actions';
import { IResponseWithoutValue } from '../../../types';
import API, { URL } from '../../../common/api/api.base';

function* workerDeleteOffice(action: ReturnType<typeof deleteOfficeRequest>) {
  try {
    const result: IResponseWithoutValue = yield call(
      API.delete,
      `${URL}/api/admin/agency-office/${action.payload.officeId}`
    );
    if (result.success) {
      yield put(
        getOfficesRequest({
          AgencyId: action.payload.agencyId,
          PageNumber: 1,
          PageSize: 50,
          Search: '',
        })
      );
    } else {
      yield put(editOfficeError(result.errors));
    }
  } catch (error) {
    console.log(error, 'workerEditOffice error');
  }
}

export default function* watchDeleteOfficeSaga(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeEvery(deleteOfficeRequest.type, workerDeleteOffice);
}
