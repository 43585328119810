import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  useDisclosure,
  VStack,
  Spinner,
  Center,
} from '@chakra-ui/react';
import { SearchIcon } from '../../../assets/icons';
import { CitiesResponse, IResponseWithValue } from '../../../types';
import { colors, fonts } from '../../../utils';
import API, { URL } from '../../api/api.base';
import { Typography } from '../Typography';

interface IProps {
  disabled?: boolean;
  typeIdsSelected: CitiesResponse[];
  setTypeIdsSelected: Dispatch<SetStateAction<CitiesResponse[]>>;
  stateIdValue: number;
}

export const SearchCitiesSelect: React.FC<IProps> = ({
  disabled,
  typeIdsSelected,
  setTypeIdsSelected,
  stateIdValue,
}) => {
  const [term, setTerm] = useState('');
  const [cities, setCities] = useState<Array<CitiesResponse>>([]);
  const [loading, setLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const checkIfValuesExist = useMemo(() => {
    if (typeIdsSelected.length > 0) {
      return typeIdsSelected.map(el => el.cityName).join(', ');
    }
    return 'Select';
  }, [typeIdsSelected]);

  const visibleItems = useMemo(() => {
    if (typeIdsSelected.length !== 0 && isOpen && cities.length === 0) {
      return [...typeIdsSelected];
    }
    if (cities.length !== 0 && isOpen && typeIdsSelected.length !== 0) {
      return [...cities];
    }
    if (cities.length !== 0 && isOpen) {
      return [...cities];
    }
    return [];
  }, [cities, typeIdsSelected, isOpen]);

  const onSelected = (checked: boolean, item: CitiesResponse) => {
    if (checked && typeIdsSelected.length !== 5) {
      setTypeIdsSelected(prev => [...prev, item]);
    } else {
      setTypeIdsSelected(prev => prev.filter(it => it.cityName !== item.cityName));
    }
  };

  useEffect(() => {
    const getCities = async (searchValue: string, stateId: number) => {
      try {
        setLoading(true);
        const result: IResponseWithValue<CitiesResponse[]> = await API.get(
          `${URL}/api/admin/get-city-info?City=${searchValue}&State=${stateId}`,
        );
        if (result.success) {
          setLoading(false);
          setCities(result.value);
        }
      } catch (err) {
        console.log(err);
      }
    };

    if (term.length >= 3 && stateIdValue !== 0) {
      getCities(term, stateIdValue).catch(console.log);
    }
  }, [term, stateIdValue]);

  useEffect(() => {
    if (!isOpen) {
      setTerm('');
      setCities([]);
    }
  }, [isOpen]);

  return (
    <>
      <Popover placement="right" isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
        <PopoverTrigger>
          <Flex
            fontFamily={fonts.poppins}
            bg={colors.ghost}
            height="50px"
            width="100%"
            align="center"
            px="20px"
            justifyContent="flex-start"
            fontWeight="normal"
            as={Button}
            disabled={disabled}
          >
            <Typography size={16} color="secondaryFontDarker" isTruncated>
              {checkIfValuesExist}
            </Typography>
          </Flex>
        </PopoverTrigger>
        <PopoverContent borderRadius="20px">
          <PopoverHeader>
            <Box>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <SearchIcon />
                </InputLeftElement>
                <Input placeholder="Search" value={term} onChange={e => setTerm(e.target.value)} />
              </InputGroup>
            </Box>
          </PopoverHeader>
          <PopoverBody>
            <VStack align="flex-start" p="15px" height="260px" overflow="auto">
              {loading ? (
                <Center w="100%">
                  <Spinner color="gray" />
                </Center>
              ) : (
                visibleItems.length !== 0 &&
                visibleItems.map(elem => (
                  <Checkbox
                    key={elem.cityName}
                    isChecked={typeIdsSelected.some(it => it.cityName === elem.cityName)}
                    onChange={ev => {
                      onSelected(ev.target.checked, elem);
                    }}
                  >
                    <Typography size={14} color="secondaryFontDarker">
                      {elem.cityName}
                    </Typography>
                  </Checkbox>
                ))
              )}
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  );
};
