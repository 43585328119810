import React from 'react';
import parse from 'html-react-parser';
import dayjs from 'dayjs';
import { Avatar, Box, Flex } from '@chakra-ui/react';
import relativeTime from 'dayjs/plugin/relativeTime';

import { colors } from '../../../utils';
import { URL } from '../../api/api.base';
import { Typography } from '../../components';
import { ReportCommentType } from '../../../types';

dayjs.extend(relativeTime);

interface IReportCommentsProps {
  comments: Array<ReportCommentType>
}

const ReportComments: React.FC<IReportCommentsProps> = ({ comments }) => (
  <Box mt="14px">
    <Typography size={18}>
      Comments
      {' '}
      (
      {comments.length}
      )
    </Typography>
    {comments.map((comment) => (
      <Box key={comment.id} mt={6}>
        <Flex alignItems="center" mb={4}>
          <Avatar
            loading="lazy"
            w="32px"
            h="32px"
            name="User"
            src={comment.createdBy.avatar.itemHash !== null
              ? `${URL}/api/storage/static-images/${comment.createdBy.avatar.itemHash}${comment.createdBy.avatar.itemExtension}`
              : undefined}
            mr={2}
          />
          <Box>
            <Box>
              <Typography size={14} weight={500}>{comment.createdBy.fullName}</Typography>
            </Box>
            <Typography
              size={14}
              color={colors.gray}
            >
              {dayjs(comment.createdAt).fromNow()}
            </Typography>
          </Box>
        </Flex>
        <Typography size={16}>{parse(comment.text)}</Typography>
      </Box>
    ))}
  </Box>
);

export { ReportComments };
