import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';
import qs from 'qs';

import ApiBase, { URL } from '../../../common/api/api.base';

import {
  getAdvertisment,
  getAdvertismentSuccess,
  getAdvertismentError,
} from '../../actions/advertisment.actions';

import { AdvertismentModelType, IResponseWithValue } from '../../../types';

function* workerGetAdvertisment(action: ReturnType<typeof getAdvertisment>) {
  const stringifyOptions = {
    addQueryPrefix: true,
    skipNulls: true,
  };

  try {
    const result: IResponseWithValue<{
      posts: AdvertismentModelType[];
      totalCount: number;
    }> = yield call(
      ApiBase.get,
      `${URL}/api/advertising/post${qs.stringify(
        action.payload,
        stringifyOptions
      )}`
    );

    if (result.success) {
      yield put(getAdvertismentSuccess(result));
    } else {
      yield put(getAdvertismentError(result.errors));
    }
  } catch (err: any) {
    yield put(getAdvertismentError(err));
    console.error(err);
  }
}

export default function* watchGetAdvertismentSaga(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeEvery(getAdvertisment.type, workerGetAdvertisment);
}
