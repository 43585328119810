import React, { FC } from 'react';

interface IProps {
  width?: string | number;
  height?: string | number;
}

export const ImgIcon: FC<IProps> = ({ width = 50, height = 40 }) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M45 40.5H5c-2.757 0-5-2.242-5-5v-30c0-2.757 2.243-5 5-5h40c2.758 0 5 2.243 5 5v30c0 2.758-2.242 5-5 5ZM5 3a2.504 2.504 0 0 0-2.5 2.5v30C2.5 36.877 3.623 38 5 38h40c1.377 0 2.5-1.123 2.5-2.5v-30C47.5 4.122 46.377 3 45 3H5Z"
      fill="#2C335C"
    />
    <path
      d="M12.5 18c-2.758 0-5-2.242-5-5s2.242-5 5-5 5 2.242 5 5-2.242 5-5 5Zm0-7.5A2.504 2.504 0 0 0 10 13c0 1.377 1.123 2.5 2.5 2.5S15 14.377 15 13s-1.123-2.5-2.5-2.5ZM1.425 37.825a1.25 1.25 0 0 1-.885-2.133l11.808-11.807c1.415-1.415 3.887-1.415 5.302 0l3.515 3.515 9.73-11.675a3.756 3.756 0 0 1 2.856-1.35h.027c1.095 0 2.132.475 2.848 1.307L49.7 30.938c.45.523.39 1.313-.136 1.763-.522.45-1.31.392-1.762-.135L34.728 17.31a1.237 1.237 0 0 0-.95-.435c-.26-.023-.718.157-.96.45L22.21 30.052c-.225.27-.552.433-.905.448a1.203 1.203 0 0 1-.94-.365l-4.482-4.483c-.473-.47-1.295-.47-1.768 0L2.308 37.46a1.246 1.246 0 0 1-.883.365Z"
      fill="#2C335C"
    />
  </svg>
);
