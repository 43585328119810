import { createAction } from '@reduxjs/toolkit';

import {
  PayloadError,
  IResponseWithValue,
  GetArchivedMembersPayload,
  GetMembersPayload,
  GetSuspendedMembersPayload,
  SetUserViewedPayload,
  ArchivedUsersType,
  UserViewedFlagsType,
  ZootProUsersType,
} from '../../types';

export const getMembers = createAction<GetMembersPayload>('GET_MEMBERS');
export const getMembersSuccess = createAction<IResponseWithValue<{
  isAnyUserViewedFlags: UserViewedFlagsType;
  zootProUsers: ZootProUsersType[];
  count: number;
}>>('GET_MEMBERS_SUCCESS');
export const getMembersError = createAction<PayloadError[]>('GET_MEMBERS_ERROR');

export const getSuspendedMembers = createAction<GetSuspendedMembersPayload>('GET_SUSPENDED_MEMBERS');
export const getSuspendedMembersSuccess = createAction<IResponseWithValue<{
  isAnyUserViewedFlags: UserViewedFlagsType;
  zootProUsers: ZootProUsersType[];
  count: number;
}>>('GET_SUSPENDED_MEMBERS_SUCCESS');
export const getSuspendedMembersError = createAction<PayloadError[]>('GET_SUSPENDED_MEMBERS_ERROR');

export const getArchivedMembers = createAction<GetArchivedMembersPayload>('GET_ARCHIVED_MEMBERS');
export const getArchivedMembersSuccess = createAction<IResponseWithValue<{
  models: ArchivedUsersType[];
  totalCount: number;
}>>('GET_ARCHIVED_MEMBERS_SUCCESS');
export const getArchivedMembersError = createAction<PayloadError[]>('GET_ARCHIVED_MEMBERS_ERROR');

export const archiveMember = createAction<number>('ARCHIVE_MEMBER');
export const archiveMemberSuccess = createAction<number>('ARCHIVE_MEMBER_SUCCESS');
export const archiveMemberError = createAction<PayloadError[]>('ARCHIVE_MEMBER_ERROR');

export const unArchiveMember = createAction<number>('UN_ARCHIVE_MEMBER');
export const unArchiveMemberSuccess = createAction<number>('UN_ARCHIVE_MEMBER_SUCCESS');
export const unArchiveMemberError = createAction<PayloadError[]>('UN_ARCHIVE_MEMBER_ERROR');

export const deleteMember = createAction<number>('DELETE_MEMBER');
export const deleteMemberSuccess = createAction<number>('DELETE_MEMBER_SUCCESS');
export const deleteMemberError = createAction<PayloadError[]>('DELETE_MEMBER_ERROR');

export const setUserViewedStatus = createAction<
SetUserViewedPayload>('SET_USER_VIEWED_STATUS');
export const setUserViewedStatusSuccess = createAction<SetUserViewedPayload>('SET_USER_VIEWED_STATUS_SUCCESS');
export const setUserViewedStatusError = createAction<PayloadError[]>('SET_USER_VIEWED_STATUS_ERROR');

export const setUserViewedFlags = createAction<{
  pageName: 'pending' | 'new'
}>('SET_USER_VIEWED_FLAGS');
