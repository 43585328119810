import { createSlice } from '@reduxjs/toolkit';

import { PayloadError, ReportDetailsType, ReportModelType } from '../../types';
import {
  deleteReport,
  deleteReportError,
  deleteReportSuccess,
  getReportDetails,
  getReportDetailsError,
  getReportDetailsSuccess,
  getReports,
  getReportsError,
  getReportsSuccess,
  ignoreReport,
  ignoreReportError,
  ignoreReportSuccess,
  suspendReport,
  suspendReportError,
  suspendReportSuccess,
} from '../actions/reports.actions';

export const initialState: {
  loading: boolean;
  errors: PayloadError[];
  reportsTotalCount: number;
  extraLoading: boolean;
  reports: Array<ReportModelType>;
  reportDetails: ReportDetailsType;
} = {
  loading: false,
  errors: [],
  reports: [],
  reportsTotalCount: 0,
  extraLoading: false,
  reportDetails: {
    comments: [],
    createdAt: '',
    createdBy: {
      avatar: {
        id: 0,
        itemExtension: '',
        itemHash: '',
      },
      fullName: '',
      id: 0,
    },
    isSuspended: false,
    id: 0,
    otherTypeReportsCauses: [],
    photo: {
      id: 0,
      itemExtension: '',
      itemHash: '',
    },
    reports: [],
    saveCount: 0,
    tags: [],
    text: '',
    title: '',
  },
};

const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getReports, (state) => {
        state.loading = true;
        state.errors = [];
      })
      .addCase(getReportsSuccess, (state, action) => {
        state.loading = false;
        state.errors = [];
        state.reports = action.payload.value.updatesReportModels;
        state.reportsTotalCount = action.payload.value.count;
      })
      .addCase(getReportsError, (state, action) => {
        state.loading = false;
        state.errors = action.payload;
      })

      .addCase(getReportDetails, (state) => {
        state.loading = true;
        state.errors = [];
      })
      .addCase(getReportDetailsSuccess, (state, action) => {
        state.loading = false;
        state.errors = [];
        state.reportDetails = action.payload.value.post;
      })
      .addCase(getReportDetailsError, (state, action) => {
        state.loading = false;
        state.errors = action.payload;
      })

      .addCase(deleteReport, (state) => {
        state.errors = [];
      })
      .addCase(deleteReportSuccess, (state, action) => {
        state.errors = [];
        state.reports = state.reports.filter((item) => item.id !== action.payload);
      })
      .addCase(deleteReportError, (state, action) => {
        state.errors = action.payload;
      })

      .addCase(suspendReport, (state) => {
        state.extraLoading = true;
        state.errors = [];
      })
      .addCase(suspendReportSuccess, (state, action) => {
        state.extraLoading = false;
        state.errors = [];
        state.reportDetails = state.reportDetails.id === action.payload
          ? { ...state.reportDetails, isSuspended: !state.reportDetails.isSuspended }
          : state.reportDetails;
      })
      .addCase(suspendReportError, (state, action) => {
        state.extraLoading = false;
        state.errors = action.payload;
      })

      .addCase(ignoreReport, (state) => {
        state.errors = [];
      })
      .addCase(ignoreReportSuccess, (state, action) => {
        state.errors = [];
        state.reports = state.reports.filter((item) => item.id !== action.payload);
      })
      .addCase(ignoreReportError, (state, action) => {
        state.errors = action.payload;
      });
  },
});

export default reportsSlice.reducer;
