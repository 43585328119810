import React, { useState, useEffect } from 'react';
import {
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Box,
  Center,
  Spinner,
} from '@chakra-ui/react';
import { StatesType, AgenciesWithEmailsType } from '../../../types';
import { Typography } from '../Typography';
import { CustomButton } from '../CustomButton';
import { SearchInput } from '../SearchInput';
import API, { URL } from '../../api/api.base';
import { InfiniteScroll } from '../InfiniteScroll/InfiniteScroll';
import { RadioIcon } from '../../../assets/icons';

interface IAgenciesFilterPopupProps {
  selectedItem: number;
  setSelectedItem: (value: number) => void;
  withoutSearch?: boolean;
}

export const AgenciesFilterPopup: React.FC<IAgenciesFilterPopupProps> = ({
  selectedItem,
  setSelectedItem,
  children,
  withoutSearch = false,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [items, setItems] = useState<StatesType[]>([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [onEndReachedCalled, setOnEndReachedCalled] = useState(false);
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const mapAgencies = (agencies: AgenciesWithEmailsType[]) =>
    agencies.map((elem) => ({
      id: elem.id,
      name: elem.name,
      code: elem.id.toString(),
    }));

  async function getAgencies(append: boolean) {
    setLoading(true);
    try {
      const result = await API.get(
        `${URL}/api/admin/agencies?PageNumber=${
          append ? page : 1
        }&PageSize=50&OrderType=true&SearchQuery=${searchTerm}`
      );
      if (result.success) {
        setTotalCount(result.value.totalCount);
        setItems(
          append
            ? [...items, ...mapAgencies(result.value.agencies)]
            : mapAgencies(result.value.agencies)
        );
      }
    } catch (error) {
      console.log(error, 'error filter');
    } finally {
      setLoadMoreLoading(false);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (page > 1) {
      getAgencies(true);
    }
  }, [page]);

  useEffect(() => {
    if (searchTerm) {
      setOnEndReachedCalled(false);
      getAgencies(false);
    } else {
      getAgencies(false);
    }
  }, [searchTerm]);

  const loadMoreItems = () => {
    setLoadMoreLoading(true);
    if (items.length >= totalCount) {
      setOnEndReachedCalled(true);
    } else if (!onEndReachedCalled) {
      setPage((prev: number) => prev + 1);
    }
  };

  return (
    <Popover placement="bottom-start" isLazy size="md">
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent
        maxWidth="270px"
        maxHeight="270px"
        border="none"
        boxShadow="7px 0px 30px rgba(102, 102, 102, 0.25)"
      >
        <PopoverBody>
          {!withoutSearch && (
            <SearchInput
              placeholder="Search"
              value={searchTerm}
              onChange={setSearchTerm}
              loading={loading}
            />
          )}
          <Box>
            <InfiniteScroll
              height="200px"
              onReachBottom={() => {
                if (!loadMoreLoading) {
                  loadMoreItems();
                }
              }}
            >
              {items.map((box) => (
                <CustomButton
                  key={box.id}
                  leftIcon={<RadioIcon selected={selectedItem === box.id} />}
                  onClick={() => {
                    if (selectedItem === box.id) {
                      setSelectedItem(0);
                    } else {
                      setSelectedItem(box.id);
                    }
                  }}
                  isSmall
                  background="white"
                  color="primary"
                  justifyContent="flex-start"
                  p="6px"
                  _focus={{
                    boxShadow: 'none',
                  }}
                >
                  <Typography size={14} isTruncated>
                    {box.name}
                  </Typography>
                </CustomButton>
              ))}
              {loadMoreLoading && (
                <Center>
                  <Spinner size="sm" />
                </Center>
              )}
            </InfiniteScroll>
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
