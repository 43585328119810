import React, { Dispatch, SetStateAction } from 'react';
import { Input } from '@chakra-ui/react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { colors, fonts } from '../../../utils';

interface IDatePickerProps {
  disabled?: boolean;
  maxDate?: Date;
  selected?: Date | null | undefined;
  onChange: (date: Date) => void | Dispatch<SetStateAction<Date>>
}

const DatePicker: React.FC<IDatePickerProps> = ({
  onChange, selected, disabled, maxDate,
}) => (
  <ReactDatePicker
    wrapperClassName="wrapper"
    placeholderText="Enter"
    onChange={onChange}
    selected={selected}
    disabled={disabled}
    maxDate={maxDate}
    showYearDropdown
    yearDropdownItemNumber={100}
    scrollableYearDropdown
    customInput={(
      <Input
        bg={colors.ghost}
        fontFamily={fonts.poppins}
        fontSize="16px"
        size="lg"
        variant="filled"
        errorBorderColor={colors.error}
      />
      )}
  />
);

export { DatePicker };
