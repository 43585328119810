import React from 'react';
import {
  Box, Checkbox, Popover, PopoverBody, PopoverContent, PopoverTrigger, Stack,
} from '@chakra-ui/react';

import { colors } from '../../../utils';
import { Typography } from '../Typography';
import { CustomButton } from '../CustomButton';
import { FilterIcon } from '../../../assets/icons';

interface ISuspendPopupProps {
  checkedItems: number[];
  setCheckedItems: (value: number[]) => void;
}

const SuspendPopup: React.FC<ISuspendPopupProps> = ({ checkedItems, setCheckedItems }) => {
  const initRef = React.useRef<any>();

  const suspends = [
    { id: 1, title: 'Suspended' },
    { id: 2, title: 'Not Suspended' },
  ];

  const allChecked = checkedItems.length === suspends.length;

  const handleSelectState = (id: number) => {
    if (!checkedItems.includes(id)) setCheckedItems([...checkedItems, id]);
    else setCheckedItems(checkedItems.filter((itemId) => itemId !== id));
  };

  const handleSelectAllStates = (e: boolean) => {
    if (e) setCheckedItems(suspends.map((state) => state.id));
    else setCheckedItems([]);
  };

  return (
    <Popover placement="bottom-start" isLazy size="md" initialFocusRef={initRef}>
      <PopoverTrigger>
        <Box w="270px" mr="4">
          <CustomButton
            variant="outline"
            color={colors.gray}
            isSmall
            leftIcon={<FilterIcon />}
            background="transparent"
          >
            Show all
          </CustomButton>
        </Box>
      </PopoverTrigger>
      <PopoverContent
        maxWidth="270px"
        maxHeight="270px"
        border="none"
        boxShadow="7px 0px 30px rgba(102, 102, 102, 0.25)"
        p={1}
        overflow="auto"
      >
        <PopoverBody ref={initRef}>
          <Checkbox
            colorScheme="facebook"
            borderColor={colors.primary}
            isChecked={allChecked}
            onChange={(e) => handleSelectAllStates(e.target.checked)}
          >
            <Typography size={14}>Show all</Typography>
          </Checkbox>
          <Stack mt={1} spacing={1}>
            {suspends.map((box) => (
              <Checkbox
                key={box.id}
                colorScheme="facebook"
                borderColor={colors.primary}
                isChecked={checkedItems.includes(box.id)}
                onChange={() => handleSelectState(box.id)}
              >
                <Typography size={14}>{box.title}</Typography>
              </Checkbox>
            ))}
          </Stack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export { SuspendPopup };
