import React, { FC } from 'react';

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';

import { CustomButton, Typography } from '../../../../common/components';
import { TrashIcon } from '../../../../assets/icons';

import { colors, fonts } from '../../../../utils';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  deletePost: () => void;
}

const DeletePostModal: FC<IProps> = ({ isOpen, onClose, deletePost }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="3xl" isCentered>
      <ModalOverlay />

      <ModalContent p="20px" borderRadius="20px">
        <>
          <ModalHeader alignItems="center" d="flex">
            <Typography weight={600} size={20}>
              Are you sure you want to delete this post?
            </Typography>
            <ModalCloseButton m="20px" color={colors.gray} />
          </ModalHeader>

          <ModalBody mt="20px">
            <VStack spacing="18px" m="100px 0 100px">
              <TrashIcon />
              <Text
                fontFamily={fonts.poppins}
                fontWeight="300"
                fontSize="14px"
                color={colors.gray}
              >
                This Post will be deleted from all agencies
              </Text>
            </VStack>
            <HStack spacing="70px">
              <CustomButton
                bg="none"
                color={colors.primary}
                variant="outline"
                border={colors.primary}
                onClick={onClose}
              >
                Cancel
              </CustomButton>

              <CustomButton onClick={deletePost}>Yes</CustomButton>
            </HStack>
          </ModalBody>
        </>
      </ModalContent>
    </Modal>
  );
};

export default DeletePostModal;
