import { createAction } from '@reduxjs/toolkit';
import {
  ResetPasswordPayload,
  SetRealEstatePhotoPayload,
  SuspendMemberPayload,
  UserIdPayload,
  SetRealEstatePhotoResponse,
  SetIdLicensePhotoPayload,
  SaveIdLicensePayload,
  SaveRealEstateLicenseInfoPayload,
  LicenseModelType,
  MemberInfoType,
  LicensesType,
  ConfirmMlsIdPayload,
  PayloadError,
  IResponseWithValue,
  UpdateUserProfilePayload,
  DiscardLicensePayload,
  VerifyMemberLicensesPayload,
} from '../../types';

export const getIdLicense = createAction<UserIdPayload>('GET_ID_LICENSE');
export const getIdLicenseSuccess =
  createAction<IResponseWithValue<{ licenseModel: LicenseModelType }>>('GET_ID_LICENSE_SUCCESS');
export const getIdLicenseError = createAction<PayloadError[]>('GET_ID_LICENSE_ERROR');

export const saveIdLicense = createAction<SaveIdLicensePayload>('SAVE_ID_LICENSE');
export const saveIdLicenseSuccess = createAction('SAVE_ID_LICENSE_SUCCESS');
export const saveIdLicenseError = createAction<PayloadError[]>('SAVE_ID_LICENSE_ERROR');

export const updateUserProfile = createAction<UpdateUserProfilePayload>('UPDATE_USER_PROFILE');
export const updateUserProfileSuccess = createAction('UPDATE_USER_PROFILE_SUCCESS');
export const updateUserProfileError = createAction<PayloadError[]>('UPDATE_USER_PROFILE_ERROR');

export const confirmIdLicense = createAction<number>('CONFIRM_ID_LICENSE');
export const confirmIdLicenseSuccess = createAction('CONFIRM_ID_LICENSE_SUCCESS');
export const confirmIdLicenseError = createAction<PayloadError[]>('CONFIRM_ID_LICENSE_ERROR');

export const deleteIdLicense = createAction<number>('DELETE_ID_LICENSE');
export const deleteIdLicenseSuccess = createAction('DELETE_ID_LICENSE_SUCCESS');
export const deleteIdLicenseError = createAction<PayloadError[]>('DELETE_ID_LICENSE_ERROR');

export const getMemberLicenses = createAction<UserIdPayload>('GET_MEMBER_LICENSES');
export const getMemberLicensesSuccess = createAction<
  IResponseWithValue<{ licenses: LicensesType[] }>
>('GET_MEMBER_LICENSES_SUCCESS');
export const getMemberLicensesError = createAction<PayloadError[]>('GET_MEMBER_LICENSES_ERROR');

export const verifyMemberLicenses =
  createAction<{ licensePayload: VerifyMemberLicensesPayload; userId: number }>(
    'VERIFY_MEMBER_LICENSES',
  );
export const verifyMemberLicensesSuccess = createAction('VERIFY_MEMBER_LICENSES_SUCCESS');
export const verifyMemberLicensesError = createAction<PayloadError[]>(
  'VERIFY_MEMBER_LICENSES_ERROR',
);

export const deleteMemberLicenses = createAction<number>('DELETE_MEMBER_LICENSES');
export const deleteMemberLicensesSuccess = createAction<number>('DELETE_MEMBER_LICENSES_SUCCESS');
export const deleteMemberLicensesError = createAction<PayloadError[]>(
  'DELETE_MEMBER_LICENSES_ERROR',
);

export const getMemberGeneralInfo = createAction<number>('GET_MEMBER_GENERAL_INFO');
export const getMemberGeneralInfoSuccess = createAction<
  IResponseWithValue<{ generalInfo: MemberInfoType }>
>('GET_MEMBER_GENERAL_INFO_SUCCESS');
export const getMemberGeneralInfoError = createAction<PayloadError[]>(
  'GET_MEMBER_GENERAL_INFO_ERROR',
);

export const suspendMember = createAction<SuspendMemberPayload>('SUSPEND_MEMBER');
export const suspendMemberSuccess = createAction<boolean>('SUSPEND_MEMBER_SUCCESS');
export const suspendMemberError = createAction<PayloadError[]>('SUSPEND_MEMBER_ERROR');

export const resetPassword = createAction<ResetPasswordPayload>('RESET_PASSWORD');
export const resetPasswordSuccess = createAction('RESET_PASSWORD_SUCCESS');
export const resetPasswordError = createAction<PayloadError[]>('RESET_PASSWORD_ERROR');

export const setRealEstatePhoto = createAction<SetRealEstatePhotoPayload>('SET_REAL_ESTATE_PHOTO');
export const setRealEstatePhotoSuccess = createAction<SetRealEstatePhotoResponse>(
  'SET_REAL_ESTATE_PHOTO_SUCCESS',
);
export const setRealEstatePhotoError = createAction<PayloadError[]>('SET_REAL_ESTATE_PHOTO_ERROR');

export const setIdLicensePhoto = createAction<SetIdLicensePhotoPayload>('SET_ID_LICENSE_PHOTO');
export const setIdLicensePhotoSuccess = createAction('SET_ID_LICENSE_PHOTO_SUCCESS');
export const setIdLicensePhotoError = createAction<PayloadError[]>('SET_ID_LICENSE_PHOTO_ERROR');

export const openModal = createAction('OPEN_MODAL');
export const closeModal = createAction('CLOSE_MODAL');

export const saveRealEstateLicenseInfoRequest = createAction<{
  licensePayload: SaveRealEstateLicenseInfoPayload;
  userId: number;
}>('SAVE_REAL_ESTATE_LICENSE_INFO_REQUEST');
export const saveRealEstateLicenseInfoSuccess = createAction<SaveRealEstateLicenseInfoPayload>(
  'SAVE_REAL_ESTATE_LICENSE_INFO_SUCCESS',
);
export const saveRealEstateLicenseInfoError = createAction<PayloadError[]>(
  'SAVE_REAL_ESTATE_LICENSE_INFO_ERROR',
);

export const confirmMlsId = createAction<ConfirmMlsIdPayload>('CONFIRM_MLS_ID');
export const confirmMlsIdSuccess = createAction('CONFIRM_MLS_ID_SUCCESS');
export const confirmMlsIdError = createAction<PayloadError[]>('CONFIRM_MLS_ID_ERROR');

export const discardLicenseRequest = createAction<DiscardLicensePayload>('DISCARD_LICENSE_REQUEST');
export const discardLicenseSuccess = createAction<number>('DISCARD_LICENSE_SUCCESS');
export const discardLicenseError = createAction<PayloadError[]>('DISCARD_LICENSE_ERROR');
