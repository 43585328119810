import {
  call,
  ForkEffect,
  put,
  takeEvery,
} from 'redux-saga/effects';
import {
  addNewAgencyCompanyRequest,
  addNewAgencyCompanySuccess,
  addNewAgencyCompanyError,
  getAgencyCompanyIdSuccess,
  getAgencyCompanyIdError,
  getAgencyCompanyIdRequest,
  addNewOffice,
  addNewAgency,
} from '../../actions/agency.actions';
import ApiBase, { URL } from '../../../common/api/api.base';
import { IResponseWithValue } from '../../../types';

function* workerAddNewAgencyCompany(action: ReturnType<typeof addNewAgencyCompanyRequest>) {
  console.log('addNewAgencyCompany', action.payload);
  try {
    const result: IResponseWithValue<number> = yield call(ApiBase.post, `${URL}/api/admin/companies`, action.payload.name);
    if (result.success) {
      yield put(addNewAgencyCompanySuccess(result.value));
      if (action.payload.data) {
        yield put(addNewAgency({ data: action.payload.data, companyId: result.value, officePayload: action.payload?.officePayload }));
      }
    } else {
      yield put(addNewAgencyCompanyError(result.errors));
    }
  } catch (error: any) {
    console.log('addNewAgencyCompanyError', error);
  }
}

function* workerGetAgencyCompanyId(action: ReturnType<typeof getAgencyCompanyIdRequest>) {
  console.log('getAgencyCompanyId', action.payload);
  try {
    const result: IResponseWithValue<any> = yield call(ApiBase.get, `${URL}/api/admin/companies/agencies`);
    if (result.success) {
      console.log('getAgencyCompanyId', result);
      yield put(getAgencyCompanyIdSuccess(result.value));
    } else {
      yield put(getAgencyCompanyIdError(result.errors));
    }
  } catch (error: any) {
    console.log('getAgencyCompanyIdError', error);
  }
}

function* workerAddOffice(action: ReturnType<typeof addNewOffice>) {
  console.log('workerAddOffice', action.payload);
  try {
    const result: IResponseWithValue<any> = yield call(ApiBase.post, `${URL}/api/admin/agencies/${action.payload.agencyId}/agency-office`, action.payload.data);
    if (result.success) {
      console.log('workerAddOffice', result);
      // yield put(addNewOfficeSuccess(result.value))
    } else {
      console.log('workerAddOfficeError', result);
      // yield put(addNewOfficeError(result.errors))
    }
  } catch (error: any) {
    console.log('workerAddOfficeError', error);
  }
}

export default function* watchAddNewAgencyCompanySaga():
  Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(addNewAgencyCompanyRequest.type, workerAddNewAgencyCompany);
  yield takeEvery(getAgencyCompanyIdRequest.type, workerGetAgencyCompanyId);
  yield takeEvery(addNewOffice.type, workerAddOffice);
}
