import axios from 'axios';

import { URL } from '../api/api.base';

export const getPhoto = (
  itemHash: string,
  ext: string,
  responseType: 'arraybuffer' | 'blob' = 'blob'
) =>
  axios
    .get(`${URL}/api/storage/static-images/${itemHash}${ext}`, {
      responseType,
    })
    .then((response) => {
      if (responseType === 'arraybuffer') {
        const base64 = btoa(
          new Uint8Array(response.data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ''
          )
        );

        return `data:;base64,${base64}`;
      }

      return response.data;
    })
    .catch((err) => {
      console.error('Some getting photo error', err);
    });
