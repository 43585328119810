import React from 'react';
import {
  Box,
  Accordion,
  Text,
  Flex,
  Divider,
  Spinner,
  Center,
} from '@chakra-ui/react';
import { OfficeItem } from '../components';
import { colors, fonts } from '../../../../../utils';
import {
  SearchInput,
  Typography,
  CustomButton,
} from '../../../../../common/components';
import {
  Office,
  EditOfficePayload,
  DeleteOfficePayload,
  AddOfficePayload,
} from '../../../../../types';
import { DashboardPaginator } from '../../../components';
import { toast } from '../../../../../common/api/api.base';

interface IOfficesTabProps {
  onEditOffice: (value: EditOfficePayload) => void;
  page: number;
  setPage: (value: number) => void;
  searchQuery: string;
  setQuery: (value: string) => void;
  loading: boolean;
  items: Office[];
  totalCount: number;
  editingLoading: boolean;
  officeId: number;
  agencyId: number | undefined;
  onDeleteOffice: (paylaod: DeleteOfficePayload) => void;
  setOfficesHandler: (item: any) => void;
  onAddOffice: (payload: AddOfficePayload) => void;
  newOfficeCheck: any;
}

export const OfficesTab: React.FC<IOfficesTabProps> = ({
  onEditOffice,
  editingLoading,
  items,
  loading,
  officeId,
  page,
  searchQuery,
  setPage,
  setQuery,
  totalCount,
  onDeleteOffice,
  agencyId,
  setOfficesHandler,
  onAddOffice,
  newOfficeCheck,
}) => (
  <Box>
    <Divider borderColor="#D1D2DB" opacity={1} />
    <Flex align="center" justify="space-between" my="15px">
      <Text color={colors.primary} fontFamily={fonts.roboto} fontSize="20px">
        Office Name
      </Text>
      <Box d="flex" flexDirection="row">
        {/*
        <SearchInput
          placeholder="Search..."
          value={searchQuery}
          onChange={setQuery}
        />
        */}
        <CustomButton
          isFullWidth={false}
          width="145px"
          ml="15px"
          borderRadius="10px"
          justifySelf="end"
          isLoading={false}
          isSmall
          onClick={() => {
            if (newOfficeCheck) {
              toast({
                title:
                  'Please finish submitting the already created office before creating another one.',
                status: 'warning',
                duration: 5000,
              });
            } else {
              setOfficesHandler([
                {
                  name: '',
                  email: '',
                  address: '',
                  phoneNumber: '',
                  contactPerson: '',
                },
                ...items,
              ]);
            }
          }}
        >
          Add New Office
        </CustomButton>
      </Box>
    </Flex>
    {!loading ? (
      <>
        {items.length ? (
          <Accordion defaultIndex={[0]} allowMultiple>
            {items.map((elem) => (
              <OfficeItem
                key={elem.id}
                {...elem}
                onEditOffice={onEditOffice}
                editingLoading={editingLoading}
                editingLoadingId={officeId}
                onDeleteOffice={onDeleteOffice}
                agencyId={agencyId}
                onAddOffice={onAddOffice}
              />
            ))}
          </Accordion>
        ) : (
          <Center h="500px">
            <Typography size={14}>There is no result to display</Typography>
          </Center>
        )}
      </>
    ) : (
      <Center h="500px">
        <Spinner size="xl" />
      </Center>
    )}

    <DashboardPaginator
      currentPage={page}
      pageSize={50}
      usersTotalCount={totalCount}
      membersLoading={loading}
      setCurrentPage={setPage}
    />
  </Box>
);
