import { createSlice } from '@reduxjs/toolkit';

import { PayloadError } from '../../types';
import { clearCurrentUser, setCurrentUser } from '../../common/helpers/auth/session.helper';
import {
  login, loginError, loginSuccess, logout,
} from '../actions/auth.actions';

export const initialState: {
  loading: boolean;
  accessToken: string;
  refreshToken: string;
  errors: PayloadError[];
  tokenExpiration: string | Date;
} = {
  loading: false,
  errors: [],
  accessToken: '',
  refreshToken: '',
  tokenExpiration: new Date(),
};

const sessionSlice = createSlice({
  name: 'token',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(login, (state) => {
        state.loading = true;
        state.errors = [];
        clearCurrentUser();
      })
      .addCase(loginSuccess, (state, action) => {
        state.loading = false;
        state.errors = [];
        setCurrentUser(action.payload.value);
      })
      .addCase(loginError, (state, action) => {
        state.loading = false;
        state.errors = action.payload;
      })
      .addCase(logout, (state) => {
        state.accessToken = '';
        state.refreshToken = '';
        state.tokenExpiration = '';
      });
  },
});

export default sessionSlice.reducer;
