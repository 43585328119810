import React from 'react';

export const ArrowIcon = () => (
  <svg
    width="12"
    height="10"
    viewBox="0 0 12 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.3081 4.46898H1.97305L4.78305 1.65898C4.83236 1.60967 4.87148 1.55113 4.89817 1.4867C4.92485 1.42227 4.93859 1.35322 4.93859 1.28348C4.93859 1.21374 4.92485 1.14469 4.89817 1.08026C4.87148 1.01583 4.83236 0.95729 4.78305 0.907979C4.73374 0.858667 4.6752 0.819551 4.61077 0.792864C4.54634 0.766177 4.47729 0.752441 4.40755 0.752441C4.33781 0.752441 4.26876 0.766177 4.20433 0.792864C4.1399 0.819551 4.08136 0.858667 4.03205 0.907979L0.317052 4.62398C0.267736 4.67329 0.228616 4.73183 0.201926 4.79626C0.175236 4.86068 0.161499 4.92974 0.161499 4.99948C0.161499 5.06922 0.175236 5.13827 0.201926 5.2027C0.228616 5.26713 0.267736 5.32567 0.317052 5.37498L4.03305 9.09098C4.08236 9.14029 4.1409 9.17941 4.20533 9.20609C4.26976 9.23278 4.33881 9.24652 4.40855 9.24652C4.47829 9.24652 4.54734 9.23278 4.61177 9.20609C4.6762 9.17941 4.73474 9.14029 4.78405 9.09098C4.83336 9.04167 4.87248 8.98313 4.89917 8.9187C4.92585 8.85427 4.93959 8.78521 4.93959 8.71548C4.93959 8.64574 4.92585 8.57669 4.89917 8.51226C4.87248 8.44783 4.83336 8.38929 4.78405 8.33998L1.97405 5.52998H11.3091C11.3788 5.52998 11.4478 5.51624 11.5123 5.48956C11.5767 5.46287 11.6352 5.42376 11.6845 5.37445C11.7338 5.32514 11.7729 5.26661 11.7996 5.20218C11.8263 5.13776 11.8401 5.06871 11.8401 4.99898C11.8401 4.92925 11.8263 4.8602 11.7996 4.79577C11.7729 4.73135 11.7338 4.67281 11.6845 4.6235C11.6352 4.5742 11.5767 4.53508 11.5123 4.5084C11.4478 4.48171 11.3788 4.46798 11.3091 4.46798L11.3081 4.46898Z"
      fill="#2C335C"
    />
  </svg>
);
