import React from 'react';
import { Box } from '@chakra-ui/react';

import { CustomButton } from '../../../../components';
import { ApprovedLicenseButtonsProps } from '../../types';

export const ApprovedLicenseButtons: React.FC<ApprovedLicenseButtonsProps> = ({
  setIsEditable,
  isEditable,
  licensesLoading,
  setIsSaveAsDraft,
}) => {
  return (
    <>
      {isEditable ? (
        <>
          <Box w="235px">
            <CustomButton isSmall onClick={() => setIsEditable(!isEditable)}>
              Cancel Updates
            </CustomButton>
          </Box>
          <Box w="235px">
            <CustomButton
              isSmall
              type="submit"
              isLoading={licensesLoading}
              onClick={() => setIsSaveAsDraft(true)}
            >
              Save updates
            </CustomButton>
          </Box>
        </>
      ) : (
        <Box w="235px" ml="auto">
          <CustomButton isSmall ml="auto" onClick={() => setIsEditable(!isEditable)}>
            Edit
          </CustomButton>
        </Box>
      )}
    </>
  );
};
