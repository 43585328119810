import React, { KeyboardEventHandler, useState } from 'react';
import {
  Box, Spinner,
} from '@chakra-ui/react';
import axios from 'axios';
import { axiosBase } from '../api/api.base';

interface ILoginBtn {
  userId: number;
}

const LoginBtn: React.FC<ILoginBtn> = ({
	userId,
}) => {
  const [loading, setLoading] = useState<boolean>(false);

	const loginAsuser = async () => {


		await axiosBase.post('api/auth/generateAuthToken/'+userId)
		.then(async response => {
			setLoading(false);
			
			window.open(window.baseAppUrl+'/auth/tokinLogin/check?token=' + response.data.token, '_blank');
		}).catch((error: any) => {
			//console.log('');
			setLoading(false);
		});
		
	};


  return (
    <Box>
			{loading? (
      <Spinner size="sm" />
			) : (
				<span style={{'cursor': 'pointer'}} onClick={() => {
					loginAsuser();
				}}>Login</span>
			)}
    </Box>
	);
};

export { LoginBtn };
