import React from 'react';

export const CommentsIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 20L4.3 16.1C3.17644 14.4382 2.76999 12.4704 3.15622 10.5623C3.54244 8.65415 4.69506 6.93563 6.39977 5.72623C8.10447 4.51683 10.2453 3.89885 12.4241 3.9872C14.6029 4.07554 16.6715 4.86419 18.2453 6.20652C19.819 7.54884 20.7909 9.3535 20.9801 11.2849C21.1693 13.2164 20.563 15.1432 19.2739 16.7071C17.9848 18.271 16.1007 19.3656 13.9718 19.7873C11.8429 20.2091 9.6142 19.9293 7.7 19L3 20" stroke="#2C335C" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 12V12.01" stroke="#2C335C" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8 12V12.01" stroke="#2C335C" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M16 12V12.01" stroke="#2C335C" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
