import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import ApiBase, { URL } from '../../../common/api/api.base';
import {
  getCompaniesRequest,
  getCompaniesError,
  getCompaniesSuccess,
} from '../../actions/agency.actions';
import { IResponseWithValue, CompaniesType } from '../../../types';

function* workerGetCompanies(action: ReturnType<typeof getCompaniesRequest>) {
  const statesNumber = action.payload.archivedCheckedStates?.map(
    (state) => `&States=${state}`
  );
  const states =
    action.payload.archivedCheckedStates?.length !== 0
      ? statesNumber?.join('')
      : '';
  const searchQuery = action.payload.SearchQuery
    ? `&SearchString=${action.payload.SearchQuery}`
    : '';
  try {
    const result: IResponseWithValue<{
      agencies: CompaniesType[];
      totalCount: number;
    }> = yield call(
      ApiBase.get,
      `${URL}/api/admin/companies?PageNumber=${action.payload.PageNumber}${searchQuery}&OrderType=${action.payload.orderType}${states}&PageSize=50`
    );
    if (result.success) {
      yield put(getCompaniesSuccess(result));
    } else {
      yield put(getCompaniesError(result.errors));
    }
  } catch (error: any) {
    yield put(getCompaniesError(error));
  }
}

export default function* watchGetCompanies(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeEvery(getCompaniesRequest.type, workerGetCompanies);
}
