import {
  call,
  ForkEffect,
  put,
  takeEvery,
} from 'redux-saga/effects';
import {
  saveAgencyStatesError,
  saveAgencyStatesRequest,
  saveAgencyStatesSuccess,
  getAgency,
} from '../../actions/agency.actions';
import ApiBase, { URL } from '../../../common/api/api.base';
import { IResponseWithoutValue } from '../../../types';

function* workerSaveAgencyStates(action: ReturnType<typeof saveAgencyStatesRequest>) {
  try {
    const result: IResponseWithoutValue = yield call(ApiBase.post, `${URL}/api/admin/agencies/${action.payload.id}/update-states`, action.payload.states);
    if (result.success) {
      yield put(saveAgencyStatesSuccess());
      yield put(getAgency(action.payload.id));
    } else {
      yield put(saveAgencyStatesError(result.errors));
    }
  } catch (error) {
    yield put(saveAgencyStatesError(error));
    console.log(error);
  }
}

export default function* watchSaveAgencyStatesSaga():
Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(saveAgencyStatesRequest.type, workerSaveAgencyStates);
}
