import { createAction } from '@reduxjs/toolkit';

import {
  AdvertismentModelType,
  CreateAdvertismentPostPayload,
  EditAdvertismentForAgencyPostPayload,
  EditAdvertismentPostPayload,
  GetAdvertismentForAgencyPayloadType,
  GetAdvertismentPayloadType,
  IResponseWithValue,
  PayloadError,
} from '../../types';

export const createAdvertismentPost =
  createAction<CreateAdvertismentPostPayload>('CREATE_ADVERTISMENT_POST');

export const deleteAdvertismentPost = createAction<{
  id: number;
  getAdvertismentParams?: GetAdvertismentPayloadType;
}>('DELETE_ADVERTISMENT_POST');

export const deleteAdvertismentPostForAgency = createAction<{
  id: number;
  getAdvertismentParams?: GetAdvertismentForAgencyPayloadType;
}>('DELETE_ADVERTISMENT_POST_FOR_AGENCY');

export const editAdvertismentPost = createAction<EditAdvertismentPostPayload>(
  'EDIT_ADVERTISMENT_POST'
);
export const editAdvertismentPostForAgency =
  createAction<EditAdvertismentForAgencyPostPayload>(
    'EDIT_ADVERTISMENT_POST_FOR_AGENCY'
  );

export const switchIsCreateAdvertismentModalOpen = createAction<boolean>(
  'SWITCH_IS_CREATE_ADVERTISMENT_MODAL_OPEN'
);

export const switchIsPostDetailsModalOpen = createAction<boolean>(
  'SWITCH_IS_POST_DETAILS_MODAL_OPEN'
);

export const switchIsDeletePostModalOpen = createAction<boolean>(
  'SWITCH_IS_DELETE_POST_MODAL_OPEN'
);

export const getAdvertisment =
  createAction<GetAdvertismentPayloadType>('GET_ADVERTISMENT');
export const getAdvertismentSuccess = createAction<
  IResponseWithValue<{
    posts: AdvertismentModelType[];
    totalCount: number;
  }>
>('GET_ADVERTISMENT_SUCCESS');
export const getAdvertismentError = createAction<PayloadError[]>(
  'GET_ADVERTISMENT_ERROR'
);

export const getAdvertismentForAgency =
  createAction<GetAdvertismentForAgencyPayloadType>(
    'GET_ADVERTISMENT_FOR_AGENCY'
  );
export const getAdvertismentForAgencySuccess = createAction<
  IResponseWithValue<{
    posts: AdvertismentModelType[];
    totalCount: number;
  }>
>('GET_ADVERTISMENT_FOR_AGENCY_SUCCESS');
export const getAdvertismentForAgencyError = createAction<PayloadError[]>(
  'GET_ADVERTISMENT_FOR_AGENCY_ERROR'
);

export const getAdvertismentPostById = createAction<number>(
  'GET_ADVERTISMENT_POST_BY_ID'
);
export const getAdvertismentPostByIdSuccess = createAction<
  IResponseWithValue<{
    post: AdvertismentModelType;
  }>
>('GET_ADVERTISMENT_POST_BY_ID_SUCCESS');
export const getAdvertismentPostByIdError = createAction<PayloadError[]>(
  'GET_ADVERTISMENT_POST_BY_ID_ERROR'
);

export const cleanAdvertismentPostByIdData = createAction(
  'CLEAR_ADVERTISMENT_POST_BY_ID_DATA'
);
