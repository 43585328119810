import React, { useState } from 'react';
import { Tabs, TabList, TabPanel, TabPanels, Flex } from '@chakra-ui/react';

import RenderAgentLicense from '../../../common/helpers/license/licenseRendering';
import { licensesInfoRendering } from '../../../common/helpers/license/licensesInfoRendering';
import {
  AgenciesType,
  GetAgenciesByStatePayload,
  StatesType,
  ConfirmMlsIdPayload,
  LicensesType,
  SetRealEstatePhotoPayload,
  VerifyMemberLicensesFunctionType,
} from '../../../types';

interface IDashboardLicensesFormProps {
  title: string;
  userId: number;
  onCloseModal: () => void;
  auxiliaryLoading: boolean;
  states: Array<StatesType>;
  agencies: Array<AgenciesType>;
  licensesInfo: Array<LicensesType>;
  onGetAgencies: (payload: GetAgenciesByStatePayload) => void;
  onDeleteMember: (id: number) => void;
  onUnArchiveMember: (id: number) => void;
  onDeleteMemberLicense: (id: number) => void;
  onSetRealEstatePhoto: (data: SetRealEstatePhotoPayload) => void;
  onVerifyMemberLicenses: VerifyMemberLicensesFunctionType;
  onConfirmIdLicense: (id: number) => void;
  userFullName?: string;
  onConfirmMldId?: (payload: ConfirmMlsIdPayload) => void;
  confirmMlsLoading?: boolean;
  isIdLicenseVerified: boolean;
  createdCompanyId: number;
  agencyAdded: boolean;
  agencyCompanyId: any;
}

export const DashboardLicensesForm: React.FC<IDashboardLicensesFormProps> = React.memo(
  ({
    title,
    userId,
    onDeleteMember,
    onUnArchiveMember,
    states,
    agencies,
    licensesInfo,
    onCloseModal,
    onGetAgencies,
    auxiliaryLoading,
    onVerifyMemberLicenses,
    onDeleteMemberLicense,
    onSetRealEstatePhoto,
    onConfirmIdLicense,
    userFullName,
    onConfirmMldId,
    confirmMlsLoading,
    isIdLicenseVerified,
    createdCompanyId,
    agencyAdded,
    agencyCompanyId,
  }) => {
    const [tabValue, setTabValue] = useState(0);

    return (
      <>
        <Tabs
          variant="solid-rounded"
          size="sm"
          mt="27px"
          colorScheme="none"
          onChange={(index: number) => setTabValue(index)}
          isLazy
        >
          <TabList w="100%" overflowY="visible" className="flex_column">
            {licensesInfo.length > 2 ? (
              <Flex
                overflowX="auto"
                overflowY="visible"
                w="100%"
                pt="13px"
                sx={{
                  scrollbarWidth: 'none',
                  '&::-webkit-scrollbar': {
                    display: 'none',
                  },
                }}
              >
                {licensesInfoRendering(licensesInfo, tabValue)}
              </Flex>
            ) : (
              licensesInfoRendering(licensesInfo, tabValue)
            )}
          </TabList>
          <TabPanels mt="50px">
            {licensesInfo.map(tab => (
              <TabPanel p="0" key={tab.id}>
                <RenderAgentLicense
                  onConfirmMldId={onConfirmMldId}
                  confirmMlsLoading={confirmMlsLoading}
                  userFullName={userFullName}
                  title={title}
                  userId={userId}
                  onDeleteMember={onDeleteMember}
                  onUnArchiveMember={onUnArchiveMember}
                  license={tab}
                  onCloseModal={onCloseModal}
                  states={states}
                  agencies={agencies}
                  onGetAgencies={onGetAgencies}
                  auxiliaryLoading={auxiliaryLoading}
                  onSetRealEstatePhoto={onSetRealEstatePhoto}
                  onDeleteMemberLicense={onDeleteMemberLicense}
                  onVerifyMemberLicenses={onVerifyMemberLicenses}
                  onConfirmIdLicense={onConfirmIdLicense}
                  isIdLicenseVerified={isIdLicenseVerified}
                  createdCompanyId={createdCompanyId}
                  agencyAdded={agencyAdded}
                  agencyCompanyId={agencyCompanyId}
                />
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </>
    );
  },
);
