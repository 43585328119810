import React, { ChangeEvent, LegacyRef, ReactElement } from 'react';
import {
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react';

import { Typography } from '../Typography';
import { colors, fonts } from '../../../utils';
import { CheckIcon, EyeIcon } from '../../../assets/icons';

interface ITextInputProps {
  isPassword?: boolean;
  isInvalid?: boolean;
  label: string;
  subLabel?: string;
  placeholder?: string;
  value?: string;
  defaultValue?: string;
  icon?: ReactElement;
  isConfirmed?: boolean;
  name?: string;
  color?: string;
  width?: string;
  isReadOnly?: boolean;
  onReset?: () => void;
  onBlur?: any;
  type?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void | any;
  rightIcon?: string | ReactElement;
  rightIconAsButton?: boolean;
  onClickRightIcon?: () => void
}

const TextInput: React.FC<ITextInputProps> = React.forwardRef(
  (
    {
      isPassword,
      isInvalid,
      label,
      subLabel,
      value,
      placeholder,
      icon,
      isConfirmed,
      defaultValue,
      onChange,
      name,
      onBlur,
      color,
      width,
      isReadOnly,
      onReset,
      type = 'text',
      rightIcon,
      rightIconAsButton,
      onClickRightIcon,
    },
    ref: LegacyRef<HTMLInputElement>,
  ) => {
    const [show, setShow] = React.useState(false);

    return (
      <Box w={width || '345px'} mb="5px">
        <Flex mb="1" justify="space-between" align="center">
          <Typography size={13} weight={300}>
            {label}
          </Typography>
          {subLabel && (
          <Box as="button" type="button" onClick={onReset}>
            <Typography size={13} weight={300} isUnderline>
              {subLabel}
            </Typography>
          </Box>
          )}
        </Flex>
        <InputGroup>
          {icon && (
          <InputLeftElement
            h="100%"
            pointerEvents="none"
          >
            {icon}
          </InputLeftElement>
          )}
          <Input
            ref={ref}
            name={name}
            value={value}
            onChange={onChange}
            bg={colors.ghost}
            fontFamily={fonts.poppins}
            fontSize="16px"
            size="lg"
            isFullWidth
            onBlur={onBlur}
            variant="filled"
            placeholder={placeholder}
            isInvalid={isInvalid}
            errorBorderColor={colors.error}
            color={color}
            defaultValue={defaultValue}
            isReadOnly={isReadOnly}
            type={!isPassword ? type : (show ? type : 'password')}
            _focus={isReadOnly ? undefined : {borderColor: '#3182CE', background: '#FFF'}}
            cursor='auto'
          />
          {rightIcon && (
            (rightIconAsButton ? (
              <InputRightElement
                right="13px"
                h="100%"
              >
                <Button onClick={onClickRightIcon} bgColor="transparent">
                  {rightIcon}
                </Button>
              </InputRightElement>
            ) : (
              <InputRightElement
                right="13px"
                h="100%"
              >
                {rightIcon}
              </InputRightElement>
            ))
          )}
          {isPassword && (
          <InputRightElement
            h="100%"
            as="button"
            type="button"
            onClick={() => setShow(!show)}
          >
            <EyeIcon />
          </InputRightElement>
          )}
          {isConfirmed && (
          <InputRightElement
            zIndex={1}
            h="100%"
            pointerEvents="none"
          >
            <CheckIcon isActive />
          </InputRightElement>
          )}
        </InputGroup>
      </Box>
    );
  },
);

export { TextInput };
