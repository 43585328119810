import {
  call, ForkEffect, put, takeEvery,
} from 'redux-saga/effects';
import {
  setUserViewedStatus,
  setUserViewedStatusSuccess,
  setUserViewedStatusError,
} from '../../actions/members.actions';
import ApiBase, { URL } from '../../../common/api/api.base';
import { IResponseWithoutValue } from '../../../types';

function* workerUserViewedStatus(action: ReturnType<typeof setUserViewedStatus>) {
  try {
    const result: IResponseWithoutValue = yield call(ApiBase.put, `${URL}/api/admin/set-user-viewed-status`, action.payload);
    console.log('workerUserViewedStatus', action, result);
    if (result.success) {
      yield put(setUserViewedStatusSuccess({ ...action.payload }));
    } else {
      yield put(setUserViewedStatusError(result.errors));
    }
  } catch (error) {
    yield put(setUserViewedStatusError(error));
    console.log(error);
  }
}

export default function* watchUserViewedStatusSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(setUserViewedStatus.type, workerUserViewedStatus);
}
