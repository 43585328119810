import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Divider,
  Flex,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spinner,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import { RootStore } from '../../../redux';
import { colors, fonts } from '../../../utils';
import { URL } from '../../../common/api/api.base';
import { SideTextEnum } from '../../../common/const/sideBar';
import { DashboardInfoForm, DashboardLicensesForm } from '../components';
import { CustomButton, Typography } from '../../../common/components';
import { getAgencies } from '../../../redux/actions/auxiliary.actions';
import {
  GetAgenciesByStatePayload,
  ConfirmMlsIdPayload,
  LicenseModelType,
  MemberInfoType,
  ResetPasswordPayload,
  SetIdLicensePhotoPayload,
  SetRealEstatePhotoPayload,
  SetUserViewedPayload,
  SuspendMemberPayload,
  UserIdPayload,
  VerifyMemberLicensesPayload,
} from '../../../types';
import { deleteMember, archiveMember } from '../../../redux/actions/members.actions';
import {
  getIdLicense,
  suspendMember,
  resetPassword,
  deleteIdLicense,
  confirmIdLicense,
  setIdLicensePhoto,
  getMemberLicenses,
  setRealEstatePhoto,
  deleteMemberLicenses,
  getMemberGeneralInfo,
  verifyMemberLicenses,
} from '../../../redux/actions/licenses.actions';
import { agentSearchRequest } from '../../../redux/actions/import.actions';

interface IDashboardModalProps {
  userId: number;
  title: string;
  loading: boolean;
  isModalOpen: boolean;
  onCloseModal: () => void;
  isDriverLicense: boolean;
  memberInfo: MemberInfoType;
  idLicenseInfo: LicenseModelType;
  onUnArchiveMember: (id: number) => void;
  onSetUserViewed?: (data: SetUserViewedPayload) => void;
  onConfirmMldId?: (payload: ConfirmMlsIdPayload) => void;
  confirmMlsLoading?: boolean;
}

const DashboardModal: React.FC<IDashboardModalProps> = ({
  isModalOpen,
  onCloseModal,
  loading,
  idLicenseInfo,
  memberInfo,
  isDriverLicense,
  onUnArchiveMember,
  userId,
  title,
  onSetUserViewed,
  confirmMlsLoading,
  onConfirmMldId,
}) => {
  const dispatch = useDispatch();
  const [tabText, setTabText] = useState('General Info');

  const onDeleteMember = (id: number) => dispatch(deleteMember(id));

  const onArchiveMember = (id: number) => dispatch(archiveMember(id));

  const onGetAgencies = (payload: GetAgenciesByStatePayload) => dispatch(getAgencies(payload));

  const onGetIdLicense = (data: UserIdPayload) => dispatch(getIdLicense(data));

  const onSuspendMember = (data: SuspendMemberPayload) => dispatch(suspendMember(data));

  const onResetPassword = (data: ResetPasswordPayload) => dispatch(resetPassword(data));

  const onDeleteIdLicense = (id: number) => dispatch(deleteIdLicense(id));

  const onConfirmIdLicense = (id: number) => dispatch(confirmIdLicense(id));

  const onSetIdLicensePhoto = (data: SetIdLicensePhotoPayload) => dispatch(setIdLicensePhoto(data));

  const onGetMemberLicenses = (data: UserIdPayload) => dispatch(getMemberLicenses(data));

  const onSetRealEstatePhoto = (data: SetRealEstatePhotoPayload) => {
    dispatch(setRealEstatePhoto(data));
  };

  const onDeleteMemberLicense = (id: number) => dispatch(deleteMemberLicenses(id));

  const onGetMemberGeneralInfo = (id: number) => dispatch(getMemberGeneralInfo(id));

  const onVerifyMemberLicenses = (payload: {
    licensePayload: VerifyMemberLicensesPayload;
    userId: number;
  }) => dispatch(verifyMemberLicenses(payload));

  const onAgentSearch = (agent: string) => dispatch(agentSearchRequest(agent));

  const userAvatar = memberInfo.avatar
    ? `${URL}/api/storage/static-images/${memberInfo.avatar.itemHash}${memberInfo.avatar.itemExtension}`
    : '';

  const {
    agencies,
    states,
    loading: auxiliaryLoading,
  } = useSelector((state: RootStore) => state.auxiliary);
  const { agents, agentsLoading } = useSelector((state: RootStore) => state.import);
  const { licensesInfo } = useSelector((state: RootStore) => state.licenses);
  const createdCompanyId = useSelector((state: RootStore) => state.agency.createdCompanyId);
  const agencyAdded = useSelector((state: RootStore) => !!state.agency.errors);
  const agencyCompanyId = useSelector((state: RootStore) => state.agency.agencyCompanyId);

  useEffect(() => {
    setTabText(isDriverLicense ? 'General Info' : 'RE Licenses');
  }, [isDriverLicense]);

  useEffect(() => {
    if (tabText === 'General Info') {
      onGetIdLicense({ UserId: userId });
      onGetMemberGeneralInfo(userId);
      onGetMemberLicenses({ UserId: userId });
    } else {
      onGetIdLicense({ UserId: userId });
      onGetMemberLicenses({ UserId: userId });
    }
  }, [tabText]);
  useEffect(() => {
    if (title === SideTextEnum.VerifiedUsers || title === SideTextEnum.UnVerifiedUsers) {
      onSetUserViewed?.({
        userId,
        pageName: title === SideTextEnum.VerifiedUsers ? 'pending' : 'new',
      });
    }
  }, [title]);

  const handleSuspendMember = () => {
    onSuspendMember({
      UserId: idLicenseInfo.id,
      IsSuspended: !memberInfo.idSuspended,
    });
  };
  const handleArchiveMember = () => {
    onCloseModal();
    onArchiveMember(idLicenseInfo.id);
  };

  return (
    <>
      <Modal autoFocus={false} isOpen={isModalOpen} onClose={onCloseModal} size="4xl">
        <ModalOverlay />
        <ModalContent p="40px" bg="rgb(252, 252, 252)">
          {loading ? (
            <Box d="flex" justifyContent="center" alignItems="center" h="500px">
              <Spinner size="xl" />
              <ModalCloseButton m="50px" onClick={onCloseModal} />
            </Box>
          ) : (
            <>
              <Flex align="center">
                <Avatar name={memberInfo.fullName} src={userAvatar} size="lg" />
                <Flex flexDir="column" ml="24px" mr="75px" w="250px">
                  <Typography size={20} weight={500} family={fonts.roboto}>
                    {idLicenseInfo.fullName}
                  </Typography>
                  {title === SideTextEnum.ArchivedUsers ||
                  title === SideTextEnum.UnVerifiedUsers ? null : (
                    <>
                      <Box as="button" type="button" w="fit-content" onClick={handleArchiveMember}>
                        <Typography size={14} isUnderline family={fonts.roboto}>
                          Delete User
                        </Typography>
                      </Box>
                      <Box as="button" type="button" w="fit-content" onClick={handleSuspendMember}>
                        <Typography size={14} isUnderline family={fonts.roboto}>
                          {memberInfo.idSuspended ? 'UnSuspend User' : 'Suspend User'}
                        </Typography>
                      </Box>
                    </>
                  )}
                </Flex>
                <Flex>
                  <CustomButton
                    isSmall
                    background={tabText === 'RE Licenses' ? colors.white : colors.primary}
                    color={tabText === 'RE Licenses' ? colors.primary : colors.white}
                    onClick={() => setTabText('General Info')}
                  >
                    General Info
                  </CustomButton>
                  <Box ml="-10px">
                    <CustomButton
                      isSmall
                      background={tabText === 'General Info' ? colors.white : colors.primary}
                      color={tabText === 'General Info' ? colors.primary : colors.white}
                      onClick={() => setTabText('RE Licenses')}
                    >
                      Real Estate Licenses
                    </CustomButton>
                  </Box>
                </Flex>
              </Flex>
              <ModalCloseButton m="50px" onClick={onCloseModal} />
              <Divider mt="40px" />
              {tabText === 'General Info' ? (
                <DashboardInfoForm
                  title={title}
                  userId={userId}
                  licensesInfo={licensesInfo}
                  onCloseModal={onCloseModal}
                  idLicenseInfo={idLicenseInfo}
                  onDeleteMember={onDeleteMember}
                  onArchiveMember={onArchiveMember}
                  onResetPassword={onResetPassword}
                  onUnArchiveMember={onUnArchiveMember}
                  onDeleteIdLicense={onDeleteIdLicense}
                  onConfirmIdLicense={onConfirmIdLicense}
                  onSetIdLicensePhoto={onSetIdLicensePhoto}
                  isIdLicenseVerified={memberInfo.isIdLicenseVerified}
                  onAgentSearch={onAgentSearch}
                  agents={agents}
                  agentsLoading={agentsLoading}
                />
              ) : (
                <DashboardLicensesForm
                  onConfirmMldId={onConfirmMldId}
                  confirmMlsLoading={confirmMlsLoading}
                  userFullName={idLicenseInfo.fullName}
                  title={title}
                  states={states}
                  userId={userId}
                  agencies={agencies}
                  licensesInfo={licensesInfo}
                  onCloseModal={onCloseModal}
                  onGetAgencies={onGetAgencies}
                  onDeleteMember={onDeleteMember}
                  auxiliaryLoading={auxiliaryLoading}
                  onUnArchiveMember={onUnArchiveMember}
                  onSetRealEstatePhoto={onSetRealEstatePhoto}
                  onDeleteMemberLicense={onDeleteMemberLicense}
                  onVerifyMemberLicenses={onVerifyMemberLicenses}
                  onConfirmIdLicense={onConfirmIdLicense}
                  isIdLicenseVerified={memberInfo.isIdLicenseVerified}
                  createdCompanyId={createdCompanyId}
                  agencyAdded={agencyAdded}
                  agencyCompanyId={agencyCompanyId}
                />
              )}
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default DashboardModal;
