import { createSlice } from '@reduxjs/toolkit';
import {
  getOfficesRequest,
  getOfficesSuccess,
  getOfficesError,
  editOfficeRequest,
  editOfficeSuccess,
  editOfficeError,
} from '../actions/offices.actions';
import { PayloadError, Office } from '../../types';

type InitialState = {
  items: Office[];
  loading: boolean;
  totalCount: number;
  errors: PayloadError[];
  editing: {
    officeId: number;
    loading: boolean;
  }
};

const initialState: InitialState = {
  editing: {
    loading: false,
    officeId: 0,
  },
  items: [],
  errors: [],
  loading: false,
  totalCount: 0,
};

const officesSlice = createSlice({
  name: 'offices',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOfficesRequest, (state) => {
      state.loading = true;
      state.errors = [];
    }).addCase(getOfficesSuccess, (state, action) => {
      state.loading = false;
      state.items = action.payload.agencyOffices;
      state.totalCount = action.payload.totalCount;
    })
      .addCase(getOfficesError, (state, action) => {
        state.loading = false;
        state.errors = action.payload;
      })
      .addCase(editOfficeRequest, (state, action) => {
        state.editing.loading = true;
        state.editing.officeId = action.payload.agencyOfficeId;
      })
      .addCase(editOfficeSuccess, (state) => {
        state.editing.loading = false;
        state.editing.officeId = 0;
      })
      .addCase(editOfficeError, (state) => {
        state.editing.loading = false;
        state.editing.officeId = 0;
      });
  },
});

export default officesSlice.reducer;
