import {
  call, ForkEffect, put, takeEvery,
} from 'redux-saga/effects';
import {
  inviteNewUsersRequest,
  inviteNewUsersSuccess,
  inviteNewUsersError,
  deleteInviteRequest,
  deleteInviteSuccess,
  deleteInviteError,
} from '../../actions/agency.actions';
import API, { URL } from '../../../common/api/api.base';
import { IResponseWithoutValue } from '../../../types';

function* workerInviteNewUsers(action: ReturnType<typeof inviteNewUsersRequest>) {
  try {
    const result: IResponseWithoutValue = yield call(API.post, `${URL}/api/admin/invite-users`, action.payload);
    if (result.success) {
      yield put(inviteNewUsersSuccess(action.payload));
    } else {
      yield put(inviteNewUsersError(result.errors));
    }
  } catch (error) {
    console.log(error);
  }
}

function* workerDeleteInvite(action: ReturnType<typeof deleteInviteRequest>) {
  try {
    const result: IResponseWithoutValue = yield call(API.post, `${URL}/api/admin/delete-invite`, action.payload);
    if (result.success) {
      yield put(deleteInviteSuccess(action.payload));
    } else {
      yield put(deleteInviteError(result.errors));
    }
  } catch (error) {
    console.log(error);
  }
}

export default function* watchGetInvitesSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(inviteNewUsersRequest.type, workerInviteNewUsers);
  yield takeEvery(deleteInviteRequest.type, workerDeleteInvite);
}
