import {
  call, ForkEffect, put, takeEvery,
} from 'redux-saga/effects';
import API, { URL } from '../../../common/api/api.base';
import {
  inviteUsersRequest,
  inviteUsersSuccess,
  inviteUsersError,
} from '../../actions/invitation.actions';
import { IResponseWithoutValue } from '../../../types';

function* workerInviteUsers(action: ReturnType<typeof inviteUsersRequest>) {
  console.log(action, 'workerInviteUsersAction');
  try {
    const result: IResponseWithoutValue = yield call(API.post, `${URL}/api/admin/invite-users`, action.payload);
    if (result.success) {
      yield put(inviteUsersSuccess());
    } else {
      yield put(inviteUsersError(action.payload.userId ? result.errors : []));
    }
  } catch (error) {
    console.log(error, 'workerInviteUsers error');
  }
}

export default function* workerInviteUsersSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(inviteUsersRequest.type, workerInviteUsers);
}
