import {
  takeEvery, ForkEffect, call, put,
} from 'redux-saga/effects';

import ApiBase, { URL } from '../../../common/api/api.base';
import { resetPassword, resetPasswordError, resetPasswordSuccess } from '../../actions/licenses.actions';
import { IResponseWithoutValue } from '../../../types';

function* workerResetPassword(action: ReturnType<typeof resetPassword>) {
  try {
    const result: IResponseWithoutValue = yield call(ApiBase.put, `${URL}/api/admin/reset-password?UserId=${action.payload.UserId}&Password=${action.payload.Password}`);
    yield console.log('resetPassword', action, result);
    if (result.success) {
      yield put(resetPasswordSuccess());
    } else {
      yield put(resetPasswordError(result.errors));
    }
  } catch (error) {
    yield put(resetPasswordError(error));
    console.log('error', error);
  }
}

export default function* watchResetPasswordSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(resetPassword.type, workerResetPassword);
}
