import React, { Dispatch, SetStateAction } from 'react';
import {
  Box,
  Flex,
  Image,
  Spinner,
  ModalBody,
  Divider,
  Avatar,
} from '@chakra-ui/react';
import { CustomButton, Typography } from '../../../../../common/components';
import { NewAgencyForm } from '../../../components';
import FileUpload from '../../../../../common/components/FileUpload';
import { colors, fonts } from '../../../../../utils';
import { AgencyType } from '../../../../../types';

interface IGeneralInfoTabProps {
  loading: boolean;
  setIsModalOpen: (value: boolean) => void;
  defaultAgencyLogo?: string;
  agencyLogo: File | null;
  agencyId?: number;
  agency: AgencyType;
  partnerLoading: boolean;
  setAgencyLogo: Dispatch<SetStateAction<File | null>>;
  setAgencyName: Dispatch<SetStateAction<string>>;
  handleClickSeeStates: (value: number[]) => void;
  setIsSelectAgencyOpen: (value: boolean) => void;
  handleRemovePartner: (value: number) => void;
  selectedStates: number[];
  companyId: number;
}

export const GeneralInfoTab: React.FC<IGeneralInfoTabProps> = ({
  loading,
  setIsModalOpen,
  defaultAgencyLogo,
  agencyLogo,
  agencyId,
  agency,
  partnerLoading,
  handleClickSeeStates,
  setAgencyLogo,
  setAgencyName,
  setIsSelectAgencyOpen,
  handleRemovePartner,
  selectedStates,
  companyId,
}) => (
  <>
    {loading ? (
      <Box d="flex" justifyContent="center" alignItems="center" h="500px">
        <Spinner size="xl" />
      </Box>
    ) : (
      <>
        <ModalBody>
          <Flex alignItems="center">
            {defaultAgencyLogo ? (
              <Image
                h="64px"
                w="64px"
                mr="15px"
                borderRadius="50%"
                src={
                  agencyLogo
                    ? URL.createObjectURL(agencyLogo)
                    : defaultAgencyLogo
                }
                alt="Agent Logo"
                fallback={(
                  <Box
                    mr="15px"
                    d="flex"
                    h="64px"
                    w="64px"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Spinner size="md" />
                  </Box>
                )}
              />
            ) : agencyLogo ? (
              <Image
                h="64px"
                w="64px"
                mr="15px"
                src={URL.createObjectURL(agencyLogo)}
                alt="Agent Logo"
                borderRadius="50%"
                fallback={(
                  <Box
                    mr="15px"
                    d="flex"
                    h="64px"
                    w="64px"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Spinner size="md" />
                  </Box>
                )}
              />
            ) : (
              <Avatar w="64px" h="64px" name={agency.agency.name} mr="30px" />
            )}
            <FileUpload accept={'image/*'} onChange={setAgencyLogo}>
              <Typography isUnderline family={fonts.roboto} size={15}>
                Upload Logo
              </Typography>
            </FileUpload>
          </Flex>
          <NewAgencyForm
            agencyId={agencyId}
            agencyDetails={agency.agency}
            setAgencyName={setAgencyName}
            onClickSeeStates={handleClickSeeStates}
            onClose={() => setIsModalOpen(false)}
            selectedStates={selectedStates}
            companyId={companyId}
          />
          {agencyId ? (
            <>
              <Divider m="30px 0" />
              <Flex justifyContent="space-between" alignItems="center">
                <Typography weight={600} size={20}>
                  Partner Agencies
                </Typography>
                {agency.partners.length !== 0 ? (
                  <Typography size={16}>Actions</Typography>
                ) : null}
              </Flex>
              {agency.partners.length !== 0 ? <Divider mt="18px" /> : null}
              {agency.partners.map((ag) => (
                <Box key={ag.id}>
                  <Flex
                    p="20px 0"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography size={16}>{ag.name}</Typography>
                    <CustomButton variant="link" isSmall isFullWidth={false} color={colors.gray} background="transparent" isLoading={partnerLoading} onClick={() => handleRemovePartner(ag.id)}>
                    Delete connection
                      </CustomButton>

                  </Flex>
                  <Divider />
                </Box>
              ))}
              <Box w="340px" m="30px 0 100px">
                <CustomButton
                  isSmall
                  onClick={() => setIsSelectAgencyOpen(true)}
                >
                  Add Partner Agency
                </CustomButton>
              </Box>
            </>
          ) : null}
        </ModalBody>
      </>
    )}
  </>
);
