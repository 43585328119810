import {
  call, ForkEffect, put, takeEvery,
} from 'redux-saga/effects';

import ApiBase, { URL } from '../../../common/api/api.base';
import { getReports, getReportsError, getReportsSuccess } from '../../actions/reports.actions';
import { IResponseWithValue, ReportModelType } from '../../../types';

function* workerGetReports(action: ReturnType<typeof getReports>) {
  const {
    IsSuspended,
    Name,
    OrderType,
    PageNumber,
    PageSize,
    SortProperty,
    AgencyId,
  } = action.payload;
  const agencyId = AgencyId ? `&AgencyId=${AgencyId}` : '';
  const isSuspended = typeof IsSuspended === 'string' ? '' : `&IsSuspended=${IsSuspended}`;
  try {
    const result: IResponseWithValue<{
      count: number;
      updatesReportModels: ReportModelType[];
    }> = yield call(
      ApiBase.get,
      `${URL}/api/admin/get-reports?OrderType=${OrderType}&SortProperty=${SortProperty}&PageNumber=${PageNumber}&PageSize=${PageSize}${agencyId}${isSuspended}&Name=${Name}`,
    );
    yield console.log('getReports', action, result);
    if (result.success) {
      yield put(getReportsSuccess(result));
    } else {
      yield put(getReportsError(result.errors));
    }
  } catch (error) {
    yield put(getReportsError(error));
    console.log(error);
  }
}

export default function* watchGetReportsSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(getReports.type, workerGetReports);
}
