import { createAction } from '@reduxjs/toolkit';
import {
  GetInvitationUsersPayload, InvitationUser, ParsedInvitationUser, InviteUsersPayload, PayloadError,
} from '../../types';

export const getInvitationUsersRequest = createAction<GetInvitationUsersPayload>('GET_INVITATION_USERS_REQUEST');
export const getInvitationUsersSuccess = createAction<{ users: InvitationUser[], count: number }>('GET_INVITATION_USERS_SUCCESS');
export const getInvitationUsersError = createAction<PayloadError[]>('GET_INVITATION_USERS_ERROR');

export const deleteInvitedUserRequest = createAction<number>('DELETE_INVITED_USER_REQUEST');
export const deleteInvitedUserSuccess = createAction('DELETE_INVITED_USER_SUCCESS');
export const deleteInvitedUserError = createAction<PayloadError[]>('DELETE_INVITED_USER_ERROR');

export const getParsedUsersFromFileRequest = createAction<FormData>('GET_PARSED_USERS_FROM_FILE_REQUEST');
export const getParsedUsersFromFileSuccess = createAction<{ users: ParsedInvitationUser[] }>('GET_PARSED_USERS_FROM_FILE_SUCCESS');
export const getParsedUsersFromFileError = createAction<PayloadError[]>('GET_PARSED_USERS_FROM_FILE_ERROR');

export const uploadNewUsersRequest = createAction<ParsedInvitationUser[]>('UPLOAD_NEW_USERS_REQUEST');
export const uploadNewUsersSuccess = createAction('UPLOAD_NEW_USERS_SUCCESS');
export const uploadNewUsersError = createAction<PayloadError[]>('UPLOAD_NEW_USERS_ERROR');

export const inviteUsersRequest = createAction<InviteUsersPayload>('INVITE_USERS_REQUEST');
export const inviteUsersSuccess = createAction('INVITE_USERS_SUCCESS');
export const inviteUsersError = createAction<PayloadError[]>('INVITE_USERS_ERROR');

export const toggleInviteUserListModal = createAction<boolean>('TOGGLE_INVITE_USER_LIST_MODAL');
export const deleteUserFromInviteList = createAction<number>('DELETE_USER_FROM_INVITE_LIST');

export const changeInvitaionFilter = createAction<number>('CHANGE_INVITATION_FILTER');

export const setInvitationListOrder = createAction<string>('SET_INVITATION_LIST_ORDER');
