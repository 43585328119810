import {
  takeEvery, ForkEffect, call, put,
} from 'redux-saga/effects';
import ApiBase, { URL } from '../../../common/api/api.base';
import {
  getMemberGeneralInfo,
  getMemberGeneralInfoError,
  getMemberGeneralInfoSuccess,
} from '../../actions/licenses.actions';
import { MemberInfoType, IResponseWithValue } from '../../../types';

function* workerGetMemberGeneralInfo(action: ReturnType<typeof getMemberGeneralInfo>) {
  try {
    const result: IResponseWithValue<{ generalInfo: MemberInfoType }> = yield call(ApiBase.get, `${URL}/api/admin/get-member-general-info?UserId=${action.payload}`);
    yield console.log('workerGetMemberGeneralInfo', action, result);
    if (result.success) {
      yield put(getMemberGeneralInfoSuccess(result));
    } else {
      yield put(getMemberGeneralInfoError(result.errors));
    }
  } catch (error) {
    yield put(getMemberGeneralInfoError(error));
    console.log(error);
  }
}

export default function* watchGetMemberGeneralInfoSaga():
Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(getMemberGeneralInfo.type, workerGetMemberGeneralInfo);
}
