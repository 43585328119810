import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  Box, Center, Flex, Image, Spacer,
} from '@chakra-ui/react';

import { colors } from '../../../utils';
import { ArrowIcon } from '../../../assets/icons';
import background from '../../../assets/img/auth-background.png';
import {
  CustomButton,
  TextInput,
  Typography,
} from '../../../common/components';

const ForgotPassword = () => (
  <Flex>
    <Box w="50%">
      <Center h="100%">
        <Box d="flex" flexDirection="column" w="362px">
          <NavLink to="/auth">
            <Box
              pos="absolute"
              top="70px"
              border="1px"
              borderColor={colors.primary}
              borderRadius={100}
              p="10px"
            >
              <ArrowIcon />
            </Box>
          </NavLink>
          <Typography size={25} weight={600}>
            Forgot Password
          </Typography>
          <Box mt="3">
            <TextInput label="Email or phone number" placeholder="Enter" />
          </Box>
          <Box w="342px" mt="5">
            <CustomButton>Send code</CustomButton>
          </Box>
        </Box>
      </Center>
    </Box>
    <Spacer />
    <Box w="50%" h="100vh">
      <Image
        w="100%"
        h="100%"
        objectFit="cover"
        src={background}
        alt="background"
      />
    </Box>
  </Flex>
);

export default ForgotPassword;
