import { InputGroup } from '@chakra-ui/react';
import React, { Dispatch, ReactNode, SetStateAction, useRef } from 'react';

interface IFileUploadProps {
  h?: string;
  mt?: string | number;
  accept?: string;
  children?: ReactNode;
  onChange: Dispatch<SetStateAction<File | null>>;
  disabled?: boolean;
}
const FileUpload: React.FC<IFileUploadProps> = ({
  h = '100%',
  mt,
  onChange,
  accept,
  children,
  disabled,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleClick = () => {
    inputRef.current?.click();
  };

  return (
    <InputGroup onClick={handleClick} as="button" type="button" h={h} mt={mt}>
      <input
        type="file"
        hidden
        accept={accept}
        ref={inputRef}
        onChange={(e: any) => {
          onChange(e!.target!.files![0]);
          e.target.value = null;
        }}
        disabled={disabled}
      />
      <>{children}</>
    </InputGroup>
  );
};

export default FileUpload;
