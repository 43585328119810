import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  Box,
  Flex,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';

import { colors, fonts } from '../../../utils';
import { StatesType, AgenciesWithEmailsType } from '../../../types';
import AgencyDetailsModal from '../modals/AgencyDetailsModal/AgencyDetailsModal';
import {
  renderTableBody,
  renderTableHeader,
} from '../../../common/helpers/table/tableRendering';
import {
  CustomButton,
  FilterPopup,
  SearchInput,
  Typography,
} from '../../../common/components';
import { URL } from '../../../common/api/api.base';
import { BackArrow, FilterIcon } from '../../../assets/icons';
import { NavLink } from 'react-router-dom';

interface IAgenciesTableProps {
  agencyOrderType: boolean;
  auxiliaryLoading: boolean;
  states: Array<StatesType>;
  agencySearchQuery?: string;
  agencyCheckedStates: Array<number>;
  setAgencyOrderType: Dispatch<SetStateAction<boolean>>;
  agenciesWithEmails: Array<AgenciesWithEmailsType>;
  setAgencyCheckedStates: Dispatch<SetStateAction<Array<number>>>;
  setAgencySearchQuery: Dispatch<SetStateAction<string | undefined>>;
  setAgencyOrderField: Dispatch<SetStateAction<string | undefined>>;
  companyName: string;
  companyId: number;
  archivedCheckedStates: number[];
  setArchivedCheckedStates: (states: number[]) => void;
}

export const InnerAgenciesTable: React.FC<IAgenciesTableProps> = React.memo(
  ({
    agencyOrderType,
    agencySearchQuery,
    setAgencyOrderType,
    setAgencySearchQuery,
    setAgencyOrderField,
    auxiliaryLoading,
    agenciesWithEmails,
    companyName,
    companyId,
    states,
    archivedCheckedStates,
    setArchivedCheckedStates,
  }) => {
    const [agencyId, setAgencyId] = useState<number | undefined>(0);
    const [isDetailsOpen, setIsDetailsOpen] = useState(false);

    // const dencodedCompanyName = encodeURIComponent(companyName);
    const handleOpenAgencyDetails = (id: number | undefined) => {
      setAgencyId(id);
      setIsDetailsOpen(true);
    };

    const handleOrderUsers = (field: string) => {
      setAgencyOrderField(field);
      setAgencyOrderType(!agencyOrderType);
    };
    return (
      <Box>
        <Flex justify="space-between" align="center" p="25px 40px 40px">
          <Box d="flex" flexDir="row" alignItems="center">
            {/*
            <Box as={NavLink} to="/agencies" mr="10px">
              <BackArrow />
            </Box>
            */}
            <Typography size={20} family={fonts.roboto}>
              Agencies
            </Typography>
          </Box>
          <Flex>
            <FilterPopup
              states={states}
              checkedItems={archivedCheckedStates}
              setCheckedItems={setArchivedCheckedStates}
            >
              <Box w="270px" mr="4">
                <CustomButton
                  variant="outline"
                  color={colors.gray}
                  isSmall
                  leftIcon={<FilterIcon />}
                  background="transparent"
                >
                  State
                </CustomButton>
              </Box>
            </FilterPopup>
            <SearchInput
              placeholder="Search..."
              value={agencySearchQuery}
              onChange={setAgencySearchQuery}
            />
            <Box w="140px" ml="4">
              <CustomButton
                isSmall
                onClick={() => handleOpenAgencyDetails(undefined)}
                fontSize="14px"
              >
                Add New Agency
              </CustomButton>
            </Box>
          </Flex>
        </Flex>
        <Box p="0 20px" mb="50px">
          <Table colorScheme="blackAlpha" className="fixed">
            <Thead>
              <Tr bg="#EDF2F7">
                {renderTableHeader(colors.gray, 'Agency Name', () =>
                  handleOrderUsers('name')
                )}
                {renderTableHeader(colors.gray, '')}
                <Th isNumeric textTransform="capitalize" pr="100px">
                  <Typography
                    size={16}
                    family={fonts.roboto}
                    color={colors.gray}
                  >
                    Actions
                  </Typography>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {auxiliaryLoading ? (
                <Tr pos="relative" h="200px">
                  <Td
                    border="0"
                    pos="absolute"
                    top="50%"
                    left="50%"
                    transform="translate(-50%, -50%)"
                  >
                    <Spinner size="xl" />
                  </Td>
                </Tr>
              ) : agenciesWithEmails.length !== 0 ? (
                agenciesWithEmails.map((item) => (
                  <Tr key={item.id}>
                    {renderTableBody(
                      colors.gray,
                      item.name,
                      item.logo.itemHash === null
                        ? item.name
                        : `${URL}/api/storage/static-images/${item.logo?.itemHash}${item.logo?.itemExtension}`
                    )}
                    {renderTableBody(colors.gray, item.email)}
                    <Td isNumeric pr="100px">
                      <CustomButton
                        variant="link"
                        onClick={() => handleOpenAgencyDetails(item.id)}
                        isSmall
                        isFullWidth={false}
                        color={colors.gray}
                        background="transparent"
                      >
                        Details
                      </CustomButton>
                    </Td>
                  </Tr>
                ))
              ) : null}
            </Tbody>
          </Table>
        </Box>
        <AgencyDetailsModal
          agencyId={agencyId}
          isOpen={isDetailsOpen}
          setIsModalOpen={setIsDetailsOpen}
          companyId={companyId}
        />
      </Box>
    );
  }
);
