import {
  call, ForkEffect, put, takeEvery,
} from 'redux-saga/effects';
import {
  saveIdLicense,
  saveIdLicenseError,
  saveIdLicenseSuccess,
} from '../../actions/licenses.actions';
import ApiBase, { URL } from '../../../common/api/api.base';
import { IResponseWithoutValue } from '../../../types';

function* workerSaveIdLicense(action: ReturnType<typeof saveIdLicense>) {
  try {
    const result: IResponseWithoutValue = yield call(ApiBase.put, `${URL}/api/admin/save-id-license-info`, action.payload);
    yield console.log('saveIdLicense', action, result);
    if (result.success) {
      yield put(saveIdLicenseSuccess());
    } else {
      yield put(saveIdLicenseError(result.errors));
    }
  } catch (error) {
    yield put(saveIdLicenseError(error));
    console.log(error);
  }
}

export default function* watchSaveIdLicenseSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(saveIdLicense.type, workerSaveIdLicense);
}
