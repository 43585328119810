import React, {
  Dispatch, SetStateAction, useEffect,
} from 'react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import {
  Avatar, Box,
  Flex,
  Modal, ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay, Spinner,
  Image, Stack,
} from '@chakra-ui/react';

import { RootStore } from '../../../redux';
import { colors, fonts } from '../../../utils';
import { URL } from '../../../common/api/api.base';
import { ReportComments } from '../../../common/section';
import { ReportDetailsType } from '../../../types';
import { BookMarkIcon, CommentsIcon } from '../../../assets/icons';
import {
  deleteReport, getReportDetails, ignoreReport, suspendReport,
} from '../../../redux/actions/reports.actions';
import {
  CustomButton, OtherReportBox, Typography,
} from '../../../common/components';

type IReportDetailsModalProps = {
  isOpen: boolean;
  reportId: number;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
};

const ReportDetailsModal:React.FC<IReportDetailsModalProps> = React.memo(({
  isOpen,
  setIsModalOpen,
  reportId,
}) => {
  const reportDetails: ReportDetailsType = useSelector(
    (state: RootStore) => state.reports.reportDetails,
  );
  const { loading } = useSelector((state: RootStore) => state.reports);
  const { extraLoading } = useSelector((state: RootStore) => state.reports);

  const dispatch = useDispatch();
  const onDeleteReport = () => dispatch(deleteReport(reportId));
  const onSuspendReport = () => dispatch(suspendReport(reportId));
  const onGetReportDetails = () => dispatch(getReportDetails(reportId));
  const onIgnoreReport = () => dispatch(ignoreReport({ postId: reportId }));

  const handleDeleteReport = () => {
    onDeleteReport();
    setIsModalOpen(false);
  };

  const handleIgnoreReport = () => {
    onIgnoreReport();
    setIsModalOpen(false);
  };

  useEffect(() => {
    onGetReportDetails();
  }, [reportId]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => setIsModalOpen(false)}
        autoFocus={false}
        size="4xl"
      >
        <ModalOverlay />
        <ModalContent p="20px">
          {loading
            ? (
              <Box d="flex" justifyContent="center" alignItems="center" h="500px">
                <Spinner size="xl" />
                <ModalCloseButton m="50px" onClick={() => setIsModalOpen(false)} />
              </Box>
            ) : (
              <>
                <ModalHeader alignItems="center" d="flex">
                  <Typography weight={600} size={20}>
                    Post
                  </Typography>
                  <ModalCloseButton m="20px" color={colors.gray} />
                </ModalHeader>
                <ModalBody mt="20px">
                  <Flex alignItems="center">
                    <Avatar
                      loading="lazy"
                      w="32px"
                      h="32px"
                      name="User"
                      src={reportDetails.createdBy.avatar.itemHash !== null
                        ? `${URL}/api/storage/static-images/${reportDetails.createdBy.avatar.itemHash}${reportDetails.createdBy.avatar.itemExtension}`
                        : undefined}
                      mr="8px"
                    />
                    <Box mr="20px">
                      <Typography
                        size={16}
                        weight={600}
                      >
                        {reportDetails.createdBy.fullName}
                      </Typography>
                    </Box>
                    <Box mr="140px">
                      <Typography size={14} color={colors.gray}>
                        {dayjs(reportDetails.createdAt).format('MM/DD/YYYY')}
                      </Typography>
                    </Box>
                    <Flex alignItems="center" mr="24px">
                      <Box mr={1}>
                        <BookMarkIcon />
                      </Box>
                      <Typography size={16}>
                        {reportDetails.saveCount}
                      </Typography>
                    </Flex>
                    <Flex alignItems="center">
                      <Box mr={1}>
                        <CommentsIcon />
                      </Box>
                      <Typography size={16}>
                        {reportDetails.comments.length}
                      </Typography>
                    </Flex>
                  </Flex>
                  <Flex justifyContent="space-between" mt="20px">
                    <Box mr="20px">
                      {reportDetails.photo.itemHash !== null ? (
                        <Image
                          loading="lazy"
                          height="320px"
                          width="100%"
                          src={`${URL}/api/storage/static-images/${reportDetails.photo.itemHash}${reportDetails.photo.itemExtension}`}
                          alt="Report Photo"
                        />
                      ) : null}
                      {reportDetails.tags.length !== 0
                        ? (
                          <Flex mt="18px" flexWrap="wrap">
                            {reportDetails.tags.map((item) => (
                              <Box
                                key={item.id}
                                borderColor={colors.gray}
                                borderWidth="1px"
                                borderRadius="40px"
                                padding="8px 12px"
                                mr="22px"
                                mb="12px"
                              >
                                <Typography key={item.id} size={16}>
                                  {item.name}
                                </Typography>
                              </Box>
                            ))}
                          </Flex>
                        )
                        : null}
                      <Box mt={4}>
                        <Typography size={24} weight={800} noOfLines={undefined}>
                          {reportDetails.title}
                        </Typography>
                      </Box>
                      <Box mt={2}>
                        <Typography size={16} color={colors.gray}>
                          {reportDetails.text}
                        </Typography>
                      </Box>
                      {reportDetails.comments.length !== 0
                        ? <ReportComments comments={reportDetails.comments} />
                        : null}
                    </Box>
                    {reportDetails.reports.length !== 0
                      ? (
                        <Flex flexDir="column">
                          <Box mb={4}>
                            <Typography size={16} family={fonts.roboto}>Reports:</Typography>
                          </Box>
                          {reportDetails.reports.map((item) => (
                            <OtherReportBox
                              item={item}
                              key={item.id}
                              reports={reportDetails.otherTypeReportsCauses}
                              count={reportDetails.otherTypeReportsCauses.length}
                            />
                          ))}
                          <Stack mt={7} spacing={2} direction="row" align="center">
                            <CustomButton isSmall onClick={handleIgnoreReport}>Ignore</CustomButton>
                            <CustomButton
                              background={reportDetails.isSuspended
                                ? colors.orange
                                : colors.primary}
                              isSmall
                              isLoading={extraLoading}
                              onClick={onSuspendReport}
                              isDisabled={reportDetails.isSuspended}
                            >
                              {reportDetails.isSuspended ? 'Suspended' : 'Suspend' }
                            </CustomButton>
                            <CustomButton isSmall onClick={handleDeleteReport}>Delete</CustomButton>
                          </Stack>
                        </Flex>
                      )
                      : null}
                  </Flex>
                </ModalBody>
              </>
            )}
        </ModalContent>
      </Modal>
    </>
  );
});

export default ReportDetailsModal;
