import React, { useEffect } from 'react';
import { Flex, Box } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { DashboardSideBar } from './components';
import { logout } from '../../redux/actions/auth.actions';
import { RootStore } from '../../redux';
import { getStates } from '../../redux/actions/auxiliary.actions';

export const MainLayout: React.FC = ({ children }) => {
  const dispatch = useDispatch();

  const { isAnyUserViewedFlags } = useSelector((state: RootStore) => state.members);

  const onLogout = () => dispatch(logout());

  useEffect(() => {
    dispatch(getStates());
  }, []);

  return (
    <Flex>
      <DashboardSideBar
        onLogout={onLogout}
        isAnyUserViewedFlags={isAnyUserViewedFlags}
      />
      <Box w="100%" bg="#F7FAFC">{children}</Box>
    </Flex>
  );
};