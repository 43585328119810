import {
  takeEvery, ForkEffect, call, put,
} from 'redux-saga/effects';

import ApiBase, { URL } from '../../../common/api/api.base';
import { confirmIdLicense, confirmIdLicenseError, confirmIdLicenseSuccess } from '../../actions/licenses.actions';
import { IResponseWithoutValue } from '../../../types';

function* workerConfirmIdLicense(action: ReturnType<typeof confirmIdLicense>) {
  try {
    const result: IResponseWithoutValue = yield call(ApiBase.put, `${URL}/api/admin/confirm-id-license?UserId=${action.payload}`);
    yield console.log('confirmIdLicense', action, result);
    if (result.success) {
      yield put(confirmIdLicenseSuccess());
    } else {
      yield put(confirmIdLicenseError(result.errors));
    }
  } catch (error) {
    console.log('confirmIdLicenseError', error);
  }
}

export default function* watchConfirmIdLicenseSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(confirmIdLicense.type, workerConfirmIdLicense);
}
