import {
  Checkbox,
  Flex,
  Modal,
  ModalBody,
  CloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  IconButton,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomButton, Typography } from '../../../common/components';
import {
  renderTableBody,
  renderTableHeader,
} from '../../../common/helpers/table/tableRendering';
import { RootStore } from '../../../redux';
import { colors, fonts } from '../../../utils';
import { DashboardPaginator } from '../components';
import { UploadIcon, EyeIcon, BackArrow } from '../../../assets/icons';
import StateUserModal from './StateUserModal';
import FileUpload from '../../../common/components/FileUpload';
import { SaveAgencyStatesPayload, UploadCSVPayload } from '../../../types';
import {
  saveAgencyStatesRequest,
  uploadCSVRequest,
} from '../../../redux/actions/agency.actions';
import { toast } from '../../../common/api/api.base';

interface IStatesModal {
  isOpen: boolean;
  onClose: () => void;
  agencyId: number;
  selectedStates: number[];
  setSelectedStates: (value: number[]) => void;
}

const StatesModal: React.FC<IStatesModal> = ({
  isOpen,
  onClose,
  agencyId,
  selectedStates,
  setSelectedStates,
}) => {
  const [isUsersModalOpen, setIsUserModalOpen] = useState<boolean>(false);
  const { loading } = useSelector((state: RootStore) => state.agency);
  const [currentPage, setCurrentPage] = useState(1);
  const [chosenStates, setChosenStates] = useState<Array<number>>([]);
  const [csv, setCsv] = useState<File | null>(null);
  const [stateId, setStateId] = useState<number>(0);

  const states = useSelector((state: RootStore) => state.auxiliary.states);

  const [paginatedStates, setPaginatedStates] = useState([]);

  const dispatch = useDispatch();

  const uploadCSV = (payload: UploadCSVPayload) =>
    dispatch(uploadCSVRequest(payload));
  const saveStates = (payload: SaveAgencyStatesPayload) =>
    dispatch(saveAgencyStatesRequest(payload));

  useEffect(() => {
    setChosenStates(selectedStates);
  }, [selectedStates]);

  const closeAndClean = () => {
    onClose();
    setSelectedStates([]);
  };

  useEffect(() => {
    if (csv !== null && stateId !== 0) {
      if (
        csv.type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        csv.name.split('.').pop() === 'csv'
      ) {
        uploadCSV({
          StateId: stateId,
          AgencyId: agencyId,
          UsersListFile: csv,
        });
        setCsv(null);
      } else {
        toast({
          title: 'This file format is not supported',
          status: 'error',
          duration: 5000,
        });
      }
    }
  }, [csv, stateId]);

  useEffect(() => {
    setPaginatedStates(
      states.slice((currentPage - 1) * 12, (currentPage - 1) * 12 + 12)
    );
  }, [currentPage]);

  const handleClickSave = () => {
    if (agencyId) {
      saveStates({ id: agencyId, states: chosenStates });
    } else {
      setSelectedStates(chosenStates);
      onClose();
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={closeAndClean}
        autoFocus={false}
        size="4xl"
      >
        <ModalOverlay />
        <ModalContent p="20px">
          <>
            <ModalHeader
              alignItems="center"
              d="flex"
              justifyContent="space-between"
            >
              <Flex alignItems="center">
                <IconButton
                  aria-label="Back to previous modal"
                  icon={<BackArrow />}
                  onClick={onClose}
                  mr="30px"
                  variant="unstyled"
                />
                <Typography weight={500} size={20}>
                  States
                </Typography>
              </Flex>
              <CloseButton color={colors.gray} onClick={closeAndClean} />
            </ModalHeader>
            <ModalBody>
              <Table colorScheme="blackAlpha" size="sm">
                <Thead>
                  <Tr bg="#EDF2F7">
                    {renderTableHeader(colors.gray, 'Select')}
                    {renderTableHeader(colors.gray, 'State name')}
                    <Th textTransform="capitalize">
                      <Typography
                        size={16}
                        family={fonts.roboto}
                        color={colors.gray}
                      >
                        Actions
                      </Typography>
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <>
                    {paginatedStates.length !== 0
                      ? paginatedStates.map((item: any) => (
                          <Tr key={item.id}>
                            <Td>
                              <Checkbox
                                colorScheme="facebook"
                                borderColor={colors.primary}
                                isChecked={chosenStates.includes(item.id)}
                                onChange={
                                  chosenStates.includes(item.id)
                                    ? () => {
                                        chosenStates.splice(
                                          chosenStates.indexOf(item.id),
                                          1
                                        );
                                        setChosenStates(
                                          chosenStates.filter(
                                            (i: number) => i !== item.id
                                          )
                                        );
                                      }
                                    : () =>
                                        setChosenStates([
                                          ...chosenStates,
                                          item.id,
                                        ])
                                }
                              />
                            </Td>

                            {renderTableBody(
                              chosenStates.includes(item.id)
                                ? colors.primary
                                : colors.gray,
                              item.name
                            )}
                            <Td isNumeric>
                              <Flex justify="flex-end" align="center">
                                <FileUpload
                                  onChange={setCsv}
                                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                  disabled={!chosenStates.includes(item.id)}
                                >
                                  <CustomButton
                                    isSmall
                                    width="auto"
                                    background="white"
                                    leftIcon={<UploadIcon />}
                                    color={
                                      chosenStates.includes(item.id)
                                        ? colors.primary
                                        : colors.gray
                                    }
                                    isDisabled={!chosenStates.includes(item.id)}
                                    fontFamily={fonts.roboto}
                                    fontSize={16}
                                    onClick={() => setStateId(item.id)}
                                    textDecoration="underline"
                                  >
                                    Upload Users
                                  </CustomButton>
                                </FileUpload>
                                <CustomButton
                                  isSmall
                                  flexShrink={0}
                                  width="auto"
                                  background="white"
                                  leftIcon={<EyeIcon />}
                                  color={
                                    chosenStates.includes(item.id)
                                      ? colors.primary
                                      : colors.gray
                                  }
                                  isDisabled={!chosenStates.includes(item.id)}
                                  fontFamily={fonts.roboto}
                                  fontSize={16}
                                  onClick={() => {
                                    setIsUserModalOpen(true);
                                    setStateId(item.id);
                                  }}
                                  textDecoration="underline"
                                >
                                  See Users
                                </CustomButton>
                              </Flex>
                            </Td>
                          </Tr>
                        ))
                      : null}
                  </>
                </Tbody>
              </Table>
              <DashboardPaginator
                pageSize={50}
                currentPage={currentPage}
                membersLoading={loading}
                usersTotalCount={states.length}
                setCurrentPage={setCurrentPage}
              />
              <Flex justify="space-between">
                <CustomButton
                  w="40%"
                  background="transparent"
                  variant="outline"
                  color={colors.gray}
                  isSmall
                  onClick={closeAndClean}
                >
                  Cancel
                </CustomButton>
                <CustomButton isSmall w="40%" onClick={handleClickSave}>
                  Save
                </CustomButton>
              </Flex>
            </ModalBody>
          </>
        </ModalContent>
        <StateUserModal
          isOpen={isUsersModalOpen}
          setIsModalOpen={setIsUserModalOpen}
          stateId={stateId}
          agencyId={agencyId}
        />
      </Modal>
    </>
  );
};

export default StatesModal;
