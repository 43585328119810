import React, { ReactElement } from 'react';
import {
  Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger,
} from '@chakra-ui/react';

import { fonts } from '../../../utils';
import { Typography } from '../Typography';

interface IPopupProps {
  text: string;
  children: ReactElement;
}
const Popup: React.FC<IPopupProps> = ({ children, text }) => (
  <Popover trigger="hover">
    <PopoverTrigger>
      {children}
    </PopoverTrigger>
    <PopoverContent>
      <PopoverArrow />
      <PopoverBody>
        <Typography family={fonts.roboto} size={16} />
        {text}
      </PopoverBody>
    </PopoverContent>
  </Popover>
);

export { Popup };
