import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spinner,
  Image,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';
import dayjs from 'dayjs';

import { CustomButton, Typography } from '../../../../common/components';
import PostInfoElem from './components/PostInfoElem';

import { RootStore } from '../../../../redux';
import { colors, fonts } from '../../../../utils';
import {
  getAdvertismentPostById,
  switchIsPostDetailsModalOpen,
} from '../../../../redux/actions/advertisment.actions';
import { getPhoto } from '../../../../common/helpers/getPhoto';
import { getNamesListString } from '../../../../common/helpers/getNamesListString';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  postId?: number;
  choosePostToDelete: (id: number) => void;
  choosePostToEdit: (id: number) => void;
}

const PostDetailsModal: FC<IProps> = ({
  isOpen,
  onClose,
  postId,
  choosePostToDelete,
  choosePostToEdit,
}) => {
  if (!isOpen) return null;

  const [photo, setPhoto] = useState<string | undefined>(undefined);

  const {
    advertismentPost: {
      title,
      body,
      states,
      agencies,
      tags,
      createdAt,
      daysLeft,
      link,
      photo: serverPhoto,
    },
    loadingPost,
  } = useSelector((state: RootStore) => state.advertisment);

  const dispatch = useDispatch();

  const onChoosePostToDelete = () => {
    if (postId) {
      choosePostToDelete(postId);
    }
  };

  const onChoosePostToEdit = () => {
    if (postId) {
      choosePostToEdit(postId);
    }
  };

  useEffect(() => {
    if (postId) {
      dispatch(getAdvertismentPostById(postId));
    }
  }, [postId]);

  useEffect(() => {
    if (serverPhoto) {
      const { itemHash, itemExtension } = serverPhoto;

      getPhoto(itemHash, itemExtension).then(result => setPhoto(URL.createObjectURL(result)));
    } else {
      setPhoto(undefined);
    }
  }, [serverPhoto?.itemHash, serverPhoto?.itemExtension]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="5xl">
      <ModalOverlay />
      <ModalContent p="20px">
        {loadingPost ? (
          <Box d="flex" justifyContent="center" alignItems="center" h="500px">
            <Spinner size="xl" />
            <ModalCloseButton m="50px" onClick={onClose} />
          </Box>
        ) : (
          <>
            <ModalHeader alignItems="center" d="flex">
              <Typography weight={600} size={20}>
                Post
              </Typography>
              <ModalCloseButton m="20px" color={colors.gray} />
            </ModalHeader>

            <ModalBody mt="20px">
              <SimpleGrid templateColumns="2fr 1fr" columnGap="20px">
                <Box>
                  {photo && (
                    <Image
                      maxW="505px"
                      maxH="325px"
                      src={photo}
                      alt="Post Photo"
                      objectFit="contain"
                      mb="25px"
                    />
                  )}
                  {Boolean(tags) && (
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '10px',
                      }}
                    >
                      {tags.map((tag: any) => (
                        <Text
                          key={tag.id}
                          p="10px"
                          color={colors.primary}
                          border={`1px solid ${colors.gray}`}
                          borderRadius="24px"
                        >
                          {tag.name}
                        </Text>
                      ))}
                    </div>
                  )}
                  <Text
                    fontSize="24px"
                    fontWeight="600"
                    mt="25px"
                    fontFamily={fonts.poppins}
                    color="#16192C"
                  >
                    {title}
                  </Text>

                  <Text mt="25px" fontFamily={fonts.poppins} color={colors.gray}>
                    {body}
                  </Text>
                </Box>

                <Box>
                  <VStack spacing="24px" alignItems="flex-start">
                    <PostInfoElem header="State:" data={getNamesListString(states)} />
                    <PostInfoElem
                      header="Agencies:"
                      data={getNamesListString(agencies) || 'All agencies'}
                    />
                    <PostInfoElem
                      header="Date of creation:"
                      data={dayjs(createdAt).format('MM/DD/YYYY')}
                    />
                    <PostInfoElem header="Days left:" data={daysLeft} />
                    <PostInfoElem header="Link:" data={link} />
                  </VStack>

                  <HStack spacing="10px" mt="24px">
                    <CustomButton onClick={onChoosePostToEdit}>Edit</CustomButton>
                    <CustomButton
                      bg="none"
                      color={colors.primary}
                      variant="outline"
                      border={colors.primary}
                      onClick={onChoosePostToDelete}
                    >
                      Delete
                    </CustomButton>
                  </HStack>
                </Box>
              </SimpleGrid>
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default PostDetailsModal;
