import {
  call,
  ForkEffect,
  put,
  takeEvery,
} from 'redux-saga/effects';
import {
  editOfficeRequest,
  editOfficeSuccess,
  editOfficeError,
} from '../../actions/offices.actions';
import { IResponseWithoutValue } from '../../../types';
import API, { URL } from '../../../common/api/api.base';

function* workerEditOffice(action: ReturnType<typeof editOfficeRequest>) {
  try {
    const result: IResponseWithoutValue = yield call(API.put, `${URL}/api/admin/agency-office/${action.payload.agencyOfficeId}`, action.payload);
    if (result.success) {
      yield put(editOfficeSuccess(action.payload.agencyOfficeId));
    } else {
      yield put(editOfficeError(result.errors));
    }
  } catch (error) {
    console.log(error, 'workerEditOffice error');
  }
}

export default function* watchEditOfficeSaga():
  Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(editOfficeRequest.type, workerEditOffice);
}
