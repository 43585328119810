import { createSlice } from '@reduxjs/toolkit';

import {
  getPostTags,
  getPostTagsError,
  getPostTagsSuccess,
} from '../actions/directories.actions';

import { PayloadError } from '../../types';
import { IdNameTypes } from '../../types/directories.types';

export const initialState: {
  loading: boolean;
  errors: PayloadError[];
  postTags: Array<IdNameTypes>;
} = {
  loading: false,
  errors: [],
  postTags: [],
};

const directoriesSlice = createSlice({
  name: 'directories',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPostTags, (state) => {
        state.loading = true;
        state.errors = [];
      })
      .addCase(getPostTagsSuccess, (state, action) => {
        state.loading = false;
        state.errors = [];
        state.postTags = action.payload.value.tags;
      })
      .addCase(getPostTagsError, (state, action) => {
        state.loading = false;
        state.errors = action.payload;
      });
  },
});

export default directoriesSlice.reducer;
