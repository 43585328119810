import React from 'react';
import { Text, TextProps } from '@chakra-ui/react';
import { colors, fonts } from '../../../utils';

interface ITypographyProps {
  size: number;
  family?: string;
  weight?: number;
  isUnderline?: boolean;
  color?: string;
  noOfLines?: number;
  lineHeight?: string;
  margin?: string;
  isTruncated?: boolean;
  textProps?: TextProps
}

const Typography: React.FC<ITypographyProps> = ({
  size,
  family,
  weight = 400,
  color = colors.primary,
  isUnderline,
  noOfLines,
  children,
  lineHeight,
  margin,
  isTruncated,
  textProps,
}) => (
  <Text
    fontFamily={family || fonts.poppins}
    fontSize={size}
    fontWeight={weight}
    color={color}
    noOfLines={noOfLines}
    as={isUnderline ? 'u' : 'samp'}
    lineHeight={lineHeight}
    margin={margin}
    isTruncated={isTruncated}
    {...textProps}
  >
    {children}
  </Text>
);

export { Typography };
