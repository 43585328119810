import React from 'react';
import { Badge, BadgeProps } from '@chakra-ui/react';

import { useBadgeOptions } from './hooks/useBadgeOptions';
import { ExpirationStatus } from '../../../types/license.types';

interface LicenseExpiryLableProps extends BadgeProps {
  status: ExpirationStatus['ACTIVE' | 'EXPIRED' | 'EXPIRING' | 'NEW'];
  expiresIn: number;
}

export const LicenseExpiryLable: React.FC<LicenseExpiryLableProps> = ({
  expiresIn,
  status,
  ...restProps
}) => {
  const { title, variant } = useBadgeOptions(status, expiresIn);

  return (
    <Badge variant={variant} {...restProps}>
      {title}
    </Badge>
  );
};
