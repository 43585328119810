import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DashboardDataImport, DashboardPaginator } from '../components';
import {
  getImportInfoRequest,
  deleteImportLog,
} from '../../../redux/actions/import.actions';
import { RootStore } from '../../../redux';
import { openModal, closeModal } from '../../../redux/actions/licenses.actions';

const PAGE_SIZE = 50;

export const DataImport: React.FC = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);

  const {
    importLogs,
    totalCount: dataImportTotalCount,
    loading: dataImportLoading,
  } = useSelector((state: RootStore) => state.import);

  const { isModalOpen } = useSelector((state: RootStore) => state.licenses);

  useEffect(() => {
    dispatch(
      getImportInfoRequest({
        PageNumber: currentPage,
        PageSize: PAGE_SIZE,
      })
    );
  }, [currentPage]);

  const onDeleteImportLog = (id: number) => dispatch(deleteImportLog(id));

  const onOpenModal = () => dispatch(openModal());
  const onCloseModal = () => dispatch(closeModal());

  return (
    <>
      <DashboardDataImport
        title="Data Import"
        importLogs={importLogs}
        membersLoading={dataImportLoading}
        onCloseModal={onCloseModal}
        isModalOpen={isModalOpen}
        onOpenModal={onOpenModal}
        onDeleteImportLog={onDeleteImportLog}
      />
      <DashboardPaginator
        pageSize={PAGE_SIZE}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        membersLoading={dataImportLoading}
        usersTotalCount={dataImportTotalCount}
      />
    </>
  );
};
