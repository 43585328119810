import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Box,
  Flex,
  Spinner,
  Table,
  Tbody,
  Td,
  Thead,
  Tr,
  Text,
} from '@chakra-ui/react';
import dayjs from 'dayjs';

import {
  CustomButton,
  FilterPopup,
  SearchInput,
  Typography,
} from '../../../common/components';
import CreateAdvertismentModal from '../modals/CreateAdvertismentModal';

import { RootStore } from '../../../redux';
import {
  cleanAdvertismentPostByIdData,
  deleteAdvertismentPost,
  getAdvertisment,
  switchIsCreateAdvertismentModalOpen,
  switchIsDeletePostModalOpen,
  switchIsPostDetailsModalOpen,
} from '../../../redux/actions/advertisment.actions';
import {
  getAgencies,
  getStates,
} from '../../../redux/actions/auxiliary.actions';
import { colors, fonts } from '../../../utils';
import {
  renderTableBody,
  renderTableHeader,
} from '../../../common/helpers/table/tableRendering';

import { FilterIcon } from '../../../assets/icons';
import { DashboardPaginator } from './DashboardPaginator';
import PostDetailsModal from '../modals/PostDetailsModal';
import { getNamesListString } from '../../../common/helpers/getNamesListString';
import DeletePostModal from '../modals/DeletePostModal';

const PAGE_SIZE = 50;

const statuses = [
  { id: 1, name: 'Published', code: 'published' },
  { id: 2, name: 'Expired', code: 'expired' },
];

const AdvertismentTable = () => {
  const [sortProperty, setSortProperty] = useState<string | null>(null);
  const [orderType, setOrderType] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [checkedStatus, setCheckedStatus] = useState<number[]>([]);
  const [checkedStates, setCheckedStates] = useState<number[]>([]);
  const [checkedAgencies, setCheckedAgencies] = useState<number[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [detailsPostId, setDetailsPostId] = useState<number | undefined>(
    undefined
  );

  const searchDebounceInterval = useRef<ReturnType<typeof setTimeout> | null>(
    null
  );

  const [postToEditId, setPostToEditId] = useState<number | undefined>(
    undefined
  );
  const [postToDeleteId, setPostToDeleteId] = useState<number | undefined>(
    undefined
  );

  const getAdvertismentParams = useRef({
    Title: searchQuery,
    OrderType: orderType,
    SortProperty: sortProperty,
    Agencies: checkedAgencies,
    States: checkedStates,
    Status: checkedStatus[0],
    PageNumber: pageNumber,
    PageSize: PAGE_SIZE,
  });

  const { advertisment, loading, totalCount } = useSelector(
    (state: RootStore) => state.advertisment
  );

  const isCreateAdvertismentModalOpen = useSelector(
    (state: RootStore) => state.advertisment.isCreateAdvertismentModalOpen
  );
  const isDeletePostModalOpen = useSelector(
    (state: RootStore) => state.advertisment.isDeletePostModalOpen
  );
  const isPostDetailsModalOpen = useSelector(
    (state: RootStore) => state.advertisment.isPostDetailsModalOpen
  );

  const states = useSelector((state: RootStore) => state.auxiliary.states);
  const agencies = useSelector((state: RootStore) => state.auxiliary.agencies);

  const dispatch = useDispatch();

  const handleOrderAdvertisment = (field: string) => {
    setSortProperty(field);
    setOrderType(!orderType);
  };

  const choosePostToSeeDetails = (id: number) => {
    setDetailsPostId(id);

    dispatch(switchIsPostDetailsModalOpen(true));
  };

  const choosePostToDelete = (id: number) => {
    setPostToDeleteId(id);
    dispatch(switchIsDeletePostModalOpen(true));
  };

  const choosePostToEdit = (id: number) => {
    setPostToEditId(id);
    dispatch(switchIsCreateAdvertismentModalOpen(true));
  };

  const closeCreateAdvertismentModal = () => {
    if (postToEditId) {
      setPostToEditId(undefined);

      if (!isPostDetailsModalOpen) {
        dispatch(cleanAdvertismentPostByIdData());
      }
    }
    dispatch(switchIsCreateAdvertismentModalOpen(false));
  };

  const closePostDetailsModal = () => {
    dispatch(switchIsPostDetailsModalOpen(false));
    dispatch(cleanAdvertismentPostByIdData());
    setDetailsPostId(undefined);
    setPostToEditId(undefined);
  };

  const deletePost = () => {
    if (postToDeleteId) {
      dispatch(
        deleteAdvertismentPost({
          id: postToDeleteId,
          getAdvertismentParams: getAdvertismentParams.current,
        })
      );

      dispatch(switchIsDeletePostModalOpen(false));
    }
  };

  useEffect(() => {
    if (states.length === 0) {
      dispatch(getStates());
    }
  }, []);

  useEffect(() => {
    if (checkedStates.length === 1) {
      dispatch(getAgencies({ stateId: checkedStates[0] }));
    } else {
      setCheckedAgencies([]);
    }
  }, [checkedStates[0], checkedStates.length]);

  useEffect(() => {
    getAdvertismentParams.current = {
      Title: searchQuery,
      OrderType: orderType,
      SortProperty: sortProperty,
      Agencies: checkedAgencies,
      States: checkedStates,
      Status: checkedStatus[0],
      PageNumber: pageNumber,
      PageSize: PAGE_SIZE,
    };

    dispatch(getAdvertisment(getAdvertismentParams.current));
  }, [
    orderType,
    sortProperty,
    pageNumber,
    checkedStates.length,
    checkedAgencies.length,
    checkedStatus[0],
    searchQuery,
  ]);

  return (
    <>
      <Flex justify="space-between" align="center" p="25px 40px 40px">
        <Box d="flex" flexDir="row" alignItems="center">
          <Text fontSize="20px" fontFamily={fonts.roboto} mr="12px">
            Advertisment
          </Text>
        </Box>

        <div
          style={{
            display: 'flex',
            gap: '12px',
          }}
        >
          <FilterPopup
            states={statuses}
            checkedItems={checkedStatus}
            setCheckedItems={setCheckedStatus}
            canChooseOnlyOne
          >
            <Box w="270px">
              <CustomButton
                variant="outline"
                color={colors.gray}
                isSmall
                leftIcon={<FilterIcon />}
                background="transparent"
              >
                Publish
              </CustomButton>
            </Box>
          </FilterPopup>

          <FilterPopup
            states={checkedStates.length === 1 ? agencies : []}
            checkedItems={checkedAgencies}
            setCheckedItems={setCheckedAgencies}
          >
            <Box w="270px">
              <CustomButton
                variant="outline"
                color={colors.gray}
                isSmall
                leftIcon={<FilterIcon />}
                background="transparent"
              >
                Agencies
              </CustomButton>
            </Box>
          </FilterPopup>

          <FilterPopup
            states={states}
            checkedItems={checkedStates}
            setCheckedItems={setCheckedStates}
          >
            <Box w="270px">
              <CustomButton
                variant="outline"
                color={colors.gray}
                isSmall
                leftIcon={<FilterIcon />}
                background="transparent"
              >
                State
              </CustomButton>
            </Box>
          </FilterPopup>

          <SearchInput
            placeholder="Search..."
            onChange={(newSearch: string) => {
              if (searchDebounceInterval.current) {
                clearTimeout(searchDebounceInterval.current);
              }

              searchDebounceInterval.current = setTimeout(() => {
                setSearchQuery(newSearch);
              }, 500);
            }}
          />

          <CustomButton
            isSmall
            w="120px"
            onClick={() => dispatch(switchIsCreateAdvertismentModalOpen(true))}
          >
            Create
          </CustomButton>
        </div>
      </Flex>

      <Box p="0 20px" mb="50px">
        <Table colorScheme="blackAlpha">
          <Thead>
            <Tr bg="#EDF2F7">
              {renderTableHeader(colors.gray, 'Advertisment', () =>
                handleOrderAdvertisment('Title')
              )}
              {renderTableHeader(colors.gray, 'State')}
              {renderTableHeader(colors.gray, 'Agency')}
              {renderTableHeader(colors.gray, 'Date', () =>
                handleOrderAdvertisment('CreatedAt')
              )}
              {renderTableHeader(colors.gray, 'Expired', () =>
                handleOrderAdvertisment('DaysLeft')
              )}
              {renderTableHeader(colors.gray, 'Tags')}
              {renderTableHeader(colors.gray, 'Status', () =>
                handleOrderAdvertisment('Status')
              )}
              {renderTableHeader(colors.gray, 'Actions')}
            </Tr>
          </Thead>
          <Tbody>
            {loading ? (
              <Tr pos="relative" h="200px">
                <Td
                  border="0"
                  pos="absolute"
                  top="50%"
                  left="50%"
                  transform="translate(-50%, -50%)"
                >
                  <Spinner size="xl" />
                </Td>
              </Tr>
            ) : advertisment.length !== 0 ? (
              advertisment.map((item: any) => (
                <Tr
                  key={item.id}
                  cursor="pointer"
                  onClick={() => choosePostToSeeDetails(item.id)}
                >
                  {renderTableBody(colors.gray, item.title)}
                  {renderTableBody(
                    colors.gray,
                    getNamesListString(item.states)
                  )}
                  {renderTableBody(
                    colors.gray,
                    getNamesListString(item.agencies) || 'All agencies'
                  )}
                  {renderTableBody(
                    colors.gray,
                    dayjs(item.createdAt).format('MM/DD/YYYY')
                  )}
                  {renderTableBody(colors.gray, item.daysLeft, undefined, true)}
                  {renderTableBody(colors.gray, getNamesListString(item.tags))}
                  {renderTableBody(
                    item.status === 'Published' ? colors.active : colors.gray,
                    item.status
                  )}
                  <Td maxW="200px">
                    <Flex align="center" justifyContent="space-between">
                      <Text
                        fontSize={16}
                        family={fonts.roboto}
                        color={colors.gray}
                        mr="20px"
                        cursor="pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          choosePostToDelete(item.id);
                        }}
                      >
                        Delete
                      </Text>

                      <Text
                        fontSize={16}
                        family={fonts.roboto}
                        color={colors.gray}
                        cursor="pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          choosePostToEdit(item.id);
                        }}
                      >
                        Edit
                      </Text>
                    </Flex>
                  </Td>
                </Tr>
              ))
            ) : (
              <Tr pos="relative" h="200px">
                <Td
                  border="0"
                  pos="absolute"
                  top="50%"
                  left="50%"
                  transform="translate(-50%, -50%)"
                >
                  <Typography size={14}>
                    There is no result to display
                  </Typography>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Box>

      <DashboardPaginator
        pageSize={PAGE_SIZE}
        currentPage={pageNumber}
        membersLoading={loading}
        usersTotalCount={totalCount}
        setCurrentPage={setPageNumber}
      />

      <CreateAdvertismentModal
        isOpen={isCreateAdvertismentModalOpen}
        onClose={closeCreateAdvertismentModal}
        editPostId={postToEditId}
        getAdvertismentParams={getAdvertismentParams.current}
      />

      <PostDetailsModal
        isOpen={isPostDetailsModalOpen}
        onClose={closePostDetailsModal}
        postId={detailsPostId}
        choosePostToDelete={choosePostToDelete}
        choosePostToEdit={choosePostToEdit}
      />

      <DeletePostModal
        isOpen={isDeletePostModalOpen}
        onClose={() => dispatch(switchIsDeletePostModalOpen(false))}
        deletePost={deletePost}
      />
    </>
  );
};

export default AdvertismentTable;
