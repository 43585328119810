import React from 'react';
import { Flex } from '@chakra-ui/react';

import { ArchivedUserButtons } from './atoms/ArchivedUserButtons';
import { SideTextEnum } from '../../../const/sideBar';
import { ActionButtonsProps } from '../types';
import { ApprovedLicenseButtons } from './atoms/ApprovedLicenseButtons';
import { UnapprovedLicenseButtons } from './atoms/UnapprovedLicenseButtons';

export const ActionButtons: React.FC<ActionButtonsProps> = ({
  userStatus,
  license,
  isEditable,
  licensesLoading,
  handlers,
}) => {
  const {
    handleDeleteMember,
    handleUnArchiveMember,
    handleDeleteLicense,
    setIsSaveAsDraft,
    setIsEditable,
  } = handlers;

  let buttons = null;

  // If user is archived
  if (userStatus === SideTextEnum.ArchivedUsers) {
    buttons = (
      <ArchivedUserButtons
        handleUnArchiveMember={handleUnArchiveMember}
        handleDeleteMember={handleDeleteMember}
      />
    );
  }

  // If license is active
  if (license.isVerified) {
    buttons = (
      <ApprovedLicenseButtons
        setIsSaveAsDraft={setIsSaveAsDraft}
        setIsEditable={setIsEditable}
        licensesLoading={licensesLoading}
        isEditable={isEditable}
      />
    );
  }

  // If license is not active
  if (!license.isVerified || license.isNew) {
    buttons = (
      <UnapprovedLicenseButtons
        handleDeleteLicense={handleDeleteLicense}
        setIsSaveAsDraft={setIsSaveAsDraft}
        license={license}
        licensesLoading={licensesLoading}
      />
    );
  }

  return (
    <Flex justifyContent="space-between" mt="25px">
      {buttons}
    </Flex>
  );
};
