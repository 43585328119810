import { ExpirationStatus } from '../../../../types/license.types';

export const useBadgeOptions = (
  status: ExpirationStatus['ACTIVE' | 'EXPIRED' | 'EXPIRING' | 'NEW'],
  expiresIn: number,
) => {
  const newLicense = status === 'new';
  const activeLicenseWithoutWarn = status === 'active';
  const activeLicenseWithWarn = status === 'expiring';

  const defineBadgeTitle = () => {
    if (newLicense) return 'New';
    if (activeLicenseWithoutWarn) return 'Active';
    if (activeLicenseWithWarn) return `Expires in ${expiresIn}d`;

    return 'Expired';
  };

  const defineBadgeVariant = () => {
    if (newLicense) return 'primary';
    if (activeLicenseWithoutWarn) return 'primary';
    if (activeLicenseWithWarn) return 'warn';

    return 'error';
  };

  return {
    title: defineBadgeTitle(),
    variant: defineBadgeVariant(),
  };
};
