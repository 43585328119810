import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DashboardPaginator, NewDashboardTable } from '../components';
import { RootStore } from '../../../redux';
import { getMembers } from '../../../redux/actions/members.actions';
import { SideTextEnum } from '../../../common/const/sideBar';

const PAGE_SIZE = 50;

export const NewApplications: React.FC = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [nameValue, setNameValue] = useState('');
  const [orderType, setOrderType] = useState(true);
  const [orderField, setOrderField] = useState<string | undefined>();
  const [memberCheckedStates, setMemberCheckedStates] = useState<number[]>([]);
  const {
    usersTotalCount,
    loading: membersLoading,
    isAnyUserViewedFlags,
  } = useSelector((state: RootStore) => state.members);
  //console.log('currentPage', currentPage);

  const [changeTable, setChangeTable] = useState(1);

  useEffect(() => {
    dispatch(
      getMembers({
        OrderField: orderField,
        OrderType: orderType,
        PageNumber: currentPage,
        PageSize: PAGE_SIZE,
        States: memberCheckedStates,
        Name: nameValue,
        IsIdLicenseVerified: false,
        IsAllLicensesVerified: null,
      })
    );
  }, [currentPage, nameValue, orderType, orderField, memberCheckedStates, changeTable]);
  

  return (
    <>
      <NewDashboardTable
        checkedStates={memberCheckedStates}
        nameValue={nameValue}
        orderType={orderType}
        setCheckedStates={setMemberCheckedStates}
        setNameValue={setNameValue}
        setOrderField={setOrderField}
        title={SideTextEnum.UnVerifiedUsers}
        setOrderType={setOrderType}
        setChangeTable={setChangeTable}
        tooltip="List of new account applications requiring user verification."
        showBadge={!isAnyUserViewedFlags.isAsNewApplicationViewed}
      />
      <DashboardPaginator
        pageSize={PAGE_SIZE}
        currentPage={currentPage}
        membersLoading={membersLoading}
        setCurrentPage={setCurrentPage}
        usersTotalCount={usersTotalCount}
      />
    </>
  );
};
