import {
  call, ForkEffect, put, takeEvery,
} from 'redux-saga/effects';

import {
  deleteReport,
  deleteReportError,
  deleteReportSuccess,
} from '../../actions/reports.actions';
import ApiBase, { URL } from '../../../common/api/api.base';
import { IResponseWithoutValue } from '../../../types';

function* workerDeleteReport(action: ReturnType<typeof deleteReport>) {
  try {
    const result: IResponseWithoutValue = yield call(ApiBase.delete, `${URL}/api/admin/delete-post?Id=${action.payload}`);
    yield console.log('deleteReport', action, result);
    if (result.success) {
      yield put(deleteReportSuccess(action.payload));
    } else {
      yield put(deleteReportError(result.errors));
    }
  } catch (error) {
    yield put(deleteReportError(error));
    console.log(error);
  }
}

export default function* watchDeleteReportSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(deleteReport.type, workerDeleteReport);
}
