import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  HStack,
  IconButton,
} from '@chakra-ui/react';

import { RootStore } from '../../../../redux';
import { colors, fonts } from '../../../../utils';
import { ArrowIcon, FilterIcon } from '../../../../assets/icons';
import { DashboardPaginator } from '../../components';
import {
  CustomButton,
  FilterPopup,
  SearchInput,
  Typography,
} from '../../../../common/components';
import { renderTableHeader } from '../../../../common/helpers/table/tableRendering';
import {
  addPartnerToAgency,
  getAgenciesForPartnership,
  removePartnerFromAgency,
  editPartnerAgencyChoicesRequest,
} from '../../../../redux/actions/agency.actions';
import {
  AddPartnerToAgencyPayload,
  AgencyPartnershipDetails,
  GetAgenciesForPartnershipPayload,
  EditPartnerAgencyChoicePayload,
  RemovePartnerFromAgencyPayload,
} from '../../../../types';
import { RowItem } from './RowItem';

const PAGE_SIZE = 50;

type ISelectAgencyModalProps = {
  isOpen: boolean;
  agencyId: number;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
};

const SelectAgencyModal: React.FC<ISelectAgencyModalProps> = React.memo(
  ({ isOpen, agencyId, setIsModalOpen }) => {
    const [agencyCurrentPage, setAgencyCurrentPage] = useState(1);
    const [agencyName, setAgencyName] = useState('');
    const [orderType, setOrderType] = useState(true);
    const [agencyCheckedStates, setAgencyCheckedStates] = useState<
      Array<number>
    >([]);

    const { isTableLoading } = useSelector((state: RootStore) => state.agency);
    const { statesByAgency } = useSelector(
      (state: RootStore) => state.auxiliary
    );
    const { partnerLoading } = useSelector((state: RootStore) => state.agency);
    const { agenciesForPartnershipTotalCount } = useSelector(
      (state: RootStore) => state.agency
    );
    const agenciesForPartnership: Array<AgencyPartnershipDetails> = useSelector(
      (state: RootStore) => state.agency.agenciesForPartnership
    );

    const dispatch = useDispatch();
    const onAddPartnerToAgency = (data: AddPartnerToAgencyPayload) =>
      dispatch(addPartnerToAgency(data));
    const onRemovePartnerFromAgency = (data: RemovePartnerFromAgencyPayload) =>
      dispatch(removePartnerFromAgency(data));
    const onGetAgenciesForPartnership = (
      data: GetAgenciesForPartnershipPayload
    ) => dispatch(getAgenciesForPartnership(data));

    const onEditPartnerAgencyChoices = (data: EditPartnerAgencyChoicePayload) =>
      dispatch(editPartnerAgencyChoicesRequest(data));

    const handleKeyDown = (event: any) => {
      if (event.key === 'Enter') {
        onGetAgenciesForPartnership({
          Id: agencyId,
          Name: agencyName,
          OrderType: orderType,
          States: agencyCheckedStates,
          PageNumber: agencyCurrentPage,
          PageSize: PAGE_SIZE,
        });
      }
    };

    useEffect(() => {
      onGetAgenciesForPartnership({
        Id: agencyId,
        Name: '',
        OrderType: orderType,
        States: agencyCheckedStates,
        PageNumber: agencyCurrentPage,
        PageSize: PAGE_SIZE,
      });
    }, [agencyId, agencyCurrentPage, orderType, agencyCheckedStates]);

    return (
      <>
        <Modal
          isOpen={isOpen}
          onClose={() => setIsModalOpen(false)}
          autoFocus={false}
          size="4xl"
        >
          <ModalOverlay />
          <ModalContent p="20px">
            <ModalHeader
              alignItems="center"
              d="flex"
              justifyContent="space-between"
            >
              <Flex alignItems="center" justify="space-between" w="100%">
                <HStack>
                  <IconButton
                    aria-label="Close select agency modal"
                    variant="unstyled"
                    borderColor={colors.gray}
                    borderWidth="1px"
                    borderRadius="100"
                    justifyContent="center"
                    alignItems="center"
                    d="flex"
                    mr="13px"
                    onClick={() => setIsModalOpen(false)}
                  >
                    <ArrowIcon />
                  </IconButton>
                  <Typography size={16} color={colors.gray}>
                    Back
                  </Typography>
                </HStack>
                <Flex alignItems="center">
                  <FilterPopup
                    states={statesByAgency}
                    checkedItems={agencyCheckedStates}
                    setCheckedItems={setAgencyCheckedStates}
                  >
                    <Box w="270px" mr="4">
                      <CustomButton
                        variant="outline"
                        color={colors.gray}
                        isSmall
                        leftIcon={<FilterIcon />}
                        background="transparent"
                      >
                        States
                      </CustomButton>
                    </Box>
                  </FilterPopup>
                  <SearchInput
                    placeholder="Search..."
                    value={agencyName}
                    onChange={setAgencyName}
                    handleKeyDown={handleKeyDown}
                  />
                </Flex>
              </Flex>
            </ModalHeader>
            <ModalBody minH="550px">
              <Flex alignItems="center" justifyContent="space-between">
                <Typography family={fonts.roboto} size={20}>
                  Select agency
                </Typography>
              </Flex>
              <Box mt="20px">
                <Table colorScheme="blackAlpha">
                  <Thead>
                    <Tr bg="#EDF2F7">
                      {renderTableHeader(colors.gray, 'Name of agency', () =>
                        setOrderType(!orderType)
                      )}
                      {renderTableHeader(colors.gray, 'Email')}
                      {renderTableHeader(colors.gray, 'Name')}
                      <Th isNumeric textTransform="capitalize">
                        <Typography
                          size={16}
                          family={fonts.roboto}
                          color={colors.gray}
                        >
                          Actions
                        </Typography>
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {isTableLoading ? (
                      <Tr pos="relative" h="400px">
                        <Td
                          border="0"
                          pos="absolute"
                          top="50%"
                          left="50%"
                          transform="translate(-50%, -50%)"
                        >
                          <Spinner size="xl" />
                        </Td>
                      </Tr>
                    ) : agenciesForPartnership.length !== 0 ? (
                      agenciesForPartnership.map((item) => (
                        <RowItem
                          agency={item}
                          partnerLoading={partnerLoading}
                          onEditPartnerAgencyChoices={
                            onEditPartnerAgencyChoices
                          }
                          onRemovePartnerFromAgency={onRemovePartnerFromAgency}
                          onAddPartnerToAgency={onAddPartnerToAgency}
                          agencyId={agencyId}
                        />
                      ))
                    ) : null}
                  </Tbody>
                </Table>
                <DashboardPaginator
                  pageSize={PAGE_SIZE}
                  currentPage={agencyCurrentPage}
                  membersLoading={isTableLoading}
                  usersTotalCount={agenciesForPartnershipTotalCount}
                  setCurrentPage={setAgencyCurrentPage}
                />
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    );
  }
);

export default SelectAgencyModal;
