import {
  takeEvery, ForkEffect, call, put,
} from 'redux-saga/effects';

import ApiBase, { URL } from '../../../common/api/api.base';
import { deleteIdLicense, deleteIdLicenseError, deleteIdLicenseSuccess } from '../../actions/licenses.actions';
import { IResponseWithoutValue } from '../../../types';

function* workerDeleteIdLicense(action: ReturnType<typeof deleteIdLicense>) {
  try {
    const result: IResponseWithoutValue = yield call(ApiBase.delete, `${URL}/api/admin/delete-id-license?Userid=${action.payload}`);
    yield console.log('deleteIdLicense', action, result);
    if (result.success) {
      yield put(deleteIdLicenseSuccess());
    } else {
      yield put(deleteIdLicenseError(result.errors));
    }
  } catch (error) {
    yield put(deleteIdLicenseError(error));
    console.log(error);
  }
}

export default function* watchDeleteIdLicenseSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(deleteIdLicense.type, workerDeleteIdLicense);
}
