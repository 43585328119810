import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Box,
  Flex,
  Spinner,
  Table,
  Tbody,
  Td,
  Thead,
  Tr,
  Text,
} from '@chakra-ui/react';
import dayjs from 'dayjs';

import { Typography } from '../../../../../common/components';
import CreateAdvertismentModal from '../../../modals/CreateAdvertismentModal';

import { RootStore } from '../../../../../redux';
import {
  deleteAdvertismentPostForAgency,
  getAdvertismentForAgency,
  switchIsCreateAdvertismentModalOpen,
  switchIsDeletePostModalOpen,
  switchIsPostDetailsModalOpen,
} from '../../../../../redux/actions/advertisment.actions';
import { colors, fonts } from '../../../../../utils';
import {
  renderTableBody,
  renderTableHeader,
} from '../../../../../common/helpers/table/tableRendering';

import { DashboardPaginator } from '../../../components/DashboardPaginator';
import PostDetailsModal from '../../../modals/PostDetailsModal';
import { getNamesListString } from '../../../../../common/helpers/getNamesListString';
import DeletePostModal from '../../../modals/DeletePostModal';

const PAGE_SIZE = 50;

interface IProps {
  agencyId?: number;
}

const AdvertismentTab: FC<IProps> = ({ agencyId }) => {
  const [pageNumber, setPageNumber] = useState(1);

  const [detailsPostId, setDetailsPostId] = useState<number | undefined>(
    undefined
  );
  const [postToEditId, setPostToEditId] = useState<number | undefined>(
    undefined
  );
  const [postToDeleteId, setPostToDeleteId] = useState<number | undefined>(
    undefined
  );

  const getAdvertismentForAgencyParams = useRef({
    AgencyId: agencyId,
    PageNumber: pageNumber,
    PageSize: PAGE_SIZE,
  });

  const { posts, loading, totalCount } = useSelector(
    (state: RootStore) => state.advertisment.advertismentForAgency
  );

  const isCreateAdvertismentModalOpen = useSelector(
    (state: RootStore) => state.advertisment.isCreateAdvertismentModalOpen
  );
  const isDeletePostModalOpen = useSelector(
    (state: RootStore) => state.advertisment.isDeletePostModalOpen
  );
  const isPostDetailsModalOpen = useSelector(
    (state: RootStore) => state.advertisment.isPostDetailsModalOpen
  );

  const dispatch = useDispatch();

  const choosePostToSeeDetails = (id: number) => {
    setDetailsPostId(id);

    dispatch(switchIsPostDetailsModalOpen(true));
  };

  const choosePostToDelete = (id: number) => {
    setPostToDeleteId(id);
    dispatch(switchIsDeletePostModalOpen(true));
  };

  const choosePostToEdit = (id: number) => {
    setPostToEditId(id);
    dispatch(switchIsCreateAdvertismentModalOpen(true));
  };

  const closeCreateAdvertismentModal = () => {
    if (postToEditId) {
      setPostToEditId(undefined);
    }
    dispatch(switchIsCreateAdvertismentModalOpen(false));
  };

  const closePostDetailsModal = () => {
    dispatch(switchIsPostDetailsModalOpen(false));
    setDetailsPostId(undefined);
    setPostToEditId(undefined);
  };

  const deletePost = () => {
    if (postToDeleteId) {
      dispatch(
        deleteAdvertismentPostForAgency({
          id: postToDeleteId,
          getAdvertismentParams: getAdvertismentForAgencyParams.current,
        })
      );

      dispatch(switchIsDeletePostModalOpen(false));
    }
  };

  useEffect(() => {
    getAdvertismentForAgencyParams.current = {
      AgencyId: agencyId,
      PageNumber: pageNumber,
      PageSize: PAGE_SIZE,
    };

    if (agencyId) {
      dispatch(
        getAdvertismentForAgency(getAdvertismentForAgencyParams.current)
      );
    }
  }, [pageNumber]);

  return (
    <>
      <Flex justify="space-between" align="center" p="25px 40px 40px">
        <Box d="flex" flexDir="row" alignItems="center">
          <Typography size={20} family={fonts.roboto}>
            Advertisment
          </Typography>
        </Box>
      </Flex>

      <Box p="0 20px" mb="50px">
        <Table colorScheme="blackAlpha" className="fixed">
          <Thead>
            <Tr bg="#EDF2F7">
              {renderTableHeader(colors.gray, 'Advertisment')}
              {renderTableHeader(colors.gray, 'State')}
              {renderTableHeader(colors.gray, 'Agency')}
              {renderTableHeader(colors.gray, 'Date')}
              {renderTableHeader(colors.gray, 'Expired')}
              {renderTableHeader(colors.gray, 'Tags')}
              {renderTableHeader(colors.gray, 'Status')}
              {renderTableHeader(colors.gray, 'Actions')}
            </Tr>
          </Thead>
          <Tbody>
            {loading ? (
              <Tr pos="relative" h="200px">
                <Td
                  border="0"
                  pos="absolute"
                  top="50%"
                  left="50%"
                  transform="translate(-50%, -50%)"
                >
                  <Spinner size="xl" />
                </Td>
              </Tr>
            ) : posts.length !== 0 ? (
              posts.map((item: any) => (
                <Tr
                  key={item.id}
                  cursor="pointer"
                  onClick={() => choosePostToSeeDetails(item.id)}
                >
                  {renderTableBody(colors.gray, item.title)}
                  {renderTableBody(
                    colors.gray,
                    getNamesListString(item.states)
                  )}
                  {renderTableBody(
                    colors.gray,
                    getNamesListString(item.agencies)
                  )}
                  {renderTableBody(
                    colors.gray,
                    dayjs(item.createdAt).format('MM/DD/YYYY')
                  )}
                  {renderTableBody(colors.gray, item.daysLeft)}
                  {renderTableBody(colors.gray, getNamesListString(item.tags))}
                  {renderTableBody(colors.gray, item.status)}
                  <Td maxW="200px">
                    <Flex align="center" justifyContent="space-between">
                      <Text
                        fontSize={16}
                        family={fonts.roboto}
                        color={colors.gray}
                        cursor="pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          choosePostToDelete(item.id);
                        }}
                      >
                        Delete
                      </Text>

                      <Text
                        fontSize={16}
                        family={fonts.roboto}
                        color={colors.gray}
                        cursor="pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          choosePostToEdit(item.id);
                        }}
                      >
                        Edit
                      </Text>
                    </Flex>
                  </Td>
                </Tr>
              ))
            ) : (
              <Tr pos="relative" h="200px">
                <Td
                  border="0"
                  pos="absolute"
                  top="50%"
                  left="50%"
                  transform="translate(-50%, -50%)"
                >
                  <Typography size={14}>
                    There is no result to display
                  </Typography>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Box>

      <DashboardPaginator
        pageSize={PAGE_SIZE}
        currentPage={pageNumber}
        membersLoading={loading}
        usersTotalCount={totalCount}
        setCurrentPage={setPageNumber}
      />

      <CreateAdvertismentModal
        isOpen={isCreateAdvertismentModalOpen}
        onClose={closeCreateAdvertismentModal}
        editPostId={postToEditId}
        getAdvertismentParams={getAdvertismentForAgencyParams.current}
      />

      <PostDetailsModal
        isOpen={isPostDetailsModalOpen}
        onClose={closePostDetailsModal}
        postId={detailsPostId}
        choosePostToDelete={choosePostToDelete}
        choosePostToEdit={choosePostToEdit}
      />

      <DeletePostModal
        isOpen={isDeletePostModalOpen}
        onClose={() => dispatch(switchIsDeletePostModalOpen(false))}
        deletePost={deletePost}
      />
    </>
  );
};

export default AdvertismentTab;
