import React from 'react';

import { Box, Select, Spinner, Text } from '@chakra-ui/react';

import { colors, fonts } from '../../../utils';

import { AgenciesType, StatesType } from '../../../types';

interface ICustomSelectProps {
  value?: string;
  defaultValue?: string;
  onChange?: any;
  isLoading?: boolean;
  isDisabled?: boolean;
  items: Array<StatesType | AgenciesType>;
  label?: string;
  borderRadius?: string;
  labelProps?: any;
  register?: any;
  valueIsId?: boolean;
  placeholder?: string;
  errors?: any;
}

const CustomSelect: React.FC<ICustomSelectProps> = ({
  isDisabled,
  isLoading,
  items,
  value,
  defaultValue,
  onChange,
  label,
  borderRadius,
  labelProps,
  register,
  valueIsId,
  placeholder = 'Select',
  errors,
}) => (
  <Box>
    {label && (
      <Text
        fontFamily={fonts.poppins}
        fontSize="14px"
        lineHeight="21px"
        pb="4px"
        color={colors.primary}
        {...labelProps}
      >
        {label}
      </Text>
    )}

    <Select
      onChange={onChange}
      value={value}
      defaultValue={defaultValue}
      isDisabled={isDisabled}
      placeholder={placeholder}
      size="lg"
      bg={colors.ghost}
      fontSize="16px"
      variant="filled"
      icon={isLoading ? <Spinner size="xl" /> : undefined}
      errorBorderColor={colors.error}
      fontFamily={fonts.poppins}
      borderRadius={borderRadius}
      {...register}
    >
      {items.map((item, index) => (
        <option key={item.name + index} value={valueIsId ? item.id : item.name}>
          {item.name}
        </option>
      ))}
    </Select>

    {errors?.message && (
      <Text mt="8px" fontSize="12px" color="#EC7474">
        {errors.message}
      </Text>
    )}
  </Box>
);

export { CustomSelect };
