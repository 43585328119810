import {
  call, ForkEffect, put, takeEvery,
} from 'redux-saga/effects';
import {
  getInvitedFilterDirectoryRequest,
  getInvitedFilterDirectorySuccess,
  getInvitedFilterDirectoryError,
} from '../../actions/auxiliary.actions';
import API, { URL } from '../../../common/api/api.base';
import { IResponseWithValue, AgenciesType } from '../../../types';

function* workerGetInviteFilterDirectory() {
  try {
    const result: IResponseWithValue<{ filters: AgenciesType[] }> = yield call(API.get, `${URL}/api/directories/uploaded-user-filters`);
    console.log(result, 'workerGetInviteFilterDirectory result');
    if (result.success) {
      yield put(getInvitedFilterDirectorySuccess(result.value.filters));
    } else {
      yield put(getInvitedFilterDirectoryError(result.errors));
    }
  } catch (error) {
    console.log(error, 'workerGetInviteFilterDirectory error');
  }
}

export default function* watchGetInviteFilterDirectorySaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(getInvitedFilterDirectoryRequest.type, workerGetInviteFilterDirectory);
}
