import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';
import {
  addNewAgency,
  addNewAgencyError,
  addNewAgencySuccess,
  addNewOffice,
  getAgenciesWithEmails,
  getAgencyCompanyIdRequest,
  openModalDraftDetails,
} from '../../actions/agency.actions';
import ApiBase, { URL } from '../../../common/api/api.base';
import { IResponseWithValue } from '../../../types';

function* workerAddNewAgency(action: ReturnType<typeof addNewAgency>) {
  try {
    const result: IResponseWithValue<number> = yield call(
      ApiBase.post,
      `${URL}/api/admin/companies/agencies`,
      action.payload.data
    );
    console.log('addNewAgency', result, action);
    if (result.success) {
      yield put(addNewAgencySuccess(result));
      yield put(getAgencyCompanyIdRequest(action.payload.companyId));
      if (action.payload.officePayload) {
        yield put(
          addNewOffice({
            ...action.payload.officePayload,
            agencyId: result.value,
          })
        );
        yield put(openModalDraftDetails());
      }
      yield put(
        getAgenciesWithEmails({
          PageNumber: 1,
          PageSize: 50,
          States: [],
          OrderType: true,
          companyId: action.payload.companyId,
        })
      );
    } else {
      yield put(addNewAgencyError(result.errors));
    }
  } catch (error: any) {
    yield put(addNewAgencyError(error));
    console.log(error);
  }
}

export default function* watchAddNewAgencySaga(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeEvery(addNewAgency.type, workerAddNewAgency);
}
