import React from 'react';
import { Box, Flex } from '@chakra-ui/react';

import { OtherPopup } from '../OtherPopup';
import { Typography } from '../Typography';
import { colors, fonts } from '../../../utils';
import { ReportsDictionaryType } from '../../../types';

interface IOtherReportBoxProps {
  count: number;
  item: ReportsDictionaryType;
  reports: Array<{ message: string; count: number }> ;
}

const OtherReportBox: React.FC<IOtherReportBoxProps> = ({ item, count, reports }) => (
  <Flex alignItems="center" justifyContent="space-between">
    {item.name === 'Other'
      ? (
        <OtherPopup
          count={count}
          body={(
            <Box>
              {reports.map((report) => (
                <Flex id={report.message} alignItems="center" justifyContent="space-between">
                  <Typography size={16} color={colors.gray}>
                    {report.message}
                  </Typography>
                  <Typography size={16} color={colors.gray}>
                    {report.count}
                  </Typography>
                </Flex>
              ))}
            </Box>
          )}
        >
          <Box>
            <Typography
              size={16}
              family={fonts.roboto}
              isUnderline
            >
              {item.name}
              :
            </Typography>
          </Box>
        </OtherPopup>
      )
      : (
        <Typography
          size={16}
          family={fonts.roboto}
          color={colors.gray}
        >
          {item.name}
        </Typography>
      )}
    <Typography
      size={16}
      family={fonts.roboto}
      color={colors.gray}
    >
      {item.count}
    </Typography>
  </Flex>
);

export { OtherReportBox };
