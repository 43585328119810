import React, { ReactElement } from 'react';
import {
    Modal,
    ModalContent,
    ModalOverlay,
    ModalCloseButton,
    Box,
} from '@chakra-ui/react';
import { Typography, CustomButton } from '../../../common/components';
import { colors } from '../../../utils';

interface IProps {
    isOpen: boolean
    setIsModalOpen: (isOpen: boolean) => void;
    actionFunction: (data: any) => void;
    title: string;
    subTitle: string;
    icon: ReactElement
}

export const OfficeAlertModal: React.FC<IProps> = ({
    isOpen,
    setIsModalOpen,
    actionFunction,
    title,
    subTitle,
    icon,
}) => (
    <Modal
        onClose={() => setIsModalOpen(false)}
        autoFocus={false}
        isOpen={isOpen}
        isCentered
    >
        <ModalOverlay />
        <ModalContent p="20px">
            <Box
                d="flex"
                alignItems="center"
                p="10px 0"
            >
                <Typography weight={600} size={16}>
                    {title}
                </Typography>

                <ModalCloseButton m="18px 10px" color={colors.gray} />
            </Box>
            <Box
                d="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                w="100%"
                m="60px 0"
            >
                {icon}
                <Typography textProps={{ textAlign: 'center' }} margin="20px 0 0 0" weight={400} size={16} color={colors.gray}>
                    {subTitle}
                </Typography>
            </Box>
            <Box
                d="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <CustomButton
                    isFullWidth={false}
                    background="transparent"
                    variant="outline"
                    width="30%"
                    height="50px"
                    borderRadius="10px"
                    justifySelf="end"
                    color={colors.primary}
                    isLoading={false}
                    onClick={() => setIsModalOpen(false)}
                >
                    Cancel
                </CustomButton>
                <CustomButton
                    isFullWidth={false}
                    width="30%"
                    height="50px"
                    borderRadius="10px"
                    justifySelf="end"
                    type="submit"
                    isLoading={false}
                    onClick={actionFunction}
                >
                    Yes
                </CustomButton>
            </Box>
        </ModalContent>
    </Modal>
);
