import { createAction } from '@reduxjs/toolkit';

import { IResponseWithValue, PayloadError } from '../../types';
import { IGetPostTagsSuccessResponse } from '../../types/directories.types';

export const getPostTags = createAction('GET_POST_TAGS');
export const getPostTagsSuccess = createAction<
  IResponseWithValue<IGetPostTagsSuccessResponse>
>('GET_POST_TAGS_SUCCESS');
export const getPostTagsError = createAction<PayloadError[]>(
  'GET_POST_TAGS_ERROR'
);
