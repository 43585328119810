import React from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';

import { colors, fonts } from '../../../utils';

interface ICustomButtonProps extends ButtonProps {
  isLoading?: boolean;
  isSmall?: boolean;
  onClick?: (data: any) => void;
  type?: 'button' | 'submit' | 'reset';
  variant?: 'solid' | 'ghost' | 'outline' | 'link'
  leftIcon?: React.ReactElement
  color?: string;
  background?: string;
  isDisabled?: boolean;
  border?: string;
  isFullWidth?: boolean;
}

const CustomButton: React.FC<ICustomButtonProps> = ({
  isLoading,
  isSmall,
  onClick,
  children,
  type,
  border,
  variant,
  color,
  background,
  isDisabled,
  leftIcon,
  isFullWidth = true,
  ...rest
}) => (
  <Button
    bg={background || colors.primary}
    color={color || colors.white}
    fontFamily={isSmall ? fonts.roboto : fonts.poppins}
    fontSize={16}
    fontWeight={400}
    height={isSmall ? '40px' : '59px'}
    isLoading={isLoading}
    borderRadius={isSmall ? '8px' : '20px'}
    onClick={onClick}
    type={type}
    isFullWidth={isFullWidth}
    variant={variant}
    leftIcon={leftIcon}
    isDisabled={isDisabled}
    borderColor={border || '#E2E8F0'}
    {...rest}
  >
    {children}
  </Button>
);

export { CustomButton };
