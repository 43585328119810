import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  Box,
  CloseButton,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spinner,
  Text,
} from '@chakra-ui/react';
import { useForm, Controller, useWatch } from 'react-hook-form';

import {
  CustomButton,
  CustomInput,
  CustomTextarea,
  MultiSelect,
  Typography,
} from '../../../../common/components';
import TagsField from './components/TagsField';
import { ImageUpload } from '../../../../common/components/ImageUpload';

import { RootStore } from '../../../../redux';
import { colors, fonts } from '../../../../utils';
import { getAgencies } from '../../../../redux/actions/auxiliary.actions';
import {
  createAdvertismentPost,
  editAdvertismentPost,
  editAdvertismentPostForAgency,
  getAdvertismentPostById,
} from '../../../../redux/actions/advertisment.actions';
import { getPhoto } from '../../../../common/helpers/getPhoto';

import {
  CreateAdvertismentPostPayload,
  EditAdvertismentForAgencyPostPayload,
  EditAdvertismentPostPayload,
  GetAdvertismentForAgencyPayloadType,
  GetAdvertismentPayloadType,
  SelectValue,
} from '../../../../types';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  editPostId?: number;
  getAdvertismentParams?: GetAdvertismentPayloadType | GetAdvertismentForAgencyPayloadType;
}

const postDurationDays = [30, 60, 90];

const CreateAdvertismentModal: FC<IProps> = ({
  isOpen,
  onClose,
  editPostId,
  getAdvertismentParams,
}) => {
  if (!isOpen) return null;

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({ shouldUnregister: true });
  const watchState = useWatch({ control, name: 'StatesIds' });
  const watchAgencies = useWatch({ control, name: 'AgenciesIds' });

  const [selectedPhoto, setSelectedPhoto] = useState<any>(null);

  const states = useSelector((state: RootStore) => state.auxiliary.states);
  const agencies = useSelector((state: RootStore) => state.auxiliary.agencies);

  const {
    advertismentPost: {
      id: postId,
      title: postTitle,
      body: postBody,
      link: postLink,
      states: postStates,
      agencies: postAgencies,
      tags: postTags,
      photo: postPhoto,
    },
    loadingPost,
  } = useSelector((state: RootStore) => state.advertisment);

  const dispatch = useDispatch();

  const onCreateAdvertismentPost = (data: CreateAdvertismentPostPayload) =>
    dispatch(createAdvertismentPost(data));
  const onEditAdvertismentPost = (data: EditAdvertismentPostPayload) =>
    dispatch(editAdvertismentPost(data));
  const onEditAdvertismentPostForAgency = (data: EditAdvertismentForAgencyPostPayload) =>
    dispatch(editAdvertismentPostForAgency(data));

  const needToUpdatePhoto = useRef(false);

  const onSubmit = (data: any) => {
    let formData: FormData | undefined = undefined;

    if (selectedPhoto) {
      let photo = selectedPhoto;
      if (!(photo instanceof File)) {
        const extention = photo.type.substring(photo.type.indexOf('/') + 1);

        photo = new File([photo], `photo.${extention}`, { type: photo.type });
      }

      formData = new FormData();
      formData.append('photo', photo);
    }

    const submitData = {
      ...data,
      AgenciesIds: data.AgenciesIds[0] === 0 ? null : data.AgenciesIds,
      Title: data.Title.trim(),
      Body: data.Body.trim(),
      Photo: formData,
      getAdvertismentParams,
    };

    if (editPostId) {
      const editSubmitData = {
        ...submitData,
        id: editPostId,
        NeedToUpdatePhoto: needToUpdatePhoto.current,
      };
      if (getAdvertismentParams && 'AgencyId' in getAdvertismentParams) {
        onEditAdvertismentPostForAgency(editSubmitData);
      } else {
        onEditAdvertismentPost(editSubmitData);
      }
    } else {
      onCreateAdvertismentPost(submitData);
    }

    onClose();
  };

  const onDropPhoto = useCallback(acceptedFiles => {
    setSelectedPhoto(acceptedFiles[0]);

    if (editPostId) {
      needToUpdatePhoto.current = true;
    }
  }, []);

  const resetPhoto = useCallback(() => {
    setSelectedPhoto(null);
    needToUpdatePhoto.current = true;
  }, []);

  const allAgenciesOption: SelectValue = { value: 0, label: 'All agencies' };
  const mappedAgencies: SelectValue[] = agencies.map((ag: any) => ({
    value: ag.id,
    label: ag.name,
  }));

  const allAgenciesSelected = watchAgencies?.includes(allAgenciesOption.value);

  const agenciesOption: SelectValue[] =
    watchState?.length === 1 && !allAgenciesSelected ? [allAgenciesOption, ...mappedAgencies] : [];

  const getAgencyIdsValue = (
    fieldValue: number[],
    isSelectedAllAgencies: boolean,
  ): SelectValue[] => {
    if (isSelectedAllAgencies) {
      return [allAgenciesOption];
    }
    const filteredAgencies = mappedAgencies.filter(ag =>
      fieldValue.some(elem => elem === ag.value),
    );
    return filteredAgencies;
  };

  useEffect(() => {
    if (watchState?.length === 1) {
      dispatch(getAgencies({ stateId: Number(watchState[0]) }));
    } else {
      setValue('AgenciesIds', []);
    }
  }, [watchState?.length]);

  useEffect(() => {
    if (isOpen && editPostId && editPostId !== postId) {
      dispatch(getAdvertismentPostById(editPostId));
    }
  }, [editPostId]);

  useEffect(() => {
    if (postId && editPostId === postId) {
      (async () => {
        const AgenciesIds = postAgencies?.length
          ? postAgencies.map((elem: any) => elem.id)
          : [allAgenciesOption.value];

        const resetData = {
          StatesIds: postStates.map((elem: any) => elem.id),
          AgenciesIds,
          Title: postTitle,
          Link: postLink,
          Body: postBody,
          Tags: postTags.map((elem: any) => elem.id),
          Photo: undefined,
        };

        if (postPhoto) {
          const { itemHash, itemExtension } = postPhoto;

          const Photo = await getPhoto(itemHash, itemExtension);

          if (Photo) {
            setSelectedPhoto(Photo);
          }
        }

        reset(resetData);
      })();
    }
  }, [postId, editPostId]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="5xl">
      <ModalOverlay />
      {/* @ts-ignore */}
      <ModalContent p="20px" minH="790px">
        <ModalHeader alignItems="center" d="flex" justifyContent="space-between">
          <Flex alignItems="center">
            <Typography weight={500} size={20}>
              Create new Post
            </Typography>
          </Flex>
          <CloseButton color={colors.gray} onClick={onClose} />
        </ModalHeader>
        <ModalBody h="100%" display="flex" alignItems="stretch" justifyContent="center">
          {loadingPost ? (
            <Spinner size="xl" alignSelf="center" />
          ) : (
            <SimpleGrid templateColumns="1fr 1fr" templateRows="100%" columnGap="55px">
              <Flex direction="column" justifyContent="space-between" h="93%">
                <Controller
                  name="StatesIds"
                  control={control}
                  render={({ field }) => {
                    let value = states.filter((st: any) =>
                      field?.value?.some((elem: any) => elem === st.id),
                    );

                    value = value.map((st: any) => ({
                      value: st.id,
                      label: st.name,
                    }));

                    return (
                      <Box>
                        <MultiSelect
                          label="States"
                          borderRadius="20px"
                          onChange={field.onChange}
                          value={value}
                          errors={errors.StatesIds?.message}
                          options={states.map((st: any) => ({
                            value: st.id,
                            label: st.name,
                          }))}
                        />
                      </Box>
                    );
                  }}
                  rules={{
                    required: { value: true, message: 'This is required' },
                  }}
                />
                <Controller
                  name="AgenciesIds"
                  control={control}
                  render={({ field }) => {
                    const isSelectedAllAgencies = field.value?.some((id: number) => id === 0);
                    const value = getAgencyIdsValue(field.value, isSelectedAllAgencies);

                    return (
                      <Box>
                        <MultiSelect
                          label="Agencies"
                          borderRadius="20px"
                          value={value}
                          placeholder={watchState?.length > 1 ? 'All agencies' : undefined}
                          onChange={(value: number[]) => {
                            if (value.some(id => id === 0)) {
                              field.onChange([allAgenciesOption.value]);
                              return;
                            }
                            field.onChange(value);
                          }}
                          options={agenciesOption}
                          isDisabled={Boolean(watchState?.length > 1)}
                          errors={errors.AgenciesIds?.message}
                        />
                      </Box>
                    );
                  }}
                  rules={{
                    required: {
                      value: watchState ? watchState.length < 2 : true,
                      message: 'This is required',
                    },
                  }}
                />
                <Controller
                  name="Title"
                  control={control}
                  render={({ field }) => (
                    <CustomInput
                      maxLength={150}
                      value={field.value}
                      label="post title"
                      placeholder="Write Post Title"
                      labelProps={{ pl: '15px' }}
                      errors={errors?.Title}
                      onChange={field.onChange}
                    />
                  )}
                  rules={{
                    required: 'This is required',
                    validate: value =>
                      value.trim().length > 1 || 'Texts legth must be at least 2 characters',
                  }}
                />
                <Controller
                  name="Link"
                  control={control}
                  render={({ field }) => (
                    <CustomInput
                      value={field.value}
                      label="link"
                      placeholder="https://"
                      labelProps={{ pl: '15px' }}
                      errors={errors?.Link}
                      onChange={field.onChange}
                    />
                  )}
                  rules={{
                    pattern: {
                      value: /^https:\/\/.+$/,
                      message: 'Link format should be like: https://[link-body]',
                    },
                  }}
                />
                <Controller
                  name="Body"
                  control={control}
                  render={({ field }) => (
                    <CustomTextarea
                      maxLength={1000}
                      hasCharCounter
                      value={field.value}
                      label="Post Text"
                      placeholder="Write Text"
                      minH="185px"
                      labelProps={{ pl: '15px' }}
                      errors={errors?.Body}
                      onChange={field.onChange}
                    />
                  )}
                  rules={{
                    required: 'This is required',
                    validate: value =>
                      value.trim().length > 1 || 'Texts legth must be at least 2 characters',
                  }}
                />
              </Flex>
              <Flex direction="column" justifyContent="space-between">
                <Controller
                  name="Tags"
                  control={control}
                  defaultValue={[]}
                  render={({ field }) => (
                    <TagsField
                      choosedTags={field.value}
                      onClick={(id: number) => {
                        const newTags = [...field.value];

                        if (newTags.includes(id)) {
                          const index = newTags.indexOf(id);

                          newTags.splice(index, 1);
                        } else {
                          newTags.push(id);
                        }

                        field.onChange(newTags);
                      }}
                    />
                  )}
                />
                <ImageUpload
                  onDrop={onDropPhoto}
                  uploadedPhoto={selectedPhoto}
                  resetPhoto={resetPhoto}
                />
                <Box>
                  <Text mb="4px" fontFamily={fonts.poppins} color={colors.primary} fontSize="14px">
                    Post Duration
                  </Text>
                  <SimpleGrid
                    templateColumns="repeat(auto-fit, minmax(0px, 1fr))"
                    border={`1px solid ${colors.gray}`}
                    borderRadius="8px"
                  >
                    <Controller
                      name="DurationDays"
                      control={control}
                      defaultValue={!editPostId ? postDurationDays[0] : undefined}
                      render={({ field }) => (
                        <>
                          {postDurationDays.map(days => {
                            const isChoosed = days === field.value;
                            return (
                              <Text
                                key={days}
                                p="11px 22px"
                                borderRadius="8px"
                                bgColor={isChoosed ? colors.primary : 'none'}
                                color={isChoosed ? 'white' : colors.primary}
                                opacity={editPostId ? '0.3' : '1'}
                                cursor={editPostId ? 'not-allowed' : 'pointer'}
                                onClick={() => {
                                  if (editPostId) {
                                    return;
                                  }
                                  if (days !== field.value) {
                                    field.onChange(days);
                                  }
                                }}
                              >
                                {`${days} Days`}
                              </Text>
                            );
                          })}
                        </>
                      )}
                    />
                  </SimpleGrid>
                </Box>
                <CustomButton onClick={handleSubmit(onSubmit)}>Post</CustomButton>
              </Flex>
            </SimpleGrid>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CreateAdvertismentModal;
