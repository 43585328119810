import React, { FC } from 'react';

import { Box, Text } from '@chakra-ui/react';

import { colors, fonts } from '../../../../../utils';

interface IProps {
  header: string;
  data?: number | string | Array<number | string>;
  mt?: string;
}

const PostInfoElem: FC<IProps> = ({ header, data, mt }) => {
  if (!data) {
    return null;
  }

  if (Array.isArray(data)) {
    if (!data.length) {
      return null;
    }

    data = data.join(', ');
  }

  return (
    <Box mt={mt}>
      <Text fontFamily={fonts.poppins} color={colors.primary} mb="8px">
        {header}
      </Text>
      <Text fontFamily={fonts.poppins} color={colors.gray}>
        {data}
      </Text>
    </Box>
  );
};

export default PostInfoElem;
