import { call, put, takeEvery } from 'redux-saga/effects';

import Api from '../../../common/api/api.base';

import { IResponseWithValue } from '../../../types';
import { IGetPostTagsSuccessResponse } from '../../../types/directories.types';
import {
  getPostTags,
  getPostTagsError,
  getPostTagsSuccess,
} from '../../actions/directories.actions';

function* workerGetPostTags() {
  try {
    const result: IResponseWithValue<IGetPostTagsSuccessResponse> = yield call(
      Api.get,
      '/api/directories/get-post-tags',
      {}
    );
    if (result.success) {
      yield put(getPostTagsSuccess(result));
    } else {
      yield put(getPostTagsError(result.errors));
    }
  } catch (error) {
    console.error('workerGetPostTags error', error);
  }
}

export function* watchGetPostTagsDirectorySaga() {
  yield takeEvery(getPostTags.type, workerGetPostTags);
}
