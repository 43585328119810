import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  Box,
  Flex,
  Spinner,
  Table,
  Tbody,
  Td,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import {
  CustomButton,
  FilterPopup,
  SearchInput,
  Typography,
} from '../../../common/components';
import { colors, fonts } from '../../../utils';
import { FilterIcon, TooltipIcon } from '../../../assets/icons';
import { URL } from '../../../common/api/api.base';
import DashboardModal from '../modals/DashboardModal';
import {
  renderTableBody,
  renderTableHeader,
} from '../../../common/helpers/table/tableRendering';
import {
  StatesType,
  ArchivedUsersType,
  LicenseModelType,
  MemberInfoType,
} from '../../../types';

interface IArchiveTableProps {
  title: string;
  nameValue?: string;
  orderType: boolean;
  isModalOpen: boolean;
  membersLoading: boolean;
  onOpenModal: () => void;
  onCloseModal: () => void;
  licensesLoading: boolean;
  isDriverLicense: boolean;
  states: Array<StatesType>;
  memberInfo: MemberInfoType;
  users: Array<ArchivedUsersType>;
  idLicenseInfo: LicenseModelType;
  archivedCheckedStates: Array<number>;
  onUnArchiveMember: (id: number) => void;
  setOrderType: Dispatch<SetStateAction<boolean>>;
  setNameValue: Dispatch<SetStateAction<string | undefined>>;
  setOrderField: Dispatch<SetStateAction<string | undefined>>;
  setArchivedCheckedStates: Dispatch<SetStateAction<Array<number>>>;
  setChangeTable?: Dispatch<SetStateAction<number>>;
}

export const ArchiveTable: React.FC<IArchiveTableProps> = ({
  title,
  states,
  users,
  idLicenseInfo,
  membersLoading,
  nameValue,
  setNameValue,
  licensesLoading,
  onCloseModal,
  isModalOpen,
  onUnArchiveMember,
  archivedCheckedStates,
  setArchivedCheckedStates,
  setOrderType,
  isDriverLicense,
  onOpenModal,
  orderType,
  memberInfo,
  setOrderField,
  setChangeTable,
}) => {
  const [userId, setUserId] = useState(0);

  const handleOrderUsers = (field: string) => {
    setOrderField(field);
    setOrderType(!orderType);
  };

  const handleOpenUserModal = (id: number) => {
    setUserId(id);
    onOpenModal();
  };

  const onUnArchiveMemberAction = (id: number) => {
    onUnArchiveMember(id);
    if(setChangeTable !== undefined){
      setChangeTable(Date.now());
    }
  };

  return (
    <Box>
      <Flex justify="space-between" align="center" p="25px 40px 40px">
        <Box d="flex" flexDir="row" alignItems="center">
          <Typography size={20} family={fonts.roboto}>
            {title}
          </Typography>
          <Box ml="5px">
            <Tooltip
              shouldWrapChildren
              hasArrow
              textColor="#888DAB"
              fontSize="14px"
              fontWeight="400"
              label="List of user accounts whose access to the system is being withdrawn. These users cannot log into the system. Their accounts are archived for 6-Months after which they are permanently deleted from the system."
              placement="bottom-start"
              bg="white"
              color="black"
              mt="1vh"
              p="25px"
              borderRadius="10px"
            >
              <TooltipIcon />
            </Tooltip>
          </Box>
        </Box>
        <Flex>
          <FilterPopup
            states={states}
            checkedItems={archivedCheckedStates}
            setCheckedItems={setArchivedCheckedStates}
          >
            <Box w="270px" mr="4">
              <CustomButton
                variant="outline"
                color={colors.gray}
                isSmall
                leftIcon={<FilterIcon />}
                background="transparent"
              >
                State
              </CustomButton>
            </Box>
          </FilterPopup>
          <SearchInput
            placeholder="Search..."
            value={nameValue}
            onChange={setNameValue}
          />
        </Flex>
      </Flex>
      <Box p="0 20px" mb="50px">
        <Table colorScheme="blackAlpha" className="fixed">
          <Thead>
            <Tr bg="#EDF2F7">
              {renderTableHeader(colors.gray, 'Name', () =>
                handleOrderUsers('name')
              )}
              {renderTableHeader(colors.gray, 'Email', () =>
                handleOrderUsers('email')
              )}
              {renderTableHeader(
                colors.gray,
                'User Will be Deleted in # Days',
                () => handleOrderUsers('days')
              )}
              {renderTableHeader(colors.gray, 'Actions')}
            </Tr>
          </Thead>
          <Tbody>
            {membersLoading ? (
              <Tr pos="relative" h="200px">
                <Td
                  border="0"
                  pos="absolute"
                  top="50%"
                  left="50%"
                  transform="translate(-50%, -50%)"
                >
                  <Spinner size="xl" />
                </Td>
              </Tr>
            ) : users.length !== 0 ? (
              users.map((user) => (
                <Tr key={user.id}>
                  {renderTableBody(
                    colors.primary,
                    user.fullName,
                    user.avatar !== null
                      ? `${URL}/api/storage/static-images/${user.avatar.itemHash}${user.avatar.itemExtension}`
                      : undefined
                  )}
                  {renderTableBody(colors.gray, user.email)}
                  {renderTableBody(colors.gray, user.daysTillDeletion)}
                  <Td>
                    <Flex>
                      <CustomButton
                        color={colors.gray}
                        background="transparent"
                        isSmall
                        isFullWidth={false}
                        variant="link"
                        mr="20px"
                        onClick={() => handleOpenUserModal(user.id)}
                      >
                        Details
                      </CustomButton>
                      <CustomButton
                        color={colors.gray}
                        background="transparent"
                        isSmall
                        isFullWidth={false}
                        variant="link"
                        onClick={() => onUnArchiveMemberAction(user.id)}
                      >
                        Restore
                      </CustomButton>
                    </Flex>
                  </Td>
                </Tr>
              ))
            ) : null}
          </Tbody>
        </Table>
      </Box>
      {isModalOpen && (
        <DashboardModal
          title={title}
          userId={userId}
          memberInfo={memberInfo}
          loading={licensesLoading}
          isModalOpen={isModalOpen}
          onCloseModal={onCloseModal}
          idLicenseInfo={idLicenseInfo}
          isDriverLicense={isDriverLicense}
          onUnArchiveMember={onUnArchiveMember}
        />
      )}
    </Box>
  );
};
