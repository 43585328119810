import React, { Dispatch, SetStateAction, useState } from 'react';
import dayjs from 'dayjs';
import {
  Box,
  Flex,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react';

import { useDispatch } from 'react-redux';
import { colors, fonts } from '../../../utils';
import { FilterIcon, TooltipIcon } from '../../../assets/icons';
import { StatesType, ReportModelType } from '../../../types';
import ReportDetailsModal from '../modals/ReportDetailsModal';
import { deleteReport } from '../../../redux/actions/reports.actions';
import {
  renderTableBody,
  renderTableHeader,
} from '../../../common/helpers/table/tableRendering';
import {
  CustomButton,
  SearchInput,
  Typography,
  AgenciesFilterPopup,
} from '../../../common/components';
import { SuspendPopup } from '../../../common/components/SuspendPopup';

interface IReportsTableProps {
  reportsLoading: boolean;
  reportOrderType: boolean;
  states: Array<StatesType>;
  reports: Array<ReportModelType>;
  setReportProperty: Dispatch<SetStateAction<string>>;
  setReportOrderType: Dispatch<SetStateAction<boolean>>;
  suspendedFilterItems: number[];
  setSuspendedFilterItems: (value: number[]) => void;
  agencySelectedFilter: number;
  setAgencySelectedFilter: (value: number) => void;
  setReportsName: (value: string) => void;
  reportsName: string;
}

export const ReportsTable: React.FC<IReportsTableProps> = React.memo(
  ({
    reports,
    reportsLoading,
    reportOrderType,
    setReportProperty,
    setReportOrderType,
    setSuspendedFilterItems,
    suspendedFilterItems,
    agencySelectedFilter,
    setAgencySelectedFilter,
    reportsName,
    setReportsName,
  }) => {
    const [reportId, setReportId] = useState(0);
    const [isDetailsOpen, setIsDetailsOpen] = useState(false);
    // const [checkedItems, setCheckedItems] = useState<Array<number>>([]);
    // const [suspendedItems, setSuspendedItems] = useState<Array<number>>([]);

    const dispatch = useDispatch();
    const onDeleteReport = (id: number) => dispatch(deleteReport(id));

    const handleOrderReports = (field: string) => {
      setReportProperty(field);
      setReportOrderType(!reportOrderType);
    };

    const handleOpenModal = (id: number) => {
      setReportId(id);
      setIsDetailsOpen(true);
    };

    return (
      <Box>
        <Flex justify="space-between" align="center" p="25px 40px 40px">
          <Box d="flex" flexDir="row" alignItems="center">
            <Typography size={20} family={fonts.roboto}>
              Updates reports
            </Typography>
            <Box ml="5px">
              <Tooltip
                shouldWrapChildren
                hasArrow
                textColor="#888DAB"
                fontSize="14px"
                fontWeight="400"
                label="User submitted reporting for posts within the Updates section that meet one of 6 categories (Harassment or Hateful, Violence  or Physical Harm, Nudity or Adult Content, IP infringement or Defamation, Other Issue)."
                placement="bottom-start"
                bg="white"
                color="black"
                mt="1vh"
                p="25px"
                borderRadius="10px"
              >
                <TooltipIcon />
              </Tooltip>
            </Box>
          </Box>
          <Flex>
            <SuspendPopup
              checkedItems={suspendedFilterItems}
              setCheckedItems={setSuspendedFilterItems}
            />
            <AgenciesFilterPopup
              selectedItem={agencySelectedFilter}
              setSelectedItem={setAgencySelectedFilter}
            >
              <Box w="270px" mr="4">
                <CustomButton
                  variant="outline"
                  color={colors.gray}
                  isSmall
                  leftIcon={<FilterIcon />}
                  background="transparent"
                >
                  Agency
                </CustomButton>
              </Box>
            </AgenciesFilterPopup>
            <SearchInput
              placeholder="Search..."
              value={reportsName}
              onChange={setReportsName}
            />
          </Flex>
        </Flex>
        <Box p="0 20px" mb="50px">
          <Table colorScheme="blackAlpha" className="fixed">
            <Thead>
              <Tr bg="#EDF2F7">
                {renderTableHeader(colors.gray, 'Name of agency', () =>
                  handleOrderReports('NameOfAgency')
                )}
                {renderTableHeader(colors.gray, 'Created by', () =>
                  handleOrderReports('CreatedBy')
                )}
                {renderTableHeader(colors.gray, 'Date', () =>
                  handleOrderReports('Date')
                )}
                {renderTableHeader(colors.gray, '# of reports', () =>
                  handleOrderReports('NumberOfReports')
                )}
                {renderTableHeader(colors.gray, 'Report reason')}
                <Th textTransform="capitalize" pr="100px">
                  <Typography
                    size={16}
                    family={fonts.roboto}
                    color={colors.gray}
                  >
                    Actions
                  </Typography>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {reportsLoading ? (
                <Tr pos="relative" h="200px">
                  <Td
                    border="0"
                    pos="absolute"
                    top="50%"
                    left="50%"
                    transform="translate(-50%, -50%)"
                  >
                    <Spinner size="xl" />
                  </Td>
                </Tr>
              ) : reports.length !== 0 ? (
                reports.map((item) => (
                  <Tr key={item.id}>
                    {renderTableBody(colors.gray, item.nameOfAgency)}
                    {renderTableBody(colors.gray, item.createdBy)}
                    {renderTableBody(
                      colors.gray,
                      dayjs(item.date).format('MM/DD/YYYY')
                    )}
                    {renderTableBody(colors.gray, item.numberOfReports)}
                    {renderTableBody(colors.gray, item.reportReason)}
                    <Td>
                      <Flex>
                        <CustomButton
                          mr="20px"
                          onClick={() => handleOpenModal(item.id)}
                          color={colors.gray}
                          isSmall
                          isFullWidth={false}
                          background="transparent"
                          variant="link"
                        >
                          Details
                        </CustomButton>
                        <CustomButton
                          onClick={() => onDeleteReport(item.id)}
                          color={colors.gray}
                          isSmall
                          isFullWidth={false}
                          background="transparent"
                          variant="link"
                        >
                          Delete
                        </CustomButton>
                      </Flex>
                    </Td>
                  </Tr>
                ))
              ) : (
                <Tr pos="relative" h="200px">
                  <Td
                    border="0"
                    pos="absolute"
                    top="50%"
                    left="50%"
                    transform="translate(-50%, -50%)"
                  >
                    <Typography size={14}>
                      There is no result to display
                    </Typography>
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </Box>
        {isDetailsOpen ? (
          <ReportDetailsModal
            reportId={reportId}
            isOpen={isDetailsOpen}
            setIsModalOpen={setIsDetailsOpen}
          />
        ) : null}
      </Box>
    );
  }
);
