import React from 'react';

interface IReportsIconProps {
  isActive?: boolean;
}

export const ReportsIcon: React.FC<IReportsIconProps> = ({ isActive }) => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 3.5H15M0 11.5H15M0 7.5H15" stroke={isActive ? '#4FD1C5' : '#FFF'} />
  </svg>
);
