import {
  takeEvery, ForkEffect, call, put,
} from 'redux-saga/effects';

import ApiBase, { URL } from '../../../common/api/api.base';
import {
  deleteMemberLicenses, deleteMemberLicensesError, deleteMemberLicensesSuccess,
} from '../../actions/licenses.actions';
import { IResponseWithoutValue } from '../../../types';

function* workerDeleteMemberLicenses(action: ReturnType<typeof deleteMemberLicenses>) {
  try {
    const result: IResponseWithoutValue = yield call(ApiBase.delete, `${URL}/api/admin/delete-member-licenses?LicenseId=${action.payload}`);
    yield console.log('deleteMemberLicenses', action, result);
    if (result.success) {
      yield put(deleteMemberLicensesSuccess(action.payload));
    } else {
      yield put(deleteMemberLicensesError(result.errors));
    }
  } catch (error) {
    yield put(deleteMemberLicensesError(error));
    console.log(error);
  }
}

export default function* watchDeleteMemberLicensesSaga()
: Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(deleteMemberLicenses.type, workerDeleteMemberLicenses);
}
