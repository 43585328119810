import React from 'react';

export const ChevronIcon = () => (
  <svg
    width="8"
    height="12"
    viewBox="0 0 8 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.02333 5.99999L7.14833 10.125L5.97 11.3033L0.666667 5.99999L5.97 0.696655L7.14833 1.87499L3.02333 5.99999Z"
      fill="#2C335C"
    />
  </svg>
);
