import { Box } from '@chakra-ui/react';
import React from 'react';

import { CustomButton } from '../../../../components';
import { Popup } from '../../../../components/Popup';
import { UnapprovedLicenseButtonsProps } from '../../types';

export const UnapprovedLicenseButtons: React.FC<UnapprovedLicenseButtonsProps> = ({
  handleDeleteLicense,
  setIsSaveAsDraft,
  licensesLoading,
  license,
}) => {
  return (
    <>
      <Box w="225px">
        <CustomButton isSmall onClick={handleDeleteLicense}>
          Decline all
        </CustomButton>
      </Box>
      <Box w="225px">
        <CustomButton
          isLoading={licensesLoading}
          isSmall
          type="submit"
          onClick={() => setIsSaveAsDraft(true)}
        >
          Save As A draft
        </CustomButton>
      </Box>
      <Box w="225px">
        {license.licenseNumber && license.expirationDate && license.state ? (
          <CustomButton
            type="submit"
            isLoading={licensesLoading}
            isSmall
            onClick={() => setIsSaveAsDraft(false)}
          >
            Approve
          </CustomButton>
        ) : (
          <Popup
            text="Please fill all fileds on both tabs (General Info and Real Estate Licenses)
    to send all to verification. You can also save it as a draft and back later when
    you will available to finish filling the fields"
          >
            <Box w="235px">
              <CustomButton isDisabled isSmall>
                Approve
              </CustomButton>
            </Box>
          </Popup>
        )}
      </Box>
    </>
  );
};
