import { Box } from '@chakra-ui/react';

import { CustomButton } from '../../../../components';
import { colors } from '../../../../../utils';
import { ArchivedUserButtonsProps } from '../../types';

export const ArchivedUserButtons: React.FC<ArchivedUserButtonsProps> = ({
  handleDeleteMember,
  handleUnArchiveMember,
}) => (
  <>
    <Box w="235px" mr="24px">
      <CustomButton
        background="transparent"
        variant="outline"
        color={colors.gray}
        isSmall
        onClick={handleDeleteMember}
      >
        Delete permanently
      </CustomButton>
    </Box>
    <Box w="235px">
      <CustomButton isSmall onClick={handleUnArchiveMember}>
        Restore
      </CustomButton>
    </Box>
  </>
);
