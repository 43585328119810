import {
  call, ForkEffect, put, takeEvery,
} from 'redux-saga/effects';
import {
  ignoreReport,
  ignoreReportError,
  ignoreReportSuccess,
} from '../../actions/reports.actions';
import ApiBase, { URL } from '../../../common/api/api.base';
import { IResponseWithoutValue } from '../../../types';

function* workerIgnoreReport(action: ReturnType<typeof ignoreReport>) {
  try {
    const result: IResponseWithoutValue = yield call(ApiBase.delete, `${URL}/api/admin/${action.payload.postId}/delete-reports`);
    yield console.log('ignoreReport', action, result);
    if (result.success) {
      yield put(ignoreReportSuccess(action.payload.postId));
    } else {
      yield put(ignoreReportError(result.errors));
    }
  } catch (error) {
    yield put(ignoreReportError(error));
    console.log(error);
  }
}

export default function* watchIgnoreReportSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(ignoreReport.type, workerIgnoreReport);
}
