import { createAction } from '@reduxjs/toolkit';

import {
  PayloadError, IResponseWithValue, AuthPayload, UserType,
} from '../../types';

export const login = createAction<AuthPayload>('LOGIN');
export const loginSuccess = createAction<IResponseWithValue<UserType>>('LOGIN_SUCCESS');
export const loginError = createAction<PayloadError[]>('LOGIN_ERROR');

export const logout = createAction('LOGOUT');
