import { createSlice } from '@reduxjs/toolkit';
import {
  agentSearchError,
  agentSearchRequest,
  agentSearchSuccess,
  deleteImportLog,
  deleteImportLogError,
  deleteImportLogSuccess,
  getImportInfoError,
  getImportInfoRequest,
  getImportInfoSuccess,
} from '../actions/import.actions';
import { AgentsType, ImportLogsType, PayloadError } from '../../types';

export const initialState: {
  loading: boolean;
  errors: PayloadError[];
  totalCount: number;
  importLogs: ImportLogsType[];
  agents: AgentsType[]
  agentsLoading: boolean;
} = {
  loading: false,
  errors: [],
  totalCount: 0,
  importLogs: [],
  agents: [],
  agentsLoading: false,
};

const importSlice = createSlice({
  name: 'import',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getImportInfoRequest, (state) => {
        state.loading = true;
        state.errors = [];
      })
      .addCase(getImportInfoSuccess, (state, action) => {
        state.loading = false;
        state.errors = [];
        state.importLogs = action.payload.value.importLogs;
        state.totalCount = action.payload.value.totalCount;
      })
      .addCase(getImportInfoError, (state, action) => {
        state.loading = false;
        state.errors = action.payload;
      })
      .addCase(deleteImportLog, (state) => {
        state.errors = [];
      })
      .addCase(deleteImportLogSuccess, (state, action) => {
        state.errors = [];
        state.importLogs = state.importLogs.filter((log) => log.id !== action.payload);
      })
      .addCase(deleteImportLogError, (state, action) => {
        state.errors = action.payload;
      })
      .addCase(agentSearchRequest, (state) => {
        state.agentsLoading = true;
        state.errors = [];
      })
      .addCase(agentSearchSuccess, (state, action) => {
        state.agentsLoading = false;
        state.errors = [];
        state.agents = action.payload;
      })
      .addCase(agentSearchError, (state, action) => {
        state.agentsLoading = false;
        state.errors = action.payload;
      });
  },
});

export default importSlice.reducer;
