import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {
  Box,
  Button,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
} from '@chakra-ui/react';

import { AgenciesType } from '../../../types';
import { DownIcon, TickIcon } from '../../../assets/icons';
import { Typography } from '../Typography';
import { fonts } from '../../../utils';

interface IAgencyDraftSelect {
  isLoading?: boolean
  items: Array<AgenciesType>
  value?: string
  onChange?: (value: string) => void
  label?: string
  isDisabled?: boolean,
  error?: string
  setIsPickDraftAgency?:Dispatch<SetStateAction<boolean>>
}

export const AgencyDraftSelect: React.FC<IAgencyDraftSelect> = ({
  isLoading,
  items,
  value,
  onChange,
  label,
  isDisabled,
  error,
  setIsPickDraftAgency
}) => {
  const [selectedValue, setSelectedValue] = useState<{
    id: number, name: string, isDraft?: boolean
  }>();

  useEffect(() => {
    if (value && items.length !== 0) {
      const selectedItem = items.find((it) => it.name === value);
      setSelectedValue(selectedItem);
    }
  }, [value, items.length]);

  return (
    <>
      {
        label ? (
          <Typography size={13} weight={300}>
            {label}
          </Typography>
        )
          : null
      }
      <Popover matchWidth>
        {
                    ({ onClose }) => (
                      <>
                        <PopoverTrigger>
                          <Button
                            isDisabled={isDisabled}
                            isLoading={isLoading}
                            justifyContent={!isLoading ? 'space-between' : 'center'}
                            border={error ? '2px solid rgb(252, 128, 92)' : 'none'}
                            isFullWidth
                            fontWeight={400}
                            fontSize="16px"
                            backgroundColor="#F6F7FB"
                            borderRadius="8px"
                            h="50px"
                            pr="17px"
                            pl={5}
                            py="13px"
                            rightIcon={(
                              <DownIcon
                                height="10"
                                width="10"
                                strokeFill="#2B335D"
                              />
)}
                          >
                            <Text
                              _after={{
                                content: '" (not added)"',
                                color: '#FE805C',
                                display: selectedValue?.isDraft ? 'inline' : 'none',
                              }}
                              fontSize="16px"
                              fontWeight={400}
                              color="rgb(26, 32, 44)"
                              isTruncated
                              fontFamily={fonts.poppins}
                            >
                              {selectedValue?.name ? selectedValue.name : 'Select'}
                            </Text>
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent
                          px="25px"
                          backgroundColor="white"
                          // maxW="352px"
                          maxHeight="316px"
                          overflow="auto"
                        >

                          <PopoverBody pt="10px" px="0px">
                            <Stack spacing="10px">
                              {
                                            items && items.length !== 0
                                              ? items.map((it, index) => (
                                                <Flex
                                                  position="relative"
                                                  as="button"
                                                  type="button"
                                                  onClick={() => {
                                                    setSelectedValue(it);
                                                    onChange?.(it.name);
                                                    setIsPickDraftAgency?.(it.isDraft);
                                                    onClose();
                                                  }}
                                                  key={index.toString()}
                                                  alignItems="center"
                                                >

                                                  {
                                                            selectedValue?.name === it.name
                                                              ? (
                                                                <Box
                                                                  left="-16px"
                                                                  position="absolute"
                                                                >
                                                                  <TickIcon
                                                                    strokeColor="#2B335D"
                                                                    height="15px"
                                                                    width="15px"
                                                                  />
                                                                </Box>
                                                              )
                                                              : null
                                                        }

                                                  <Text
                                                    _after={{
                                                      content: '" (not added)"',
                                                      color: '#FE805C',
                                                      display: it.isDraft ? 'inline' : 'none',
                                                    }}
                                                    fontSize="16px"
                                                    fontWeight={400}
                                                    isTruncated
                                                    color="#2B335D"
                                                  >
                                                    {it.name}
                                                  </Text>

                                                </Flex>
                                              ))
                                              : (
                                                <Text
                                                  align="center"
                                                  color="#888DAB"
                                                >
                                                  No Options
                                                </Text>
                                              )
                                        }

                            </Stack>
                          </PopoverBody>
                        </PopoverContent>
                        {error
                        && (
                        <Text
                          mt="2px"
                          fontSize="13px"
                          color="#E53E3F"
                        >
                          {error}
                        </Text>
                        )}
                      </>
                    )

                }

      </Popover>

    </>
  );
};
