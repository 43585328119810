import { createSlice } from '@reduxjs/toolkit';
import {
  ArchivedUsersType,
  UserViewedFlagsType,
  PayloadError,
  ZootProUsersType,
} from '../../types';
import {
  archiveMember,
  archiveMemberError,
  archiveMemberSuccess,
  deleteMember,
  deleteMemberError,
  deleteMemberSuccess,
  getArchivedMembers,
  getArchivedMembersError,
  getArchivedMembersSuccess,
  getMembers,
  getMembersError,
  getMembersSuccess,
  getSuspendedMembers,
  getSuspendedMembersError,
  getSuspendedMembersSuccess,
  unArchiveMember,
  unArchiveMemberError,
  unArchiveMemberSuccess,
  setUserViewedStatus,
  setUserViewedStatusError,
  setUserViewedStatusSuccess,
  setUserViewedFlags,
} from '../actions/members.actions';

export const initialState: {
  loading: boolean;
  errors: PayloadError[];
  users: Array<ZootProUsersType>;
  archivedUsers: Array<ArchivedUsersType>;
  archivedUsersTotalCount: number;
  usersTotalCount: number;
  isAnyUserViewedFlags: UserViewedFlagsType
} = {
  loading: false,
  errors: [],
  users: [],
  archivedUsers: [],
  usersTotalCount: 0,
  archivedUsersTotalCount: 0,
  isAnyUserViewedFlags: {
    isAsNewApplicationViewed: true,
    isAsPendingApprovalViewed: true,
  },
};

const membersSlice = createSlice({
  name: 'members',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMembers, (state) => {
        state.loading = true;
        state.errors = [];
      })
      .addCase(getMembersSuccess, (state, action) => {
        state.loading = false;
        state.errors = [];
        state.users = action.payload.value.zootProUsers;
        state.usersTotalCount = action.payload.value.count;
        state.isAnyUserViewedFlags = { ...action.payload.value.isAnyUserViewedFlags };
      })
      .addCase(getMembersError, (state, action) => {
        state.loading = false;
        state.errors = action.payload;
      })

      .addCase(getSuspendedMembers, (state) => {
        state.loading = true;
        state.errors = [];
      })
      .addCase(getSuspendedMembersSuccess, (state, action) => {
        state.loading = false;
        state.errors = [];
        state.users = action.payload.value.zootProUsers;
        state.usersTotalCount = action.payload.value.count;
      })
      .addCase(getSuspendedMembersError, (state, action) => {
        state.loading = false;
        state.errors = action.payload;
      })

      .addCase(getArchivedMembers, (state) => {
        state.loading = true;
        state.errors = [];
      })
      .addCase(getArchivedMembersSuccess, (state, action) => {
        state.loading = false;
        state.errors = [];
        state.archivedUsers = action.payload.value.models;
        state.archivedUsersTotalCount = action.payload.value.totalCount;
      })
      .addCase(getArchivedMembersError, (state, action) => {
        state.loading = false;
        state.errors = action.payload;
      })

      .addCase(archiveMember, (state) => {
        state.errors = [];
      })
      .addCase(archiveMemberSuccess, (state, action) => {
        state.errors = [];
        state.users = state.users.filter((user) => user.id !== action.payload);
      })
      .addCase(archiveMemberError, (state, action) => {
        state.errors = action.payload;
      })

      .addCase(unArchiveMember, (state) => {
        state.errors = [];
      })
      .addCase(unArchiveMemberSuccess, (state, action) => {
        state.errors = [];
        state.archivedUsers = state.archivedUsers.filter((user) => user.id !== action.payload);
      })
      .addCase(unArchiveMemberError, (state, action) => {
        state.errors = action.payload;
      })

      .addCase(deleteMember, (state) => {
        state.errors = [];
      })
      .addCase(deleteMemberSuccess, (state, action) => {
        state.errors = [];
        state.archivedUsers = state.archivedUsers.filter((user) => user.id !== action.payload);
      })
      .addCase(deleteMemberError, (state, action) => {
        state.errors = action.payload;
      })
      .addCase(setUserViewedStatus, (state) => {
        state.errors = [];
      })
      .addCase(setUserViewedStatusSuccess, (state, action) => {
        state.users = state.users.map((item) => (
          item.id === action.payload.userId
            ? {
              ...item,
              isAsNewApplicationViewed:
(!item.isAsNewApplicationViewed && action.payload.pageName === 'new')
  ? true : item.isAsNewApplicationViewed,
              isAsPendingApprovalViewed:
(!item.isAsPendingApprovalViewed && action.payload.pageName === 'pending')
  ? true : item.isAsPendingApprovalViewed,
            }
            : item
        ));
      })
      .addCase(setUserViewedStatusError, (state, action) => {
        state.errors = action.payload;
      })
      .addCase(setUserViewedFlags, (state, action) => {
        console.log(action.payload.pageName, 'PAGE NAME ACTION');
        state.isAnyUserViewedFlags = {
          ...state.isAnyUserViewedFlags,
          isAsNewApplicationViewed: (action.payload.pageName === 'new' && state.isAnyUserViewedFlags.isAsNewApplicationViewed)
            ? false
            : state.isAnyUserViewedFlags.isAsNewApplicationViewed,
          isAsPendingApprovalViewed: (action.payload.pageName === 'pending' && state.isAnyUserViewedFlags.isAsPendingApprovalViewed)
            ? false : state.isAnyUserViewedFlags.isAsPendingApprovalViewed,

        };
      });
  },
});

export default membersSlice.reducer;
