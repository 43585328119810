import React from 'react';
import {
  Box, Flex, Spacer, Image, Center,
} from '@chakra-ui/react';

import SignInForm from './components/SignInForm';
import { Typography } from '../../../common/components';
import { AuthPayload } from '../../../types';
import background from '../../../assets/img/auth-background.png';

interface ISignInProps {
  loading: boolean;
  onLogin: (data: AuthPayload) => void;
}

const SignIn: React.FC<ISignInProps> = ({ onLogin, loading }) => (
  <Flex>
    <Box w="50%">
      <Center h="100%">
        <Box d="flex" flexDirection="column" w="362px">
          <Typography size={25} weight={600}>
            Admin Sign In
          </Typography>
          <SignInForm onLogin={onLogin} loading={loading} />
        </Box>
      </Center>
    </Box>
    <Spacer />
    <Box w="50%" h="100vh">
      <Image
        w="100%"
        h="100%"
        objectFit="cover"
        src={background}
        alt="background"
      />
    </Box>
  </Flex>
);

export default SignIn;
