import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';

import { LicensesType, LicenseType as DashboardLicenseType } from '../../types';
import { ExpirationStatus } from '../../types/license.types';
import { getDuration } from '../helpers/date';
import { EXPIRATION_STATUS, WARN_EXPIRY_LIMIT } from '../const/license';
import { discardLicenseRequest } from '../../redux/actions/licenses.actions';

interface ReturnType {
  expirationStatus: ExpirationStatus['ACTIVE' | 'EXPIRED' | 'EXPIRING' | 'NEW'];
  expiresIn: number;
}

export const useLicense = () => {
  const dispatch = useDispatch();

  const defineExpirationStatus = (license: LicensesType | DashboardLicenseType): ReturnType => {
    const { expirationDate, isNew } = license;

    if (isNew) return { expirationStatus: EXPIRATION_STATUS.NEW, expiresIn: 0 };

    const days = getDuration(
      dayjs().format('YYYY-MM-DD'),
      dayjs(expirationDate).format('YYYY-MM-DD'),
    );
    const duration = Math.ceil(days);

    const activeLicenseWithoutWarn = duration > 0 && duration > WARN_EXPIRY_LIMIT;
    const activeLicenseWithWarn = duration > 0 && duration <= WARN_EXPIRY_LIMIT;

    if (activeLicenseWithoutWarn)
      return { expirationStatus: EXPIRATION_STATUS.ACTIVE, expiresIn: duration };
    if (activeLicenseWithWarn)
      return { expirationStatus: EXPIRATION_STATUS.EXPIRING, expiresIn: duration };

    return { expirationStatus: EXPIRATION_STATUS.EXPIRED, expiresIn: duration };
  };

  const discardLicense = (license: LicensesType) => {
    dispatch(discardLicenseRequest({ id: license.id }));
  };

  return { defineExpirationStatus, discardLicense };
};
