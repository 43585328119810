import React from 'react';

interface ICheckIconProps {
  isActive?: boolean;
}

export const CheckIcon: React.FC<ICheckIconProps> = ({ isActive }) => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 7L5.5 11.5L14 3"
      stroke={isActive ? '#4FD1C5' : '#FFF'}
      strokeLinecap="square"
    />
  </svg>
);
