import { createAction } from '@reduxjs/toolkit';

import {
  GetReportsPayload,
  ReportDetailsType,
  ReportModelType,
  PayloadError,
  IResponseWithValue,
} from '../../types';

export const getReports = createAction<GetReportsPayload>('GET_REPORTS');
export const getReportsSuccess = createAction<IResponseWithValue<{
  count: number;
  updatesReportModels: ReportModelType[];
}>>('GET_REPORTS_SUCCESS');
export const getReportsError = createAction<PayloadError[]>('GET_REPORTS_ERROR');

export const getReportDetails = createAction<number>('GET_REPORT_DETAILS');
export const getReportDetailsSuccess = createAction<IResponseWithValue<{ post: ReportDetailsType }>>('GET_REPORT_DETAILS_SUCCESS');
export const getReportDetailsError = createAction<PayloadError[]>('GET_REPORT_DETAILS_ERROR');

export const deleteReport = createAction<number>('DELETE_REPORT');
export const deleteReportSuccess = createAction<number>('DELETE_REPORT_SUCCESS');
export const deleteReportError = createAction<PayloadError[]>('DELETE_REPORT_ERROR');

export const suspendReport = createAction<number>('SUSPEND_REPORT');
export const suspendReportSuccess = createAction<number>('SUSPEND_REPORT_SUCCESS');
export const suspendReportError = createAction<PayloadError[]>('SUSPEND_REPORT_ERROR');

export const ignoreReport = createAction<{ postId: number }>('IGNORE_REPORT');
export const ignoreReportSuccess = createAction<number>('IGNORE_REPORT_SUCCESS');
export const ignoreReportError = createAction<PayloadError[]>('IGNORE_REPORT_ERROR');
