import React, { ReactElement, useEffect, useRef, useState } from 'react';

import {
  Textarea,
  FormControl,
  InputGroup,
  FormErrorMessage,
  TextareaProps,
  InputRightElement,
  Button,
  Text,
  HStack,
} from '@chakra-ui/react';

import { colors, fonts } from '../../../utils';

interface ICustomTextarea extends TextareaProps {
  errors?: any;
  register?: any;
  label: string;
  placeholder?: string;
  rightIcon?: string | ReactElement;
  rightIconAsButton?: boolean;
  onClickRightIcon?: () => void;
  labelProps?: any;
  onChange?: (arg?: any) => void;
  maxLength?: number;
  hasCharCounter?: boolean;
  value?: string;
}

export const CustomTextarea = React.forwardRef<null, ICustomTextarea>(
  (
    {
      onChange,
      label,
      errors,
      register,
      placeholder,
      rightIcon,
      rightIconAsButton,
      onClickRightIcon,
      labelProps,
      maxLength,
      hasCharCounter,
      value,
      ...props
    },
    ref
  ) => {
    const [charLeft, setCharLeft] = useState(maxLength);

    const wasCharLeftSettedByEffect = useRef(false);

    const debounce = useRef<ReturnType<typeof setTimeout> | null>(null);

    const textareaOnChange = (e: any) => {
      if (onChange) {
        onChange(e);
      }

      if (hasCharCounter && maxLength) {
        if (debounce.current) {
          clearTimeout(debounce.current);
        }

        debounce.current = setTimeout(() => {
          setCharLeft(maxLength - e.target.value.length);
        }, 500);
      }
    };

    useEffect(() => {
      if (
        !wasCharLeftSettedByEffect.current &&
        value &&
        hasCharCounter &&
        maxLength
      ) {
        setCharLeft(maxLength - value.length);
        wasCharLeftSettedByEffect.current = true;
      }
    }, [value]);

    return (
      <FormControl isInvalid={!!errors}>
        <HStack justifyContent="space-between">
          <Text fontSize="14px" fontFamily={fonts.poppins} {...labelProps}>
            {label}
          </Text>
          {hasCharCounter && Boolean(maxLength) && (
            <Text
              fontSize="14px"
              fontFamily={fonts.poppins}
              color={colors.gray}
            >
              Characters Left{' '}
              <span style={{ fontWeight: 500, color: colors.primary }}>
                {charLeft}
              </span>
            </Text>
          )}
        </HStack>
        <InputGroup>
          <Textarea
            value={value}
            maxLength={maxLength}
            ref={ref}
            borderRadius="20px"
            color="brand"
            placeholder={placeholder}
            bg={colors.ghost}
            fontFamily={fonts.poppins}
            fontSize="16px"
            paddingRight="40px"
            onChange={textareaOnChange}
            {...register}
            {...props}
          />
          {rightIcon &&
            (rightIconAsButton ? (
              <InputRightElement right="13px" h="100%">
                <Button onClick={onClickRightIcon} bgColor="transparent">
                  {rightIcon}
                </Button>
              </InputRightElement>
            ) : (
              <InputRightElement right="13px" h="100%">
                {rightIcon}
              </InputRightElement>
            ))}
        </InputGroup>
        {errors && (
          <FormErrorMessage fontSize="1.2vh">{errors.message}</FormErrorMessage>
        )}
      </FormControl>
    );
  }
);

CustomTextarea.displayName = 'CustomTextarea';
