import {
  call, ForkEffect, put, takeEvery,
} from 'redux-saga/effects';

import ApiBase, { URL } from '../../../common/api/api.base';
import { getStates, getStatesError, getStatesSuccess } from '../../actions/auxiliary.actions';
import { IResponseWithValue, StatesType } from '../../../types';

function* workerGetStates(action: ReturnType<typeof getStates>) {
  try {
    const result: IResponseWithValue<{ states: StatesType[] }> = yield call(ApiBase.get, `${URL}/api/directories/get-states`);
    yield console.log('getStates', action, result);
    if (result.success) {
      yield put(getStatesSuccess(result));
    } else {
      yield put(getStatesError(result.errors));
    }
  } catch (error) {
    yield put(getStatesError(error));
    console.log('getStatesError', error);
  }
}

export default function* watchGetStatesSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(getStates.type, workerGetStates);
}
