import {
  call,
  ForkEffect,
  put,
  takeEvery,
} from 'redux-saga/effects';
import {
  getUploadedUsersError,
  getUploadedUsersRequest,
  getUploadedUsersSuccess,
} from '../../actions/agency.actions';
import ApiBase, { URL } from '../../../common/api/api.base';
import { IResponseWithValue, UploadedUsersResponse } from '../../../types';

function* workerGetUploadedUsers(action: ReturnType<typeof getUploadedUsersRequest>) {
  try {
    const result: IResponseWithValue<{ users: UploadedUsersResponse[], count: number }> = yield call(ApiBase.get, `${URL}/api/admin/agencies/${action.payload.agencyId}/uploaded-users`, {
      StateId: action.payload.stateId,
      PageNumber: action.payload.pageNumber,
      PageSize: action.payload.pageSize,
      filter: action.payload.filter,
      isAsc: action.payload.isAsc,
    });
    if (result.success) {
      yield put(getUploadedUsersSuccess(result.value));
    } else {
      yield put(getUploadedUsersError(result.errors));
    }
  } catch (error) {
    yield put(getUploadedUsersError(error));
  }
}

export default function* watchGetUploadedUsersSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(getUploadedUsersRequest.type, workerGetUploadedUsers);
}
