import { createSlice } from '@reduxjs/toolkit';

import {
  addNewAgency,
  addNewAgencyError,
  addNewAgencySuccess,
  addPartnerToAgency,
  addPartnerToAgencyError,
  addPartnerToAgencySuccess,
  changeAgencyLogo,
  changeAgencyLogoError,
  changeAgencyLogoSuccess,
  editAgencyDetails,
  editAgencyDetailsError,
  editAgencyDetailsSuccess,
  editAgencyEmail,
  editAgencyEmailError,
  editAgencyEmailSuccess,
  getAgenciesForPartnership,
  getAgenciesForPartnershipError,
  getAgenciesForPartnershipSuccess,
  getAgenciesWithEmails,
  getAgenciesWithEmailsError,
  getAgenciesWithEmailsSuccess,
  getAgency,
  getAgencyError,
  getAgencySuccess,
  getUploadedUsersError,
  getUploadedUsersRequest,
  getUploadedUsersSuccess,
  removePartnerFromAgency,
  removePartnerFromAgencyError,
  removePartnerFromAgencySuccess,
  saveAgencyStatesError,
  saveAgencyStatesRequest,
  saveAgencyStatesSuccess,
  uploadCSVError,
  uploadCSVRequest,
  uploadCSVSuccess,
  deleteInviteSuccess,
  inviteNewUsersSuccess,
  editPartnerAgencyChoicesSuccess,
  clearAgency,
  getCompaniesSuccess,
  getCompaniesRequest,
  addNewAgencyCompanySuccess,
  addNewAgencyCompanyError,
  getAgencyCompanyIdRequest,
  getAgencyCompanyIdSuccess,
  getAgencyCompanyIdError,
  openModalDraftDetails,
  closeModalDraftDetails,
} from '../actions/agency.actions';
import {
  AgenciesWithEmailsType,
  AgencyPartnershipDetails,
  AgencyType,
  UploadedUsersResponse,
  PayloadError,
  CompaniesType,
} from '../../types';

type InitialState = {
  loading: boolean;
  errors: PayloadError[];
  agency: AgencyType;
  companies: CompaniesType[]
  companiesTotalCount: number
  companiesLoading: boolean
  createdCompanyId: number;
  emailLoading: boolean;
  buttonLoading: boolean;
  isAgencyEditable: boolean;
  partnerLoading: boolean;
  isTableLoading: boolean;
  agenciesTotalCount: number;
  agenciesWithEmailsLoading: boolean;
  agenciesForPartnershipTotalCount: number;
  agenciesWithEmails: AgenciesWithEmailsType[];
  agenciesForPartnership: AgencyPartnershipDetails[];
  uploadCSVLoading: boolean;
  uploadCSVErrors: PayloadError[];
  uploadedUsers: {
    loading: boolean;
    errors: PayloadError[];
    users: UploadedUsersResponse[];
    count: number;
  }
  saveAgencyStates: {
    loading: boolean;
    errors: PayloadError[];
  }
  agencyCompanyId: any;
  modalOpen: boolean;
};

export const initialState: InitialState = {
  loading: false,
  errors: [],
  emailLoading: false,
  companies: [],
  companiesTotalCount: 0,
  companiesLoading: false,
  createdCompanyId: 0,
  buttonLoading: false,
  isAgencyEditable: false,
  partnerLoading: false,
  isTableLoading: false,
  agenciesTotalCount: 0,
  agenciesWithEmails: [],
  agenciesForPartnership: [],
  agenciesWithEmailsLoading: false,
  agenciesForPartnershipTotalCount: 0,
  agency: {
    agency: {
      email: '',
      id: 0,
      name: '',
      address: '',
      contactPerson: '',
      license: '',
      logo: null,
      phoneNumber: '',
      principalBrokerLicenseNumber: '',
      principalBrokerName: '',
      states: [],
    },
    partners: [],
  },
  uploadCSVLoading: false,
  uploadCSVErrors: [],
  uploadedUsers: {
    loading: false,
    errors: [],
    users: [],
    count: 0,
  },
  saveAgencyStates: {
    loading: false,
    errors: [],
  },
  agencyCompanyId: 0,
  modalOpen: false,
};

const agencySlice = createSlice({
  name: 'agency',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAgency, (state) => {
        state.loading = true;
        state.errors = [];
      })
      .addCase(getAgencySuccess, (state, action) => {
        state.loading = false;
        state.errors = [];
        state.agency = action.payload.value.agency;
      })
      .addCase(getAgencyError, (state, action) => {
        state.loading = false;
        state.errors = action.payload;
      })

      .addCase(getAgenciesForPartnership, (state) => {
        state.isTableLoading = true;
        state.errors = [];
      })
      .addCase(getAgenciesForPartnershipSuccess, (state, action) => {
        state.isTableLoading = false;
        state.errors = [];
        state.agenciesForPartnership = action.payload.value.agencies;
        state.agenciesForPartnershipTotalCount = action.payload.value.count;
      })
      .addCase(getAgenciesForPartnershipError, (state, action) => {
        state.isTableLoading = false;
        state.errors = action.payload;
      })

      .addCase(addPartnerToAgency, (state) => {
        state.partnerLoading = true;
        state.errors = [];
      })
      .addCase(addPartnerToAgencySuccess, (state, action) => {
        state.partnerLoading = false;
        state.errors = [];
        state.agency.partners = [...state.agency.partners, {
          ...action.payload, address: '', contactPerson: '', license: '', phoneNumber: '', principalBrokerLicenseNumber: '', principalBrokerName: '', states: [], email: action.payload.email || '',
        }];
        state.agenciesForPartnership = state.agenciesForPartnership.map(
          (agency) => (agency.id === action.payload.id
            ? {
              ...agency, isPartner: true, isHostOpenHouse: action.payload.isHostOpenHouse, isShowingAssistance: action.payload.isShowingAssistance,
            }
            : agency),
        );
      })
      .addCase(addPartnerToAgencyError, (state, action) => {
        state.partnerLoading = false;
        state.errors = action.payload;
      })

      .addCase(getAgenciesWithEmails, (state) => {
        state.agenciesWithEmailsLoading = true;
        state.errors = [];
      })
      .addCase(getAgenciesWithEmailsSuccess, (state, action) => {
        state.agenciesWithEmailsLoading = false;
        state.errors = [];
        state.agenciesWithEmails = action.payload.value.agencies;
        state.agenciesTotalCount = action.payload.value.totalCount;
      })
      .addCase(getAgenciesWithEmailsError, (state, action) => {
        state.agenciesWithEmailsLoading = false;
        state.errors = action.payload;
      })

      .addCase(editAgencyEmail, (state) => {
        state.emailLoading = true;
        state.errors = [];
      })
      .addCase(editAgencyEmailSuccess, (state, action) => {
        state.emailLoading = false;
        state.errors = [];
        state.agenciesWithEmails = state.agenciesWithEmails.map(
          (agency) => (agency.id === action.payload.agencyId
            ? { ...agency, email: action.payload.email }
            : agency),
        );
      })
      .addCase(editAgencyEmailError, (state, action) => {
        state.emailLoading = false;
        state.errors = action.payload;
      })

      .addCase(removePartnerFromAgency, (state) => {
        state.partnerLoading = true;
        state.errors = [];
      })
      .addCase(removePartnerFromAgencySuccess, (state, action) => {
        state.partnerLoading = false;
        state.errors = [];
        state.agency.partners = state.agency.partners.filter((ag) => ag.id !== action.payload);
        state.agenciesForPartnership = state.agenciesForPartnership.map(
          (agency) => (agency.id === action.payload
            ? {
              ...agency, isPartner: false, isHostOpenHouse: null, isShowingAssistance: null,
            }
            : agency),
        );
      })
      .addCase(removePartnerFromAgencyError, (state, action) => {
        state.partnerLoading = false;
        state.errors = action.payload;
      })

      .addCase(addNewAgency, (state) => {
        state.isAgencyEditable = false;
        state.buttonLoading = true;
        state.errors = [];
      })
      .addCase(addNewAgencySuccess, (state) => {
        state.isAgencyEditable = true;
        state.buttonLoading = false;
        state.errors = [];
      })
      .addCase(addNewAgencyError, (state, action) => {
        state.isAgencyEditable = false;
        state.buttonLoading = false;
        state.errors = action.payload;
      })

      .addCase(changeAgencyLogo, (state) => {
        state.errors = [];
      })
      .addCase(changeAgencyLogoSuccess, (state) => {
        state.errors = [];
      })
      .addCase(changeAgencyLogoError, (state, action) => {
        state.errors = action.payload;
      })

      .addCase(editAgencyDetails, (state) => {
        state.isAgencyEditable = false;
        state.buttonLoading = true;
        state.errors = [];
      })
      .addCase(editAgencyDetailsSuccess, (state) => {
        state.isAgencyEditable = true;
        state.buttonLoading = false;
        state.errors = [];
      })
      .addCase(editAgencyDetailsError, (state, action) => {
        state.isAgencyEditable = false;
        state.buttonLoading = false;
        state.errors = action.payload;
      })
      .addCase(uploadCSVRequest, (state) => {
        state.uploadCSVLoading = true;
        state.uploadCSVErrors = [];
      })
      .addCase(uploadCSVSuccess, (state) => {
        state.uploadCSVLoading = false;
      })
      .addCase(uploadCSVError, (state, action) => {
        state.uploadCSVLoading = false;
        state.uploadCSVErrors = action.payload;
      })
      .addCase(getUploadedUsersRequest, (state) => {
        state.uploadedUsers.loading = true;
        state.uploadedUsers.errors = [];
      })
      .addCase(getUploadedUsersSuccess, (state, action) => {
        state.uploadedUsers.users = action.payload.users;
        state.uploadedUsers.count = action.payload.count;
        state.uploadedUsers.loading = false;
      })
      .addCase(getUploadedUsersError, (state, action) => {
        state.uploadedUsers.loading = false;
        state.uploadedUsers.errors = action.payload;
      })
      .addCase(saveAgencyStatesRequest, (state) => {
        state.saveAgencyStates.loading = true;
        state.saveAgencyStates.errors = [];
      })
      .addCase(saveAgencyStatesSuccess, (state) => {
        state.saveAgencyStates.loading = false;
      })
      .addCase(saveAgencyStatesError, (state, action) => {
        state.saveAgencyStates.loading = false;
        state.saveAgencyStates.errors = action.payload;
      })
      .addCase(getCompaniesRequest, (state) => {
        state.companiesLoading = true;
      })
      .addCase(getCompaniesSuccess, (state, action) => {
        state.companiesLoading = false;
        state.companies = action.payload.value.companies;
        state.companiesTotalCount = action.payload.value.totalCount;
      })
      .addCase(inviteNewUsersSuccess, (state, action) => {
        if (action.payload.userId) {
          state.uploadedUsers.users = state.uploadedUsers.users.map((user) => {
            if (action.payload.userId === user.id) {
              return ({ ...user, statusId: 2 });
            }
            return user;
          });
        } else {
          state.uploadedUsers.users = state.uploadedUsers.users.map((user) => ({ ...user, statusId: 2 }));
        }
      })
      .addCase(deleteInviteSuccess, (state, action) => {
        if (action.payload.userId) {
          state.uploadedUsers.users = state.uploadedUsers.users.filter((user) => user.id !== action.payload.userId);
        } else {
          state.uploadedUsers.users = [];
        }
      })
      .addCase(editPartnerAgencyChoicesSuccess, (state, action) => {
        state.agenciesForPartnership = state.agenciesForPartnership.map((elem) => {
          if (elem.id === action.payload.partnerId) {
            return { ...elem, isHostOpenHouse: action.payload.isHostOpenHouse, isShowingAssistance: action.payload.isShowingAssistance };
          }
          return elem;
        });
      })
      .addCase(addNewAgencyCompanySuccess, (state, action) => {
        state.createdCompanyId = action.payload;
      })
      .addCase(addNewAgencyCompanyError, (state) => {
        state.createdCompanyId = 0;
      })
      .addCase(getAgencyCompanyIdRequest, () => {
        // state.saveAgencyStates.loading = true;
        // state.agencyCompanyId.errors = [];
      })
      .addCase(getAgencyCompanyIdSuccess, (state, action) => {
        // state.saveAgencyStates.loading = false;
        state.agencyCompanyId = action.payload;
      })
      .addCase(getAgencyCompanyIdError, () => {
        // state.saveAgencyStates.loading = false;
      })
      .addCase(openModalDraftDetails, (state) => {
        state.modalOpen = true;
      })
      .addCase(closeModalDraftDetails, (state) => {
        state.modalOpen = false;
      })
      .addCase(clearAgency, (state) => {
        state.agency = { ...initialState.agency };
      });
    // .addCase(deletePartnerAgencyChoicesSuccess, (state, action) => {
    //   state.agenciesForPartnership = state.agenciesForPartnership.map((elem) => {
    //     if (elem.id === action.payload) {
    //       return { ...elem, isHostOpenHouse: null, isShowingAssistance: null };
    //     }
    //     return elem;
    //   });
    // });
  },
});

export default agencySlice.reducer;
