import {
  call, ForkEffect, put, takeEvery,
} from 'redux-saga/effects';
import {
  editPartnerAgencyChoicesRequest,
  editPartnerAgencyChoicesSuccess,
  editPartnerAgencyChoicesError,
} from '../../actions/agency.actions';
import ApiBase, { URL } from '../../../common/api/api.base';
import { IResponseWithoutValue } from '../../../types';

function* workerEditPartnerAgencyChoices(action: ReturnType<typeof editPartnerAgencyChoicesRequest>) {
  try {
    const result: IResponseWithoutValue = yield call(ApiBase.post, `${URL}/api/admin/agencies/${action.payload.agencyId}/choices`, { isShowingAssistance: action.payload.isShowingAssistance, isHostOpenHouse: action.payload.isHostOpenHouse, partnerId: action.payload.partnerId });
    if (result.success) {
      yield put(editPartnerAgencyChoicesSuccess(action.payload));
    } else {
      yield put(editPartnerAgencyChoicesError(result.errors));
    }
  } catch (error) {
    console.log(error, 'workerEditPartnerAgencyChoices');
  }
}

export default function* watchEditPartnerAgencyChoicesSaga():
Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(editPartnerAgencyChoicesRequest.type, workerEditPartnerAgencyChoices);
}
