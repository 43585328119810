import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Text } from '@chakra-ui/react';

import { colors, fonts } from '../../../../../utils';

import { RootStore } from '../../../../../redux';
import { getPostTags } from '../../../../../redux/actions/directories.actions';

interface IProps {
  choosedTags: number[];
  onClick: (id: number) => void;
}

const TagsField: FC<IProps> = ({ choosedTags, onClick }) => {
  const postTags = useSelector(
    (state: RootStore) => state.directories.postTags
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPostTags());
  }, []);

  return (
    <Box>
      <Text mb="4px" fontFamily={fonts.poppins} fontSize="14px">
        Tags
      </Text>

      <div // because no gap prop in Flex of Chakras current version
        style={{
          display: 'flex',
          borderRadius: '24px',
          backgroundColor: colors.ghost,
          flexWrap: 'wrap',
          gap: '15px',
          padding: '15px',
        }}
      >
        {postTags.map(({ id, name }: { id: number; name: string }) => {
          const isTagChoosed = choosedTags.includes(id);

          return (
            <Text
              key={id}
              p="10px"
              bgColor={isTagChoosed ? colors.primary : 'none'}
              color={isTagChoosed ? 'white' : colors.primary}
              border={`1px solid ${colors.gray}`}
              borderRadius="24px"
              cursor="pointer"
              onClick={() => onClick(id)}
            >
              {name}
            </Text>
          );
        })}
      </div>
    </Box>
  );
};

export default TagsField;
