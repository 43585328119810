import { extendTheme, ThemeConfig } from '@chakra-ui/react';
import { colors } from './colors';
import { Badge } from './components/Badge';

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

export const theme = extendTheme({
  config,
  components: {
    Badge,
  },
  colors: {
    white: colors.white,
    green: colors.green,
    green2: colors.green2,
    orange: colors.orange,
    primary: colors.primary,
    ghost: colors.ghost,
    active: colors.active,
    gray: colors.gray,
    darkError: colors.darkError,
  },
});
