import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';
import qs from 'qs';

import ApiBase, { URL } from '../../../common/api/api.base';

import {
  getAdvertismentForAgency,
  getAdvertismentForAgencySuccess,
  getAdvertismentForAgencyError,
} from '../../actions/advertisment.actions';

import { AdvertismentModelType, IResponseWithValue } from '../../../types';

function* workerGetAdvertismentForAgency(
  action: ReturnType<typeof getAdvertismentForAgency>
) {
  const stringifyOptions = {
    addQueryPrefix: true,
    skipNulls: true,
  };

  try {
    const result: IResponseWithValue<{
      posts: AdvertismentModelType[];
      totalCount: number;
    }> = yield call(
      ApiBase.get,
      `${URL}/api/advertising/posts-for-agency${qs.stringify(
        action.payload,
        stringifyOptions
      )}`
    );

    if (result.success) {
      yield put(getAdvertismentForAgencySuccess(result));
    } else {
      yield put(getAdvertismentForAgencyError(result.errors));
    }
  } catch (err: any) {
    yield put(getAdvertismentForAgencyError(err));
    console.error(err);
  }
}

export default function* watchGetAdvertismentForAgencySaga(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeEvery(
    getAdvertismentForAgency.type,
    workerGetAdvertismentForAgency
  );
}
