/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React, {
  Dispatch, SetStateAction, useEffect, useState, useRef,
} from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  Text,
} from '@chakra-ui/react';

import { useDispatch, useSelector } from 'react-redux';
import { colors, fonts } from '../../../utils';
import {
  AddNewAgencyPayload,
  AgencyDetails,
  EditAgencyDetails,
  EditAgencyDetailsPayload,
} from '../../../types';
import { addNewAgency, editAgencyDetails } from '../../../redux/actions/agency.actions';
import {
  CustomButton,
  CustomTextarea,
  TextInput,
  Typography,
  FilterPopup,
} from '../../../common/components';
import { RootStore } from '../../../redux';
import { DropdownArrowIcon } from '../../../assets/icons';


interface INewAgencyFormProps {
  agencyId: number | undefined;
  agencyDetails: AgencyDetails;
  setAgencyName: Dispatch<SetStateAction<string>>;
  onClickSeeStates: (value: number[]) => void;
  onClose: (arg0: boolean) => void;
  selectedStates: number[];
  companyId: number;
}

export const NewAgencyForm: React.FC<INewAgencyFormProps> = ({
  agencyId,
  setAgencyName,
  agencyDetails,
  // onClickSeeStates,
  onClose,
  // selectedStates,
  companyId,
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const boxRef = useRef(null);

  const dispatch = useDispatch();
  const onAddNewAgency = (data: AddNewAgencyPayload) => dispatch(addNewAgency(data));
  const onEditAgencyDetails = (data: EditAgencyDetailsPayload) => dispatch(editAgencyDetails(data));

  const initialStateIds = agencyDetails.states.length !== 0 ? agencyDetails.states.map(item => item.id) : [];
  const initialStateNames = agencyDetails.states.length !== 0 ? agencyDetails.states.map(item => item.name) : [];

  const { buttonLoading } = useSelector((state: RootStore) => state.agency);
  const { states } = useSelector((state: RootStore) => state.auxiliary);

  const [isEditable, setIsEditable] = useState(false);
  const { isAgencyEditable } = useSelector((state: RootStore) => state.agency);

  const [archivedCheckedStates, setArchivedCheckedStates] = useState<number[]>(initialStateIds);
  const [stateNames, setStateNames] = useState<string[]>(initialStateNames);

  useEffect(() => {
    if (isAgencyEditable) {
      setIsEditable(false);
    } else if (agencyId === undefined) {
      setIsEditable(true);
    }
  }, [isAgencyEditable]);

  const handleEditAgencyDetails = (data: EditAgencyDetails) => {
    const dataWithState = {
      ...data,
      stateIds: archivedCheckedStates,
    };
    if (agencyId) {
      onEditAgencyDetails({ id: agencyId, data: dataWithState });
    } else {
      onAddNewAgency({
        data: {
          licenseNumber: data.license,
          name: data.name,
          principalBrokerLicenseNumber: data.principalBrokerLicenseNumber,
          principalBrokerName: data.principalBrokerName,
          stateIds: archivedCheckedStates,
        },
        companyId: companyId,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(handleEditAgencyDetails)}>
      <Flex mt="24px" justifyContent="space-between">
        <Box>
          <FormControl isInvalid={errors.name}>
            <TextInput
              label="Agency Name"
              {...register('name', {
                required: { value: true, message: 'This is required' },
              })}
              defaultValue={agencyId ? agencyDetails.name : ''}
              placeholder="Enter"
              onChange={(event) => setAgencyName(event.target.value)}
              isReadOnly={!isEditable}
            />
            <FormErrorMessage m="0px">
              {errors.name && errors.name.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.principalBrokerName} mt={3}>
            <TextInput
              label="Principal Broker Name"
              {...register('principalBrokerName')}
              placeholder="Enter"
              defaultValue={agencyId ? agencyDetails.principalBrokerName : ''}
              isReadOnly={!isEditable}
            />
            <FormErrorMessage m="0px">
              {errors.principalBrokerName && errors.principalBrokerName.message}
            </FormErrorMessage>
          </FormControl>
        </Box>
        <Box>
          <FormControl isInvalid={errors.license}>
            <TextInput
              label="Agency License Number"
              {...register('license', {
                required: { value: true, message: 'This is required' },
              })}
              placeholder="Enter"
              defaultValue={agencyId ? agencyDetails.license : ''}
              isReadOnly={!isEditable}
            />
            <FormErrorMessage m="0px">
              {errors.license && errors.license.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={errors.principalBrokerLicenseNumber} mt={3}>
            <TextInput
              label="Principal Broker License Number"
              {...register('principalBrokerLicenseNumber')}
              placeholder="Enter"
              defaultValue={agencyId ? agencyDetails.principalBrokerLicenseNumber : ''}
              isReadOnly={!isEditable}
            />
            <FormErrorMessage m="0px">
              {errors.principalBrokerLicenseNumber
                && errors.principalBrokerLicenseNumber.message}
            </FormErrorMessage>
          </FormControl>
        </Box>
      </Flex>
      <FormControl pointerEvents={!isEditable ? 'none' : 'auto'} isInvalid={errors.idLicenseNumber} mt={3}>
        <Typography size={13} weight={300}>
          State
        </Typography>
        <FilterPopup
          states={states}
          checkedItems={archivedCheckedStates}
          setCheckedItems={setArchivedCheckedStates}
          boxRef={boxRef}
          setStateNames={setStateNames}
          stateNames={stateNames}
          withoutDefaultAll
        >
          <Box w="100%">
            <Flex
              as="button"
              type="button"
              ref={boxRef}
              width="100%"
              bg={colors.ghost}
              justifyContent="space-between"
              alignItems="center"
              h="50px"
              borderRadius="6px"
              pl={4}
              pr="10px"
            >
              <Text color={stateNames.length === 0 ? colors.gray : 'rgb(26, 32, 44)'} fontFamily={fonts.poppins}>
                {stateNames.length === 0 ? 'Select state' : stateNames.join(', ')}
              </Text>
              <DropdownArrowIcon />
            </Flex>
          </Box>
        </FilterPopup>
      </FormControl>
      {/* <FormControl isInvalid={errors.idLicenseNumber} mt={3}>
        <CustomTextarea
          label="States"
          value={agencyId ? agencyDetails.states.map((i) => i.name).join(', ') : states.filter((elem: any) => selectedStates.includes(elem.id)).map((i: any) => i.name).join(', ')}
          rightIcon={<Typography isUnderline size={16}>See</Typography>}
          rightIconAsButton
          onClickRightIcon={() => onClickSeeStates(agencyDetails.states.map((i) => i.id))}
        />
        <FormErrorMessage m="0px">
          {errors.idLicenseNumber && errors.idLicenseNumber.message}
        </FormErrorMessage>
      </FormControl> */}
      {isEditable
        ? (
          <Flex justifyContent="space-between" mt="35px">
            <Box w="200px">
              <CustomButton
                background="transparent"
                variant="outline"
                color={colors.primary}
                isSmall
                onClick={agencyId ? () => setIsEditable(false) : () => onClose(true)}
              >
                Cancel
              </CustomButton>
            </Box>
            <Box w="200px">
              <CustomButton
                isSmall
                isLoading={buttonLoading}
                type="submit"
              >
                Save
              </CustomButton>
            </Box>
          </Flex>
        )
        : (
          <Flex justifyContent="flex-end" mt="35px">
            <Box w="200px">
              <CustomButton
                isSmall
                onClick={() => setIsEditable(true)}
              >
                Edit
              </CustomButton>
            </Box>
          </Flex>
        )}
    </form>
  );
};
