import {
  call, ForkEffect, put, takeEvery,
} from 'redux-saga/effects';
import {
  editAgencyEmail,
  editAgencyEmailError,
  editAgencyEmailSuccess,
} from '../../actions/agency.actions';
import ApiBase, { URL } from '../../../common/api/api.base';
import { IResponseWithoutValue } from '../../../types';

function* workerEditAgencyEmail(action: ReturnType<typeof editAgencyEmail>) {
  try {
    const result: IResponseWithoutValue = yield call(ApiBase.patch, `${URL}/api/admin/agencies/emails`, action.payload);
    yield console.log('editAgencyEmail', action, result);
    if (result.success) {
      yield put(editAgencyEmailSuccess(action.payload));
    } else {
      yield put(editAgencyEmailError(result.errors));
    }
  } catch (error) {
    yield put(editAgencyEmailError(error));
    console.log(error);
  }
}

export default function* watchEditAgencyEmailSaga():
Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(editAgencyEmail.type, workerEditAgencyEmail);
}
