import { UserType } from '../../../types';

const SESSION = 'session';

const getUser = (user: UserType) => ({
  avatar: user.avatar,
  email: user.email,
  firstName: user.firstName,
  id: user.id,
  isIdLicensePhotoProvided: user.isIdLicensePhotoProvided,
  isPhoneNumberConfirmed: user.isPhoneNumberConfirmed,
  allowPaywall: user.allowPaywall,
  lastName: user.lastName,
  phoneNumber: user.phoneNumber,
  roles: user.roles,
  token: user.token,
  isSignedIn: true,
});

export const setCurrentUser = (user: UserType) => {
  localStorage.setItem(SESSION, JSON.stringify(user));
};

export const clearCurrentUser = () => {
  localStorage.removeItem(SESSION);
};

export const getCurrentUser = () => {
  const json = localStorage.getItem(SESSION);
  const user = json && JSON.parse(json);
  if (user) return getUser(user);
  return {
    allowPaywall: false,
    avatar: null,
    email: '',
    firstName: '',
    id: 0,
    isIdLicensePhotoProvided: false,
    isPhoneNumberConfirmed: false,
    lastName: '',
    phoneNumber: '',
    roles: [],
    token: {
      accessToken: '',
      accessTokenExpiration: '',
      refreshToken: '',
    },
    isSignedIn: false,
  };
};
