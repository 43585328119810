import React from 'react';
import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
} from '@chakra-ui/react';

import { colors } from '../../../utils';
import { CustomButton } from '../../../common/components';

interface IDashboardModalProps {
  // userId: number;
  loading: boolean;
  isModalOpen: boolean;
  onCloseModal: () => void;
  time: string;
  message: string;
  body: string;
}

const DataImportInfoModal: React.FC<IDashboardModalProps> = ({
  isModalOpen,
  onCloseModal,
  loading,
  time,
  message,
  body,
}) => (
  <>
    <Modal
      autoFocus={false}
      isOpen={isModalOpen}
      onClose={() => console.log('CLOSE')}
      size="3xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex justifyContent="center" alignItems="center">
            <Text style={{ fontSize: 22 }}>{time}</Text>
            {/* <ModalCloseButton onClick={onCloseModal} /> */}
          </Flex>
        </ModalHeader>
        <ModalBody>
          {loading ? (
            <Box d="flex" justifyContent="center" alignItems="center" h="500px">
              <Spinner size="xl" />
            </Box>
          ) : (
            <>
              <Flex align="center" flexDir="column">
                <Text style={{ fontSize: 22 }}>
                  Message
                </Text>
                <Text>
                  {message}
                </Text>
              </Flex>
              {body !== '{}' && (
              <Flex align="center" flexDir="column" paddingTop="20px">
                <Text style={{ fontSize: 22 }}>
                  Details
                </Text>
                <Text>
                  {body}
                </Text>
              </Flex>
              )}
              <Flex justifyContent="flex-end" p="25px">
                <CustomButton
                  isSmall
                  background={colors.primary}
                  color={colors.white}
                  onClick={onCloseModal}
                  isFullWidth={false}
                >
                  Cancel
                </CustomButton>
              </Flex>
            </>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  </>
);

export default DataImportInfoModal;
