import {
  UsersIcon, ClockIcon, CheckIcon, ListIcon, ReportsIcon, AddsIcon, ArchiveIcon,
} from '../../assets/icons';

export enum SideTextEnum {
  WorkMembers = 'Active Users',
  UnVerifiedUsers = 'New Applications',
  VerifiedUsers = 'Pending Approvals',
  SuspendedUsers = 'Suspended users',
  ArchivedUsers = 'Archived Users',
  Agencies = 'Agencies',
  UpdatesReports = 'Updates Reports',
  Invitation = 'Invitation',
  DataImport = 'Data import',
  Advertisment = 'Advertisment',
}

export const SIDEBAR_TEXT = [
  {
    id: 1,
    text: SideTextEnum.WorkMembers,
    icon: UsersIcon,
    link: '/active-users',
  },
  {
    id: 3,
    text: SideTextEnum.VerifiedUsers,
    icon: CheckIcon,
    isViewed: true,
    link: '/pending-approvals',
  },
  {
    id: 2,
    text: SideTextEnum.UnVerifiedUsers,
    icon: ClockIcon,
    isViewed: true,
    link: '/new-applications',
  },
  {
    id: 4,
    text: SideTextEnum.SuspendedUsers,
    icon: UsersIcon,
    link: '/suspended-users',
  },
  {
    id: 8,
    text: SideTextEnum.ArchivedUsers,
    icon: ArchiveIcon,
    link: '/archived-users',
  },
  {
    id: 5,
    text: SideTextEnum.Agencies,
    icon: ListIcon,
    isLast: true,
    link: '/inner-agencies',
  },
  {
    id: 9,
    text: SideTextEnum.Invitation,
    icon: ReportsIcon,
    isLast: true,
    link: '/invitation',
  },
  {
    id: 6,
    text: SideTextEnum.UpdatesReports,
    icon: ReportsIcon,
    link: '/updates-reports',
  },
  {
    id: 11,
    text: SideTextEnum.Advertisment,
    icon: ReportsIcon,
    link: '/advertisment',
  },
  {
    id: 10,
    text: SideTextEnum.DataImport,
    icon: ListIcon,
    isLast: true,
    link: '/data-import',
  },
  {
    id: 13,
    text: 'Settings',
    icon: ListIcon,
    isLast: true,
    link: '/settings',
  },
];
