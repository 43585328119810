import {
  call, ForkEffect, put, takeEvery,
} from 'redux-saga/effects';
import API, { URL } from '../../../common/api/api.base';
import {
  getParsedUsersFromFileRequest,
  getParsedUsersFromFileSuccess,
  getParsedUsersFromFileError,
  toggleInviteUserListModal,
} from '../../actions/invitation.actions';

import { IResponseWithValue, ParsedInvitationUser } from '../../../types';

function* workerGetParsedUsersFromFile(action: ReturnType<typeof getParsedUsersFromFileRequest>) {
  try {
    const result: IResponseWithValue<{ users: ParsedInvitationUser[] }> = yield call(API.post, `${URL}/api/admin/parsed-users`, action.payload);
    if (result.success) {
      yield put(getParsedUsersFromFileSuccess(result.value));
      yield put(toggleInviteUserListModal(true));
    } else {
      yield put(getParsedUsersFromFileError(result.errors));
    }
  } catch (error) {
    console.log(error, 'workerGetParsedUsersFromFile error');
  }
}

export default function* workerGetParsedUsersFromFileSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(getParsedUsersFromFileRequest.type, workerGetParsedUsersFromFile);
}
