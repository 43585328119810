import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { getCurrentUser } from '../auth/session.helper';

function PrivateRoute({ component: Component, ...rest }: any) {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (getCurrentUser().isSignedIn) {
          return <Component {...props} />;
        }
        return (
          <Redirect to={{
            pathname: '/auth',
            state: { from: props.location },
          }}
          />
        );
      }}
    />
  );
}

export default PrivateRoute;
