import {
  takeEvery, ForkEffect, call, put,
} from 'redux-saga/effects';
import ApiBase, { URL } from '../../../common/api/api.base';
import { deleteMember, deleteMemberError, deleteMemberSuccess } from '../../actions/members.actions';
import { IResponseWithoutValue } from '../../../types';

function* workerDeleteMember(action: ReturnType<typeof deleteMember>) {
  try {
    const result: IResponseWithoutValue = yield call(ApiBase.delete, `${URL}/api/admin/delete-member?UserId=${action.payload}`);
    yield console.log('deleteMember', action, result);
    if (result.success) {
      location.reload();
      yield put(deleteMemberSuccess(action.payload));
    } else {
      yield put(deleteMemberError(result.errors));
    }
  } catch (error) {
    yield put(deleteMemberError(error));
    console.log(error);
  }
}

export default function* watchDeleteMemberSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(deleteMember.type, workerDeleteMember);
}
