import { createSlice } from '@reduxjs/toolkit';
import { LicenseModelType, LicensesType, MemberInfoType, PayloadError } from '../../types';
import {
  closeModal,
  confirmIdLicense,
  confirmIdLicenseError,
  confirmIdLicenseSuccess,
  confirmMlsId,
  confirmMlsIdError,
  confirmMlsIdSuccess,
  deleteIdLicense,
  deleteIdLicenseError,
  deleteIdLicenseSuccess,
  deleteMemberLicenses,
  deleteMemberLicensesError,
  deleteMemberLicensesSuccess,
  getIdLicense,
  getIdLicenseError,
  getIdLicenseSuccess,
  getMemberGeneralInfo,
  getMemberGeneralInfoError,
  getMemberGeneralInfoSuccess,
  getMemberLicenses,
  getMemberLicensesError,
  getMemberLicensesSuccess,
  openModal,
  resetPassword,
  resetPasswordError,
  resetPasswordSuccess,
  saveIdLicense,
  saveIdLicenseError,
  saveIdLicenseSuccess,
  saveRealEstateLicenseInfoError,
  saveRealEstateLicenseInfoRequest,
  saveRealEstateLicenseInfoSuccess,
  setIdLicensePhoto,
  setIdLicensePhotoError,
  setIdLicensePhotoSuccess,
  setRealEstatePhoto,
  setRealEstatePhotoError,
  setRealEstatePhotoSuccess,
  suspendMember,
  suspendMemberError,
  suspendMemberSuccess,
  verifyMemberLicenses,
  verifyMemberLicensesError,
  verifyMemberLicensesSuccess,
  updateUserProfile,
  updateUserProfileSuccess,
  updateUserProfileError,
  discardLicenseRequest,
  discardLicenseSuccess,
  discardLicenseError,
} from '../actions/licenses.actions';

type InitialState = {
  loading: boolean;
  isModalOpen: boolean;
  errors: PayloadError[];
  isDriverLicense: boolean;
  memberInfo: MemberInfoType;
  idLicenseInfo: LicenseModelType;
  licensesInfo: Array<LicensesType>;
  buttonLoading: boolean;
  isLicenseEditable: boolean;
  confirmMlsId: {
    loading: boolean;
    errors: PayloadError[];
  };
  discardLicense: {
    loading: boolean;
    errors: PayloadError[];
  };
};

export const initialState: InitialState = {
  loading: false,
  isLicenseEditable: false,
  buttonLoading: false,
  errors: [],
  isModalOpen: false,
  isDriverLicense: true,
  licensesInfo: [],
  idLicenseInfo: {
    id: 0,
    fullName: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: null,
    photo: null,
    licenseNumber: null,
    birthDate: null,
    advertiserId: 0,
    agentFullName: '',
    nrdsId: '',
    agentEmail: '',
  },
  memberInfo: {
    birthDate: null,
    driverLicense: null,
    email: '',
    exp: null,
    fullName: '',
    idSuspended: false,
    isIdLicenseVerified: false,
    licenseNumber: null,
    phoneNumber: '',
    avatar: null,
  },
  confirmMlsId: {
    loading: false,
    errors: [],
  },
  discardLicense: {
    loading: false,
    errors: [],
  },
};

const licensesSlice = createSlice({
  name: 'licenses',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder

      .addCase(getIdLicense, state => {
        state.loading = true;
        state.errors = [];
      })
      .addCase(getIdLicenseSuccess, (state, action) => {
        state.loading = false;
        state.errors = [];
        state.idLicenseInfo = action.payload.value.licenseModel;
      })
      .addCase(getIdLicenseError, (state, action) => {
        state.loading = false;
        state.errors = action.payload;
      })

      .addCase(saveIdLicense, state => {
        state.isLicenseEditable = false;
        state.buttonLoading = true;
        state.errors = [];
      })
      .addCase(saveIdLicenseSuccess, state => {
        state.buttonLoading = false;
        state.isLicenseEditable = true;
        state.errors = [];
        state.isDriverLicense = false;
      })
      .addCase(saveIdLicenseError, (state, action) => {
        state.isLicenseEditable = false;
        state.buttonLoading = false;
        state.errors = action.payload;
      })
      .addCase(updateUserProfile, state => {
        state.isLicenseEditable = false;
        state.buttonLoading = true;
        state.errors = [];
      })
      .addCase(updateUserProfileSuccess, state => {
        state.buttonLoading = false;
        state.isLicenseEditable = true;
        state.errors = [];
        state.isDriverLicense = false;
      })
      .addCase(updateUserProfileError, (state, action) => {
        state.isLicenseEditable = false;
        state.buttonLoading = false;
        state.errors = action.payload;
      })

      .addCase(saveRealEstateLicenseInfoRequest, state => {
        state.buttonLoading = true;
        state.errors = [];
      })
      .addCase(saveRealEstateLicenseInfoSuccess, (state, action) => {
        state.buttonLoading = false;
        state.licensesInfo = state.licensesInfo.map(license =>
          action.payload.licenseId === license.id
            ? {
                ...license,
                expirationDate: action.payload.expirationDate,
                licenseNumber: action.payload.licenseNumber,
                mlsId: action.payload.mlsId,
                preferredAreasOfServices: action.payload.preferredAreasOfServices,
                state: action.payload.state,
                licenseId: action.payload.licenseId,
                agencyId: action.payload.agencyId,
              }
            : license,
        );
        state.errors = [];
      })
      .addCase(saveRealEstateLicenseInfoError, (state, action) => {
        state.buttonLoading = false;
        state.errors = action.payload;
      })

      .addCase(confirmIdLicense, state => {
        // state.loading = true;
        state.errors = [];
      })
      .addCase(confirmIdLicenseSuccess, state => {
        state.loading = false;
        state.errors = [];
        state.isModalOpen = false;
      })
      .addCase(confirmIdLicenseError, (state, action) => {
        state.loading = false;
        state.errors = action.payload;
      })

      .addCase(suspendMember, state => {
        state.loading = true;
        state.errors = [];
      })
      .addCase(suspendMemberSuccess, (state, action) => {
        state.loading = false;
        state.errors = [];
        state.memberInfo.idSuspended = action.payload;
      })
      .addCase(suspendMemberError, (state, action) => {
        state.loading = false;
        state.errors = action.payload;
      })

      .addCase(deleteIdLicense, state => {
        state.errors = [];
      })
      .addCase(deleteIdLicenseSuccess, state => {
        state.errors = [];
        state.isModalOpen = false;
      })
      .addCase(deleteIdLicenseError, (state, action) => {
        state.errors = action.payload;
      })

      .addCase(getMemberLicenses, state => {
        state.loading = true;
        state.errors = [];
      })
      .addCase(getMemberLicensesSuccess, (state, action) => {
        state.loading = false;
        state.errors = [];
        state.licensesInfo = action.payload.value.licenses;
      })
      .addCase(getMemberLicensesError, (state, action) => {
        state.loading = false;
        state.errors = action.payload;
      })

      .addCase(verifyMemberLicenses, state => {
        state.buttonLoading = true;
        state.errors = [];
      })
      .addCase(verifyMemberLicensesSuccess, state => {
        state.buttonLoading = false;
        state.errors = [];
        // state.isModalOpen = false;
        state.isDriverLicense = true;
      })
      .addCase(verifyMemberLicensesError, (state, action) => {
        state.buttonLoading = false;
        state.errors = action.payload;
      })

      .addCase(setRealEstatePhoto, state => {
        state.errors = [];
      })
      .addCase(setRealEstatePhotoSuccess, (state, action) => {
        state.errors = [];
        state.licensesInfo = state.licensesInfo.map(license =>
          action.payload.licenseId === license.id
            ? {
                ...license,
                photo: action.payload.result.value,
              }
            : license,
        );
      })
      .addCase(setRealEstatePhotoError, (state, action) => {
        state.errors = action.payload;
      })

      .addCase(setIdLicensePhoto, state => {
        state.errors = [];
      })
      .addCase(setIdLicensePhotoSuccess, state => {
        state.errors = [];
      })
      .addCase(setIdLicensePhotoError, (state, action) => {
        state.errors = action.payload;
      })

      .addCase(deleteMemberLicenses, state => {
        state.loading = true;
        state.errors = [];
      })
      .addCase(deleteMemberLicensesSuccess, (state, action) => {
        state.loading = false;
        state.errors = [];
        state.licensesInfo = state.licensesInfo.filter(license => license.id !== action.payload);
      })
      .addCase(deleteMemberLicensesError, (state, action) => {
        state.loading = false;
        state.errors = action.payload;
      })

      .addCase(getMemberGeneralInfo, state => {
        state.errors = [];
      })
      .addCase(getMemberGeneralInfoSuccess, (state, action) => {
        state.errors = [];
        state.memberInfo = action.payload.value.generalInfo;
      })
      .addCase(getMemberGeneralInfoError, (state, action) => {
        state.errors = action.payload;
      })

      .addCase(resetPassword, state => {
        state.errors = [];
      })
      .addCase(resetPasswordSuccess, state => {
        state.errors = [];
      })
      .addCase(resetPasswordError, (state, action) => {
        state.errors = action.payload;
      })

      .addCase(openModal, state => {
        state.isModalOpen = true;
      })
      .addCase(closeModal, state => {
        state.isModalOpen = false;
      })
      .addCase(confirmMlsId, state => {
        state.confirmMlsId.loading = true;
        state.confirmMlsId.errors = [];
      })
      .addCase(confirmMlsIdSuccess, state => {
        state.confirmMlsId.loading = false;
      })
      .addCase(confirmMlsIdError, (state, action) => {
        state.confirmMlsId.loading = false;
        state.confirmMlsId.errors = action.payload;
      })
      .addCase(discardLicenseRequest, state => {
        state.discardLicense.loading = true;
      })
      .addCase(discardLicenseSuccess, (state, action) => {
        state.discardLicense.loading = false;

        const licenseToUpdate = state.licensesInfo.find(({ id }) => id === action.payload);

        if (!licenseToUpdate) return;

        // licenseToUpdate.isDiscarded = true;
      })
      .addCase(discardLicenseError, (state, action) => {
        state.discardLicense.loading = false;
        state.discardLicense.errors = action.payload;
      });
  },
});

export default licensesSlice.reducer;
