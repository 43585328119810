import {
  call, ForkEffect, put, takeEvery,
} from 'redux-saga/effects';
import {
  editAgencyDetails,
  editAgencyDetailsError,
  editAgencyDetailsSuccess,
} from '../../actions/agency.actions';
import ApiBase, { URL } from '../../../common/api/api.base';
import { IResponseWithoutValue } from '../../../types';

function* workerEditAgencyDetails(action: ReturnType<typeof editAgencyDetails>) {
  try {
    const result: IResponseWithoutValue = yield call(ApiBase.put, `${URL}/api/admin/agencies/${action.payload.id}`, action.payload.data);
    yield console.log('editAgencyDetails', action, result);
    if (result.success) {
      yield put(editAgencyDetailsSuccess(result));
    } else {
      yield put(editAgencyDetailsError(result.errors));
    }
  } catch (error: any) {
    yield put(editAgencyDetailsError(error));
    console.log(error);
  }
}

export default function* watchEditAgencyDetailsSaga():
  Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(editAgencyDetails.type, workerEditAgencyDetails);
}
