import { createSlice } from '@reduxjs/toolkit';

import {
  getAgencies,
  getAgenciesError,
  getAgenciesSuccess,
  getStates,
  getStatesByAgency,
  getStatesByAgencyError,
  getStatesByAgencySuccess,
  getStatesError,
  getStatesSuccess,
  getInvitedFilterDirectoryRequest,
  getInvitedFilterDirectorySuccess,
  getInvitedFilterDirectoryError,
  getAgenciesAllSuccess,
} from '../actions/auxiliary.actions';
import { AgenciesType, StatesType, PayloadError, AgencyAllType } from '../../types';

type InitialState = {
  loading: boolean;
  errors: PayloadError[];
  states: StatesType[];
  agenciesAll: AgencyAllType[];
  agencies: AgenciesType[];
  statesByAgency: StatesType[];
  inviteFiltersDirectory: AgenciesType[];
};

export const initialState: InitialState = {
  loading: false,
  errors: [],
  states: [],
  agencies: [],
  statesByAgency: [],
  inviteFiltersDirectory: [],
  agenciesAll: [],
};

const auxiliarySlice = createSlice({
  name: 'auxiliary',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAgenciesAllSuccess, (state, action) => {
        state.agenciesAll = action.payload.value.agencies;
      })

      .addCase(getStates, (state) => {
        state.loading = true;
        state.errors = [];
      })
      .addCase(getStatesSuccess, (state, action) => {
        state.loading = false;
        state.errors = [];
        state.states = action.payload.value.states;
      })
      .addCase(getStatesError, (state, action) => {
        state.loading = false;
        state.errors = action.payload;
      })

      .addCase(getAgencies, (state) => {
        state.loading = true;
        state.errors = [];
      })
      .addCase(getAgenciesSuccess, (state, action) => {
        state.loading = false;
        state.errors = [];
        state.agencies = action.payload.value.agencies;
      })
      .addCase(getAgenciesError, (state, action) => {
        state.loading = false;
        state.errors = action.payload;
      })
      .addCase(getStatesByAgency, (state) => {
        state.loading = true;
        state.errors = [];
      })
      .addCase(getStatesByAgencySuccess, (state, action) => {
        state.loading = false;
        state.errors = [];
        state.statesByAgency = action.payload;
      })
      .addCase(getStatesByAgencyError, (state, action) => {
        state.loading = false;
        state.errors = action.payload;
      })
      .addCase(getInvitedFilterDirectoryRequest, (state) => {
        state.loading = true;
        state.errors = [];
      })
      .addCase(getInvitedFilterDirectorySuccess, (state, action) => {
        state.loading = false;
        state.inviteFiltersDirectory = action.payload;
      })
      .addCase(getInvitedFilterDirectoryError, (state, action) => {
        state.loading = false;
        state.errors = action.payload;
      });
  },
});

export default auxiliarySlice.reducer;
