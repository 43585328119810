import { Radio, RadioGroup, Stack } from '@chakra-ui/react';
import React, { FC, useEffect, useState } from 'react';
import { clearCurrentUser, getCurrentUser, setCurrentUser } from '../../../common/helpers/auth/session.helper';
import { UserType } from '../../../types';

import ApiBase, { URL } from '../../../common/api/api.base';

export const SettingsPage: FC = () => {
  const [checkBoxValue, setCheckBoxValue] = useState('0');
  const [currentUser, setCurrentUserSet] = useState<UserType | null>(null);

  useEffect(() => {
    if(currentUser === null) {
      return;
    }
    if(currentUser.allowPaywall){
      setCheckBoxValue('1');
    }else{  
      setCheckBoxValue('0');
    }
  }, [currentUser]);

  useEffect(() => {
    setCurrentUserSet(getCurrentUser());
  }, []);

  const saveForm = (val: string) => {
    setCheckBoxValue(val);

    ApiBase.post2(`${URL}/api/admin/settings/update-allow-pay`, {
      is_allow_pay: (val == '1')? true : false
    }, (res: any) => {
      setCheckBoxValue(val);
      
      const currentUserNew: null | UserType  = currentUser;
      if(currentUserNew){
        currentUserNew.allowPaywall = (val == '1')? true : false;
        setCurrentUser(currentUserNew);
      }
    });

  };

  return (
    <div className="settings_container">
      <div className="label_pw">
        Allow Paywall
      </div>
      <RadioGroup
        value={checkBoxValue}
        defaultValue={checkBoxValue}
        mt="4"
        mr="45px"
        onChange={val => {
          console.log('1111111');
          saveForm(val);
        }}
      >
        <Stack spacing={10} direction="row">
          <Radio
            value="1"
            size="lg"
          >
            Yes
          </Radio>
          <Radio
            value="0"
            size="lg"
          >
            No
          </Radio>
        </Stack>
      </RadioGroup>
    </div>
  );
};
