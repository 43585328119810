/* eslint-disable max-len */
/* eslint-disable no-trailing-spaces */
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useDelta } from 'react-delta';

import {
  Box,
  Center,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  IconButton,
  Image,
  Spinner,
  VStack,
} from '@chakra-ui/react';

import FileUpload from '../../components/FileUpload';
import {
  CustomButton,
  CustomSelect,
  DatePicker,
  TextInput,
  Typography,
  AgencyDraftSelect,
  SearchCitiesSelect,
} from '../../components';
import { Popup } from '../../components/Popup';
import { ActionButtons } from './components/ActionButtons';

import { RootStore } from '../../../redux';
import {
  getMemberLicenses,
  saveRealEstateLicenseInfoRequest,
} from '../../../redux/actions/licenses.actions';
import { getAgencies } from '../../../redux/actions/auxiliary.actions';
import {
  closeModalDraftDetails,
  openModalDraftDetails,
} from '../../../redux/actions/agency.actions';

import DashboardPhotoModal from '../../../pages/Dashboard/modals/DashboardPhotoModal';
import AddNewAgencyFromDraft from '../../../pages/Dashboard/modals/AddNewAgencyFromDraft';
import { AddNewAgencyCompanyModal } from '../../../pages/Dashboard/modals/AddNewAgencyCompanyModal';
import DraftDetailsModal from '../../../pages/Dashboard/modals/DraftDetailsModal/DraftDetailsModal';

import { useLicense } from '../../hooks/useLicense';

import { WARN_EXPIRY_LIMIT } from '../../const/license';
import { SideTextEnum } from '../../const/sideBar';
import { URL as BaseURL } from '../../api/api.base';

import { colors } from '../../../utils';
import { CloseIcon, ResizeIcon, TickIcon } from '../../../assets/icons';

import {
  AgenciesType,
  GetAgenciesByStatePayload,
  StatesType,
  ConfirmMlsIdPayload,
  LicensesType,
  SaveRealEstateLicenseInfoPayload,
  SetRealEstatePhotoPayload,
  CitiesResponse,
  VerifyMemberLicensesFunctionType,
} from '../../../types';

interface IRenderAgentLicenseProps {
  title: string;
  userId: number;
  license: LicensesType;
  onCloseModal: () => void;
  auxiliaryLoading: boolean;
  states: Array<StatesType>;
  agencies: Array<AgenciesType>;
  onGetAgencies: (payload: GetAgenciesByStatePayload) => void;
  onDeleteMember: (id: number) => void;
  onUnArchiveMember: (id: number) => void;
  onDeleteMemberLicense: (id: number) => void;
  onSetRealEstatePhoto: (data: SetRealEstatePhotoPayload) => void;
  onVerifyMemberLicenses: VerifyMemberLicensesFunctionType;
  onConfirmIdLicense: (id: number) => void;
  userFullName?: string;
  onConfirmMldId?: (payload: ConfirmMlsIdPayload) => void;
  confirmMlsLoading?: boolean;
  isIdLicenseVerified: boolean;
  createdCompanyId: number;
  agencyAdded: boolean;
  agencyCompanyId: any;
}

const getExpirationDate = (expirationDate: string | null, licenseExpirationDate: string | null) => {
  if (expirationDate) {
    // We accept a date with a time zone, and when creating a date in utc mode, we subtract the time of the time zone. So we create a date without time and time zone
    return dayjs.utc(dayjs(expirationDate).format('YYYY-MM-DD')).toISOString();
  }
  if (licenseExpirationDate) {
    return dayjs.utc(dayjs(licenseExpirationDate).format('YYYY-MM-DD')).toISOString();
  }
  return null;
};

const RenderAgentLicense: React.FC<IRenderAgentLicenseProps> = React.memo(
  ({
    title,
    userId,
    onCloseModal,
    onDeleteMember,
    onUnArchiveMember,
    agencies,
    license,
    onGetAgencies,
    onSetRealEstatePhoto,
    auxiliaryLoading,
    states,
    onVerifyMemberLicenses,
    onDeleteMemberLicense,
    onConfirmIdLicense,
    userFullName,
    onConfirmMldId,
    confirmMlsLoading,
    isIdLicenseVerified,
    createdCompanyId,
    // agencyAdded,
    agencyCompanyId,
  }) => {
    const [isEditable, setIsEditable] = useState(false);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [agencyName, setAgencyName] = useState(license.agency || '');
    const [realLicensePhoto, setRealLicensePhoto] = useState<File | null>(null);
    const [isSaveAsDraft, setIsSaveAsDraft] = useState(false);
    const [licenseState, setLicenseState] = useState<string>(license.state || '');
    const [typeIdsSelected, setTypeIdsSelected] = useState<CitiesResponse[]>([]);
    const [showModalAgencyModal, setShowModalAgency] = useState(false);
    const [stateIdValue, setStateIdValue] = useState(0);
    const [isAddNewAgencyModalOpen, setIsAddNewAgencyModalOpen] = useState(false);
    const [isPickDraftAgency, setIsPickDraftAgency] = useState(false);
    console.log(isPickDraftAgency, 'isPickDraftAgency');

    const {
      handleSubmit,
      control,
      register,
      formState: { errors },
    } = useForm();
    const { defineExpirationStatus, discardLicense } = useLicense();

    const { expiresIn } = defineExpirationStatus(license);

    const licenseImage = license.photo
      ? `${BaseURL}/api/storage/static-images/${license.photo.itemHash}${license.photo.itemExtension}`
      : undefined;

    const { buttonLoading } = useSelector((state: RootStore) => state.licenses);
    const { modalOpen, errors: agencyErrors } = useSelector((state: RootStore) => state.agency);
    const dispatch = useDispatch();

    const modalOpenDelta = useDelta(modalOpen);

    // eslint-disable-next-line max-len
    const saveRELicense = (payload: {
      licensePayload: SaveRealEstateLicenseInfoPayload;
      userId: number;
    }) => {
      dispatch(saveRealEstateLicenseInfoRequest(payload));
    };
    const onCloseModalDraftDetails = () => dispatch(closeModalDraftDetails());
    const handleDeleteLicense = () => onDeleteMemberLicense(license.id);
    const handleVerifyMemberLicenses = (data: SaveRealEstateLicenseInfoPayload) => {
      const licensePayload = {
        ...data,
        licenseId: license.id,
        expirationDate: getExpirationDate(data.expirationDate, license.expirationDate),
        // agencyId: agencies.filter((ag) => ag.name === agencyName)[0]?.id,
        agencyId: isPickDraftAgency ? 0 : agencies.filter(ag => ag.name === agencyName)[0]?.id,
        preferredAreasOfServices: typeIdsSelected,
        state: licenseState,
      };

      if (realLicensePhoto) {
        onSetRealEstatePhoto({
          licenseId: license.id,
          photo: realLicensePhoto,
        });
      }

      if (isSaveAsDraft) {
        // console.log(
        //     {
        //       ...data,
        //       licenseId: license.id,
        //       expirationDate: data.expirationDate
        //       ? new Date(data.expirationDate).toISOString()
        //       : license.expirationDate !== null
        //           ? new Date(license.expirationDate).toISOString()
        //           : null,
        //       agencyId: isPickDraftAgency ? null : agencies.filter((ag) => ag.name === agencyName)[0]?.id,
        //       preferredAreasOfServices: typeIdsSelected,
        //       state: licenseState,
        // },
        //     'PAYLOAD'
        // );
        saveRELicense({ licensePayload, userId });
        
      } else if (isIdLicenseVerified) {
        onVerifyMemberLicenses({ licensePayload, userId });
      } else {
        onConfirmIdLicense(userId);
      }
    };
    const handleDeleteMember = () => {
      onDeleteMember(userId);
      onCloseModal();
    };
    const handleUnArchiveMember = () => {
      onUnArchiveMember(userId);
      onCloseModal();
    };

    useEffect(() => {
      setAgencyName('');
      const stateId = states.filter(st => st.name === licenseState)[0]?.id;
      if (licenseState !== null && stateId !== undefined) {
        setStateIdValue(stateId);
        setTypeIdsSelected([]);
        onGetAgencies({
          stateId,
          userId,
        });
      }
    }, [licenseState]);

    useEffect(() => {
      let defaultAgency;
      if (license.agencyDraft !== null && license.agencyId) {
        defaultAgency = agencies.filter(ag => ag.id === license.agencyId)[0]?.name;
        setAgencyName(defaultAgency);
      } else if (license.agencyDraft !== null) {
        defaultAgency = agencies.filter(ag => ag.id === license.agencyDraft?.id && ag.isDraft)[0]
          ?.name;
        setAgencyName(defaultAgency);
      } else {
        defaultAgency = agencies.filter(ag => ag.id === license.agencyId)[0]?.name;
        setAgencyName(defaultAgency);
      }
    }, [agencies, license.agencyId, license.agencyDraft]);

    useEffect(() => {
      const defaultState = states.filter(st => st.name === license.state)[0]?.name;
      const stateId = states.filter(st => st.name === license.state)[0]?.id;
      setLicenseState(defaultState);
      setStateIdValue(stateId);
    }, [states, license.state]);

    useEffect(() => {
      if (
        Array.isArray(license.preferredAreasOfService) &&
        license.preferredAreasOfService.length !== 0
      ) {
        setTypeIdsSelected(license.preferredAreasOfService);
      }
    }, [license]);

    useEffect(() => {
      if (modalOpenDelta?.prev && !modalOpenDelta?.curr && !agencyErrors.length) {
        dispatch(getMemberLicenses({ UserId: userId }));
      }
    }, [modalOpenDelta]);

    const sortedAgencies =
      agencies.length !== 0 ? [...agencies].sort((a, b) => a.name.localeCompare(b.name)) : [];

    const shouldDisableControls = license.isVerified ? !isEditable : false;

    return (
      <>
        <form onSubmit={handleSubmit(handleVerifyMemberLicenses)}>
          <Typography size={16} weight={600}>
            License Confirmation Photo
          </Typography>
          <Flex justify="space-between">
            <Box>
              <Box mt="3">
                <Box pos="relative">
                  <IconButton
                    // zIndex={10000}
                    pos="absolute"
                    borderRadius="14"
                    bg="rgba(44, 51, 92, 0.9)"
                    size="lg"
                    right="0"
                    aria-label="Resize"
                    icon={<ResizeIcon />}
                    colorScheme="blackAlpha"
                    onClick={() => {
                      if (licenseImage) {
                        setIsOpenModal(true);
                      }
                    }}
                  />
                  {licenseImage ? (
                    <Image
                      borderRadius="14"
                      objectFit="cover"
                      w="350px"
                      h="250px"
                      src={realLicensePhoto ? URL.createObjectURL(realLicensePhoto) : licenseImage}
                      alt="Driver license"
                      fallback={
                        <Box
                          d="flex"
                          h="250px"
                          w="350px"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Spinner size="xl" />
                        </Box>
                      }
                    />
                  ) : realLicensePhoto ? (
                    <Image
                      borderRadius="14"
                      objectFit="cover"
                      w="350px"
                      h="250px"
                      src={URL.createObjectURL(realLicensePhoto)}
                      alt="Driver license"
                    />
                  ) : (
                    <Center h="250px" w="350px">
                      <Typography size={16}>No License</Typography>
                    </Center>
                  )}
                  <FileUpload mt={4} accept={'image/*'} onChange={setRealLicensePhoto}>
                    <CustomButton isSmall isDisabled={shouldDisableControls}>
                      Upload
                    </CustomButton>
                  </FileUpload>
                </Box>
              </Box>
              <Box mt="4">
                <Box w="345px">
                  <Typography size={13} weight={300}>
                    Preferred Areas
                  </Typography>
                  <SearchCitiesSelect
                    stateIdValue={stateIdValue}
                    setTypeIdsSelected={setTypeIdsSelected}
                    typeIdsSelected={typeIdsSelected}
                    disabled={shouldDisableControls}
                  />
                </Box>
                <FormControl isInvalid={errors.mlsId}>
                  <TextInput
                    isReadOnly={shouldDisableControls}
                    label="MLS ID #"
                    defaultValue={license.mlsId || ''}
                    {...register('mlsId')}
                    placeholder="Enter"
                  />
                  <FormErrorMessage m="0px">
                    {errors.mlsId && errors.mlsId.message}
                  </FormErrorMessage>
                </FormControl>
              </Box>
            </Box>
            <Box>
              <FormControl isInvalid={errors.licenseNumber}>
                <TextInput
                  isReadOnly={shouldDisableControls}
                  label="License Number*"
                  defaultValue={license.licenseNumber || ''}
                  {...register('licenseNumber')}
                  placeholder="Enter"
                  isConfirmed={license.isVerified}
                  color={license.isVerified ? colors.active : '#1A202C'}
                />
                <FormErrorMessage m="0px">
                  {errors.licenseNumber && errors.licenseNumber.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.expirationDate} w="345px">
                <Box mb="1">
                  <Typography size={13} weight={300}>
                    Expiration Date*
                  </Typography>
                </Box>
                <Controller
                  control={control}
                  name="expirationDate"
                  defaultValue={license.expirationDate ? new Date(license.expirationDate) : ''}
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      selected={value}
                      onChange={onChange}
                      disabled={shouldDisableControls}
                    />
                  )}
                />
                <FormErrorMessage m="0px">
                  {errors.expirationDate && 'This is required'}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.state} w="345px">
                <Box>
                  <Typography size={13} weight={300}>
                    State*
                  </Typography>
                  <Controller
                    name="state"
                    control={control}
                    defaultValue={license.state !== null ? license.state : ''}
                    render={() => (
                      <CustomSelect
                        isDisabled={shouldDisableControls}
                        items={states}
                        onChange={(e: any) => setLicenseState(e.target.value)}
                        value={licenseState}
                      />
                    )}
                  />
                </Box>
                <FormErrorMessage m="0px">{errors.state && 'This is required'}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.agencyId} w="345px">
                <Box>
                  <Controller
                    name="agencyId"
                    control={control}
                    defaultValue={agencyName}
                    render={() => (
                      <AgencyDraftSelect
                        setIsPickDraftAgency={setIsPickDraftAgency}
                        value={agencyName}
                        onChange={value => setAgencyName(value)}
                        isDisabled={license.isVerified ? !isEditable : auxiliaryLoading}
                        isLoading={auxiliaryLoading}
                        error={errors.agencyId?.message}
                        label="Agency*"
                        items={sortedAgencies}
                      />
                    )}
                  />
                </Box>
                <FormErrorMessage m="0px">{errors.agencyId && 'This is required'}</FormErrorMessage>
              </FormControl>
              {license.agencyDraft !== null ? (
                <Box w="155px" ml="auto">
                  <CustomButton
                    mt="20px"
                    isSmall
                    onClick={() => {
                      setIsAddNewAgencyModalOpen(true);
                      // setShowModalAgency(true);
                    }}
                  >
                    Add agency
                  </CustomButton>
                </Box>
              ) : null}
            </Box>
          </Flex>
          {license.isMlsIdConfirmed === null && (
            <>
              {/* CONFIRM MLS ID */}
              <VStack align="flex-start" mt="8px">
                <Typography
                  textProps={{
                    _before: {
                      content: '"(!) "',
                      color: '#FE805C',
                    },
                  }}
                  size={13}
                >
                  The names do not match:
                </Typography>
                <Typography
                  textProps={{
                    _before: {
                      content: '"Name MLS: "',
                      color: '#888DAB',
                    },
                  }}
                  size={13}
                >
                  {license.agentNameFromListings.join(' ')}
                </Typography>
                <Typography
                  textProps={{
                    _before: {
                      content: '"Entered Name: "',
                      color: '#888DAB',
                    },
                  }}
                  size={13}
                >
                  {userFullName}
                </Typography>
              </VStack>
              {/* BUTTONS CONFIRM */}
              <Flex my="14px">
                <Box w="128px">
                  <CustomButton
                    leftIcon={<CloseIcon fill={colors.orange} />}
                    border={colors.orange}
                    variant="outline"
                    backgroundColor="transparent"
                    isSmall
                    color={colors.orange}
                    isLoading={confirmMlsLoading}
                    onClick={() => {
                      onConfirmMldId?.({
                        userId,
                        licenseId: license.id,
                        isConfirmed: false,
                      });
                    }}
                  >
                    Reject
                  </CustomButton>
                </Box>
                <Box w="128px" ml="10px">
                  <CustomButton
                    isLoading={confirmMlsLoading}
                    leftIcon={<TickIcon />}
                    border="#6FCF97"
                    variant="outline"
                    backgroundColor="transparent"
                    isSmall
                    color="#6FCF97"
                    onClick={() => {
                      onConfirmMldId?.({
                        userId,
                        licenseId: license.id,
                        isConfirmed: true,
                      });
                    }}
                  >
                    Confirm
                  </CustomButton>
                </Box>
              </Flex>
              <Divider border="1px solid #D1D2DB" />
            </>
          )}
          {/* FOOTER BUTTONS */}
          <ActionButtons
            expiresIn={expiresIn}
            license={license}
            isEditable={isEditable}
            licensesLoading={buttonLoading}
            userStatus={title}
            handlers={{
              handleDeleteMember,
              handleUnArchiveMember,
              handleDeleteLicense,
              discardLicense,
              setIsEditable,
              setIsSaveAsDraft,
            }}
          />
          {isOpenModal && (
            <DashboardPhotoModal
              isOpen={isOpenModal}
              setIsOpen={setIsOpenModal}
              imageSource={licenseImage}
            />
          )}
        </form>
        {showModalAgencyModal && (
          <AddNewAgencyFromDraft
            license={license}
            agentId={userId}
            isOpen={showModalAgencyModal}
            onClose={setShowModalAgency}
          />
        )}
        <AddNewAgencyCompanyModal
          isOpen={isAddNewAgencyModalOpen}
          setIsModalOpen={setIsAddNewAgencyModalOpen}
          draftLicense={license}
          userId={userId}
        />
        <DraftDetailsModal
          agencyId={agencyCompanyId ? agencyCompanyId?.agencies[0]?.id : 0}
          companyId={createdCompanyId}
          isOpen={modalOpen}
          setIsModalOpen={onCloseModalDraftDetails}
        />
      </>
    );
  },
);
export default RenderAgentLicense;
