import React, { useEffect } from 'react';
import {
  AccordionItem, AccordionButton, Box, AccordionPanel, Text, FormControl, SimpleGrid,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { fonts, colors } from '../../../../../utils';
import { SolidChevronIcon } from '../../../../../assets/icons';
import { TextInput } from '../../../../../common/components';
import { Office, IOfficeItemForm, EditOfficePayload } from '../../../../../types';

interface IOfficeItemProps extends Office {
  onEditOffice: (value: EditOfficePayload) => void;
  editingLoading: boolean;
  editingLoadingId: number;
}

export const OfficeItem: React.FC<IOfficeItemProps> = ({
  address, contactPerson, email, id, name, onEditOffice, phoneNumber,
}) => {
  const {
    register,
    handleSubmit,
    // control,
    reset,
    // formState: { errors },
  } = useForm<IOfficeItemForm>();

  useEffect(() => {
    reset({
      address, contactPerson, email, name, phoneNumber,
    });
  }, []);

  const onSubmit = (data: IOfficeItemForm) => {
    onEditOffice({ ...data, agencyOfficeId: id });
  };

  return (
    <AccordionItem borderColor="#D1D2DB">
      {({ isExpanded }) => (
        <>
          <AccordionButton py="20px" px="0">
            <Box flex="1" textAlign="left">
              <Text color={colors.primary} fontWeight="400" fontSize="16px" fontFamily={fonts.poppins}>{name}</Text>
            </Box>
            <Text color={colors.primary} mr="15px" fontWeight="400" fontSize="16px" fontFamily={fonts.poppins}>{isExpanded ? 'Hide Details' : 'Show Details'}</Text>
            <Box transform={isExpanded ? '' : 'rotate(180deg)'}><SolidChevronIcon /></Box>
          </AccordionButton>
          <AccordionPanel pb={4} px="0">
            <form onSubmit={handleSubmit(onSubmit)}>
              <SimpleGrid columns={2} columnGap="90px">
                <FormControl
                  mt={3}
                >
                  <TextInput
                    width="100%"
                    label="Name"
                    {...register('name')}
                    placeholder="Enter"
                    defaultValue={name}
                  />
                  {/* <FormErrorMessage m="0px">
              {errors.address && errors.address.message}
            </FormErrorMessage> */}
                </FormControl>
                <FormControl
                  mt={3}
                >
                  <TextInput
                    width="100%"
                    label="Email"
                    type="email"
                    {...register('email')}
                    placeholder="Enter"
                    defaultValue={email}
                  />
                  {/* <FormErrorMessage m="0px">
              {errors.address && errors.address.message}
            </FormErrorMessage> */}
                </FormControl>
                <FormControl
                  mt={3}
                >
                  <TextInput
                    width="100%"
                    label="Address"
                    {...register('address')}
                    placeholder="Enter"
                    defaultValue={address}
                  />
                  {/* <FormErrorMessage m="0px">
              {errors.address && errors.address.message}
            </FormErrorMessage> */}
                </FormControl>
                <FormControl
                  mt={3}
                >
                  <TextInput
                    width="100%"
                    label="Phone Number"
                    {...register('phoneNumber')}
                    placeholder="Enter"
                    defaultValue={phoneNumber}
                  />
                  {/* <FormErrorMessage m="0px">
              {errors.address && errors.address.message}
            </FormErrorMessage> */}
                </FormControl>
                <FormControl
                  mt={3}
                >
                  <TextInput
                    width="100%"
                    label="Contact Person"
                    {...register('contactPerson')}
                    placeholder="Enter"
                    defaultValue={contactPerson}
                  />
                  {/* <FormErrorMessage m="0px">
              {errors.address && errors.address.message}
            </FormErrorMessage> */}
                </FormControl>
                {/* <CustomButton isFullWidth={false} width="60%" height="50px" borderRadius="10px" mt="35px" justifySelf="end" type="submit" isLoading={editingLoading && editingLoadingId === id}> */}
                {/*  Edit */}
                {/* </CustomButton> */}
              </SimpleGrid>
            </form>
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  );
};
