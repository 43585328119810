import {
  takeEvery, ForkEffect, call, put,
} from 'redux-saga/effects';

import ApiBase, { URL } from '../../../common/api/api.base';
import { suspendMember, suspendMemberError, suspendMemberSuccess } from '../../actions/licenses.actions';
import { IResponseWithoutValue } from '../../../types';

function* workerSuspendMember(action: ReturnType<typeof suspendMember>) {
  try {
    const result: IResponseWithoutValue = yield call(ApiBase.put, `${URL}/api/admin/suspend-member?UserId=${action.payload.UserId}&IsSuspended=${action.payload.IsSuspended}`);
    yield console.log('suspendMember', action, result);
    if (result.success) {
      yield put(suspendMemberSuccess(action.payload.IsSuspended));
    } else {
      yield put(suspendMemberError(result.errors));
    }
  } catch (error) {
    yield put(suspendMemberError(error));
    console.log(error);
  }
}

export default function* watchSuspendMemberSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(suspendMember.type, workerSuspendMember);
}
