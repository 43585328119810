import {
  call, ForkEffect, put, takeEvery,
} from 'redux-saga/effects';

import ApiBase, { URL } from '../../../common/api/api.base';
import { getAgenciesAll, getAgenciesAllSuccess } from '../../actions/auxiliary.actions';
import { AgencyAllType, IResponseWithValue, StatesType } from '../../../types';

function* workerGetAgenciesAll(action: ReturnType<typeof getAgenciesAll>) {
  const result: IResponseWithValue<{ agencies: AgencyAllType[] }> = yield call(ApiBase.get, `${URL}/api/directories/agenciesAll`);
  yield console.log('getAgenciesAll', action, result);
  if (result.success) {
    yield put(getAgenciesAllSuccess(result));
  }

}

export default function* watchGetAgenciesAll(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(getAgenciesAll.type, workerGetAgenciesAll);
}
