import {
  call, ForkEffect, put, takeEvery,
} from 'redux-saga/effects';
import API, { URL } from '../../../common/api/api.base';
import {
  getInvitationUsersRequest,
  getInvitationUsersSuccess,
  getInvitationUsersError,
} from '../../actions/invitation.actions';
import { IResponseWithValue, InvitationUser } from '../../../types';

function* workerGetInvitationUsers(action: ReturnType<typeof getInvitationUsersRequest>) {
  try {
    const {
      Search, FilterId, Order, IsAsc, PageNumber, PageSize,
    } = action.payload;
    const result: IResponseWithValue<{ users: InvitationUser[], count: number }> = yield call(API.get, `${URL}/api/admin/uploaded-users?Search=${Search}&FilterId=${FilterId}&Order=${Order}&IsAsc=${IsAsc}&PageNumber=${PageNumber}&PageSize=${PageSize}`);
    if (result.success) {
      yield put(getInvitationUsersSuccess(result.value));
    } else {
      yield put(getInvitationUsersError(result.errors));
    }
  } catch (error) {
    console.log(error, 'workerGetInvitationUsers');
  }
}

export default function* workerGetInvitationsUsersSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(getInvitationUsersRequest.type, workerGetInvitationUsers);
}
