import { takeEvery, ForkEffect, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { logout } from '../../actions/auth.actions';
import { clearCurrentUser } from '../../../common/helpers/auth/session.helper';

function* workerLogout(action: ReturnType<typeof logout>) {
  yield console.log('logout', 'action', action);
  clearCurrentUser();
  yield put(push('/auth'));
}

export default function* watchLogoutSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(logout.type, workerLogout);
}
