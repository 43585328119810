import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import { createStandaloneToast } from '@chakra-ui/react';

import {
  deleteAdvertismentPostForAgency,
  getAdvertismentForAgency,
  switchIsPostDetailsModalOpen,
} from '../../actions/advertisment.actions';
import ApiBase, { URL } from '../../../common/api/api.base';

import { IResponseWithoutValue } from '../../../types';

function* workerDeleteAdvertismentPostForAgency(
  action: ReturnType<typeof deleteAdvertismentPostForAgency>
) {
  const toast = createStandaloneToast();

  try {
    const result: IResponseWithoutValue = yield call(
      ApiBase.delete,
      `${URL}/api/advertising/post?Id=${action.payload.id}`
    );

    if (result.success) {
      toast({
        title: 'Advertisment Post Successfully Deleted',
        status: 'success',
        duration: 5000,
      });

      yield put(switchIsPostDetailsModalOpen(false));

      if (action.payload?.getAdvertismentParams)
        yield put(
          getAdvertismentForAgency(action.payload.getAdvertismentParams)
        );
    }
  } catch (error: any) {
    console.error(error);
  }
}

export default function* watchDeleteAdvertismentPostForAgencySaga(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeEvery(
    deleteAdvertismentPostForAgency.type,
    workerDeleteAdvertismentPostForAgency
  );
}
