import { createStandaloneToast } from '@chakra-ui/react';
import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import ApiBase, { URL } from '../../../common/api/api.base';
import {
  getAdvertismentPostById,
  getAdvertismentPostByIdSuccess,
  getAdvertismentPostByIdError,
} from '../../actions/advertisment.actions';

import { AdvertismentModelType, IResponseWithValue } from '../../../types';

function* workerGetAdvertismentPostById(
  action: ReturnType<typeof getAdvertismentPostById>
) {
  const toast = createStandaloneToast();

  try {
    const result: IResponseWithValue<{
      post: AdvertismentModelType;
    }> = yield call(
      ApiBase.get,
      `${URL}/api/advertising/post/${action.payload}`
    );

    if (result.success) {
      yield put(getAdvertismentPostByIdSuccess(result));
    } else {
      yield put(getAdvertismentPostByIdError(result.errors));
    }
  } catch (err: any) {
    yield put(getAdvertismentPostByIdError(err));
    console.error(err);

    toast({
      title: 'Some post data loading error',
      status: 'error',
      duration: 3000,
    });
  }
}

export default function* watchGetAdvertismentPostByIdSaga(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeEvery(getAdvertismentPostById.type, workerGetAdvertismentPostById);
}
