import { Tab } from '@chakra-ui/react';

import { colors } from '../../../utils';
import { Typography } from '../../components';
import { LicensesType } from '../../../types';
import { useLicense } from '../../hooks/useLicense';
import { LicenseExpiryLable } from '../../components/LicenseExpiryLabel';

export const licensesInfoRendering = (data: Array<LicensesType>, tabValue: number) => {
  const { defineExpirationStatus } = useLicense();

  return (
    <>
      {data.map((tab, i) => {
        const { expirationStatus, expiresIn } = defineExpirationStatus(data[i]);

        console.log(expirationStatus);

        return (
          <Tab
            key={tab.id}
            backgroundColor={i === tabValue ? colors.primary : colors.white}
            mr="20px"
            borderWidth="1px"
            flexShrink={0}
            borderColor={colors.primary}
            w="200px"
            py="10px"
            position="relative"
          >
            <LicenseExpiryLable
              status={expirationStatus}
              expiresIn={expiresIn}
              position="absolute"
              top="-13px"
              right="-10px"
              pointerEvents="none"
            />

            <Typography size={16} color={i === tabValue ? colors.white : colors.primary}>
              RE License {i + 1}
            </Typography>
          </Tab>
        );
      })}
    </>
  );
};
