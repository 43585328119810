import axios, { AxiosResponse } from 'axios';
import { createStandaloneToast } from '@chakra-ui/react';
import { clearCurrentUser, getCurrentUser } from '../helpers/auth/session.helper';

export const axiosBase = axios.create();
export const toast = createStandaloneToast();

axiosBase.interceptors.request.use(request => {
  const currentUser = getCurrentUser();

  request.headers.Authorization = currentUser.isSignedIn
    ? `Bearer ${currentUser.token.accessToken}`
    : '';

  return request;
});

//export const URL = `${process.env.REACT_APP_BASE_URL}`;
//axiosBase.defaults.baseURL = URL;

// for auth
window.baseAppUrl = 'https://zootpro.com';

// for axios
axiosBase.defaults.baseURL = 'https://api.zootpro.com';
export const URL = axiosBase.defaults.baseURL;

axiosBase.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      toast({
        title: '401 - Unauthorized',
        status: 'warning',
        duration: 5000,
      });
      clearCurrentUser();
    } else if (error.response.status === 500) {
      toast({
        title: '500 - Network Error',
        status: 'error',
        duration: 5000,
      });
    } else {
      error.response.data.errors.forEach((message: { errorMessage: string }) => {
        toast({
          title: message.errorMessage,
          status: 'error',
          duration: 5000,
        });
      });
    }
    return Promise.reject(error);
  },
);

function extractData(response: AxiosResponse) {
  response.data.messages.forEach((message: { messageText: string }) => {
    toast({
      title: message.messageText,
      status: 'success',
      duration: 5000,
    });
  });
  return response.data;
}

class ApiBase {
  get(url: string, params = {}, headers = {}) {
    return axiosBase
      .get(url, { params, headers })
      .then(res => extractData(res))
      .catch(err => ({ data: err.response.data }));
  }

  post(url: string, body = {}, headers = {}) {
    return axiosBase
      .post(url, body, { headers })
      .then(res => extractData(res))
      .catch(err => ({ data: err.response.data }));
  }

  post2(url: string, body = {}, callback: any) {
    return axiosBase
      .post(url, body, { })
      .then(res => {
        callback(res);
      });
  }

  put(url: string, body = {}, headers = {}) {
    return axiosBase
      .put(url, body, { headers })
      .then(res => extractData(res))
      .catch(err => ({ data: err.response.data }));
  }

  patch(url: string, body = {}, headers = {}) {
    return axiosBase
      .patch(url, body, { headers })
      .then(res => extractData(res))
      .catch(err => ({ data: err.response.data }));
  }

  delete(url: string, params = {}, headers = {}) {
    return axiosBase.delete(url, { params, headers }).then(res => extractData(res));
  }

  file(url: string, body: string | Blob, headers = {}) {
    const formData = new FormData();
    formData.append('photo', body);

    return axiosBase.post(url, formData, { headers }).then(res => extractData(res));
  }

  patchFile(url: string, body: string | Blob, headers = {}) {
    const formData = new FormData();
    formData.append('logo', body);

    return axiosBase.patch(url, formData, { headers }).then(res => extractData(res));
  }
}

export default new ApiBase();
