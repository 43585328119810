import React, { Dispatch, SetStateAction } from 'react';
import { Box, Flex, Button } from '@chakra-ui/react';

import { colors, fonts } from '../../../utils';
import { ChevronIcon } from '../../../assets/icons';
import { Typography } from '../../../common/components';
import UsePagination, { DOTS } from '../../../common/hooks/UsePagination';

interface IDashboardPaginatorProps {
  pageSize: number;
  currentPage: number;
  usersTotalCount: number;
  membersLoading: boolean;
  setCurrentPage: Dispatch<SetStateAction<any>>;
}

export const DashboardPaginator: React.FC<IDashboardPaginatorProps> = ({
  pageSize,
  currentPage,
  setCurrentPage,
  membersLoading,
  usersTotalCount,
}) => {
  const siblingCount = 1;

  const paginationRange = UsePagination({
    usersTotalCount,
    pageSize,
    siblingCount,
    currentPage,
  });
  if (currentPage === 0 || paginationRange!.length < 2) {
    return null;
  }

  const pageCount = Math.ceil(usersTotalCount / pageSize);

  const onPageChanged = (pageNumber: number | string) => {
    setCurrentPage(pageNumber);
  };
  const goToNextPage = () => {
    if (currentPage >= 1 && currentPage < pageCount) {
      setCurrentPage(currentPage + 1);
    }
  };
  const goToPreviousPage = () => {
    if (currentPage > 1 && currentPage <= pageCount) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <Box m="50px 0">
      {!membersLoading
        ? usersTotalCount !== 0 && (
        <Flex align="center" justify="center">
          <Button mr="25px" onClick={goToPreviousPage} background="transparent">
            <ChevronIcon />
          </Button>
          {paginationRange!.map((page, index) => {
            if (page === DOTS) {
              return <Box key={`${index + page}`}>&#8230;</Box>;
            }
            return (
              <Button
                key={page}
                onClick={() => onPageChanged(page)}
                bg={currentPage === page ? colors.primary : 'transparent'}
                borderRadius="100%"
                p="5px 10px"
                alignItems="center"
                justify="center"
              >
                <Typography
                  size={12}
                  family={fonts.roboto}
                  color={currentPage === page ? colors.white : colors.gray}
                >
                  {page}
                </Typography>
              </Button>
            );
          })}
          <Button ml="25px" onClick={goToNextPage} background="transparent" transform="rotate(180deg)">
           <ChevronIcon />
          </Button>
        </Flex>
        )
        : null}
    </Box>
  );
};
