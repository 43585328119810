import {
  call,
  ForkEffect,
  put,
  takeEvery,
} from 'redux-saga/effects';
import {
  getOfficesRequest,
  getOfficesSuccess,
  getOfficesError,
} from '../../actions/offices.actions';
import API, { URL } from '../../../common/api/api.base';
import { IResponseWithValue, Office } from '../../../types';

function* workerGetOffices(action: ReturnType<typeof getOfficesRequest>) {
  try {
    const {
      AgencyId, PageNumber, PageSize,
    } = action.payload;

    const result: IResponseWithValue<{ totalCount: number, agencyOffices: Office[] }> = yield call(API.get, `${URL}/api/admin/agencies/${AgencyId}/agency-offices?PageNumber=${PageNumber}&PageSize=${PageSize}`);
    if (result.success) {
      yield put(getOfficesSuccess(result.value));
    } else {
      yield put(getOfficesError(result.errors));
    }
  } catch (error) {
    console.log(error, 'workerGetOffices error');
  }
}

export default function* watchGetOfficesSaga():
  Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(getOfficesRequest.type, workerGetOffices);
}
