import {
  call, ForkEffect, put, takeEvery,
} from 'redux-saga/effects';
import {
  getReportDetails,
  getReportDetailsError,
  getReportDetailsSuccess,
} from '../../actions/reports.actions';
import ApiBase, { URL } from '../../../common/api/api.base';
import { IResponseWithValue, ReportDetailsType } from '../../../types';

function* workerGetReportDetails(action: ReturnType<typeof getReportDetails>) {
  try {
    const result: IResponseWithValue<{ post: ReportDetailsType }> = yield call(ApiBase.get, `${URL}/api/admin/get-post-by-id?PostId=${action.payload}`);
    yield console.log('getReportDetails', action, result);
    if (result.success) {
      yield put(getReportDetailsSuccess(result));
    } else {
      yield put(getReportDetailsError(result.errors));
    }
  } catch (error) {
    yield put(getReportDetailsError(error));
    console.log(error);
  }
}

export default function* watchGetReportDetailsSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(getReportDetails.type, workerGetReportDetails);
}
