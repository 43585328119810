import React from 'react';
import { Box, Flex, Image } from '@chakra-ui/react';

import {
  useLocation,
} from 'react-router-dom';
import { colors, fonts } from '../../../utils';
import LogoIcon from '../../../assets/img/logo.svg';
import { DashboardSideText } from './DashboardSideText';
import { Typography } from '../../../common/components';
import { SIDEBAR_TEXT } from '../../../common/const/sideBar';
import { LogoutIcon } from '../../../assets/icons';
import { UserViewedFlagsType } from '../../../types';

interface IDashboardSideBarProps {
  onLogout: () => void;
  isAnyUserViewedFlags: UserViewedFlagsType;
}

export const DashboardSideBar: React.FC<IDashboardSideBarProps> = ({
  onLogout,
  isAnyUserViewedFlags,

}) => {
  const location = useLocation();
  return (
    <Box p="45px 40px" bg={colors.primary} w="300px" minH="100vh">
      <Image src={LogoIcon} alt="logo" mb="100" />
      {SIDEBAR_TEXT.map((text) => (
        <DashboardSideText
          isPendingViewed={text.isViewed === isAnyUserViewedFlags.isAsPendingApprovalViewed}
          isNewViewed={text.isViewed === isAnyUserViewedFlags.isAsNewApplicationViewed}
          key={text.id}
          link={text.link}
          text={text.text}
          isLast={text.isLast}
          isActive={text.link === location.pathname}
          icon={<text.icon isActive={text.link === location.pathname} />}
        />
      ))}
      <Flex alignItems="center" onClick={onLogout} as="button" type="button" mt="100">
        <Box mr="12px">
          <LogoutIcon />
        </Box>
        <Typography size={16} family={fonts.roboto} color={colors.white}>
          Log Out
        </Typography>
      </Flex>
    </Box>
  );
};
