import {
  call,
  ForkEffect,
  put,
  takeEvery,
} from 'redux-saga/effects';
import {
  uploadCSVError,
  uploadCSVRequest,
  uploadCSVSuccess,
  getAgency,
} from '../../actions/agency.actions';
import ApiBase, { URL } from '../../../common/api/api.base';
import { IResponseWithoutValue } from '../../../types';

function* workerUploadCSV(action: ReturnType<typeof uploadCSVRequest>) {
  const formData = new FormData();
  formData.append('UsersListFile', action.payload.UsersListFile!);
  formData.append('StateId', action.payload.StateId.toString());
  formData.append('AgencyId', action.payload.AgencyId.toString());
  try {
    const result: IResponseWithoutValue = yield call(ApiBase.post, `${URL}/api/admin/upload-users`, formData);
    if (result.success) {
      yield put(uploadCSVSuccess());
      yield put(getAgency(action.payload.AgencyId));
    } else {
      yield put(uploadCSVError(result.errors));
    }
  } catch (error) {
    yield put(uploadCSVError(error));
    console.log(error);
  }
}

export default function* watchUploadCSVSaga():
Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(uploadCSVRequest.type, workerUploadCSV);
}
