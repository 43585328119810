import React, { useEffect, useState } from 'react';
import {
  AccordionItem, AccordionButton, Box, AccordionPanel, Text, FormControl, SimpleGrid, FormErrorMessage
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { fonts, colors } from '../../../../../utils';
import { SolidChevronIcon, OfficeDeleteIcon } from '../../../../../assets/icons';
import { TextInput, CustomButton } from '../../../../../common/components';
import {
  Office,
  IOfficeItemForm,
  EditOfficePayload,
  DeleteOfficePayload,
  AddOfficePayload,
} from '../../../../../types';
import { OfficeAlertModal } from '../../OfficeAlertModal';
import { addOfficeResolver } from '../../../../../common/schemes/office.scheme';

interface IOfficeItemProps extends Office {
  onEditOffice: (value: EditOfficePayload) => void;
  editingLoading: boolean;
  editingLoadingId: number;
  agencyId: number | undefined;
  onDeleteOffice: (paylaod: DeleteOfficePayload) => void;
  onAddOffice: (payload: AddOfficePayload) => void;
}

export const OfficeItem: React.FC<IOfficeItemProps> = ({
  address,
  contactPerson,
  // editingLoading,
  // editingLoadingId,
  email,
  id,
  name,
  onEditOffice,
  phoneNumber,
  onDeleteOffice,
  agencyId,
  onAddOffice,
}) => {
  const {
    register,
    handleSubmit,
    // control,
    // reset,
    formState: { errors },
  } = useForm<IOfficeItemForm>({ resolver: addOfficeResolver });

  const [isFormEditable, setIsFormEditable] = useState<boolean>(!id ? true : false);
  const [officeDeleteAlert, setOfficeDeleteAlert] = useState<boolean>(false);

  useEffect(() => {
    // reset({
    //   address, contactPerson, email, name, phoneNumber,
    // });
  }, []);

  const onSubmit = (data: IOfficeItemForm) => {
    id ? onEditOffice({ ...data, agencyOfficeId: id }) : onAddOffice({
      data: { ...data },
      agencyId: agencyId || 1,
    });
  };

  const onCancel = () => {
    isFormEditable
      ? setIsFormEditable(!isFormEditable)
      : setOfficeDeleteAlert(true);
  };

  const deleteData = {
    officeId: id,
    agencyId: agencyId || 1,
  };
  console.log('ERRORS', errors);
  return (
    <>
      <AccordionItem borderColor="#D1D2DB">
        {({ isExpanded }) => (
          <>
            <AccordionButton py="20px" px="0">
              <Box flex="1" textAlign="left">
                <Text color={colors.primary} fontWeight="400" fontSize="16px" fontFamily={fonts.poppins}>{name}</Text>
              </Box>
              <Text color={colors.primary} mr="15px" fontWeight="400" fontSize="16px" fontFamily={fonts.poppins}>{isExpanded ? 'Hide Details' : 'Show Details'}</Text>
              <Box transform={isExpanded ? '' : 'rotate(180deg)'}><SolidChevronIcon /></Box>
            </AccordionButton>
            <AccordionPanel pb={4} px="0">
              <form onSubmit={handleSubmit(onSubmit)}>
                <SimpleGrid columns={2} columnGap="90px">
                  <FormControl
                    mt={3}
                  >
                    <TextInput
                      width="100%"
                      label="Name"
                      {...register('name')}
                      placeholder="Enter"
                      defaultValue={name}
                    />
                    {/* <FormErrorMessage m="0px">
                      {errors.address && errors.address.message}
                    </FormErrorMessage> */}
                  </FormControl>
                  <FormControl
                    mt={3}
                    isInvalid={!!errors.email}
                  >
                    <TextInput
                      width="100%"
                      label="Email"
                      type="email"
                      {...register('email')}
                      placeholder="Enter"
                      defaultValue={email}
                    />
                    {errors.email && <FormErrorMessage m="0px">
                      {errors.email.message}
                    </FormErrorMessage>}
                  </FormControl>
                  <FormControl
                    mt={3}
                  >
                    <TextInput
                      width="100%"
                      label="Address"
                      {...register('address')}
                      placeholder="Enter"
                      defaultValue={address}
                    />
                    {/* <FormErrorMessage m="0px">
              {errors.address && errors.address.message}
            </FormErrorMessage> */}
                  </FormControl>
                  <FormControl
                    isInvalid={!!errors.phoneNumber}
                    mt={3}
                  >
                    <TextInput
                      width="100%"
                      label="Phone Number"
                      {...register('phoneNumber')}
                      placeholder="Enter"
                      defaultValue={phoneNumber}
                    />
                    {errors.phoneNumber && <FormErrorMessage m="0px">
                      {errors.phoneNumber.message}
                    </FormErrorMessage>}
                  </FormControl>
                  <FormControl
                    mt={3}
                  >
                    <TextInput
                      width="100%"
                      label="Contact Person"
                      {...register('contactPerson')}
                      placeholder="Enter"
                      defaultValue={contactPerson}
                    />
                    {/* <FormErrorMessage m="0px">
              {errors.address && errors.address.message}
            </FormErrorMessage> */}
                  </FormControl>
                </SimpleGrid>
                <Box
                  w="100%"
                  d="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  flexDirection="row"
                  mt="35px"
                >
                  {id && <CustomButton
                    isFullWidth={false}
                    background="transparent"
                    variant="outline"
                    width="30%"
                    height="50px"
                    borderRadius="10px"
                    justifySelf="end"
                    color={colors.primary}
                    isLoading={false}
                    onClick={onCancel}
                  >
                    {isFormEditable ? 'Cancel' : 'Delete'}
                  </CustomButton>}
                  {isFormEditable
                    ? <CustomButton
                      isFullWidth={false}
                      width="30%"
                      height="50px"
                      borderRadius="10px"
                      justifySelf="end"
                      type="submit"
                      isLoading={false}
                    >
                      Save
                    </CustomButton>
                    : <Box
                      width="30%"
                      height="50px"
                      borderRadius="10px"
                      bg={colors.primary}
                      color="white"
                      d="flex"
                      justifyContent="center"
                      alignItems="center"
                      onClick={() => setIsFormEditable(!isFormEditable)}
                    >
                      Edit
                    </Box>}
                </Box>
              </form>
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
      <OfficeAlertModal
        isOpen={officeDeleteAlert}
        setIsModalOpen={setOfficeDeleteAlert}
        actionFunction={() => onDeleteOffice(deleteData)}
        title={`Are you sure you want to delete ${name}?`}
        subTitle="This Office will deleted from the Agency"
        icon={<OfficeDeleteIcon />}
      />
    </>
  );
};
