import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AgenciesTable, DashboardPaginator } from '../components';
import { getCompaniesRequest } from '../../../redux/actions/agency.actions';
import { RootStore } from '../../../redux';

export const Agencies: React.FC = () => {
  const dispatch = useDispatch();
  const savedSearchQuery = sessionStorage.getItem('agencies-search-query');
  const savedCheckedStates = JSON.parse(
    sessionStorage.getItem('archived-checked-states')!
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState<string | undefined>(
    savedSearchQuery || ''
  );
  const [agencyCheckedStates, setAgencyCheckedStates] = useState<number[]>([]);
  const [orderType, setOrderType] = useState(true);
  const [archivedCheckedStates, setArchivedCheckedStates] = useState<number[]>(
    savedCheckedStates || []
  );

  const {
    agenciesWithEmails,
    companiesTotalCount,
    companiesLoading,
    companies,
  } = useSelector((state: RootStore) => state.agency);
  const { states } = useSelector((state: RootStore) => state.auxiliary);

  useEffect(() => {
    sessionStorage.setItem('agencies-search-query', searchQuery || '');
    sessionStorage.setItem(
      'archived-checked-states',
      JSON.stringify(archivedCheckedStates)
    );
  }, [searchQuery, archivedCheckedStates]);

  // useEffect(() => {
  //   savedSearchQuery && setSearchQuery(savedSearchQuery);
  // }, []);

  useEffect(() => {
    dispatch(
      getCompaniesRequest({
        orderType: orderType,
        PageNumber: currentPage,
        SearchQuery: searchQuery,
        archivedCheckedStates,
      })
    );
  }, [currentPage, searchQuery, orderType, archivedCheckedStates]);

  return (
    <>
      <AgenciesTable
        states={states}
        auxiliaryLoading={companiesLoading}
        agenciesWithEmails={agenciesWithEmails}
        agencyCheckedStates={agencyCheckedStates}
        setAgencyCheckedStates={setAgencyCheckedStates}
        agencyOrderType={orderType}
        setAgencyOrderType={setOrderType}
        // setAgencyOrderField={setOrderField}
        agencySearchQuery={searchQuery}
        setAgencySearchQuery={setSearchQuery}
        companies={companies}
        archivedCheckedStates={archivedCheckedStates}
        setArchivedCheckedStates={setArchivedCheckedStates}
      />
      <DashboardPaginator
        pageSize={50}
        currentPage={currentPage}
        membersLoading={companiesLoading}
        usersTotalCount={companiesTotalCount}
        setCurrentPage={setCurrentPage}
      />
    </>
  );
};
