import React from 'react';
import Select from 'react-select';
import { FormControl, FormErrorMessage, Text } from '@chakra-ui/react';
import { SelectValue } from '../../../types';
import { Typography } from '../Typography';
import { fonts, colors } from '../../../utils';

interface IMultiSelectProps {
  options: SelectValue[];
  defaultValue?: SelectValue[];
  errors?: string;
  label?: string;
  height?: string;
  onChange?: (value: any) => void;
  isDisabled?: boolean;
  placeholder?: string;
  value?: SelectValue[];
  borderRadius?: string;
  titleProps?: any;
}

export const MultiSelect: React.FC<IMultiSelectProps> = ({
  options,
  defaultValue,
  label,
  errors,
  height = '50px',
  onChange,
  isDisabled = false,
  placeholder,
  value,
  borderRadius = '6px',
  titleProps,
}) => (
  <FormControl isInvalid={!!errors}>
    {label ? (
      <Text
        fontFamily={fonts.poppins}
        fontSize="14px"
        lineHeight="21px"
        pb="4px"
        pl="15px"
        color={colors.primary}
        {...titleProps}
      >
        {label}
      </Text>
    ) : null}
    <Select
      value={value}
      components={{
        IndicatorSeparator: null,
      }}
      onChange={(value) => {
        onChange?.(value.map((st) => st.value));
      }}
      defaultValue={defaultValue}
      isMulti
      options={options}
      placeholder={placeholder}
      styles={{
        control: (provider) => ({
          ...provider,
          paddingLeft: '8px',
          paddingRight: '8px',
          backgroundColor: '#F6F7FB',
          fontSize: '16px',
          minHeight: height,
          border: 'none',
          outline: 'none',
          '&:hover': {
            backgroundColor: '#E2E8F0',
          },
          '&:focus': {
            border: '2px solid #3182ce',
            backgroundColor: 'white',
          },
          borderRadius: borderRadius,
        }),
      }}
      isDisabled={isDisabled}
    />
    {errors && <FormErrorMessage fontSize="1.2vh">{errors}</FormErrorMessage>}
  </FormControl>
);
