import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import { createStandaloneToast } from '@chakra-ui/react';
import qs from 'qs';

import {
  cleanAdvertismentPostByIdData,
  editAdvertismentPost,
  getAdvertisment,
  switchIsCreateAdvertismentModalOpen,
  switchIsPostDetailsModalOpen,
} from '../../actions/advertisment.actions';
import ApiBase, { URL } from '../../../common/api/api.base';

import { IResponseWithValue } from '../../../types';

function* workerEditAdvertismentPost(
  action: ReturnType<typeof editAdvertismentPost>
) {
  const toast = createStandaloneToast();

  const stringifyOptions = {
    addQueryPrefix: true,
    skipNulls: true,
  };
  const params = qs.stringify(
    { ...action.payload, Photo: undefined, getAdvertismentParams: undefined },
    stringifyOptions
  );

  try {
    const result: IResponseWithValue<number> = yield call(
      ApiBase.put,
      `${URL}/api/advertising/post${params}`,
      action.payload.Photo
    );

    if (result.success) {
      toast({
        title: 'Advertisment Post Successfully Edited',
        status: 'success',
        duration: 5000,
      });

      yield put(switchIsCreateAdvertismentModalOpen(false));
      yield put(switchIsPostDetailsModalOpen(false));
      yield put(cleanAdvertismentPostByIdData());

      if (action.payload?.getAdvertismentParams)
        yield put(getAdvertisment(action.payload.getAdvertismentParams));
    }
  } catch (error: any) {
    console.error(error);
  }
}

export default function* watchEditAdvertismentPostSaga(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeEvery(editAdvertismentPost.type, workerEditAdvertismentPost);
}
