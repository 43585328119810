import React from 'react';

interface IArchiveIconProps {
  isActive?: boolean;
}

export const ArchiveIcon: React.FC<IArchiveIconProps> = ({ isActive }) => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 8.5H10M0.5 0.5H14.5V4.5H0.5V0.5ZM1.5 4.5V13.5C1.5 14.0523 1.94772 14.5 2.5 14.5H12.5C13.0523 14.5 13.5 14.0523 13.5 13.5V4.5H1.5Z" stroke={isActive ? '#4FD1C5' : '#FFF'} />
  </svg>
);
