import React from 'react';

interface IIcon {
  color?: string
}

export const UpDownArrowIcon: React.FC<IIcon> = ({ color }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.95833 6.625L8.78 7.80333L6.66667 5.69V16.6667H5V5.69L2.8875 7.80333L1.70834 6.625L5.83334 2.5L9.95833 6.625ZM18.2917 13.375L14.1667 17.5L10.0417 13.375L11.22 12.1967L13.3342 14.31L13.3333 3.33333H15V14.31L17.1133 12.1967L18.2917 13.375Z"
      fill={color || '#888DAB'}
    />
  </svg>
);
