import React, { FC } from 'react';

interface IProps {
  strokeColor?: string;
  width?: string;
  height?: string;
}

export const TickIcon: FC<IProps> = ({ strokeColor, width, height }) => (
  <svg
    width={width || '16'}
    height={height || '16'}
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M1.0663 7.46686L5.8663 12.2669L14.933 3.2002"
      stroke={strokeColor || '#6FCF97'}
      strokeWidth="1.44737"
      strokeLinecap="square"
    />
  </svg>
);
