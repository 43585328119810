import {
  call, ForkEffect, put, takeEvery,
} from 'redux-saga/effects';

import {
  getAgenciesForPartnership,
  getAgenciesForPartnershipError,
  getAgenciesForPartnershipSuccess,
} from '../../actions/agency.actions';
import ApiBase, { URL } from '../../../common/api/api.base';
import { IResponseWithValue, AgencyPartnershipDetails } from '../../../types';

function* workerGetAgenciesForPartnership(action: ReturnType<typeof getAgenciesForPartnership>) {
  const statesNumber = action.payload.States.map((state) => `&States=${state}`);
  const states = action.payload.States.length !== 0 ? statesNumber.join('') : '';
  try {
    const result: IResponseWithValue<{ agencies: AgencyPartnershipDetails[]; count: number }> = yield call(ApiBase.get, `${URL}/api/admin/get-agencies-for-partnership?Id=${action.payload.Id}&Name=${action.payload.Name}&OrderType=${action.payload.OrderType}&PageNumber=${action.payload.PageNumber}&PageSize=${action.payload.PageSize}${states}`);
    yield console.log('getAgenciesForPartnership', action, result);
    if (result.success) {
      yield put(getAgenciesForPartnershipSuccess(result));
    } else {
      yield put(getAgenciesForPartnershipError(result.errors));
    }
  } catch (error) {
    yield put(getAgenciesForPartnershipError(error));
    console.log(error);
  }
}

export default function* watchGetAgenciesForPartnershipSaga():
Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(getAgenciesForPartnership.type, workerGetAgenciesForPartnership);
}
