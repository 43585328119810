import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import apiBase, { URL } from '../../../common/api/api.base';
import { IResponseWithoutValue } from '../../../types';
import {
  discardLicenseError,
  discardLicenseRequest,
  discardLicenseSuccess,
} from '../../actions/licenses.actions';

function* workerDiscardLicense(action: ReturnType<typeof discardLicenseRequest>) {
  try {
    const result: IResponseWithoutValue = yield call(
      apiBase.put,
      `${URL}/api/admin/discard-license/${action.payload.id}`,
      {},
    );

    if (result.success) {
      yield put(discardLicenseSuccess(action.payload.id));
    } else {
      yield put(discardLicenseError(result.errors));
    }
  } catch (err) {
    const message = err instanceof Error ? err.message : 'Unknown error occured';

    yield put(discardLicenseError([{ key: 'unknown', errorMessage: message }]));
  }
}

export default function* watchDiscardLicenseSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(discardLicenseRequest.type, workerDiscardLicense);
}
