import React, { useState } from 'react';
import dayjs from 'dayjs';
import {
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Thead,
  Tr,
  Spinner,
} from '@chakra-ui/react';

import { colors, fonts } from '../../../utils';
import DashboardDeleteModal from '../modals/DashboardDeleteModal';
import { Typography, CustomButton } from '../../../common/components';
import {
  renderDataHeader,
  renderDataBody,
} from '../../../common/helpers/table/tableRendering';
import { ImportLogsType } from '../../../types';
import DataImportInfoModal from '../modals/DataImportInfoModal';

interface IDashboardTableProps {
  title: string;
  importLogs: ImportLogsType[];
  membersLoading: boolean;
  onCloseModal: () => void;
  isModalOpen: boolean;
  onOpenModal: () => void;
  onDeleteImportLog: (id: number) => void;
}

export const DashboardDataImport: React.FC<IDashboardTableProps> = ({
  title,
  importLogs,
  membersLoading,
  onCloseModal,
  isModalOpen,
  onOpenModal,
  onDeleteImportLog,
}) => {
  const [logId, setLogId] = useState(0);
  const [timeModal, setTimeModal] = useState('');
  const [messageModal, setMessageModal] = useState('');
  const [bodyModal, setBodyModal] = useState('');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleOpenUserModal = (time: string, message: string, body: string) => {
    setTimeModal(time);
    setMessageModal(message);
    setBodyModal(body);
    onOpenModal();
  };

  const handleOpenDeleteModal = (id: number) => {
    setLogId(id);
    setIsDeleteModalOpen(true);
  };

  return (
    <Box>
      <Flex justify="space-between" align="center" p="25px 40px 40px">
        <Box d="flex" flexDir="row" alignItems="center">
          <Typography size={20} family={fonts.roboto}>
            {title}
          </Typography>
        </Box>
      </Flex>
      <Box p="0 20px" mb="50px">
        <Table colorScheme="blackAlpha" className="fixed">
          <Thead>
            <Tr bg="#EDF2F7">
              {renderDataHeader(colors.primary, 'Time', '10vw')}
              {renderDataHeader(colors.primary, 'Message', '40vw')}
              {renderDataHeader(colors.primary, 'Action', '10vw')}
            </Tr>
          </Thead>
          <Tbody>
            {membersLoading ? (
              <Tr pos="relative" h="200px">
                <Td
                  border="0"
                  pos="absolute"
                  top="50%"
                  left="50%"
                  transform="translate(-50%, -50%)"
                >
                  <Spinner size="xl" />
                </Td>
              </Tr>
            ) : importLogs.length !== 0 ? (
              importLogs.map((log) => {
                const idDate = dayjs(log.time).format('MM/DD/YYYY hh:mm A');
                const localIdLicenseDate =
                  log.time === '0001-01-01T00:00:00Z' ? '' : idDate;
                return (
                  <Tr key={log.id}>
                    {renderDataBody(colors.gray, localIdLicenseDate, '10vw')}
                    {renderDataBody(colors.gray, log.message, '40vw', () =>
                      handleOpenUserModal(
                        localIdLicenseDate,
                        log.message,
                        log.body
                      )
                    )}
                    <Td w="10vw">
                      <CustomButton
                        variant="link"
                        isSmall
                        isFullWidth={false}
                        color={colors.gray}
                        background="transparent"
                        onClick={() => handleOpenDeleteModal(log.id)}
                      >
                        Delete
                      </CustomButton>
                    </Td>
                  </Tr>
                );
              })
            ) : null}
          </Tbody>
        </Table>
      </Box>
      {isModalOpen && (
        <DataImportInfoModal
          isModalOpen={isModalOpen}
          onCloseModal={onCloseModal}
          loading={false}
          time={timeModal}
          message={messageModal}
          body={bodyModal}
        />
      )}
      {isDeleteModalOpen && (
        <DashboardDeleteModal
          title="Are you sure you want delete this message?"
          text="This message will be deleted from Import log"
          isOpen={isDeleteModalOpen}
          onClick={() => onDeleteImportLog(logId)}
          setIsModalOpen={setIsDeleteModalOpen}
        />
      )}
    </Box>
  );
};
