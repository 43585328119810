import React, { FC } from 'react';

interface IProps {
  strokeFill?: string;
  width?: string;
  height?: string;
}

export const DownIcon: FC<IProps> = ({ strokeFill, width, height }) => (
  <svg
    width={width || '15'}
    height={height || 'height'}
    viewBox="0 0 15 15"
    fill="none"
  >
    <path
      strokeWidth="2"
      d="M14 5L7.5 12L1 5"
      stroke={strokeFill || '#403845'}
      strokeLinecap="square"
    />
  </svg>
);
