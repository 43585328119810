import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DashboardPaginator, ReportsTable } from '../components';
import { getReports } from '../../../redux/actions/reports.actions';
import { RootStore } from '../../../redux';

const PAGE_SIZE = 50;

export const UpdatesReports: React.FC = () => {
  const dispatch = useDispatch();

  const [orderType, setOrderType] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [property, setProperty] = useState('');
  const [agencySelectedFilter, setAgencySelectedFilter] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [suspendedFilterItems, setSuspendedFilterItems] = useState<number[]>([
    1, 2,
  ]);

  const {
    reports,
    reportsTotalCount,
    loading: reportsLoading,
  } = useSelector((state: RootStore) => state.reports);
  const { states } = useSelector((state: RootStore) => state.auxiliary);

  useEffect(() => {
    dispatch(
      getReports({
        OrderType: orderType,
        SortProperty: property,
        AgencyId: agencySelectedFilter,
        PageNumber: currentPage,
        PageSize: PAGE_SIZE,
        IsSuspended:
          suspendedFilterItems.length !== 1
            ? ''
            : suspendedFilterItems.includes(1),
        Name: searchQuery,
      })
    );
  }, [
    orderType,
    property,
    agencySelectedFilter,
    currentPage,
    suspendedFilterItems,
    searchQuery,
  ]);

  return (
    <>
      <ReportsTable
        states={states}
        reports={reports}
        reportsLoading={reportsLoading}
        reportOrderType={orderType}
        setReportProperty={setProperty}
        setReportOrderType={setOrderType}
        suspendedFilterItems={suspendedFilterItems}
        setSuspendedFilterItems={setSuspendedFilterItems}
        agencySelectedFilter={agencySelectedFilter}
        setAgencySelectedFilter={setAgencySelectedFilter}
        setReportsName={setSearchQuery}
        reportsName={searchQuery}
      />
      <DashboardPaginator
        pageSize={PAGE_SIZE}
        membersLoading={reportsLoading}
        currentPage={currentPage}
        usersTotalCount={reportsTotalCount}
        setCurrentPage={setCurrentPage}
      />
    </>
  );
};
