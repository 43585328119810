import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import ApiBase, { URL } from '../../../common/api/api.base';
import {
  getAgencies,
  getAgenciesError,
  getAgenciesSuccess,
} from '../../actions/auxiliary.actions';
import { IResponseWithValue, AgenciesType } from '../../../types';

function* workerGetAgencies(action: ReturnType<typeof getAgencies>) {
  const userId = action.payload.userId
    ? `?userId=${action.payload.userId}`
    : '';

  try {
    const result: IResponseWithValue<{ agencies: AgenciesType[] }> = yield call(
      ApiBase.get,
      `${URL}/api/admin/agencies/states/${action.payload.stateId}${userId}`
    );
    yield console.log('getAgencies', action, result);
    if (result.success) {
      yield put(getAgenciesSuccess(result));
    } else {
      yield put(getAgenciesError(result.errors));
    }
  } catch (error) {
    yield put(getAgenciesError(error));
    console.log('getAgenciesError', error);
  }
}

export default function* watchGetAgenciesSaga(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeEvery(getAgencies.type, workerGetAgencies);
}
