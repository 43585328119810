import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../redux';
import { getSuspendedMembers } from '../../../redux/actions/members.actions';
import { DashboardPaginator, NewDashboardTable } from '../components';
import { SideTextEnum } from '../../../common/const/sideBar';

const PAGE_SIZE = 50;

export const SuspendedUsers: React.FC = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [suspendCheckedStates, setSuspendCheckedStates] = useState<number[]>(
    []
  );
  const [nameValue, setNameValue] = useState('');
  const [orderType, setOrderType] = useState(true);
  const [changeTable, setChangeTable] = useState(1);
  const [orderField, setOrderField] = useState<string | undefined>();

  const { usersTotalCount, loading: membersLoading } = useSelector(
    (state: RootStore) => state.members
  );

  useEffect(() => {
    dispatch(
      getSuspendedMembers({
        OrderField: orderField,
        OrderType: orderType,
        PageNumber: currentPage,
        PageSize: PAGE_SIZE,
        Name: nameValue,
        States: suspendCheckedStates,
      })
    );
  }, [currentPage, nameValue, orderType, orderField, suspendCheckedStates, changeTable]);

  return (
    <>
      <NewDashboardTable
        checkedStates={suspendCheckedStates}
        nameValue={nameValue}
        orderType={orderType}
        setCheckedStates={setSuspendCheckedStates}
        setNameValue={setNameValue}
        setOrderField={setOrderField}
        title={SideTextEnum.SuspendedUsers}
        setOrderType={setOrderType}
        setChangeTable={setChangeTable}
        tooltip="List of user accounts whose access to the system have been suspended. These users cannot log into the system. Their accounts are currently suspended, after which they can be moved into the Archived Accounts tab."
        showBadge={false}
      />
      <DashboardPaginator
        pageSize={PAGE_SIZE}
        currentPage={currentPage}
        membersLoading={membersLoading}
        setCurrentPage={setCurrentPage}
        usersTotalCount={usersTotalCount}
      />
    </>
  );
};
