import { createAction } from '@reduxjs/toolkit';
import {
  PayloadError,
  AgentsType,
  GetDataImportPayload,
} from '../../types';

export const getImportInfoRequest = createAction<GetDataImportPayload>('GET_IMPORT_INFO_REQUEST');
export const getImportInfoSuccess = createAction<any>('GET_IMPORT_INFO_SUCCESS');
export const getImportInfoError = createAction<PayloadError[]>('GET_IMPORT_INFO_ERROR');

export const deleteImportLog = createAction<number>('DELETE_IMPORT_INFO_REQUEST');
export const deleteImportLogSuccess = createAction<number>('DELETE_IMPORT_INFO_SUCCESS');
export const deleteImportLogError = createAction<PayloadError[]>('DELETE_IMPORT_INFO_ERROR');

export const agentSearchRequest = createAction<string>('AGENT_SEARCH_REQUEST');
export const agentSearchSuccess = createAction<AgentsType[]>('AGENT_SEARCH_SUCCESS');
export const agentSearchError = createAction<PayloadError[]>('AGENT_SEARCH_INFO_ERROR');
