import { createSlice } from '@reduxjs/toolkit';
import { AdvertismentModelType, PayloadError } from '../../types';
import {
  getAdvertisment,
  getAdvertismentError,
  getAdvertismentPostById,
  getAdvertismentPostByIdError,
  getAdvertismentPostByIdSuccess,
  cleanAdvertismentPostByIdData,
  getAdvertismentSuccess,
  switchIsCreateAdvertismentModalOpen,
  switchIsPostDetailsModalOpen,
  switchIsDeletePostModalOpen,
  getAdvertismentForAgency,
  getAdvertismentForAgencySuccess,
  getAdvertismentForAgencyError,
} from '../actions/advertisment.actions';

type InitialState = {
  isCreateAdvertismentModalOpen: boolean;
  isPostDetailsModalOpen: boolean;
  isDeletePostModalOpen: boolean;
  loading: boolean;
  errors: PayloadError[];
  loadingPost: boolean;
  errorsPost: PayloadError[];
  advertisment: AdvertismentModelType[];
  advertismentForAgency: {
    posts: AdvertismentModelType[];
    loading: boolean;
    errors: PayloadError[];
    totalCount: number;
  };
  advertismentPost: AdvertismentModelType | { [key: string]: never };
  totalCount: number;
};

export const initialState: InitialState = {
  isCreateAdvertismentModalOpen: false,
  isPostDetailsModalOpen: false,
  isDeletePostModalOpen: false,
  loading: false,
  errors: [],
  loadingPost: false,
  errorsPost: [],
  advertisment: [],
  advertismentForAgency: {
    posts: [],
    loading: false,
    errors: [],
    totalCount: 0,
  },
  advertismentPost: {},
  totalCount: 0,
};

const advertismentSlice = createSlice({
  name: 'advertisment',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(switchIsCreateAdvertismentModalOpen, (state, action) => {
        state.isCreateAdvertismentModalOpen = action.payload;
      })
      .addCase(switchIsPostDetailsModalOpen, (state, action) => {
        state.isPostDetailsModalOpen = action.payload;
      })
      .addCase(switchIsDeletePostModalOpen, (state, action) => {
        state.isDeletePostModalOpen = action.payload;
      })
      .addCase(getAdvertisment, (state) => {
        state.loading = true;
        state.errors = [];
      })
      .addCase(getAdvertismentSuccess, (state, action) => {
        state.loading = false;
        state.errors = [];
        state.advertisment = action.payload.value.posts;
        state.totalCount = action.payload.value.totalCount;
      })
      .addCase(getAdvertismentError, (state, action) => {
        state.loading = false;
        state.errors = action.payload;
      })
      .addCase(getAdvertismentForAgency, (state) => {
        state.advertismentForAgency.loading = true;
        state.advertismentForAgency.errors = [];
      })
      .addCase(getAdvertismentForAgencySuccess, (state, action) => {
        state.advertismentForAgency.loading = false;
        state.advertismentForAgency.errors = [];
        state.advertismentForAgency.posts = action.payload.value.posts;
        state.advertismentForAgency.totalCount =
          action.payload.value.totalCount;
      })
      .addCase(getAdvertismentForAgencyError, (state, action) => {
        state.advertismentForAgency.loading = false;
        state.advertismentForAgency.errors = action.payload;
      })
      .addCase(getAdvertismentPostById, (state) => {
        state.loadingPost = true;
        state.errorsPost = [];
      })
      .addCase(getAdvertismentPostByIdSuccess, (state, action) => {
        state.loadingPost = false;
        state.errorsPost = [];
        state.advertismentPost = action.payload.value.post;
      })
      .addCase(getAdvertismentPostByIdError, (state, action) => {
        state.loadingPost = false;
        state.errorsPost = action.payload;
      })
      .addCase(cleanAdvertismentPostByIdData, (state) => {
        state.advertismentPost = {};
      });
  },
});

export default advertismentSlice.reducer;
