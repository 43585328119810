import React, { KeyboardEventHandler } from 'react';
import {
  Box, Input, InputGroup, InputLeftElement, InputRightElement, Spinner,
} from '@chakra-ui/react';

import { colors, fonts } from '../../../utils';
import { SearchIcon } from '../../../assets/icons';

interface ISearchInputProps {
  value?: string;
  isInvalid?: boolean;
  placeholder: string;
  onChange?: (data: string) => void;
  handleKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  loading?: boolean;
}

const SearchInput: React.FC<ISearchInputProps> = ({
  value,
  onChange,
  isInvalid,
  placeholder,
  handleKeyDown,
  loading,
}) => (
  <Box>
    <InputGroup>
      <InputLeftElement pointerEvents="none">
        <SearchIcon />
      </InputLeftElement>
      <Input
        fontFamily={fonts.roboto}
        placeholder={placeholder}
        isInvalid={isInvalid}
        errorBorderColor={colors.error}
        onChange={(e) => onChange && onChange(e.target.value)}
        value={value}
        onKeyDown={handleKeyDown}
      />
      {loading && (
      <InputRightElement>
        <Spinner size="sm" />
      </InputRightElement>
      )}
    </InputGroup>
  </Box>
);

export { SearchInput };
