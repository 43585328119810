import {
  takeEvery, ForkEffect, call, put,
} from 'redux-saga/effects';
import ApiBase, { URL } from '../../../common/api/api.base';
import { getMemberLicenses, getMemberLicensesError, getMemberLicensesSuccess } from '../../actions/licenses.actions';
import { LicensesType, IResponseWithValue } from '../../../types';

export function* workerGetMemberLicenses(action: ReturnType<typeof getMemberLicenses>) {
  try {
    const result: IResponseWithValue<{ licenses: LicensesType[] }> = yield call(ApiBase.get, `${URL}/api/admin/get-member-licenses?UserId=${action.payload.UserId}`);
    yield console.log('getMemberLicenses', action, result);
    if (result.success) {
      yield put(getMemberLicensesSuccess(result));
    } else {
      yield put(getMemberLicensesError(result.errors));
    }
  } catch (error) {
    yield put(getMemberLicensesError(error));
    console.log(error);
  }
}

export default function* watchGetMemberLicensesSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(getMemberLicenses.type, workerGetMemberLicenses);
}
