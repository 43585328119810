import React from 'react';

export const LockIcon = () => (
  <svg
    width="12"
    height="16"
    viewBox="0 0 12 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.4"
      d="M10.3168 5.89H9.82579V3.927C9.82579 2.88549 9.41205 1.88665 8.6756 1.15019C7.93914 0.413736 6.94029 0 5.89879 0C4.85728 0 3.85844 0.413736 3.12198 1.15019C2.38553 1.88665 1.97179 2.88549 1.97179 3.927V5.89H1.48179C1.09121 5.89026 0.716697 6.04554 0.440513 6.32172C0.16433 6.59791 0.00905395 6.97242 0.00878906 7.363V14.235C0.00905395 14.6256 0.16433 15.0001 0.440513 15.2763C0.716697 15.5525 1.09121 15.7077 1.48179 15.708H10.3178C10.7084 15.7077 11.0829 15.5525 11.3591 15.2763C11.6352 15.0001 11.7905 14.6256 11.7908 14.235V7.363C11.7905 6.97224 11.6351 6.59758 11.3587 6.32137C11.0823 6.04516 10.7075 5.89 10.3168 5.89ZM3.28079 3.927C3.28079 3.23266 3.55661 2.56676 4.04758 2.07579C4.53855 1.58482 5.20445 1.309 5.89879 1.309C6.59313 1.309 7.25902 1.58482 7.74999 2.07579C8.24096 2.56676 8.51679 3.23266 8.51679 3.927V5.89H3.28079V3.927ZM6.55279 10.944V12.435C6.55279 12.6086 6.48383 12.7751 6.36109 12.8978C6.23835 13.0205 6.07187 13.0895 5.89829 13.0895C5.7247 13.0895 5.55823 13.0205 5.43549 12.8978C5.31275 12.7751 5.24379 12.6086 5.24379 12.435V10.944C4.99424 10.7999 4.7992 10.5775 4.68893 10.3113C4.57866 10.0451 4.55931 9.74992 4.63389 9.47158C4.70847 9.19324 4.87281 8.94729 5.10142 8.77187C5.33003 8.59646 5.61013 8.50137 5.89829 8.50137C6.18644 8.50137 6.46655 8.59646 6.69516 8.77187C6.92377 8.94729 7.08811 9.19324 7.16269 9.47158C7.23727 9.74992 7.21792 10.0451 7.10765 10.3113C6.99737 10.5775 6.80234 10.7999 6.55279 10.944Z"
      fill="#2C335C"
    />
  </svg>
);
