import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessages } from './messages';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const addOfficeScheme = yup.object().shape({
    email: yup.string().required(ErrorMessages.REQUIRED).email(ErrorMessages.EMAIL_INVALID),
    phoneNumber: yup.string().required(ErrorMessages.REQUIRED).matches(phoneRegExp, ErrorMessages.PHONE_NUMBER_INVALID)
    ,
});

export const addOfficeResolver = yupResolver(addOfficeScheme);