import React from 'react';
import { useForm } from 'react-hook-form';
import { NavLink } from 'react-router-dom';
import { FormErrorMessage, FormControl, Box } from '@chakra-ui/react';

import { AuthPayload } from '../../../../types';
import { EmailIcon, LockIcon } from '../../../../assets/icons';
import {
  CustomButton,
  TextInput,
  Typography,
} from '../../../../common/components';

interface ISignInFormProps {
  loading: boolean;
  onLogin: (data: AuthPayload) => void;
}

const SignInForm: React.FC<ISignInFormProps> = ({ onLogin, loading }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  function onSubmit(data: AuthPayload) {
    onLogin(data);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box mt="3">
        <FormControl isInvalid={errors.userIdentifier}>
          <TextInput
            label="Email"
            placeholder="emailexample@mail.com"
            icon={<EmailIcon />}
            {...register('userIdentifier', {
              required: 'This is required',
              minLength: { value: 4, message: 'Minimum length should be 4' },
            })}
          />
          <FormErrorMessage m="0px">
            {errors.userIdentifier && errors.userIdentifier.message}
          </FormErrorMessage>
        </FormControl>
      </Box>
      <Box mt="3">
        <FormControl isInvalid={errors.password}>
          <TextInput
            label="Password"
            placeholder="password"
            isPassword
            icon={<LockIcon />}
            {...register('password', {
              required: 'This is required',
              minLength: { value: 4, message: 'Minimum length should be 4' },
            })}
          />
          <FormErrorMessage m="0px">
            {errors.password && errors.password.message}
          </FormErrorMessage>
        </FormControl>
      </Box>
      <Box mt="8">
        <NavLink to="/password">
          <Typography size={14} isUnderline>
            Forgot your password?
          </Typography>
        </NavLink>
      </Box>
      <Box w="342px" h="59px" mt="5">
        <CustomButton isLoading={loading} type="submit">
          Sign In
        </CustomButton>
      </Box>
    </form>
  );
};

export default SignInForm;
