import React from 'react';

export const BackArrow: React.FC<{ color?: string }> = ({ color = '#2C335C' }) => (
  <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.308 16.469H12.973L15.783 13.659C15.8323 13.6097 15.8714 13.5512 15.8981 13.4867C15.9248 13.4223 15.9385 13.3532 15.9385 13.2835C15.9385 13.2138 15.9248 13.1447 15.8981 13.0803C15.8714 13.0159 15.8323 12.9573 15.783 12.908C15.7337 12.8587 15.6751 12.8196 15.6107 12.7929C15.5463 12.7662 15.4772 12.7525 15.4075 12.7525C15.3378 12.7525 15.2687 12.7662 15.2043 12.7929C15.1398 12.8196 15.0813 12.8587 15.032 12.908L11.317 16.624C11.2677 16.6733 11.2286 16.7319 11.2019 16.7963C11.1752 16.8607 11.1614 16.9298 11.1614 16.9995C11.1614 17.0692 11.1752 17.1383 11.2019 17.2027C11.2286 17.2672 11.2677 17.3257 11.317 17.375L15.033 21.091C15.0823 21.1403 15.1408 21.1794 15.2053 21.2061C15.2697 21.2328 15.3388 21.2465 15.4085 21.2465C15.4782 21.2465 15.5473 21.2328 15.6117 21.2061C15.6761 21.1794 15.7347 21.1403 15.784 21.091C15.8333 21.0417 15.8724 20.9832 15.8991 20.9187C15.9258 20.8543 15.9395 20.7852 15.9395 20.7155C15.9395 20.6458 15.9258 20.5767 15.8991 20.5123C15.8724 20.4479 15.8333 20.3893 15.784 20.34L12.974 17.53H22.309C22.3787 17.53 22.4478 17.5163 22.5122 17.4896C22.5766 17.4629 22.6352 17.4238 22.6845 17.3745C22.7338 17.3252 22.7729 17.2666 22.7996 17.2022C22.8263 17.1378 22.84 17.0687 22.84 16.999C22.84 16.9293 22.8263 16.8602 22.7996 16.7958C22.7729 16.7314 22.7338 16.6728 22.6845 16.6235C22.6352 16.5742 22.5766 16.5351 22.5122 16.5084C22.4478 16.4817 22.3787 16.468 22.309 16.468L22.308 16.469Z"
      fill={color}
    />
    <path
      d="M16.5 32.5C25.3366 32.5 32.5 25.3366 32.5 16.5C32.5 7.66344 25.3366 0.5 16.5 0.5C7.66344 0.5 0.5 7.66344 0.5 16.5C0.5 25.3366 7.66344 32.5 16.5 32.5Z"
      stroke={color}
    />
  </svg>
);
