import {
  call, ForkEffect, put, takeEvery,
} from 'redux-saga/effects';
import {
  getArchivedMembers,
  getArchivedMembersError,
  getArchivedMembersSuccess,
} from '../../actions/members.actions';
import ApiBase, { URL } from '../../../common/api/api.base';
import { ArchivedUsersType, IResponseWithValue } from '../../../types';

function* workerGetArchivedMembers(action: ReturnType<typeof getArchivedMembers>) {
  const {
    OrderType, PageNumber, PageSize, StateIds, OrderField, SearchQuery,
  } = action.payload;
  const searchQuery = SearchQuery ? `&SearchQuery=${SearchQuery}` : '';
  const statesNumber = StateIds.map((state) => `&StateIds=${state}`);
  const states = StateIds.length !== 0 ? statesNumber.join('') : '';
  const orderField = OrderField ? `&OrderField=${OrderField}` : '';
  try {
    const result: IResponseWithValue<{
      models: ArchivedUsersType[];
      totalCount: number;
    }> = yield call(ApiBase.get, `${URL}/api/admin/users/archived?PageNumber=${PageNumber}&PageSize=${PageSize}&OrderType=${OrderType}${orderField}${states}${searchQuery}`);
    yield console.log('getArchivedMembers', action, result);
    if (result.success) {
      yield put(getArchivedMembersSuccess(result));
    } else {
      yield put(getArchivedMembersError(result.errors));
    }
  } catch (error) {
    yield put(getArchivedMembersError(error));
    console.log(error);
  }
}

export default function* watchGetArchivedMembersSaga()
: Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(getArchivedMembers.type, workerGetArchivedMembers);
}
