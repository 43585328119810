import { LicenseType } from '../../../types';
import { SideTextEnum } from '../../const/sideBar';
import { useLicense } from '../../hooks/useLicense';

export const renderExpirationLable = (license: LicenseType, type: SideTextEnum) => {
  const { defineExpirationStatus } = useLicense();
  const { expirationStatus, expiresIn } = defineExpirationStatus(license);

  if (type === SideTextEnum.WorkMembers && expirationStatus === 'active') {
    return 'Active';
  }

  if (type === SideTextEnum.WorkMembers && expirationStatus === 'expiring') {
    return `Exp. in ${expiresIn} days`;
  }

  if (type === SideTextEnum.VerifiedUsers && expirationStatus === 'expired') {
    return 'Expired';
  }

  if (type === SideTextEnum.VerifiedUsers && expirationStatus === 'new') {
    return 'New';
  }

  return '';
};
