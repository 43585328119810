import React, { ReactElement } from 'react';
import {
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from '@chakra-ui/react';
import { Typography } from '../Typography';

interface IPopupProps {
  count: number;
  body: ReactElement;
  children: ReactElement;
}

const OtherPopup: React.FC<IPopupProps> = ({ children, count, body }) => {
  const initRef = React.useRef<any>();
  return (
    <Popover placement="bottom-start" isLazy size="md" initialFocusRef={initRef}>
      <PopoverTrigger>
        {children}
      </PopoverTrigger>
      <PopoverContent
        maxWidth="264px"
        maxHeight="300px"
        border="none"
        boxShadow="7px 0px 30px rgba(102, 102, 102, 0.25)"
        p={1}
        overflow="scroll"
      >
        <PopoverHeader fontWeight="semibold" border="none">
          <Typography size={16}>
            Other reports

            (
            {count}
            ):
          </Typography>
        </PopoverHeader>
        <PopoverCloseButton m={1} />
        <PopoverBody ref={initRef}>
          {body}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export { OtherPopup };
