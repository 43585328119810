import { combineReducers, Reducer } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import membersSlice from './members.slice';
import sessionSlice from './session.slice';
import licensesSlice from './licenses.slice';
import auxiliarySlice from './auxiliary.slice';
import directoriesSlice from './directories.slice';
import reportsSlice from './reports.slice';
import agencySlice from './agency.slice';
import invitationSlice from './invitation.slice';
import importSlice from './import.slice';
import officesSlice from './offices.slice';
import advertismentSlice from './advertisment.slice';

const rootSlice = (history: History): Reducer =>
  combineReducers({
    router: connectRouter(history),
    session: sessionSlice,
    licenses: licensesSlice,
    members: membersSlice,
    auxiliary: auxiliarySlice,
    directories: directoriesSlice,
    reports: reportsSlice,
    agency: agencySlice,
    invitation: invitationSlice,
    import: importSlice,
    offices: officesSlice,
    advertisment: advertismentSlice,
  });

export default rootSlice;
