import { createStandaloneToast } from '@chakra-ui/react';
import {
  call, ForkEffect, put, takeEvery,
} from 'redux-saga/effects';

import {
  setIdLicensePhoto,
  setIdLicensePhotoError,
  setIdLicensePhotoSuccess,
} from '../../actions/licenses.actions';
import ApiBase, { URL } from '../../../common/api/api.base';
import { IResponseWithoutValue } from '../../../types';

function* workerSetIdLicensePhoto(action: ReturnType<typeof setIdLicensePhoto>) {
  const toast = createStandaloneToast();

  try {
    const result: IResponseWithoutValue = yield call(ApiBase.file, `${URL}/api/admin/set-id-license-photo?userId=${action.payload.userId}`,
      action.payload.photo as Blob);
    yield console.log('setIdLicensePhoto', action, result);
    if (result.success) {
      yield put(setIdLicensePhotoSuccess());
      toast({
        title: 'The photo updated successfully',
        status: 'success',
        duration: 5000,
      });
    } else {
      yield put(setIdLicensePhotoError(result.errors));
    }
  } catch (error) {
    yield put(setIdLicensePhotoError(error));
    console.log('error', error);
  }
}

export default function* watchSetIdLicensePhotoSaga():
Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(setIdLicensePhoto.type, workerSetIdLicensePhoto);
}
