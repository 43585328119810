import {
  takeEvery, ForkEffect, call, put,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';

import ApiBase, { URL } from '../../../common/api/api.base';
import { login, loginError, loginSuccess } from '../../actions/auth.actions';
import { UserType, IResponseWithValue } from '../../../types';

function* workerLogin(action: ReturnType<typeof login>) {
  try {
    const result: IResponseWithValue<UserType> = yield call(ApiBase.post, `${URL}/api/auth/signin`, action.payload);
    yield console.log('login', action, result);
    if (result.success) {
      yield put(loginSuccess(result));
      yield put(push('/active-users'));
    } else {
      yield put(loginError(result.errors));
    }
  } catch (error) {
    yield put(loginError(error));
    console.log(error);
  }
}

export default function* watchLoginSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(login.type, workerLogin);
}
