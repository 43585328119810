import React, { Dispatch, SetStateAction } from 'react';
import {
  Modal,
  ModalContent,
  Image,
  ModalOverlay,
  ModalCloseButton,
} from '@chakra-ui/react';

import { Typography } from '../../../common/components';

type IProps = {
  isOpen: boolean,
  imageSource?: string;
  setIsOpen: Dispatch<SetStateAction<boolean>>
};

const DashboardPhotoModal:React.FC<IProps> = ({ isOpen, setIsOpen, imageSource }) => (
  <>
    <Modal
      autoFocus={false}
      isCentered
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent bg="rgba(255, 255, 255, 0.98)" p="40px">
        <Typography weight={600} size={16}>Licence photo</Typography>
        <ModalCloseButton m="25px" />
        <Image
          mt="20px"
          borderRadius="20"
          src={imageSource}
          alt="Driver License"
          objectFit="cover"
        />
      </ModalContent>
    </Modal>
  </>
);

export default DashboardPhotoModal;
