import {
  call, ForkEffect, put, takeEvery,
} from 'redux-saga/effects';
import { createStandaloneToast } from '@chakra-ui/react';

import ApiBase, { URL } from '../../../common/api/api.base';
import {
  setRealEstatePhoto,
  setRealEstatePhotoError,
  setRealEstatePhotoSuccess,
} from '../../actions/licenses.actions';
import { IResponseWithValue, ImageType } from '../../../types';

function* workerSetRealEstatePhoto(action: ReturnType<typeof setRealEstatePhoto>) {
  const toast = createStandaloneToast();

  try {
    const result: IResponseWithValue<ImageType> = yield call(ApiBase.file, `${URL}/api/admin/set-real-estate-license-photo?licenseId=${action.payload.licenseId}`, action.payload.photo as Blob);
    yield console.log('setRealEstatePhoto', action, result);
    if (result.success) {
      yield put(setRealEstatePhotoSuccess({ result, licenseId: action.payload.licenseId }));
      toast({
        title: 'The photo updated successfully',
        status: 'success',
        duration: 5000,
      });
    } else {
      yield put(setRealEstatePhotoError(result.errors));
    }
  } catch (error) {
    yield put(setRealEstatePhotoError(error));
    console.log('error', error);
  }
}

export default function* watchSetRealEstatePhotoSaga():
Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(setRealEstatePhoto.type, workerSetRealEstatePhoto);
}
