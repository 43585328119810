import {
  takeEvery, ForkEffect, call, put,
} from 'redux-saga/effects';
import ApiBase, { URL } from '../../../common/api/api.base';
import {
  getSuspendedMembers,
  getSuspendedMembersError,
  getSuspendedMembersSuccess,
} from '../../actions/members.actions';
import { IResponseWithValue, UserViewedFlagsType, ZootProUsersType } from '../../../types';

function* workerGetMembers(action: ReturnType<typeof getSuspendedMembers>) {
  const {
    OrderType,
    PageNumber,
    PageSize,
    States,
    Name,
    OrderField,
  } = action.payload;
  const orderField = OrderField ? `&OrderField=${OrderField}` : '';
  const name = Name ? `&Name=${Name}` : '';
  const statesNumber = States.map((state) => `&States=${state}`);
  const states = States.length !== 0 ? statesNumber.join('') : '';
  try {
    const result: IResponseWithValue<{
      isAnyUserViewedFlags: UserViewedFlagsType;
      zootProUsers: ZootProUsersType[];
      count: number;
    }> = yield call(ApiBase.get, `${URL}/api/admin/get-suspended-members?OrderType=${OrderType}${orderField}${name}${states}&PageNumber=${PageNumber}&PageSize=${PageSize}`);
    yield console.log('getSuspendedMembers', action, result);
    if (result.success) {
      yield put(getSuspendedMembersSuccess(result));
    } else {
      yield put(getSuspendedMembersError(result.errors));
    }
  } catch (error) {
    yield put(getSuspendedMembersError(error));
    console.log(error);
  }
}

export default function* watchGetSuspendedMembersSaga()
: Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(getSuspendedMembers.type, workerGetMembers);
}
