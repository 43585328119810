import React, { useState } from 'react';
import {
  Tr, Td, Spinner, Checkbox, VStack, Text, Flex,
} from '@chakra-ui/react';
import { colors, fonts } from '../../../../utils';
import { renderTableBody } from '../../../../common/helpers/table/tableRendering';
import {
  AgencyPartnershipDetails,
  EditPartnerAgencyChoicePayload,
  AddPartnerToAgencyPayload,
  RemovePartnerFromAgencyPayload,
} from '../../../../types';
import {
  Typography,
} from '../../../../common/components';

interface IRowItemProps {
  agency: AgencyPartnershipDetails;
  partnerLoading: boolean;
  onEditPartnerAgencyChoices: (data: EditPartnerAgencyChoicePayload) => void;
  agencyId: number;
  onAddPartnerToAgency: (data: AddPartnerToAgencyPayload) => void;
  onRemovePartnerFromAgency: (data: RemovePartnerFromAgencyPayload) => void;
}

export const RowItem: React.FC<IRowItemProps> = ({
  agency, partnerLoading, onEditPartnerAgencyChoices,
  agencyId, onAddPartnerToAgency, onRemovePartnerFromAgency,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [showingCheck, setShowingCheck] = useState(!!agency.isShowingAssistance);
  const [houseCheck, setHouseCheck] = useState(!!agency.isHostOpenHouse);

  return (
    <Tr>
      {renderTableBody(agency.isPartner ? colors.primary : colors.gray, agency.name)}
      {renderTableBody(agency.isPartner ? colors.primary : colors.gray, agency.email || '')}
      <Td maxW="200px">
        {editMode ? (
          <VStack align="left">
            <Checkbox colorScheme="facebook" borderColor={colors.primary} isChecked={showingCheck} onChange={(e) => setShowingCheck(e.target.checked)}><Typography size={16}>Showing Assistance</Typography></Checkbox>
            <Checkbox colorScheme="facebook" borderColor={colors.primary} isChecked={houseCheck} onChange={(e) => setHouseCheck(e.target.checked)}><Typography size={16}>Host Open House</Typography></Checkbox>
          </VStack>
        )
          : (
            <VStack align="left">
              <Typography size={16}>
                {agency.isShowingAssistance ? `Showing Assistance${agency.isHostOpenHouse ? ',' : ''}` : ''}
              </Typography>
              <Typography size={16}>
                {agency.isHostOpenHouse ? 'Host Open House' : ''}
              </Typography>
            </VStack>
          )}
      </Td>
      <Td isNumeric>
        {partnerLoading
          ? <Spinner size="sm" />
          : (
            <>
              {editMode ? (
                <Flex>
                  <Text
                    as="button"
                    type="button"
                    width="auto"
                    onClick={() => {
                      if (agency.isPartner) {
                        if (showingCheck || houseCheck) {
                          onEditPartnerAgencyChoices({
                            partnerId: agency.id, isHostOpenHouse: houseCheck, isShowingAssistance: showingCheck, agencyId,
                          });
                        } else {
                          onRemovePartnerFromAgency({ AgencyId: agencyId, PartnerId: agency.id });
                        }
                      } else if (showingCheck || houseCheck) {
                        onAddPartnerToAgency({
                          AgencyId: agencyId, IsHostOpenHouse: houseCheck, IsShowingAssistance: showingCheck, PartnerId: agency.id,
                        });
                      }
                      setEditMode(false);
                    }}
                    fontSize={16}
                    fontFamily={fonts.roboto}
                    color={agency.isPartner ? colors.primary : colors.gray}
                    textDecoration="underline"
                    textAlign="left"
                  >
                    Save
                  </Text>
                </Flex>
              ) : (
                <>
                  {agency.isPartner ? (
                    <VStack align="flex-right">
                      <Text as="button" type="button" onClick={() => { setEditMode(true); }} fontSize={16} fontFamily={fonts.roboto} color={agency.isPartner ? colors.primary : colors.gray} textDecoration="underline" width="auto" textAlign="left">Edit</Text>
                      <Text
                        as="button"
                        type="button"
                        width="auto"
                        onClick={() => { onRemovePartnerFromAgency({ AgencyId: agencyId, PartnerId: agency.id }); }}
                        fontSize={16}
                        fontFamily={fonts.roboto}
                        color={agency.isPartner ? colors.primary : colors.gray}
                        textDecoration="underline"
                        textAlign="left"
                      >
                        Remove
                      </Text>
                    </VStack>
                  )
                    : (
                      <Flex>
                        <Text
                          as="button"
                          type="button"
                          width="auto"
                          onClick={() => {
                            setEditMode(true);
                          }}
                          fontSize={16}
                          fontFamily={fonts.roboto}
                          color={agency.isPartner ? colors.primary : colors.gray}
                          textDecoration="underline"
                        >
                          Add
                        </Text>
                      </Flex>
                    )}
                </>

              )}
            </>
          )}
      </Td>
    </Tr>
  );
};
