import React, { FC } from 'react';

interface IProps {
  fill?: string;
  width?: string;
  height?: string;
}

export const CloseIcon: FC<IProps> = ({
  fill,
  width = '11',
  height = '11',
}) => (
  <svg width={width} height={height} viewBox="0 0 11 11" fill={'none'}>
    <path
      d="M4.60551 5.50024L0.777292 1.67274C0.529965 1.42546 0.529965 1.02454 0.777292 0.777257C1.02462 0.529977 1.42561 0.529977 1.67294 0.777257L5.50115 4.60476L9.32732 0.779302C9.57465 0.532022 9.97564 0.532022 10.223 0.779302C10.4703 1.02658 10.4703 1.4275 10.223 1.67478L6.3968 5.50024L10.2227 9.32548C10.4701 9.57276 10.4701 9.97368 10.2227 10.221C9.97542 10.4682 9.57443 10.4682 9.3271 10.221L5.50115 6.39572L1.67316 10.223C1.42583 10.4703 1.02484 10.4703 0.77751 10.223C0.530183 9.97572 0.530183 9.5748 0.77751 9.32752L4.60551 5.50024Z"
      fill={fill || '#2C335C'}
    />
  </svg>
);
