import React, { Dispatch, SetStateAction } from 'react';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Center, Box,
} from '@chakra-ui/react';

import { colors } from '../../../utils';
import { TrashIcon } from '../../../assets/icons';
import { CustomButton, Typography } from '../../../common/components';

type IDashboardDeleteModalProps = {
  text: string;
  title: string;
  isOpen: boolean;
  onClick: () => void;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
};

const DashboardDeleteModal:React.FC<IDashboardDeleteModalProps> = ({
  isOpen, setIsModalOpen, onClick, title, text,
}) => {
  const handleDeleteMember = () => {
    onClick();
    setIsModalOpen(false);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => setIsModalOpen(false)}
        autoFocus={false}
        isCentered
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader alignItems="center" d="flex">
            <Typography weight={600} size={16}>
              {title}
            </Typography>
            <ModalCloseButton color={colors.gray} />
          </ModalHeader>
          <ModalBody pt="104px" pb="77px">
            <Center flexDir="column">
              <Box mb="15px">
                <TrashIcon />
              </Box>
              <Typography
                color={colors.gray}
                size={14}
              >
                {text}
              </Typography>
            </Center>
          </ModalBody>
          <ModalFooter justifyContent="space-between">
            <Box width="47%">
              <CustomButton
                variant="outline"
                background={colors.white}
                color={colors.primary}
                onClick={() => {
                  setIsModalOpen(false);
                }}
              >
                Cancel
              </CustomButton>
            </Box>
            <Box width="47%">
              <CustomButton onClick={handleDeleteMember}>
                Yes
              </CustomButton>
            </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DashboardDeleteModal;
